import React, {useState, useEffect, useRef} from 'react';
import classes from './Waitlist.module.css';

import NotifyIcon from '../../../assets/images/icons/booking-notify.png';
import SuccessIcon from '../../../assets/images/icons/successcheck.png';

import CustomSelect from '../../UI/CustomSelect/CustomSelect';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';

import { useTranslation } from 'react-i18next';

import customerDetails from '../../UserData';

import axios, {config} from '../../../axiosBase';
import {EmailPattern} from '../../../Formats';

import consoleLog from '../../../log';

const Waitlist = (props) => {
	consoleLog(props)
	const [ticketOptions, setTicketOptions] = useState('');
	const [categories, setCategories] = useState('');
	const [submitted, setSubmitted] = useState(false);

	const [chosenCategory, setChosenCategory] = useState('');
	const [chosenQuantity, setChosenQuantity] = useState(0);

	const inputRef = useRef();
	const { t, i18n } = useTranslation();

    const [seeFilters, setSeeFilters] = useState(false);

	const [error, setError] = useState(false);
    const [listOfErrors, setListOfErrors] = useState([]);

	const setOptions = (total, options) => {
		const optionsArray = [];
		for(let i=0;i<total;i++){
			optionsArray.push(
				<option key={options[i]} value={options[i]}>{options[i]}</option>
			)
		}
		return optionsArray;
	}

	useEffect(()=>{
		const totalTickets = 10;
		const options = [];
		for(let i=0;i<totalTickets;i++){
			options.push({name: i+1, value: i+1});
		}
		//const optionsArray = setOptions(options.length, options)
		setTicketOptions(options);
	},[])


	useEffect(()=>{
		const categoriesLength = props.categories.length;
		const options = [];
		options.push({name: t('All'), value: t('All')})
		for(let i=0;i<categoriesLength;i++){
			options.push({name: props.categories[i].name, value: props.categories[i].name});
		}
		//const optionsArray = setOptions(options.length, options)
		setCategories(options);
	},[])

	useEffect(()=>{
		if(customerDetails){
			consoleLog(customerDetails)
			inputRef.current.value = customerDetails.data.email;
		}
	},[customerDetails])

	const addToWaitlist = (e) => {
		e.preventDefault();
		const email = e.target.email.value;
		let errorList = [];

		if(email == '' /*|| chosenQuantity == 0*/){
			errorList.push({"":t("Please_fill_in_all_the_fields")})
            setListOfErrors(errorList);
            setError(true);
            return false;
		}
		var pattern = EmailPattern;
        if (!pattern.test(email)) {
			errorList.push({
				[t("email")]: t("Please_enter_a_valid_e-mail")
			})
            setListOfErrors(errorList);
            setError(true);
            return false;
        }

		const payload = {
		    "id_product": props.product_id,
		    //"aantal": chosenQuantity,
		    //"categorie": chosenCategory,
		    "customer_email": email
		}

		axios.post('/waitinglist?a=register',payload ,config)
		.then(function(res){
			setSubmitted(true);
		})
		.catch(function(err){
			errorList.push({"":t("some_error_occured")})
            setListOfErrors(errorList);
            setError(true);
            return false;
		})
	}


	return (
		<div className={classes.WaitlistBlock}>
			{ submitted ?
			<React.Fragment>
			<div className={classes.BlockHead}>
				<div className={classes.Title}>{t('Waiting_List_Information')}</div>
				<div className={classes.SuccessInfo}>
					<div className={classes.Success}>{t('Success')}&nbsp;<img src={SuccessIcon} alt="" /></div>
					{t('register_for_the_waiting_list')}
				</div>
				<div className={classes.ClickOutsideInfo}>{t('Click_anywhere_to_go_back_to_the_event_page')}</div>
			</div>
			</React.Fragment>
			:
			<React.Fragment>
			<div className={classes.BlockHead}>
				<div className={classes.Title}>{t('Waiting_List_Information')}</div>
				<div className={classes.Info}>{t('You_will_automatically_recieve_an_e-mail_when_tickets_are_available')}</div>
			</div>
			<div className={classes.WaitlistForm}>
				<form onSubmit={addToWaitlist}>
					<div className={classes.InputBlock}>
						<input ref={inputRef} type="email" placeholder={t('Your_e-mail_address')} name="email" />
					</div>
					{ seeFilters ?
					<div className={[classes.InputBlock, classes.Filters].join(' ')}>
						{/*<select name="category">
							{categories}
						</select>
						<select name="ticket_quantity">
							{ticketOptions}
						</select>*/}
						<CustomSelect
                                optionsList={categories}
                                styling={{
                                    container: { width: "50%",
                                				display: "inline-block",
                                				marginRight: "10px"},
                                    defaultText: { background: "var(--white)",
                                                    padding: "5px 20px",
                                                    color: "var(--black)",
                                                  	border: "2px solid var(--gray)",
													boxSizing: "border-box",
													outline: "none",
                                                    borderRadius: "10px" },
                                    ul: {},
                                    li: { background: "var(--white)",
                                            color: "var(--black)",
                                            border: "1px solid var(--black)"}
                                }}
                                defaultText={t("Category")}
                                changed={setChosenCategory}
                            />
						<CustomSelect
                                optionsList={ticketOptions}
                                styling={{
                                    container: { width: "35%",
                                				display: "inline-block",
                                				marginLeft: "10px"},
                                    defaultText: { background: "var(--white)",
                                                    padding: "5px 20px",
                                                    color: "var(--black)",
                                                  	border: "2px solid var(--gray)",
													boxSizing: "border-box",
													outline: "none",
                                                    borderRadius: "10px" },
                                    ul: {},
                                    li: { background: "var(--white)",
                                            color: "var(--black)",
                                            border: "1px solid var(--black)"}
                                }}
                                changed={setChosenQuantity}
                                defaultText={t("number_of_tickets")}
                            />
					</div>
					: <>{/*<button type="button" className={classes.ShowFilter} onClick={()=>setSeeFilters(true)}>{t('show_filters')}</button>*/}</>
					}
					<div className={classes.SubmitButton}>
						<button type="submit"><img src={NotifyIcon} alt="Notify Icon"/>&nbsp;{t('Notify_me')}</button>
					</div>
				</form>
			</div>
			</React.Fragment>
		}
		{error &&
            <ErrorBox data={{ "errorTitle": t("Error(s)"), errorSubTitle: t("please_resolve_your_errors"), errors: listOfErrors, acceptText: "OK", acceptAction: () => { setError(false) } }} />
        }
		</div>
	);
}

export default Waitlist;