import React, {useState} from 'react'
import classes from './Service.module.css'
import about1 from '../../../assets/images/icons/about1.png'
import about3 from '../../../assets/images/icons/about3.png'
import about2 from '../../../assets/images/icons/about2.png'

import { useTranslation } from 'react-i18next';
import ContactUs from '../../ContactUsBox/ContactUs';

export default function Service() {

    const { t, i18n } = useTranslation();
    const [showContactBox, setShowContactBox] = useState(false);

    return (
        <div className={classes.ServiceCont}>
            <div className={classes.Service1}>
                <p className={classes.AboutHead}>Service</p>
                <p>
                    {t('service_kmt_1')}
                </p>
            </div>
            <div className={classes.AboutDiv}>
                <div className={classes.AboutDiv1}>
                    <p>
                        {t('service_kmt_head_1')}
                    </p>
                    <p>
                        {t('service_kmt_2')}
                    </p>
                </div>
                <div className={classes.AboutDiv2}><img className={classes.AboutImg} src={about1} alt="" /></div>
            </div>
            <div className={classes.AboutDiv}>
                <div className={classes.AboutDiv2}><img className={classes.AboutImg} src={about2} alt="" /></div>
                <div className={classes.AboutDiv1}>
                    <p>
                        {t('service_kmt_head_2')}
                    </p>
                    <p>
                        {t('service_kmt_3')}
                    </p>
                </div>
            </div>
            <div className={classes.AboutDiv}>
                <div className={classes.AboutDiv1}>
                    <p>
                        {t('service_kmt_head_3')}
                    </p>
                    <p>
                        {t('service_kmt_4')}
                    </p>
                </div>
                <div className={classes.AboutDiv2}><img className={classes.AboutImg} src={about3} alt="" /></div>
            </div>
            <div className={classes.AboutHr}></div>
            <p className={classes.AboutLastp}>
                
            </p>
            <p>
                {t('service_kmt_5_1')}
                <a onClick={()=>(setShowContactBox(true))}>{t('service_kmt_5_2')}</a>
                {t('service_kmt_5_3')}
            </p>
            {
                showContactBox ?
                    <ContactUs
                        hideContact={()=>setShowContactBox(false)}
                        pageDetails="About Page Contact - " // for internal use
                    /> : null
            }
        </div>
    )
}
