import React from 'react'
import './App.css';
import Header from './components/Header/Header'
import Landing from './components/Home/Landing'
import { BrowserRouter, Switch } from "react-router-dom";
import AccountListWrapper from '../src/components/AccountListWrapper/AccountListWrapper'
import Events from '../src/components/Events/Events'
import SellTickets from './components/SellTickets/SellTickets';
import SellSearch from './components/SellSearch/SellSearch';
import Sales from './components/Sales/Sales';
import MyListings from './components/MyListings/MyListings';
import Footer from './components/Footer/Footer';
import Privacy from './components/Privacy/Privacy';
import TermsConditions from './components/TermsConditions/TermsConditions';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Impressum from './components/Impressum/Impressum';
import FAQ from './components/FAQ/FAQ';
import SearchResults from './components/SearchResults/SearchResults';
import ResetPassword from './components/ResetPassword/ResetPassword';
import HowItWorks from './components/HowItWorks/HowItWorks';
import Deregister from './components/DeregisterWaitingList/Deregister';
import DownloadTickets from './components/DownloadTickets/DownloadTickets';
import Contact from './components/ContactUsPage/ContactUs';
import OrderCompletion from './components/Events/OrderCompletion/OrderCompletion';
import SaleCompletion from './components/SellTickets/SaleCompletion/SaleCompletion';

import { HOME, SETTINGS, LISTINGS, ABOUT, ORDERS, EVENT, ARTIST, EVENT_STRING, FESTIVAL_STRING, THEATER_STRING, SPORT_STRING, SELL, SELLFORM, SALES, PRIVACYPOLICY, TERMS, FAQS, IMPRESSUM, SEARCHRESULTS, RESETPASSWORD, HOWITWORKS, DEREGISTER, DOWNLOADTICKETS, CONTACT, ORDERCONFIRM, SALECONFIRM } from './URLS';

function App() {

  const langPrefix = "/:lang?";
  const hashSuffix = "/:hash?";

  return (
    <div className="AppRoot">
      <BrowserRouter>
      <Header />
      <div className="AppBackground">
        <div className="AppFront">
          {/* Setting routing for all the components. */}
            <Switch>
              <PrivateRoute exact path={langPrefix+HOME} component={Landing} />
              {/* Common component for Settings and MyPurchases. */}
              <PrivateRoute exact path={langPrefix+SETTINGS} component={() => <AccountListWrapper name="SETTINGS" />} />
              <PrivateRoute exact path={langPrefix+LISTINGS} component={MyListings} />
              <PrivateRoute exact path={langPrefix+ABOUT} component={() => <AccountListWrapper name="ABOUT_US" />} />
              <PrivateRoute exact path={langPrefix+ORDERS} component={() => <AccountListWrapper name="MY_ORDERS" />} />
              {/* EVENT PAGE URLS */}
              <PrivateRoute exact path={langPrefix+EVENT_STRING+EVENT} component={Events} />
              <PrivateRoute exact path={langPrefix+FESTIVAL_STRING+EVENT} component={Events}/>
              <PrivateRoute exact path={langPrefix+THEATER_STRING+EVENT} component={Events}/>
              <PrivateRoute exact path={langPrefix+SPORT_STRING+EVENT} component={Events}/>
              {/* EVENT PAGE URLS */}
              {/*<PrivateRoute path={EVENT} component={Events} exact /> */}
              <PrivateRoute exact path={langPrefix+SELL} component={SellSearch} />
              <PrivateRoute exact path={langPrefix+SELLFORM} component={SellTickets} />
              {/*<Route exact path={SALES} component={Sales} />*/}
              <PrivateRoute path={langPrefix+SALES} component={Sales} exact />
              <PrivateRoute exact path={langPrefix+PRIVACYPOLICY} component={Privacy} />
              <PrivateRoute exact path={langPrefix+TERMS} component={TermsConditions} />
              <PrivateRoute exact path={langPrefix+FAQS} component={FAQ} />
              <PrivateRoute exact path={langPrefix+IMPRESSUM} component={Impressum} />
              <PrivateRoute exact path={langPrefix+SEARCHRESULTS} component={SearchResults}/>
              {/* ARTIST PAGE URLS */}
              <PrivateRoute exact path={langPrefix+EVENT_STRING+ARTIST} component={SearchResults}/>
              <PrivateRoute exact path={langPrefix+FESTIVAL_STRING+ARTIST} component={SearchResults}/>
              <PrivateRoute exact path={langPrefix+THEATER_STRING+ARTIST} component={SearchResults}/>
              <PrivateRoute exact path={langPrefix+SPORT_STRING+ARTIST} component={SearchResults}/>
              {/* ARTIST PAGE URLS */}
              <PrivateRoute exact path={langPrefix+RESETPASSWORD} component={ResetPassword} />
              <PrivateRoute exact path={langPrefix+HOWITWORKS} component={HowItWorks} />
              <PrivateRoute exact path={langPrefix+DEREGISTER+hashSuffix} component={Deregister} />
              <PrivateRoute exact path={langPrefix+DOWNLOADTICKETS} component={DownloadTickets} />
              <PrivateRoute exact path={langPrefix+HOME} component={Landing} />
              <PrivateRoute exact path={langPrefix+CONTACT} component={Contact} />
              <PrivateRoute exact path={langPrefix+ORDERCONFIRM} component={OrderCompletion} />
              <PrivateRoute exact path={langPrefix+SALECONFIRM} component={SaleCompletion} />
            </Switch>
        </div>
        <Footer />
      </div>
      </BrowserRouter>
    </div >
  );
}

export default App;
