import React, {useState, useEffect} from 'react'
import { Route, Redirect } from "react-router-dom";

import customerDetails, {storage, token, refresh_token} from '../UserData';

import {RESTRICTED_URLS, HOME} from '../../URLS';
import {encryptWithAES} from '../Encrypt/AES';
import axios, { config } from '../../axiosBase';
import consoleLog from '../../log';

import Cookies from 'universal-cookie';

async function refreshUserToken(_refresh_token){
    const payload = {
        "refresh_token": _refresh_token
    }
    const data = await axios.post('/jwt?a=refresh', payload, config);
    consoleLog(data)
    if (data.data.success) {
        storage.setItem("userId", encryptWithAES(data.data.token+":"+data.data.refresh_token))
        return data.data.token
    }
}

const checkForTokenExpire = (tokenExpDate, currentDate, storage) => {
    const cookies = new Cookies();
    const cookieTime = cookies.get('loginTime')
    if(
        (currentDate > tokenExpDate) ||
        (!cookieTime ) ||
        (currentDate > cookieTime)
    ){
        // Login Again
        storage.removeItem("userId");
        return false;
    }else{
        return true;
    }
}

const checkForLogin = async () => {
    const currentDate = Math.floor(new Date().getTime()/1000);
    if(storage !== null){
        if(checkForTokenExpire(customerDetails.exp, currentDate, storage)){
            return token
        }else{
            const new_token = await refreshUserToken(refresh_token)
            consoleLog(new_token)
            return new_token
        }
    }else{
        return false;
    }
}

const PrivateRoute = ({ component: Component, ...rest }) => {

    const [allow, setAllow] = useState(null);
    const [restriced, setRestriced] = useState(null);

    useEffect(() => {
        const currentDate = Math.floor(new Date().getTime()/1000);

        if(customerDetails){
            // Do nothing
        }
        else{
            setAllow(false);
        }

        if(storage !== null){
            //const status = checkForTokenExpire(customerDetails.exp, currentDate, storage) WITHOUT REFRESH FUNCTION
            checkForLogin().then(function(token){
                if(token){
                    setAllow(true);
                }
                else{
                    setAllow(false);
                }
            }) // WITH REFRESH FUNCTIONALITY
        }
        let currentLanguage = localStorage.getItem("i18nextLng")
        let checkURL = rest.location.pathname

        if(!RESTRICTED_URLS.includes(checkURL)){
            setRestriced(false);
        }else{
            setRestriced(true);
        }

    },[])

const callURL = () => {
     consoleLog(restriced, allow, window.location.pathname, "IN PrivateRoute");
     window.location.href = HOME;
}

    return (
        <>
        {allow !== null ?
        <Route {...rest} render={props => (
            restriced ?
            allow ? <Component {...props} /> :  callURL()
            : <Component {...props} />
        )} />
        : null }
        </>
    );
};

export default PrivateRoute;
export {checkForLogin};