import React, {useState, useEffect} from 'react';
import classes from './Cart.module.css';

import { useTranslation } from 'react-i18next';
import axios, {token_config} from '../../axiosBase';
import customerDetails from '../UserData';
import {checkForLogin} from '../PrivateRoute/PrivateRoute';

import {EVENT_STRING, LANG_GERMAN} from '../../URLS';

import TicketIcon from '../../assets/images/icons/hard-ticket-dark.png';
//import DeleteIcon from '../../assets/images/icons/delete.png';

import {deleteCart} from '../Events/MakePayment/Payments/PostPayment';
import consoleLog from '../../log';

export default function Cart(props) {

    const { t, i18n } = useTranslation();
    const [cartItems, setCartItems] = useState([]);
    const [event, setEvent] = useState({});
    const linkLangCode = localStorage.getItem("i18nextLng") === LANG_GERMAN ? 4 : 7;

    useEffect(()=>{

        checkForLogin().then(function(token){
        if(token){
            const config = token_config(token);
            const cart_id = sessionStorage.getItem("cart")
            axios.get('/carts?output_format=JSON&display=full&filter[id]=['+cart_id+']', config)
            .then(function(data){
                if(data.data.carts && data.data.carts.length){
                    const cartItem = data.data.carts[data.data.carts.length-1];
                    setCartItems([cartItem])
                    axios.get('/products/'+cartItem.associations.cart_rows[0].id_product+'?output_format=JSON', config)
                    .then(function(data){
                        if(data.data.product){
                            let cartEvent = {
                                name: data.data.product.name[0].value,
                                category_name: data.data.product.category_name,
                                location: data.data.product.location
                            }
                            if(data.data.product.associations){
                                const combs = data.data.product.associations.combinations;
                                for(let i=0; i < combs.length; i++){
                                    if(combs[i].id == cartItem.associations.cart_rows[0].id_product_attribute){
                                        cartEvent["price"] = combs[i].price;
                                        break;
                                    }
                                }
                                setEvent(cartEvent)
                            }
                        }
                    })
                }
            })
            .catch(function(err){
                consoleLog(err)
            })            
        }})
        
    },[])

    const reloadAfterSeconds = (n) => {
        setTimeout(function(){
            window.location.reload()
        },n*1000)
    }

    const modifyArtistForURL = (artist_name) => {
        if(artist_name && artist_name != ""){
            artist_name = artist_name.replaceAll(' ','-')
            artist_name = artist_name + "-karten"
            return artist_name
        }else{
            return ""
        }
    }


    return (
        <div className={props.mobileV ? classes.Mid : null} >
            <div className={classes.CartCont}>
                {
                    cartItems.length && cartItems[0].associations && cartItems[0].associations.cart_rows ?
                        <>
                        {cartItems.map(function(item, index){
                            return <p className={classes.CartItems}>
                            {item.associations && item.associations.cart_rows ?
                                item.associations.cart_rows.map(function(value, index){
                                    return <a href={
                                        EVENT_STRING
                                        +modifyArtistForURL(event.category_name)+"/"
                                        +event.location+"/"
                                        +cartItems[cartItems.length-1].associations.cart_rows[0].id_product+"?cart_ticket="+cartItems[cartItems.length-1].associations.cart_rows[0].id_product_attribute
                                    }>
                                        <div className={classes.CartItem}>
                                            <div>{value.quantity} X<img src={TicketIcon} alt="" /></div>
                                            <div>
                                                <div>{ event.name }</div>
                                                <div>€ { event.price ? event.price.toFixed(2).toString().replace('.',',') : "0,00" } / {t('ticket_cart')}</div>
                                            </div>
                                        </div>
                                        </a>
                                })
                            : t('cart_empty')
                            }</p>
                        })}
                        <div className={classes.Total}>Total <span>€ { event.price ? (parseFloat(event.price)*parseInt(cartItems[cartItems.length-1].associations.cart_rows[0].quantity)).toFixed(2).toString().replace('.',',') : "0,00" }</span></div>
                        <div className={classes.Checkout}>
                            <button
                                onClick={()=>{
                                    window.location.href=
                                        EVENT_STRING
                                        +modifyArtistForURL(event.category_name)+"/"
                                        +event.location+"/"
                                        +cartItems[cartItems.length-1].associations.cart_rows[0].id_product+"?cart_ticket="+cartItems[cartItems.length-1].associations.cart_rows[0].id_product_attribute}}>{t('checkout')}</button>
                            <button onClick={()=>{deleteCart(cartItems[0].id); reloadAfterSeconds(1.5);} }>{t('delete')}</button>
                        </div>
                        </>
                    : <>
                        <p className={props.mobileV ? [classes.Black, classes.P1].join(" ") : classes.P1}>{t('cart_empty')}</p>
                        <p>{t('cart_box_text')}</p>                        
                    </>
                }
                <p onClick={() => { props.closeIt() }} className={props.mobileV ? [classes.Black, classes.P3].join(" ") : classes.P1}> <u>{t('back')}</u>  </p>
            </div>
        </div>
    )
}