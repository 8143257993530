import {getEventDateMinus} from '../SellTickets/ShippingData';

let today_date = new Date();

export const removePastEvents = (events) => {
    const data = [];

    for(let i=0; i<events.length; i++){
        let events_date = getEventDateMinus(events[i].event_date)
        if(today_date < events_date){
            data.push(events[i])
        }
    }
    return data
}
