export const ShippingOptionsDefault = JSON.stringify({
        "german": {
            "active": false,
            "methods": [
                {
                    "method": "registered_mail",
                    "active": false,
                    "active_until": ""
                },
                {
                    "method": "DHL",
                    "active": false,
                    "active_until": "",
                    "status": ""
                },
                {
                    "method": "pickup_at_venue",
                    "active": false,
                    "active_until": ""
                }
            ]
        },
        "nonGerman": {
            "active": false,
            "methods": [
                {
                    "method": "international_shipment",
                    "active": false,
                    "active_until": ""
                },
                {
                    "method": "pickup_at_venue",
                    "active": false,
                    "active_until": ""
                }
            ]
        }
    })

export const ShippingMethodsCode = {
    "registered_mail": "1",
    "DHL": "2",
    "pickup_at_venue": "3"
}

const listingInactivateData = {
    "0": [5],
    "1": [4],
    "2": [5],
    "3": [3],
    "4": [3],
    "5": [3],
    "6": [4]
} // KEYS : Days of Event.  // Values : Days to be reduced from the event date <== Derived from table below

/*"0": [4],
    "1": [4],
    "2": [0],
    "3": [1],
    "4": [2],
    "5": [2],
    "6": [2]*/
 // KEYS : Days of Event. // Values : Days of Inactivation

let today = new Date();

export const get_date_position = (date, position) => {
    let split_by_hifun = date.split('-')

    if(split_by_hifun.length == 1){
        let split_by_dot = date.split('.')
        if(split_by_dot.length == 1){
            return null
        }else{
            return split_by_dot[position]
        }
    }else{
        return split_by_hifun[position]
    }
}

export const getEventDateMinus = (event_date, days=0, months=0, years=0) => {
    return new Date(get_date_position(event_date.split(' ')[0], 2) - years // year
        ,get_date_position(event_date.split(' ')[0], 1)-1 - months // month
        ,get_date_position(event_date.split(' ')[0], 0) - days // date
        ,event_date.split(' ')[1].split(':')[0]
        ,event_date.split(' ')[1].split(':')[1]
        )
}

const calculateShippingOptions = (eventState, shippingOptions) => {
    let today_date = today
    let events_date = getEventDateMinus(eventState.event_date)

    let events_date_minus_month = getEventDateMinus(eventState.event_date, 0, 1)

    let events_date_minus_14_days = getEventDateMinus(eventState.event_date, 14)
    let events_date_minus_14_days_15_hours = events_date_minus_14_days;
    events_date_minus_14_days_15_hours.setHours(15)

    let events_date_minus_7_days = getEventDateMinus(eventState.event_date, 7)
    let events_date_minus_7_days_15_hours = events_date_minus_7_days
    events_date_minus_7_days_15_hours.setHours(15)

    let dayOfTheEvent = new Date(Date.parse(events_date)).getDay();
    let daysToBeReduced = listingInactivateData[dayOfTheEvent];
    let dateOfInactivation = getEventDateMinus(eventState.event_date, daysToBeReduced);
    dateOfInactivation.setHours(15); // setting 3 PM as the inactive time
    
    let today_plus_7 = new Date();
    let today_plus_14 = new Date();

    if(eventState.event_date){
        if(today_plus_7.setDate(today_date.getDate()+7) < events_date && today_date < events_date_minus_7_days_15_hours){
            shippingOptions["german"]["methods"][0]["active"] = true
            shippingOptions["german"]["methods"][0]["active_until"] = events_date_minus_7_days.toLocaleDateString('en-GB').replaceAll('/','-') + " 15:00"
        }
        if(today_plus_14.setDate(today_date.getDate()+14) < events_date && today_date < events_date_minus_14_days_15_hours){
            shippingOptions["nonGerman"]["methods"][0]["active"] = true
            shippingOptions["nonGerman"]["methods"][0]["active_until"] = events_date_minus_14_days.toLocaleDateString('en-GB').replaceAll('/','-') + " 15:00"
        }
        if(today_date > events_date_minus_month && today_date < dateOfInactivation){
            shippingOptions["german"]["methods"][1]["active"] = true
            shippingOptions["german"]["methods"][1]["active_until"] = dateOfInactivation.toLocaleDateString('en-GB').replaceAll('/','-') + " 15:00"
        }
        if(today_date < events_date_minus_month){
            shippingOptions["german"]["methods"][1]["status"] = "PRE"
        }
        if(today_date > dateOfInactivation){
            shippingOptions["german"]["methods"][1]["status"] = "POST"
        }
        if(today_date > events_date_minus_14_days && today_date < events_date){
            shippingOptions["german"]["methods"][2]["active"] = true
            shippingOptions["german"]["methods"][2]["active_until"] = eventState.event_date

            shippingOptions["nonGerman"]["methods"][1]["active"] = true                    
            shippingOptions["nonGerman"]["methods"][1]["active_until"] = eventState.event_date
        }
    }

    return shippingOptions;
}

export const prepareShippingMethods = (userAddressesData, eventState, shippingOptions) => {
    if(userAddressesData.data.addresses[0].id_country === "1"){
        shippingOptions["german"]["active"] = true
        shippingOptions["nonGerman"]["active"] = false
    }else{
        shippingOptions["german"]["active"] = false
        shippingOptions["nonGerman"]["active"] = true
    }
    shippingOptions = calculateShippingOptions(eventState, shippingOptions);
    return shippingOptions;
}


const checkShippingMethodAvailability = (ticketDetails, shippingOptions, shippingOrigin) => {
    const shipping_method_index_german = {
        "0": ShippingMethodsCode.registered_mail,
        "1": ShippingMethodsCode.DHL,
        "2": ShippingMethodsCode.pickup_at_venue
    }
    const shipping_method_index_non_german = {
        "0": ShippingMethodsCode.registered_mail,
        "1": ShippingMethodsCode.pickup_at_venue
    }

    let n = 3;
    let shipping_indexes = shipping_method_index_german;
    if(shippingOrigin === "nonGerman"){
        n = 2;
        shipping_indexes = shipping_method_index_non_german;
    }
    for(let i=0; i<n; i++){
        if(shippingOptions[shippingOrigin]["methods"][i]["active"]){
            if(ticketDetails.shipping_methods && ticketDetails.shipping_methods.includes(shipping_indexes[i])){
                shippingOptions[shippingOrigin]["methods"][i]["active"] = true
            }else{
                shippingOptions[shippingOrigin]["methods"][i]["active"] = false
            }
        }
    }
    return shippingOptions
}

export const prepareShippingMethodsBuying = (buyer_country, sellerCountry, eventState, shippingOptions, ticketDetails) => {
    let shippingOrigin = "german";

    if(buyer_country === "1" && sellerCountry === "1"){
        shippingOptions["german"]["active"] = true
        shippingOptions["nonGerman"]["active"] = false
    }else{
        shippingOptions["nonGerman"]["active"] = true
        shippingOptions["german"]["active"] = false
        shippingOrigin = "nonGerman"
    }
    shippingOptions["buyer_country"] = buyer_country;
    shippingOptions["seller_country"] = sellerCountry;

    shippingOptions = calculateShippingOptions(eventState, shippingOptions);
    shippingOptions = checkShippingMethodAvailability(ticketDetails, shippingOptions, shippingOrigin);
    return shippingOptions;
}