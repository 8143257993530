import React, { useState, useEffect } from 'react';
import SellTicketsSearch from '../SellTicketsSearch/SellTicketsSearch';
import classes from './SellSearch.module.css';
import logo from '../../assets/images/logo/kaufmeinticket_logo.png';
import USP1Icon from '../../assets/images/icons/1iconsmisc.png';
import USP2Icon from '../../assets/images/icons/secure-shield.png';
import USP3Icon from '../../assets/images/icons/3iconsmisc2.png';
import {HOWITWORKS, SELLFORM} from '../../URLS';
import { useTranslation } from 'react-i18next';
import ContactUs from '../ContactUsBox/ContactUs';
import customerDetails from '../UserData';

import consoleLog from '../../log';

const SellSearch = (props) => {

	const { t, i18n } = useTranslation();
	const [showSearchBar, setShowSearchBar] = useState(false);
	const [showContactBox, setShowContactBox] = useState(false);


    useEffect(()=>{
    	consoleLog(sessionStorage.getItem("sell_search_proceed_props"),customerDetails,props)
        if(sessionStorage.getItem("sell_search_proceed_props") && customerDetails){
            const data = JSON.parse(sessionStorage.getItem("sell_search_proceed_props"));
            sessionStorage.removeItem("sell_search_proceed_props");
            consoleLog(data,"IN USE EFFECT")
            props.history.push({
              pathname: SELLFORM,
              //search: '?query=abc',
              state: data.id
            })
        }
    })

	//const [simpleSearch, setSimpleSearch] = useState(true);	// For toggling between simple and advanced search
	// Advanced search will be removed once finalized


	// Toggle between simple and advanced search
	/*const searchTypeToggle = () => {
		setSimpleSearch(!simpleSearch);
	}*/

	return (
		<div className={classes.Jumbotron}>
			<div className={classes.Jumbotron2}>
				<div className={classes.Jumbotron3}>
					<div className={classes.LogoContainer}>
						<span>{t('Sell_on')}</span>
						<img onClick={() => { window.location.pathname = '/' }} className={classes.Logo} src={logo} alt="" />
					</div>
					{ showSearchBar ?
						<>
						<SellTicketsSearch />
						<div className={classes.Help}>
							<div><a href={HOWITWORKS}>{t('How_it_works_2')}</a></div>
							<div>{t('Need_help')}&nbsp;<a onClick={()=>(setShowContactBox(true))}>{t('Contact_Us')}</a>.</div>
						</div>
						</>
					: <button className={classes.SellTicketBtn} onClick={()=>setShowSearchBar(true)}>{t("sell_your_tickets")}</button> }
				</div>
			</div>
			<hr />
			<div className={classes.KaufmeinticketUSP}>
				<div className={classes.USP}>
					<div className={classes.USPIcon}>
						<img src={USP1Icon} alt="" />
					</div>
					<div className={classes.USPText}>
						<div>{t('Headline1')}</div>
						<div>
							{t('SellText1')}
						</div>
					</div>
				</div>
				<div className={classes.USP}>
					<div className={[classes.USPIcon, classes.MobileHide].join(' ')}>
						<img src={USP2Icon} alt="" />
					</div>
					<div className={classes.USPText}>
						<div>{t('Headline2')}</div>
						<div>
							{t('SellText2')}
						</div>
					</div>
					<div className={[classes.USPIcon, classes.USPIconMobile].join(' ')}>
						<img src={USP2Icon} alt="" />
					</div>
				</div>
				<div className={classes.USP}>
					<div className={classes.USPIcon}>
						<img src={USP3Icon} alt="" />
					</div>
					<div className={classes.USPText}>
						<div>{t('Headline3')}</div>
						<div>
							{t('SellText3')}
						</div>
					</div>
				</div>
			</div>
			{
                showContactBox ?
                    <ContactUs
                        hideContact={()=>setShowContactBox(false)}
                        pageDetails="Search While Selling HELP - " // for internal use
                    /> : null
            }
		</div>
	);
}

export default SellSearch;