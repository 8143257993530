import axios, { token_config } from '../../axiosBase';
import {checkForLogin} from '../PrivateRoute/PrivateRoute';
import {cartsData} from '../Profile/XMLData/XMLData';
import customerDetails from '../UserData';

import {deleteCart} from '../Events/MakePayment/Payments/PostPayment';
import consoleLog from '../../log';

const PostCart = () => {

    checkForLogin().then(function(token){
    if(token){

        const payload_json = {
            product_id: sessionStorage.getItem("event_id"),
            quantity: sessionStorage.getItem("event_proceed_quantity"),
            customer_id: customerDetails.data.id,
            combination_id: sessionStorage.getItem("ticket_id"),
            address_id: 0
        };
        const payload = cartsData(payload_json);
        const _config = token_config(token);
        _config["headers"]["Content-Type"] = "text/xml";
        
        let cartsToBeDeleted = [];

        axios.get('/carts?output_format=JSON&display=full&filter[id_customer]=['+customerDetails.data.id+']', _config)
        .then(function(data){
            if(data.data.carts && data.data.carts.length){
                data.data.carts.map(function(item){
                    cartsToBeDeleted.push(item.id)
                })
            }

            axios.post('/carts?output_format=JSON', payload, _config)
            .then(function(data){
                sessionStorage.setItem("cart",data.data.cart.id)
                sessionStorage.setItem("cart_payload",JSON.stringify(payload_json))

                cartsToBeDeleted.map(function(item){
                    if(item == data.data.cart.id){
                        consoleLog("ERROR : GOT SAME CART")
                    }
                    deleteCart(item);
                })
            })
            .catch(function(err){
                consoleLog(err)
            });
        })
        .catch(function(err){
            
        })

    }})
}

const addPrefixZero = (number) => {
    if(number < 10){
        return "0"+number.toString()
    }else{
        return number
    }
}

const PutCart = () => {

    return checkForLogin().then(function(token){
    if(token){
        var today = new Date();
        var date = today.getFullYear()+'-'+addPrefixZero(today.getMonth()+1)+'-'+addPrefixZero(today.getDate());
        var time = addPrefixZero(today.getHours()) + ":" + addPrefixZero(today.getMinutes()) + ":" + addPrefixZero(today.getSeconds());

        let cart_payload = JSON.parse(sessionStorage.getItem("cart_payload"));
        let address_id = sessionStorage.getItem("address");
        let shipping_id_carrier = sessionStorage.getItem("shipping_id_carrier") ? sessionStorage.getItem("shipping_id_carrier") : 0;
        let quantity = sessionStorage.getItem("event_proceed_quantity");
        if(address_id && parseInt(address_id) > 0){
            cart_payload['address_id'] = address_id;
        }else{
            return false
        }
        if(quantity && parseInt(quantity) > 0){
            cart_payload['quantity'] = quantity;
        }else{
            return false
        }

        const payload = cartsData(cart_payload, sessionStorage.getItem("cart"), date+" "+time, shipping_id_carrier);
        
        const _config = token_config(token);
        _config["headers"]["Content-Type"] = "text/xml";

        return axios.put('/carts?output_format=JSON', payload, _config)
        .then(function(data){
            return true
        })
        .catch(function(err){
            return false
        });

    }})
}

export {PutCart, PostCart};