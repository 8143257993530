import React, { useState, useRef, useEffect } from "react"
import classes from './Events.module.css'
//import quantIcon from '../../assets/images/icons/quantity.png'
//import filter from '../../assets/images/icons/filter.png'
import useOutsideAlerter from '../UseOutsideAlerter/UseOutsideAlerter'
//import left from '../../assets/images/icons/left-chevron.png';
//import right from '../../assets/images/icons/right-chevron.png';
//import rightBlack from '../../assets/images/icons/right-chevronBlack.png';
import Banner from './Banner/Banner';
import BackgroundBlock from './BackgroundBlock/BackgroundBlock';
import Waitlist from './Waitlist/Waitlist';
import BuyTickets from './BuyTickets/BuyTickets';
import MakePayment from './MakePayment/MakePayment';
//import eTicket from '../../assets/images/icons/eticket-1.png';
//import physicalTicket from '../../assets/images/icons/physical-ticket-1.png';
//import ppbutton from '../../assets/images/icons/ppbutton.png';
import axios, { config } from '../../axiosBase';

import { useTranslation } from 'react-i18next';

import MyList from './MyList.js';
import CustomSelect from '../UI/CustomSelect/CustomSelect';
import AlertBox from '../UI/AlertBox/AlertBox';

import customerDetails from '../UserData';
import {checkForLogin} from '../PrivateRoute/PrivateRoute';

import {cartsData} from '../Profile/XMLData/XMLData';

import { ReactSVG } from 'react-svg';
import {SEARCHRESULTS, EVENT_STRING, ORDERCONFIRM, HOWITWORKS} from '../../URLS';
import { CreateOrdersData } from '../Profile/XMLData/XMLData';
import {stripePromise} from './MakePayment/Payments/Sofort/Stripe';

import {PostCart, PutCart} from '../Cart/CartFunctions';
import {deleteCart} from './MakePayment/Payments/PostPayment';
import consoleLog from '../../log';
import panzoom from "panzoom";
import {ShippingOptionsDefault, prepareShippingMethodsBuying} from '../SellTickets/ShippingData';

export default function Events(props) {

    const [mainState, setMainState] = useState({});

    const [showFilter, setFilter] = useState(false) //toggle show filter and hide filter

    const [buttonType, setButtonType] = useState(null) //set ticket type filter
    const [chosenFilter, setChosenFilter] = useState("") //to display the list according to filter chosen
    const [chosenQuantityFilter, setChosenQuantityFilter] = useState(null)
    const [filterApplied, setFilterApplied] = useState(false)

    const [alert, setAlert] = useState(false)
    const [showMyListAlertBox, setMyListAlertBox] = useState(false)
    const [cartFailed, setCartFailed] = useState(false)

    var editRef = useRef(null);
    var backdropRef = useRef(null);

    const { t, i18n } = useTranslation();

    const [eventsContainerStyle, setEventsContainerStyle] = useState({});
    const [showFilterList, setFilterList] = useState([])
    const [filterCheckbox, setFilterCheckbox] = useState(new Array(showFilterList.length).fill(false)) //filter checkbox state

    const [detailList, setDetail] = useState([])
    const [quantity, setQuantity] = useState({});
    const [quantityDisplayList, setQuantityDisplayList] = useState({});

    const [backgroundBlockVisible, setBackgroundBlockVisible] = useState(false);
    const [backgroundBlockData, setBackgroundBlockData] = useState('');
    const [backgroundBlockStyle, setBackgroundBlockStyle] = useState({});

    const [cartCheck, setCartCheck] = useState(true);
    const url_string = window.location.href;
    const url = new URL(url_string);
    const ct = url.searchParams.get("cart_ticket");

    const divRef = useRef();
    const [payment, setPayment] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({});
    //document.body.style.overflow = "hidden";
    const [listOpened, setListOpened] = useState(false);

    let waitlistStyles = {};

    if (window.screen.width >= 600) {
        waitlistStyles = {
            marginTop: '100px',
            width: '450px'
        }
    } else {
        waitlistStyles = {
            marginTop: '100px',
            width: '300px',
            marginLeft: '0',
            marginRight: '0',
            padding: "20px 0px"
        }
    }

    const SplitTypes = {
        PerTicket: 1,
        NoSplitting: 2,
        PreventOne: 3,
        EvenOnly: 4
    }

    const [waitlistBlockStyle, setWaitlistBlockStyle] = useState(waitlistStyles)
    const [purchaseBlockStyle, setPurchaseBlockStyle] = useState({
        marginTop: '50px'
    })
    const [filteredDetails, setFilteredDetails] = useState([]);

    const setBackgroundBlock = (visibility, styling, component) => {
        setBackgroundBlockVisible(visibility);
        setBackgroundBlockStyle(styling);
        setBackgroundBlockData(component);
    }

    const openLoginBox = (data) => {
        setMyListAlertBox(false)
        sessionStorage.setItem("sell_proceed_props",JSON.stringify(data));
        if(window.screen.width > 1100){
          document.getElementById("loginBtnId").click();
        }else{
          document.getElementById("loginBtnMobileId").click();
        }
    }

    const waitlist = () => {
        setBackgroundBlock(true, waitlistBlockStyle, <Waitlist product_id={props.match.params.id} categories={showFilterList} />);
    }

    const callPayment = (detail) => {
        //consoleLog(detail)

        checkForLogin().then(function(token){
        if(token){

        if(customerDetails && customerDetails.data.id){

            axios.get('/products/'+ props.match.params.id +'?output_format=JSON', config)
            .then(function (res) {
                const product = res.data.product
                if(product.associations.combinations){
                    product.associations.combinations.map(function(item){
                        if(item.id == detail.allDetails.id){
                            sessionStorage.setItem("event_id", props.match.params.id)
                            sessionStorage.setItem("ticket_id", item.id)
                            if(cartCheck){
                                if(item.quantity < detail.quantity){
                                    consoleLog(cartCheck, "Cart checking for bool")
                                    setAlert(true);
                                    throw new Error("Quantity not available")
                                }
                                PostCart()
                            }else{
                                if(ct == item.id){
                                    PutCart().then(function(res){
                                        if(res){
                                            // Do nothing, put cart is successful
                                        }else{
                                            // Prevent user from proceeding
                                            setCartFailed(true);
                                        }
                                    })
                                }else{
                                    PostCart()
                                }
                            }
                        }
                    })
                }
            })
            .then(function(){
                setBackgroundBlock(true, purchaseBlockStyle, <BuyTickets backClick={setEditFunc} loading={true} />);
                setPayment(true);
                setPaymentDetails(detail);
                setBackgroundBlock(false, {}, <></>);

                axios.get('/addresses/?output_format=JSON&filter[id_customer]=['+customerDetails.data.id+']&filter[deleted]=[0]', config)
                .then(function (data) {
                    if(!(data.data instanceof Array) && Object.keys(data.data).length){
                        sessionStorage.setItem("address",data.data.addresses[0].id)
                    }else{
                    }
                })
            })
            .catch(function(err){
                consoleLog(err)
            })
        }

        }})
    }

    const discardPayment = () => {
        setPayment(false)
    }

    const buyTicket = (detail, index, internal_call=false) => {
        if(Object.keys(mainState).length){
            if(internal_call){
                detail['quantity'] = quantity[index];
            }else{
                detail['quantity'] = sessionStorage.getItem("event_proceed_quantity");
            }
            setBackgroundBlock(true, purchaseBlockStyle, <BuyTickets eventDetails={mainState} ticketDetails={detail} backClick={setEditFunc} callPayment={callPayment} index={index} />);
            sessionStorage.setItem("RetryBlock", JSON.stringify({
                details: detail,
                id: index
            }))
            sessionStorage.setItem("event_url", window.location.pathname);

            if(internal_call){
                sessionStorage.setItem("event_proceed_quantity", detail['quantity'])
            }

            if(!customerDetails){
                sessionStorage.setItem("event_proceed_detail", JSON.stringify(detail))
                sessionStorage.setItem("event_proceed_index", index)
                sessionStorage.setItem("event_proceed_quantity", quantity[index])
                sessionStorage.setItem("event_id", props.match.params.id)
            }else{
                sessionStorage.removeItem("event_proceed_detail")
            }
        }
    }

    useEffect(() => {
        axios.get('/products/'+ props.match.params.id +'?output_format=JSON', config)
        .then(function (res) {
            setMainState(res.data.product)
        })
        .catch(function(err){
            consoleLog(err)
        })
        if(customerDetails && customerDetails.data.id){
            console.log(customerDetails)
            axios.get('/addresses/?output_format=JSON&display=full&filter[id_customer]=['+customerDetails.data.id+']&filter[deleted]=[0]', config)
                .then(function (data) {
                    if(!(data.data instanceof Array) && Object.keys(data.data).length){
                        sessionStorage.setItem("buyer_country",data.data.addresses[0].id_country)
                    }else{
                    }
                })
        }
    },[])

    useEffect(()=>{
        if(sessionStorage.getItem("event_proceed_detail")
            && sessionStorage.getItem("event_proceed_index")
            && Object.keys(mainState).length
            && sessionStorage.getItem("event_proceed_quantity") != "undefined" ){
            if(sessionStorage.getItem("event_id") == props.match.params.id){
                const detail = JSON.parse(sessionStorage.getItem("event_proceed_detail"))
                const index = sessionStorage.getItem("event_proceed_index")
                buyTicket(detail, index)
            }
        }
    },[Object.keys(mainState).length])

    const cartCheckout = () => {
        if(ct && cartCheck){
            const cart_id = sessionStorage.getItem("cart")
            checkForLogin().then(function(token){
                if(token){
                    axios.get('/carts/'+cart_id+'?output_format=JSON', config)
                      .then(function (data) {
                        if(data.data && data.data.cart.id_customer == customerDetails.data.id){
                            if(data.data.cart.associations && data.data.cart.associations.cart_rows){                            
                                if(data.data.cart.associations.cart_rows[0].id_product == props.match.params.id){                            
                                    if(data.data.cart.associations.cart_rows[0].id_product_attribute == ct){
                                        if(Object.keys(mainState).length){
                                            let len = mainState?.associations?.combinations.length || 0;
                                            for(let i=0; i<len; i++){
                                                if(mainState.associations.combinations[i].id === data.data.cart.associations.cart_rows[0].id_product_attribute){
                                                    if( (mainState.associations.combinations[i].quantity >= 0)
                                                        && (mainState.associations.combinations[i].activated === "1")
                                                        && (mainState.associations.combinations[i].deleted === "0")
                                                    ){
                                                        sessionStorage.setItem("event_proceed_quantity", data.data.cart.associations.cart_rows[0].quantity)
                                                        setCartCheck(false)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                      })
                      .catch(function (err) {
                        consoleLog(err)
                      });
                }})
        }
    }

    useEffect(()=>{
        if(divRef.current){
            cartCheckout();
        }
    },[divRef.current])

    useEffect(()=>{
        if(!cartCheck){
            const retryBlock = JSON.parse(sessionStorage.getItem("RetryBlock"))
            buyTicket(retryBlock['details'], retryBlock['id'])            
        }
    },[cartCheck])

    useEffect(()=>{
        const url_string = window.location.href;
        const url = new URL(url_string);
        const pi = url.searchParams.get("payment_intent");
        if(pi && Object.keys(mainState).length){
            const PI = url.searchParams.get("payment_intent");
            const PICS = url.searchParams.get("payment_intent_client_secret");
            const status = url.searchParams.get("redirect_status");

//            const SPI = sessionStorage.getItem("SPI");
//            const SPICS = sessionStorage.getItem("SPICS");
            const SP_Details = JSON.parse(sessionStorage.getItem("SP_Details"));

//            sessionStorage.removeItem("SPI");
//            sessionStorage.removeItem("SPICS");
//            sessionStorage.removeItem("SP_Details");
            const cart_id = sessionStorage.getItem("cart");
            const order_id = SP_Details.eventDetails.id+"-"+SP_Details.ticketDetails.allDetails.id+"-"+customerDetails.data.id;

            if(status == "failed"){
                //setBackgroundBlock(true, {}, <MakePayment eventDetails={SP_Details.eventDetails} ticketDetails={SP_Details.ticketDetails} close={discardPayment} retry={callRetryBG} paymentStatus="FAILED" />)
                deleteCart(cart_id, "FAILED", order_id)
            }else if(status == "succeeded" || status == "processing" || status == "pending"){
                stripePromise.then(function(stripe){
                    //consoleLog(stripe,"STRIPE")
                    stripe.retrievePaymentIntent(PICS).then(function(response) {
                        consoleLog(response, "sofort response")
                        if (response.error) {
                            // Handle error
                            //consoleLog(response,"error")
                            //setBackgroundBlock(true, {}, <MakePayment eventDetails={SP_Details.eventDetails} ticketDetails={SP_Details.ticketDetails} close={discardPayment} retry={callRetryBG} paymentStatus="FAILED" />)
                            deleteCart(cart_id, "FAILED", order_id)
                        } else if (response.paymentIntent && ( response.paymentIntent.status === 'succeeded' || response.paymentIntent.status === 'processing' || response.paymentIntent.status === 'pending' )) {
                            // Handle successful payment
                            //consoleLog(response,"success")

                            const address_id = sessionStorage.getItem("address")
                            const shipping_method = sessionStorage.getItem("shipping_method")
                            const ticketmaster_details = JSON.parse(sessionStorage.getItem("ticketmaster_details"))
                            let customer_data = customerDetails.data;
                            customer_data["ticketmaster_details"] = ticketmaster_details;

                            const payload = CreateOrdersData("sofort", customer_data, SP_Details.eventDetails, SP_Details.ticketDetails, cart_id, address_id, shipping_method, sessionStorage.getItem("totalPrice"));

                            config['headers']['Content-Type'] = 'text/xml';

                            checkForLogin().then(function(token){
                            if(token){

                            axios.post('/orders', payload, config)
                              .then(function (data) {
                                //setBackgroundBlock(true, {}, <MakePayment eventDetails={SP_Details.eventDetails} ticketDetails={SP_Details.ticketDetails} close={discardPayment} retry={callRetryBG} paymentStatus="SUCCESS" />)
                                deleteCart(cart_id, "SUCCESS", order_id)
                              })
                              .catch(function (err) {
                                //setBackgroundBlock(true, {}, <MakePayment eventDetails={SP_Details.eventDetails} ticketDetails={SP_Details.ticketDetails} close={discardPayment} retry={callRetryBG} paymentStatus="FAILED" />)
                                deleteCart(cart_id, "FAILED", order_id)
                              });
                            }})
                        }
                    });
                })
            }
        }
    },[Object.keys(mainState).length])


/* MAP: ZOOMING EVENTS */
    const SVGZoomScript = () => {
        let shape = document.getElementsByTagName("svg")[0];
        if(!shape){
            return;
        }
        let instance = panzoom(shape, {
              maxZoom: 2,
              minZoom: 1,
              bounds: true,
              boundsPadding: 1,
              onTouch: function(e) {
                return false; // Prevent stop propogation
              }
            });
        let plusButton = document.getElementById("plus-button-id");
        let minusButton = document.getElementById("minus-button-id");

        plusButton.addEventListener("click", ()=>{instance.smoothZoom(0, 0, 2)});
        minusButton.addEventListener("click", ()=>{instance.smoothZoom(0, 0, 0.5)});
    }
/* MAP: ZOOMING EVENTS */


    //function called in outsideAlerter to close the filter list on outside click
    const setEditF = (value) => {
        if (value) {
            setFilter(value)
        }
        else {
            setTimeout(() => {
                setFilter(value)
            }, 300)
        }
    }

    const callRetry = () => {
        discardPayment()
        const retryBlock = JSON.parse(sessionStorage.getItem("RetryBlock"))
        buyTicket(retryBlock['details'], retryBlock['id'])
    }

    const callRetryBG = () => {
        const retryBlock = JSON.parse(sessionStorage.getItem("RetryBlock"))

        sessionStorage.setItem("event_proceed_detail", JSON.stringify(retryBlock['details']))
        sessionStorage.setItem("event_proceed_index", retryBlock['id'])
        sessionStorage.setItem("event_id", props.match.params.id)

        window.location.href = window.location.pathname;
    }

    //close the filter list on outside click
    useOutsideAlerter(editRef, setEditF, false);


    //function called in outsideAlerter to close the filter list on outside click
    const setEditFunc = (value) => {
        setBackgroundBlockVisible(value)
    }
    //close the filter list on outside click
    useOutsideAlerter(backdropRef, setEditFunc, false);

    useEffect(() => {
        //consoleLog("ch fill",chosenFilter)
        //filter details list according to filter selected
        let tempFilteredDetails = detailList.filter(list => chosenFilter.includes(list.filter));
        if (tempFilteredDetails.length === 0) {
            tempFilteredDetails = detailList;
        }
        setFilteredDetails(tempFilteredDetails)

    }, [chosenFilter])

/*    useEffect(() => {
        //filter details list according to filter selected
        let tempFilteredDetails = []
        if (chosenQuantityFilter === -1) {
            tempFilteredDetails = detailList;
            //consoleLog(tempFilteredDetails)
        }
        else if (chosenQuantityFilter >= 5) {
            tempFilteredDetails = detailList.filter(list => chosenQuantityFilter <= list.maxQuantity);
        } else {
            tempFilteredDetails = detailList.filter(list => chosenQuantityFilter == list.maxQuantity);
        }
        setFilteredDetails(tempFilteredDetails)

    }, [chosenQuantityFilter])*/

    useEffect(() => {
        //filter details list according to filter selected
        let tempFilteredDetails = []
        if (chosenQuantityFilter <= 0) {
            tempFilteredDetails = detailList;
        }else{
            detailList.map(function(item){
                if(item.maxQuantity >= chosenQuantityFilter){
                    if(item.allDetails.split == SplitTypes.PerTicket){
                        tempFilteredDetails.push(item)
                    }else if(item.allDetails.split == SplitTypes.NoSplitting && chosenQuantityFilter === item.maxQuantity){
                        tempFilteredDetails.push(item)
                    }else if(item.allDetails.split == SplitTypes.PreventOne){
                        if(item.maxQuantity - chosenQuantityFilter == 1){
                            // Do nothing
                        }else{
                            tempFilteredDetails.push(item)
                        }
                    }else if(item.allDetails.split == SplitTypes.EvenOnly){
                        if(chosenQuantityFilter % 2 == 0){
                            tempFilteredDetails.push(item)
                        }else{
                            // Do nothing
                        }
                    }else{
                        // Do nothing
                    }                    
                }else{
                    // Do nothing
                }

            })
        }

        setFilteredDetails(tempFilteredDetails)

    }, [chosenQuantityFilter])

    const createCategoryList = (combinations) => {
        let cats = [];
        let catList = [];
        cats.push("")
        catList.push({name: t("All"), value: "all"})
        for(let i=0; i<combinations.length; i++){
            if(!cats.includes(combinations[i].ticket_category) && combinations[i].ticket_category && combinations[i].ticket_category != ""){
                cats.push(combinations[i].ticket_category)
                catList.push({ name: combinations[i].ticket_category, value: combinations[i].ticket_category })
            }
        }
        setFilterList(catList)        
    }

    const getDisplayShippingMethods = (shippingJSON) => {
        let shipping_methods_available = "";
        let hide_listing = false;

        if(shippingJSON["german"]["active"]){
            for(let i=0; i<3; i++){
                if(shippingJSON["german"]["methods"][i]["active"]){
                    shipping_methods_available = shipping_methods_available + (i+1).toString() + ","
                }
            }
        }else if(shippingJSON["nonGerman"]["active"]){
            for(let i=0; i<2; i++){
                if(shippingJSON["nonGerman"]["methods"][i]["active"]){
                    shipping_methods_available = shipping_methods_available + (i+1).toString() + ","
                }
            }
        }

        if(shipping_methods_available === ""){
            hide_listing = true;
        }else{
            shipping_methods_available = shipping_methods_available.slice(0, shipping_methods_available.length - 1);
        }

        return [shipping_methods_available, hide_listing];
    }

    useEffect(() => {
        if(Object.keys(mainState).length){
            if(mainState.associations.combinations){
                createCategoryList(mainState.associations.combinations)
                let listOfEvents = [];
                let customer_address = sessionStorage.getItem("buyer_country")
                customer_address = customer_address ? customer_address : "1";
                mainState.associations.combinations.map(function (comb, index) {
                    let [shipping_methods_available, hide_listing] = getDisplayShippingMethods(
                        prepareShippingMethodsBuying(
                            customer_address,
                            comb.shipping_from_country,
                            mainState,
                            JSON.parse(ShippingOptionsDefault),
                            comb
                        )
                    )
                    
                    if(hide_listing == true && comb.type == "2"){
                        hide_listing = true
                    }else{
                        hide_listing = false
                    }
                    
                    if(comb.quantity > 0 && comb.editable == "1" && hide_listing == false){
                        listOfEvents.push({ index: index,
                                            name: comb.block_name || "",
                                            filter: comb.ticket_category || "",
                                            seat: "Seat",
                                            soldBy: comb.customer_name.split(" ")[0],
                                            deliveryETA: comb.days + " Days",
                                            price: "€ " + comb.price.toFixed(2),
                                            ticketType: comb.type == "1" ? "e-ticket" : comb.type == "2" ? "hard-ticket" : comb.type == "4" ? "eventim" : comb.type == "5" ? "ticketmaster" : "hard-ticket",
                                            maxQuantity: comb.quantity,
                                            ticket_image: comb.ticket_image,
                                            allDetails: comb,
                                            shipping_methods_available,
                                            hide_listing
                                        })
                    }
                })
                sortByPrice(listOfEvents)
                setDetail(listOfEvents)
            }
        }
    },[Object.keys(mainState).length])

    useEffect(() => {
        setFilteredDetails(detailList)
        let quantList = {};
        let quantDisplayList = {};

        consoleLog(detailList, "DT LIST")
        detailList.map(function(item){
            if(item.allDetails.split == SplitTypes.EvenOnly && item.maxQuantity >= 2){
                quantList[item.index] = 2
            }else if(item.allDetails.split == SplitTypes.NoSplitting){
                quantList[item.index] = item.maxQuantity
            }else if(item.allDetails.split == SplitTypes.PreventOne && item.maxQuantity == 2){
                quantList[item.index] = 2
            }else{
                quantList[item.index] = 1
            }

            quantDisplayList[item.index] = getQuantDisplayList(item.maxQuantity, item.allDetails.split);
        })
        setQuantity(quantList)
        setQuantityDisplayList(quantDisplayList)
    },[detailList])

    const getQuantDisplayList = (maxQuantity, split_type) => {
        let returnArray = [];

        if(split_type == SplitTypes.EvenOnly && maxQuantity >= 2){
            Array(maxQuantity).fill().map(function(value, index){
                let val = index+1;
                if(val % 2 === 0){
                    returnArray.push({name: val, value: val})
                }
            })
        }else if(split_type == SplitTypes.NoSplitting){
            returnArray.push({name: maxQuantity, value: maxQuantity})
        }else if(split_type == SplitTypes.PreventOne){
            Array(maxQuantity).fill().map(function(value, index){
                let val = index+1;
                if(maxQuantity - val !== 1){
                    returnArray.push({name: val, value: val})
                }
            })
        }else{
            Array(maxQuantity).fill().map(function(value, index){
                let val = index+1;
                returnArray.push({name: val, value: val})
            })
        }
        return returnArray;
    }

    const checkQuantityPossibility = (quantity, max, split_type, counter) => {
        let newQuant = quantity + counter;

        if(split_type == SplitTypes.PerTicket){
            return newQuant;
        }else if(split_type == SplitTypes.NoSplitting){
            return quantity;
        }else if(split_type == SplitTypes.PreventOne){
            if(max - newQuant == 1){
                if(counter > 0){
                    return max;
                }else{
                    return newQuant - 1;
                }
            }else{
                return newQuant
            }
        }else if(split_type == SplitTypes.EvenOnly){
            let newQuant = quantity + counter*2;
            return newQuant;
        }else{
            return 0;
        }
    }

    const changeQuantity = (index, counter, max, split_type, id) => {
        const maxQuantity = max;
        let quantList = {
            ...quantity
        };
        let org_quant = quantList[index];
        let quant = counter;//checkQuantityPossibility(org_quant, max, split_type, counter)

        if (quant > 0 && quant <= maxQuantity && org_quant != quant) {
            setQuantity({
                ...quantity,
                [index]: quant
            })
        }else{
            /*document.getElementById(id+index).classList.add(classes.Shake);
            setTimeout(function(){
                document.getElementById(id+index).classList.remove(classes.Shake);
            },100)*/
        }
    }

    const filterSeats = (filterSeat) => {
        setFilterApplied(true)
        setChosenFilter(filterSeat)
    }

    const filterQuantity = (quant) => {
        if (buttonType === quant) {
            quant = 0
        }
        setButtonType(quant)
        setFilterApplied(true)
        setChosenQuantityFilter(quant)
    }

    const buttonFilterFunc = (type) => {
        let tempFilteredDetails = detailList.filter(list => list.ticketType.includes(type));
        if (type === "" || type === "all") {
            tempFilteredDetails = detailList;
        }
        //setButtonType(type)
        setFilterApplied(true)
        setFilteredDetails(tempFilteredDetails)
    }

    const getRoundedWidth = (num1) => {
        var num2 = 100;
        var rounded = (num1 - (num1 % num2));
        return rounded;
    }

    const getRoundedHeight = (num1) => {
        var num2 = 100;
        var rounded = (num1 - (num1 % num2));
        return rounded;
    }

    const mapClickedFilter = (json_data, id) => {
        //consoleLog(json_data, id)
        if(id.includes("path")){
            let SVGfilter = "";
            try{
                SVGfilter = json_data[id].title.split(' - ')[1]
                SVGfilter = SVGfilter.split(',')[0]
                SVGfilter = SVGfilter.toLowerCase().trim()
            }
            catch(err){
            }
            //consoleLog(detailList,"SVG",SVGfilter)
            let tempFilteredDetails = detailList.filter(list => (list.name.toLowerCase() === SVGfilter));
            //consoleLog(tempFilteredDetails)
            if(window.screen.width <= 1050){
                window.scrollBy(0, 300);
            }
            setFilterApplied(true)
            setFilteredDetails(tempFilteredDetails)
        }
    }

    const removeExtraWords = (word) => {
        let extra_words = ['oberrang', 'unterrang', 'sitzplatz', 'mittelrang', 'innenraum']
        for(let i=0; i<extra_words.length; i++){
            if(word.includes(extra_words[i])){
                word = word.replace(extra_words[i],'')
                return {
                    extra_word: extra_words[i],
                    word: word.trim(),
                }
            }
        }
        return {
            extra_word: "",
            word: word,
        }
    }

    function mouseOverFunc(item_id, element)
    {
        let item_ele = document.getElementById(item_id)
        element.setAttribute("style","fill: var(--blue) !important;");
        if(item_ele){
            item_ele.setAttribute("style", "border-color: var(--green)")
        }
    }

    function mouseOutFunc(item_id, element)
    {  
        let item_ele = document.getElementById(item_id)
        element.setAttribute("style","fill: #9bf8a3 !important;");
        if(item_ele){
            item_ele.setAttribute("style", "border-color: var(--blue)")
        }
    }

    const PreProcessMap = () => {
        let shape = document.getElementsByTagName("svg")[0];
        if(!shape){
            return;
        }
        let list = [...shape.getElementsByTagName("path")]
        list.map(function(res){
            if(res){
                let SVGfilter = "";
                let tempFilteredDetails = [];
                let extra_word = "";
                let extra_word_list = [];

                try{
                    SVGfilter = res.getAttribute("inkscape:label").split(' - ')[1]
                    SVGfilter = SVGfilter.split(',')[0]
                    SVGfilter = SVGfilter.toLowerCase().trim()
                    /*let values = removeExtraWords(SVGfilter)
                    extra_word = values.extra_word;
                    SVGfilter = values.word;*/
                }
                catch(err){
                }
                //consoleLog(SVGfilter, "FILTER", detailList, "LIST")
                if(SVGfilter != ""){
                    tempFilteredDetails = detailList.filter(list => (list.name.toLowerCase() == SVGfilter));
                }

                let quantityAvailable = false;
                if(tempFilteredDetails.length){
                    let maximumQuantityDisplay = 0;
                    tempFilteredDetails.map(function(list){
                        if(list.maxQuantity){
                            /*if(extra_word != ""){
                                if(list.name.toLowerCase().includes(extra_word)){
                                    extra_word_list.push(list)
                                    quantityAvailable = true
                                }
                            }else{*/
                                maximumQuantityDisplay = maximumQuantityDisplay + list.maxQuantity;
                                quantityAvailable = true;
                            //}
                        }
                    })
                    if(quantityAvailable){
                        let event = tempFilteredDetails[0]
                        /*if(extra_word != ""){
                            event = extra_word_list[0]
                        }*/
                        let text = t("Quantity")+": "+maximumQuantityDisplay+"\n"+t("Price_From")+": "+event.price;
                        const element = document.getElementById(res.getAttribute("id"))
                        element.innerHTML = `<title>`+text+`</title>`;
                        element.setAttribute("style","fill: #9bf8a3 !important;");

                        if(extra_word != ""){
                            extra_word_list.map(function(item){
                                element.addEventListener("mouseover", () => mouseOverFunc("event_item_"+item.allDetails.id, element), false);
                                element.addEventListener("mouseout", () => mouseOutFunc("event_item_"+item.allDetails.id, element), false);                                
                            })
                        }else{
                            tempFilteredDetails.map(function(item){
                                element.addEventListener("mouseover", () => mouseOverFunc("event_item_"+item.allDetails.id, element), false);
                                element.addEventListener("mouseout", () => mouseOutFunc("event_item_"+item.allDetails.id, element), false);                                
                            })

                        }
                    }
                }else{
                    //document.getElementById(res.getAttribute("id")).setAttribute("style","fill: var(--l-gray);");
                }
            }
        })
    }

    const sortByPrice = (details) => {
        const data = details;
        data.sort(function (a, b) {
            return a.allDetails.price - b.allDetails.price;
        });
        return data
    }

    const modifyArtistForURL = (artist_name) => {
        if(artist_name && artist_name != ""){
            artist_name = artist_name.replaceAll(' ','-')
            artist_name = artist_name + "-karten"
            return artist_name
        }else{
            return ""
        }
    }


    return (
        <React.Fragment>
            { Object.keys(mainState).length ?
            <>
            {/*consoleLog(mainState)*/}
            <Banner click={waitlist} eventDetails={mainState} previousProps={props} />
            <div className={classes.EventsContainer} ref={divRef}>
                <div className={classes.Events} style={eventsContainerStyle}>
                { filteredDetails.length == 0 && !filterApplied ?
                    <p className={classes.NotAvailable}>
                        {t('No_sellers_available_before')}
                        <span onClick={waitlist}>
                            {t('No_sellers_available_link')}
                        </span>
                        {t('No_sellers_available_after')}
                    </p>
                :
                    <div className={classes.ApplyFilters}>
                        <div className={classes.MinimumQuantityFilterMobile}>
                            <div>{t('Minimum_Quantity')}</div>
                            <div className={classes.FilterButtonContainer}>
                                <div className={buttonType === -1 ? [classes.QuantityFilterButton, classes.Selected].join(' ') : classes.QuantityFilterButton} onClick={() => filterQuantity(-1)}>{t('ALL')}</div>
                                <div className={buttonType === 1 ? [classes.QuantityFilterButton, classes.Selected].join(' ') : classes.QuantityFilterButton} onClick={() => filterQuantity(1)}>1</div>
                                <div className={buttonType === 2 ? [classes.QuantityFilterButton, classes.Selected].join(' ') : classes.QuantityFilterButton} onClick={() => filterQuantity(2)}>2</div>
                                <div className={buttonType === 3 ? [classes.QuantityFilterButton, classes.Selected].join(' ') : classes.QuantityFilterButton} onClick={() => filterQuantity(3)}>3</div>
                                <div className={buttonType === 4 ? [classes.QuantityFilterButton, classes.Selected].join(' ') : classes.QuantityFilterButton} onClick={() => filterQuantity(4)}>4</div>
                                <div className={buttonType === 5 ? [classes.QuantityFilterButton, classes.Selected].join(' ') : classes.QuantityFilterButton} onClick={() => filterQuantity(5)}>5+</div>
                            </div>
                        </div>
                        <div className={classes.SeatingAreaFilter}>
                            {/*<select onChange={(e) => filterSeats(e.target.value)}>
                                <option>{ window.screen.width > 500 ? "Seating Area Filter" : "Seating Area" }</option>
                                {showFilterList.map((f, index) => {
                                    return (
                                        <option value={f.name}>{f.name}</option>
                                    )
                                })}
                            </select>*/}
                            <CustomSelect
                                optionsList={showFilterList}
                                changed={filterSeats}
                                styling={{
                                    container: window.screen.width > 500 ? { width: "200px"} : {width: "auto"},
                                    defaultText: { background: "var(--blue)",
                                                    padding: "10px 20px",
                                                    color: "var(--black)",
                                                    fontFamily: "GothamRounded-Medium",
                                                    borderRadius: listOpened === 1 ? "25px 25px 0 0" : "30px"
                                                },
                                    ul: {},
                                    li: { background: "var(--white)",
                                            color: "var(--black)",
                                            border: "1px solid var(--blue)"}
                                }}
                                defaultText={ t("Seating_Area_Filter") }
                                listOpened={(item)=>setListOpened(item)}
                                listOpenIndex={1}
                            />

                        </div>
                        <div className={classes.MinimumQuantityFilter}>
                            <div>{t('Quantity_Filter')}:</div>
                            <div className={buttonType === -1 ? [classes.QuantityFilterButton, classes.Selected].join(' ') : classes.QuantityFilterButton} onClick={() => filterQuantity(-1)}>{t('ALL')}</div>
                            <div className={buttonType === 1 ? [classes.QuantityFilterButton, classes.Selected].join(' ') : classes.QuantityFilterButton} onClick={() => filterQuantity(1)}>1</div>
                            <div className={buttonType === 2 ? [classes.QuantityFilterButton, classes.Selected].join(' ') : classes.QuantityFilterButton} onClick={() => filterQuantity(2)}>2</div>
                            <div className={buttonType === 3 ? [classes.QuantityFilterButton, classes.Selected].join(' ') : classes.QuantityFilterButton} onClick={() => filterQuantity(3)}>3</div>
                            <div className={buttonType === 4 ? [classes.QuantityFilterButton, classes.Selected].join(' ') : classes.QuantityFilterButton} onClick={() => filterQuantity(4)}>4</div>
                            <div className={buttonType === 5 ? [classes.QuantityFilterButton, classes.Selected].join(' ') : classes.QuantityFilterButton} onClick={() => filterQuantity(5)}>5+</div>
                        </div>
                        <div className={classes.TicketTypeFilter}>
                            {/*<select onChange={(e) => buttonFilterFunc(e.target.value)}>
                                <option hidden value="">{ window.screen.width > 500 ? "Ticket Type Filter" : "Ticket type" }</option>
                                <option value="all">All</option>
                                <option value="hard-ticket">Hardcopy Tickets</option>
                                <option value="e-ticket">E-Tickets</option>
                            </select>*/}
                            <CustomSelect
                                optionsList={[
                                    {
                                        name: t("All"), value: "all"
                                    },
                                    {
                                        name: t("Hardcopy_Tickets"), value: "hard-ticket"
                                    },
                                    {
                                        name: t("e-tickets"), value: "e-ticket"
                                    },
                                    {
                                        name: t("Mobile Eventim"), value: "eventim"
                                    },
                                    {
                                        name: t("Mobile Ticketmaster"), value: "ticketmaster"
                                    }
                                    ]}
                                changed={buttonFilterFunc}
                                styling={{
                                    container: window.screen.width > 500 ? { width: "200px"} : { width: "auto"},
                                    defaultText: { background: "var(--blue)",
                                                    padding: "10px 20px",
                                                    color: "var(--black)",
                                                    fontFamily: "GothamRounded-Medium",
                                                    borderRadius: listOpened === 2 ? "25px 25px 0 0" : "30px"
                                                },
                                    ul: {},
                                    li: { background: "var(--white)",
                                            color: "var(--black)",
                                            border: "1px solid var(--blue)"}
                                }}
                                defaultText={ t("Ticket_type_filter") }
                                listOpened={(item)=>setListOpened(item)}
                                listOpenIndex={2}
                            />
                        </div>
                    </div> }
                    <div className={!filteredDetails.length && !filterApplied ? [classes.EventListContainer, classes.ReverseItems].join(' ') : classes.EventListContainer}>
                        <div className={classes.Events1} style={ window.screen.width < 1050 && !mainState.associations.mapdata ? {display: "none"} : {} }>
                            {consoleLog(mainState.associations)}
                            <div style={{position: "relative"}}>
                                {mainState.associations.mapdata ?
                                    <>
                                    <ReactSVG
                                      afterInjection={(error, svg) => {
                                        if (error) {
                                          console.error(error, "ERRR", svg)
                                          return
                                        }
                                        if(svg){
                                            SVGZoomScript()
                                            PreProcessMap()
                                        }
                                      }}
                                      beforeInjection={(svg) => {
                                        svg.classList.add('svg-class-name')
                                        svg.setAttribute('style', 'width: 100%; height: 100%;')
                                        svg.setAttribute('viewBox', '0 0 '+mainState.associations.mapdata[0].width+' '+mainState.associations.mapdata[0].height)
                                      }}
                                      className="wrapper-class-name"
                                      evalScripts="always"
                                      fallback={() => <span>Error!</span>}
                                      loading={() => <span>Loading</span>}
                                      onClick={(e) => {
                                        mapClickedFilter(JSON.parse(mainState.associations.mapdata[0].regions), e.target.id)
                                      }}
                                      
                                      renumerateIRIElements={false}
                                      src={process.env.REACT_APP_DOMAIN+mainState.associations.mapdata[0].source}
                                      useRequestCache={false}
                                      wrapper="div"
                                    />
                                    <span className={classes.ZoomingButtons}>
                                        <button id="plus-button-id">+</button>
                                        <button id="minus-button-id">-</button>
                                    </span>
                                    </>
                                    /*<img src={process.env.REACT_APP_DOMAIN+mainState.associations.mapdata[0].source} />*/
                                : <p>{t('Map_Not_Available')}</p> }
                            </div>
                            {/*<p>Explore all Hans Zimmer Events &gt;</p>*/}
                            <a><p className={classes.MapDescText}>{t('map_desc_text')}</p></a>
                            <a href={EVENT_STRING+props.match.params.category}>
                                <p className={classes.ExploreAll}>{t('Explore_all_concerts')}<br />{mainState.category_name}&nbsp;&gt;</p>
                            </a>
                        </div>
                        <div className={classes.Events2}>
                            {/*<div className={classes.Events2Top}>
                                <div>
                                    <img className={classes.FilterImage} src={quantIcon} alt="" />
                            Quantity:
                            <img src={left} onClick={() => changeQuantity(-1)} alt="" />
                                    <span className={classes.EventsNum}>{quantity}</span>
                                    <img src={right} onClick={() => changeQuantity(1)} alt="" />
                                </div>
                                <div ><img className={classes.FilterImage} src={filter} alt="" />
                                    {showFilter ? <span>Hide filters <img className={classes.LeftArrow} src={left} alt="" /> </span> :
                                        <span onClick={ChangeFilter}>Show filters <img className={classes.RightArrow} src={left} alt="" /> </span>}
                                </div>
                            </div>*/}
                            {/*<div className={classes.ApplyFilters}>
                                <div className={classes.FilterBy}>{t('Filter_Tickets_by')}</div>
                                <div className={classes.FilterButtons}>
                                    <div className={buttonType === "all" ? [classes.FilterButton,classes.ActiveButton].join(' ') : classes.FilterButton} onClick={()=>buttonFilterFunc("all")}>All Types</div>
                                    <div className={buttonType === "hard-ticket" ? [classes.FilterButton,classes.ActiveButton].join(' ') : classes.FilterButton} onClick={()=>buttonFilterFunc("hard-ticket")}><img src={physicalTicket} alt="" />&nbsp;Physical</div>
                                    <div className={buttonType === "e-ticket" ? [classes.FilterButton,classes.ActiveButton].join(' ') : classes.FilterButton} onClick={()=>buttonFilterFunc("e-ticket")}><img src={eTicket} alt="" />&nbsp;e-ticket</div>
                                </div>
                                <div className={classes.FilterSeats}>
                                    <div onClick={()=>setFilter(true)}>{t('Click_to_filter_seats_by_area')}</div>
                                </div>
                            </div>*/}
                            <MyList
                                list={filteredDetails}
                                quantity={quantity}
                                quantityDisplay={quantityDisplayList}
                                showFilter={showFilter}
                                changeQuantity={changeQuantity}
                                buyTicket={buyTicket}
                                filterApplied={filterApplied}
                                waitlistBox={waitlist}
                                linkDetails={{
                                    "sellPageId": props.match.params.id,
                                    "artistCategory": props.match.params.category
                                }}
                                categoryName={mainState.category_name}
                                setMyListAlertBox={setMyListAlertBox}
                                previousProps={props}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <BackgroundBlock
                style={backgroundBlockStyle}
                visible={backgroundBlockVisible}
                reference={backdropRef}>
                {backgroundBlockData}
            </BackgroundBlock>
            {payment ? <MakePayment eventDetails={mainState} ticketDetails={paymentDetails} close={discardPayment} retry={callRetry} cartFailed={setCartFailed} /> : null}
            {/* showFilter ?
                <div ref={editRef} className={[classes.FilterPopup,"backdropCont"].join(' ')}>
                    <div className={classes.FilterPopupContainer}>
                        <div className={classes.FilterHeading}>Seats Filter</div>
                        <div className={classes.FilterSubHeading}>Please select the areas that you wish to browse tickets for and click confirm. If you do not select any option, all tickets will be displayed</div>
                        <div className={classes.FiltersDiv}>
                            <form onSubmit={(e)=>filterSeats(e)}>
                                {showFilterList.map((f, index) => {
                                    return (
                                        <div className={classes.FilterItem}>
                                            <input
                                                type="checkbox"
                                                value={f.name}
                                                name="filters"
                                                checked={filterCheckbox[index]}
                                                onChange={()=>changeFilterCheckbox(index, filterCheckbox[index])}
                                            />&nbsp;{f.name}
                                        </div>
                                    )
                                })}
                                <div className={classes.ConfirmFilter}>
                                    <button className={"myButton"}>Confirm</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> : null */}
        </>: <div></div> }
        { alert ? <AlertBox data={{
                alertTitle: t("ran_out_tickets"),
                alertMessage: "",
                acceptText: "OK",
                denyText: t("Cancel"),
                acceptAction: () => {window.location.href = window.location.pathname},
                denyAction: ()=> {window.location.href = window.location.pathname}
            }} /> : null }

        { showMyListAlertBox ?
            <AlertBox data={{
                  alertTitle:t("Login!"),
                  alertMessage:t("Please_login_first_to_sell_a_ticket"),
                  acceptText:"Okay",
                  denyText:t("Cancel"),
                  link: {
                      url: HOWITWORKS,
                      text: t("How_it_works_2")
                  },
                  acceptAction: ()=>openLoginBox(mainState),
                  denyAction: ()=>setMyListAlertBox(false)
            }}
            /> : null
        }

        {
            cartFailed ? <AlertBox data={{
                alertTitle: t("cannot_proceed_with_order"),
                alertMessage: "",
                acceptText: "OK",
                denyText: t("Cancel"),
                acceptAction: () => {window.location.href = window.location.href},
                denyAction: ()=> {window.location.href = window.location.href}
            }} /> : null
        }
        </React.Fragment>
    )
}