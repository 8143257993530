const currentLanguage = localStorage.getItem("i18nextLng");

export const ALL_LANG_URLS = {
	sell: {
		en: "/gb/sell",
		de: "/de/verkaufen"
	},
	sell_form: {
		en: "/gb/ads",
		de: "/de/ads"
	},
	home: {
		en: "/gb/",
		de: "/de/"
	},
	about: {
		en: "/gb/content/about-us",
		de: "/de/content/uber-uns"
	},
	listings: {
		en: "/gb/ads-overview",
		de: "/de/anzeigenubersicht"
	},
	event_string: {
		en: "/gb/concerts/",
		de: "/de/konzert-tickets/"
	},
	festival_string: {
		en: "/gb/festivals/",
		de: "/de/festival-tickets/"
	},
	theater_string: {
		en: "/gb/theater-tickets/",
		de: "/de/theater-tickets/"
	},
	sport_string: {
		en: "/gb/sport-tickets/",
		de: "/de/sport-tickets/"
	},
	settings: {
		en: "/gb/my-account",
		de: "/de/mein-Konto"
	},
	orders: {
		en: "/gb/order-history",
		de: "/de/bestellungsverlauf"
	},
	sales: {
		en: "/gb/sale-overview",
		de: "/de/verkaufsubersicht"
	},
	privacy_policy: {
		en: "/gb/content/privacy-statement",
		de: "/de/content/datenschutzerklarung"
	},
	terms: {
		en: "/gb/content/terms-and-conditions",
		de: "/de/content/allgemeine-geschaftsbedingunge"
	},
	faq: {
		en: "/gb/faq",
		de: "/de/faq"
	},
	impressum: {
		en: "/gb/content/impressum",
		de: "/de/content/impressum"
	},
	search_results: {
		en: "/gb/search",
		de: "/de/suche"
	},
	reset_password: {
		en: "/gb/password-recovery",
		de: "/de/passwort-zuruecksetzen"
	},
	how_it_works: {
		en: "/gb/how-it-works",
		de: "/de/sogehtes"
	},
	deregister: {
		en: "/deregister",
		de: "/deregister"
	},
	register: {
		en: "/register",
		de: "/register"
	},
	download_ticket: {
		en: "/gb/ad/:ticket_id(download-tickets-[0-9].*-[0-9].*)",
		de: "/de/ad/:ticket_id(download-tickets-[0-9].*-[0-9].*)"
	},
	download_ticket_string: {
		en: "/gb/ad/download-tickets-",
		de: "/de/ad/download-tickets-"
	},
	contact: {
		en: "/gb/contact-us",
		de: "/de/kontakt"
	},
	order_confirm: {
		en: "/gb/order-confirmation",
		de: "/de/bestellbestatigung"
	},
	sale_confirm: {
		en: "/gb/sale-confirmation",
		de: "/de/Verkaufsbestatigung"
	}
}

export const LANG_ENGLISH = "en";
export const LANG_GERMAN = "de";
export const EVENT = ':category/:location/:id';
export const ARTIST = ':category';
export const langURL = currentLanguage === LANG_ENGLISH ? "/gb" : "/de";

export const SELL = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.sell.en : ALL_LANG_URLS.sell.de;
export const SELLFORM = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.sell_form.en : ALL_LANG_URLS.sell_form.de;
export const HOME = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.home.en : ALL_LANG_URLS.home.de;
export const ABOUT = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.about.en : ALL_LANG_URLS.about.de;
export const LISTINGS = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.listings.en : ALL_LANG_URLS.listings.de;
export const EVENT_STRING = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.event_string.en : ALL_LANG_URLS.event_string.de;
export const FESTIVAL_STRING = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.festival_string.en : ALL_LANG_URLS.festival_string.de;
export const THEATER_STRING = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.theater_string.en : ALL_LANG_URLS.theater_string.de;
export const SPORT_STRING = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.sport_string.en : ALL_LANG_URLS.sport_string.de;
export const SETTINGS = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.settings.en : ALL_LANG_URLS.settings.de;
export const ORDERS = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.orders.en : ALL_LANG_URLS.orders.de;
export const SALES = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.sales.en : ALL_LANG_URLS.sales.de;
export const PRIVACYPOLICY = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.privacy_policy.en : ALL_LANG_URLS.privacy_policy.de;
export const TERMS = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.terms.en : ALL_LANG_URLS.terms.de;
export const FAQS = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.faq.en : ALL_LANG_URLS.faq.de;
export const IMPRESSUM = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.impressum.en : ALL_LANG_URLS.impressum.de;
export const SEARCHRESULTS = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.search_results.en : ALL_LANG_URLS.search_results.de;
export const RESETPASSWORD = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.reset_password.en : ALL_LANG_URLS.reset_password.de;
export const HOWITWORKS = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.how_it_works.en : ALL_LANG_URLS.how_it_works.de;
export const DEREGISTER = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.deregister.en : ALL_LANG_URLS.deregister.de;
export const REGISTER = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.register.en : ALL_LANG_URLS.register.de;
export const DOWNLOADTICKETS = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.download_ticket.en : ALL_LANG_URLS.download_ticket.de;
export const DOWNLOADTICKETS_STRING = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.download_ticket_string.en : ALL_LANG_URLS.download_ticket_string.de;
export const CONTACT = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.contact.en : ALL_LANG_URLS.contact.de;
export const ORDERCONFIRM = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.order_confirm.en : ALL_LANG_URLS.order_confirm.de;
export const SALECONFIRM = currentLanguage === LANG_ENGLISH ? ALL_LANG_URLS.sale_confirm.en : ALL_LANG_URLS.sale_confirm.de;

export const RESTRICTED_URLS = [SELLFORM, LISTINGS, SETTINGS, ORDERS, SALES];