import React from 'react';
import classes from './Account.module.css';

import { HOME, SETTINGS, LISTINGS, ORDERS, SALES } from '../../URLS';

import { useTranslation } from 'react-i18next';
import customerDetails, {storage} from '../UserData';
export default function Account() {


    const { t, i18n } = useTranslation();

    const handleLogout = () => {
        storage.removeItem("userId");
        localStorage.removeItem("showWelcomeLogin");
        window.location.href = HOME;
    }

    return (
        <div className={classes.AccountCont}>
            <ul className={classes.AccountUl}>
                <li className={classes.AccName}>{customerDetails.data.firstname}</li>
                <a href={ORDERS}><li className={[classes.AccName1, classes.AccClass].join(' ')}>{t('my_Orders')}</li></a>
                <a href={LISTINGS}><li className={[classes.AccName2, classes.AccClass].join(' ')}>{t('my_Listings')}</li></a>
                <a href={SALES}><li className={[classes.AccName3, classes.AccClass].join(' ')}>{t('my_Sales')}</li></a>
                <a href={SETTINGS}><li className={[classes.AccName4, classes.AccClass].join(' ')}>{t('settings')}</li></a>
            </ul>
            <div>
                <button className={["myButton", classes.AccButton].join(' ')} onClick={handleLogout}>{t('sign_out')}</button>
            </div>

        </div>
    )
}