import React, {useState, useRef} from 'react';
import classes from './ContactUs.module.css';
import useOutsideAlerter from '../UseOutsideAlerter/UseOutsideAlerter';
import { useTranslation } from 'react-i18next';
import axios, {config} from '../../axiosBase';
import LogoLoader from '../UI/LogoLoader/LogoLoader';
import Save from '../Profile/SaveAlert/Save';

import {EmailPattern} from '../../Formats';
import consoleLog from '../../log';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import customerDetails from '../UserData';

const ContactUs = (props) => {

	const [error, setError] = useState(false);
	const { t, i18n } = useTranslation();

    const [showSave, setShowSave] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    const [verified, setVerified] = useState(false)
    const loggedin_email = customerDetails ? customerDetails.data.email : '';

	const checkFaultyEmail = (email) => {
        if (email == ''){
            setError(t("Please_enter_a_valid")+" "+t("email_contact"));
            return true;
        }
        var pattern = EmailPattern;
        if (!pattern.test(email)) {
            setError(t("Please_enter_a_valid")+" "+t("email_contact"));
            return true;
        }
        else {
            setError(false);
            return false;
        }
	}

    const handleChangeInput = (e) => {
    	checkFaultyEmail(e.target.value);
    }

	var editRef = useRef(null);

    const setEditF = (value) => {
        if (value) {
            props.hideContact(value)        		
        	setShowSave(value)
        }
        else {
            setTimeout(() => {
	            props.hideContact(value)
		        setShowSave(value)
            }, 200)
        }
    }

    const submitContact = (e) => {
    	e.preventDefault()
    	const email = e.target.email.value
    	let message = e.target.message.value
    	if(!verified){
    		return false;
    	}
    	if(checkFaultyEmail(email)){
    		return false;
    	}
    	if(message == ""){
    		setError(t("Please_fill_in_all_the_fields"));
    		return false;
    	}
    	if(props.pageDetails){
    		message = props.pageDetails + message
    	}
    	const payload = {
		    "message": message,
		    "email": email
		}
		setShowLoader(true)
    	axios.post('/contact?a=send', payload, config)
        	.then(function(res){
        		consoleLog(res)
				setShowLoader(false)
				setShowSave(true)
        	})
        	.catch(function(err){
        		consoleLog(err)
				setShowLoader(false)
        	})

    }

    const [edit, setEdit] = useState(false);
    useOutsideAlerter(editRef, setEditF, false);

    const handleVerify = () => {
    	setVerified(true)
    }

	return (
		<div className={classes.ContactUs}>
			<div className={classes.Container}>
				<div className={classes.WrapBox} ref={editRef}>
					<div className={classes.Close} onClick={()=>props.hideContact(false)}>
						&times;
					</div>
					<form onSubmit={submitContact}>
					<div className={classes.AlertTitle}>
						{t('CONTACT_US')}
					</div>
					<div className={classes.AlertMessage}>
						<div>
							{ error ? <div className={classes.ErrorMessage}>
										{error}
									</div> : null }
							<input type="text" name="email" placeholder={t("Your_email")} onChange={handleChangeInput} defaultValue={loggedin_email} />
						</div>
						<div>
							<textarea name="message" placeholder={t("Type_your_message_here")}>
							</textarea>
						</div>
					</div>
					<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}>
    					<GoogleReCaptcha onVerify={handleVerify} />
  					</GoogleReCaptchaProvider>
					<div className={classes.AlertButtons}>
						<button type="submit" className={classes.AcceptButton} >{t('send')}</button>
					</div>
					</form>
				</div>
			</div>
			{ showLoader ? <LogoLoader /> : null }
			{showSave ?
                <div className="backdropCont">
                    <div>
                        <Save text="message_sent_successfully" refer={editRef} />
                    </div>
                </div>
            : null }
		</div>
	);
}

export default ContactUs;