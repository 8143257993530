import React, { useState, useEffect } from 'react'
import classes from './Sales.module.css'
import { useTranslation } from 'react-i18next';

import ShippedStatusIcon from '../../assets/images/icons/shippingstatus.png';
import DeliveryStatusIcon from '../../assets/images/icons/deliverystatus.png';
import PaymentStatusIcon from '../../assets/images/icons/paymentstatus.png';
import EShippedStatusIcon from '../../assets/images/icons/e_shipped.png';
import MobileTicketIcon from '../../assets/images/icons/mobile-ticket-icon.png';
import Tick from '../../assets/images/icons/bluetick.png';
import Cross from '../../assets/images/icons/canceled.png';

import DownArrow from '../../assets/images/icons/down_arrow.png';
import RightArrow from '../../assets/images/icons/right-arrow.png';
import axios, {token_config} from '../../axiosBase';
import {checkForLogin} from '../PrivateRoute/PrivateRoute';
import ErrorBox from '../UI/ErrorBox/ErrorBox';

export default function SalesBox(props) {

    const { t, i18n } = useTranslation();
    const [showAllURLs, setShowAllURLs] = useState(false);

    const [error, setError] = useState(false);
    const [listOfErrors, setListOfErrors] = useState([]);

    const getStepStatus = (step) => {
        if(step === 1){
            return props.statuses.order_states[props.order.current_state].isShipped ?
                    [classes.Step, classes.StepGapComplete].join(' ')
                : props.statuses.order_states[props.order.current_state].shipping ?
                    [classes.Step, classes.StepGapFill].join(' ')
                : classes.Step
        }else if(step === 2){
            return props.statuses.order_states[props.order.current_state].isDelivered ? 
                        [classes.Step, classes.StepGapComplete].join(' ')
                    : props.statuses.order_states[props.order.current_state].isShipped ?
                        [classes.Step, classes.StepGapFill].join(' ')
                    : classes.Step
        }else if(step === 3){
            return props.statuses.order_states[props.order.current_state].isPaymentDone ?
                        [classes.Step, classes.StepGapComplete].join(' ')
                    : props.statuses.order_states[props.order.current_state].isDelivered ?
                        [classes.Step, classes.StepGapFill].join(' ')
                    : classes.Step
        }
    }

    const getOnClickActionByStep = (step) => {
        if(step === 1){
            return props.statuses.order_states[props.order.current_state].shipping && !props.statuses.order_states[props.order.current_state].isShippedError ?
                props.clickedStatus === "shipped" && props.activeTabs['tab'+props.index] ?
                    ()=>props.onClickStatus(false, props.index, props.order.current_state)
                : ()=>props.onClickStatus("shipped", props.index, props.order.current_state)
            : null
        }else if(step === 2){
            return props.statuses.order_states[props.order.current_state].isShipped && props.order.ticket_type != "1" ?
                props.clickedStatus === "delivered" && props.activeTabs['tab'+props.index] ?
                    ()=>props.onClickStatus(false, props.index, props.order.current_state)
                : ()=>props.onClickStatus("delivered", props.index, props.order.current_state)
            : null
        }else if(step === 3){
            return props.statuses.order_states[props.order.current_state].isDelivered ?
                props.clickedStatus === "payment" && props.activeTabs['tab'+props.index] ?
                    ()=>props.onClickStatus(false, props.index, props.order.current_state)
                : ()=>props.onClickStatus("payment", props.index, props.order.current_state)
            : null
        }
    }

    const getStepClassesByStep = (step) => {
        if(step === 1){
            return props.clickedStatus === "shipped" && !props.statuses.order_states[props.order.current_state].isShippedError && props.activeTabs['tab'+props.index] ?
                    [classes.CheckedStatus, classes.StatusIcon].join(' ')
            : props.statuses.order_states[props.order.current_state].isShippedError || props.statuses.order_states[props.order.current_state].isCancelled ?
                    [classes.StatusIcon, classes.Error].join(' ')
            : classes.StatusIcon
        }else if(step === 2){
            return props.clickedStatus === "delivered" && !props.statuses.order_states[props.order.current_state].isShippedError && props.activeTabs['tab'+props.index] ?
                    [classes.CheckedStatus, classes.StatusIcon].join(' ')
            : classes.StatusIcon
        }else if(step === 3){
            return props.clickedStatus === "payment" && !props.statuses.order_states[props.order.current_state].isShippedError && !props.statuses.order_states[props.order.current_state].isPaymentDone && props.activeTabs['tab'+props.index] ?
                    [classes.CheckedStatus, classes.StatusIcon].join(' ')
            : classes.StatusIcon
        }
    }

    const getTitleIconByStep = (step) => {
        if(step === 1){
            return <>
            <img src={
                props.order.ticket_type === "1" || props.order.ticket_type === "4" || props.order.ticket_type === "5" || props.order.shipping_method === "3" ?
                    EShippedStatusIcon
                : ShippedStatusIcon }
                alt="step1"
            />
            { props.statuses.order_states[props.order.current_state].isShippedError || props.statuses.order_states[props.order.current_state].isCancelled ?
                <img className={classes.CheckMark} src={Cross} alt="" />
            : props.statuses.order_states[props.order.current_state].isShipped ?
                <img className={classes.CheckMark} src={Tick} alt="" />
            : null }
            </>
        }else if(step === 2){
            return <>
            <img src={
                props.order.ticket_type === "4" || props.order.ticket_type === "5" ?
                    MobileTicketIcon
                : DeliveryStatusIcon
            } alt="step2" />{ props.statuses.order_states[props.order.current_state].isDelivered ?
                <img className={classes.CheckMark} src={Tick} alt="" />
            : null }
            </>
        }else if(step === 3){
            return <>
            <img src={PaymentStatusIcon} alt="step3" />{ props.statuses.order_states[props.order.current_state].isPaymentDone ?
                <img className={classes.CheckMark} src={Tick} alt="" />
            : null }
            </>
        }
    }

    const getTitleTextByStep = (step) => {
        if(step === 1){
            return props.statuses.order_states[props.order.current_state].isCancelled ?
            t('order_canceled')
            : props.statuses.order_states[props.order.current_state].isShippedError ?
            t('shipping_error')
            : props.order.ticket_type === "1" || props.order.ticket_type === "4" || props.order.ticket_type === "5" || props.order.shipping_method === "3" ?
            t('tickets_sold')
            : t('Shipping')
        }else if(step === 2){
            return props.order.ticket_type === "2" && props.order.shipping_method === "3" ?
                t('tickets_received')
                : props.order.ticket_type === "4" || props.order.ticket_type === "5" ?
                t('ticket_transfer')
                : t('Delivery')
        }else if(step === 3){
            return props.order.ticket_type === "1" ? "2. "+t('Payment') : "3. "+t('Payment')
        }
    }

    const getMoreInfoBlockByStep = step => {
        if(step === 1){
            return <div className={
                        props.statuses.order_states[props.order.current_state].isShipped ?
                            [classes.ExpandCollapseButton, classes.BlueText].join(' ')
                        : props.statuses.order_states[props.order.current_state].shipping ?
                            classes.ExpandCollapseButton
                        : classes.ExpandCollapseButton
                    }>
                        {t('more_info')}
                        <span className={
                            props.clickedStatus === "shipped" && !props.statuses.order_states[props.order.current_state].isShippedError && props.activeTabs['tab'+props.index] ?
                                classes.ArrowImageUp
                            : classes.ArrowImageDown
                        }>
                            <img src={DownArrow} />
                        </span>
                    </div>
        }else if(step === 2){
            return <div className={
                        props.statuses.order_states[props.order.current_state].isDelivered ?
                            [classes.ExpandCollapseButton, classes.BlueText].join(' ')
                        : props.statuses.order_states[props.order.current_state].isShipped ?
                            classes.ExpandCollapseButton
                        : classes.ExpandCollapseButton
                    }>
                        {t('more_info')}
                        <span className={
                            props.clickedStatus === "delivered" && !props.statuses.order_states[props.order.current_state].isShippedError && props.activeTabs['tab'+props.index] ?
                                classes.ArrowImageUp
                            : classes.ArrowImageDown
                        }>
                            <img src={DownArrow} />
                        </span>
                    </div>

        }else if(step === 3){
            return <div className={
                        props.statuses.order_states[props.order.current_state].isPaymentDone ?
                            [classes.ExpandCollapseButton, classes.BlueText].join(' ')
                        : props.statuses.order_states[props.order.current_state].isDelivered ?
                            classes.ExpandCollapseButton
                        : classes.ExpandCollapseButton
                    }>
                        {t('more_info')}
                        <span className={
                            props.clickedStatus === "payment" && !props.statuses.order_states[props.order.current_state].isShippedError && props.activeTabs['tab'+props.index] ?
                                classes.ArrowImageUp
                            : classes.ArrowImageDown
                        }>
                            <img src={DownArrow} />
                        </span>
                    </div>   
        }
    }

    const getMoreInfoByStep = (step) => {
        if(step === 1){
            return props.statuses.order_states[props.order.current_state].shipping &&
            !props.statuses.order_states[props.order.current_state].isShippedError ?
                getMoreInfoBlockByStep(1)
            : null
        }else if(step === 2){
            return props.statuses.order_states[props.order.current_state].isShipped &&
            !props.statuses.order_states[props.order.current_state].isShippedError ?
                props.order.associations.order_rows[0].eticket === "1" ?
                    getMoreInfoBlockByStep(2)
                : null
            : null
        }else if(step === 3){
            return props.statuses.order_states[props.order.current_state].isDelivered &&
            !props.statuses.order_states[props.order.current_state].isShippedError ?
                getMoreInfoBlockByStep(3)
            : null
        }
    }

    const getEventimURLBlock = () => {
        return  <div className={classes.EventimURLs}>
                    <div className={classes.EventimContainer}>
                        <div className={classes.URLContainer} id="eventim-url-container">
                            {Array(parseInt(props.order.associations.order_rows[0].product_quantity)).fill().map(function(ticket, index){
                                return <div className={classes.URLItem}>
                                    <label for={"urlInputId"+index}>
                                        <span>{t("url_ticket")} {index+1+":"}</span>
                                        <input type="text" name="" id={"urlInputId"+index} />
                                    </label>                                            
                                </div>
                            })}
                            <div className={classes.SeeAllText} onClick={()=>setShowAllURLs(true)}>{t("see_all")} {"("+props.order.associations.order_rows[0].product_quantity+")"}</div>
                        </div>
                        <div className={classes.UploadButton}>
                            <div className={classes.Buttons}>
                                <button onClick={()=>uploadAllURLs("front")}>{t("upload_url_button")}</button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.DisclaimerText}>
                        {t("eventim_agreement_text")}
                    </div>
                    { showAllURLs ?
                    <div className={classes.URLBox}>
                        <div className={classes.WrapBox}>
                            <div className={classes.Close} onClick={()=>setShowAllURLs(false)}>
                                &times;
                            </div>
                            <div className={classes.Container}>
                                <div className={classes.HeadText}>
                                    {t("eventim_message")}
                                </div>
                                <div className={classes.URLList} id="eventim-url-container-popup">
                                    {Array(parseInt(props.order.associations.order_rows[0].product_quantity)).fill().map(function(ticket, index){
                                        return <div className={classes.URLItem}>
                                            <label for={"urlInputId"+index}>
                                                <span>{t("url_ticket")} {index+1+":"}</span>
                                                <input type="text" name={"eventimURL"+index} id={"urlInputId"+index} />
                                            </label>
                                        </div>
                                    })}
                                </div>
                                <div className={classes.UploadButton}>
                                    <div className={classes.Buttons}>
                                        <button onClick={()=>uploadAllURLs("popup")}>{t("upload_url_button")}</button>
                                    </div>
                                </div>
                                <div className={classes.DisclaimerText}>
                                    {t("eventim_agreement_text")}
                                </div>
                            </div>
                        </div>
                    </div> : null }
                </div>
    }


    const getEventimURLDownloadBlock = () => {
        return  <div className={classes.EventimURLs}>
                    <div className={classes.EventimContainer}>
                        <div className={classes.URLContainer}>
                            {Array(parseInt(props.order.associations.order_rows[0].product_quantity)).fill().map(function(ticket, index){
                                return <div className={classes.URLItem}>
                                        <a href={props.order.associations.order_rows[index].ticket_url} target="_blank">
                                            <button className={classes.TicketDownloadButton}>{"Ticket "+(index+1)+" URL"}</button>
                                        </a>
                                    </div>
                            })}
                            <div className={classes.SeeAllText} onClick={()=>setShowAllURLs(true)}>{t("see_all")} {"("+props.order.associations.order_rows[0].product_quantity+")"}</div>
                            <div className={classes.ChangeURLText}>
                                {t('change_url_text')}<span className={classes.CustomerService} onClick={()=>props.setShowContactBox(true)}>{t('customer_service')}</span>
                            </div>
                        </div>
                    </div>
                    { showAllURLs ?
                    <div className={classes.URLBox}>
                        <div className={classes.WrapBox}>
                            <div className={classes.Close} onClick={()=>setShowAllURLs(false)}>
                                &times;
                            </div>
                            <div className={classes.Container}>
                                <div className={classes.HeadText}>
                                    {t("eventim_uploaded_message")}
                                </div>
                                <div className={classes.URLList}>
                                    {Array(parseInt(props.order.associations.order_rows[0].product_quantity)).fill().map(function(ticket, index){
                                        return <div className={classes.URLItem}>
                                                <a href={props.order.associations.order_rows[index].ticket_url} target="_blank">
                                                    <button className={classes.TicketDownloadButton}>{"Ticket "+(index+1)+" URL"}</button>
                                                </a>
                                            </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div> : null }
                </div>
    }

    const uploadAllURLs = (type) => {
        let container_id = "eventim-url-container";
        if(type === "popup"){
            container_id = "eventim-url-container-popup"
        }
        let parentDiv = document.getElementById(container_id);
        let inputElements = parentDiv.getElementsByTagName('input');
        console.log(inputElements)
        let payloads = [];
        for (let i = 0; i < inputElements.length; i++) {
            let payload = {
                "id_order" : props.order.id,
                "number" : i+1,
                "ticket_url" : inputElements[i].value
            }
            if(inputElements[i].value == ""){
                let errorList = []
                errorList.push({"": t("Please_fill_in_all_the_fields") })
                setListOfErrors(errorList);
                setError(true);
                return;
            }
            payloads.push(payload);
        }
        console.log(payloads)

        checkForLogin().then(function(token){
        if(token){
            props.setShowLoader(true)
            const config = token_config(token);

            const requests = payloads.map(payload => axios.post('/shipping?a=save_ticket_urls', payload, config));

            Promise.all(requests)
                .then(responses => {
                    responses.forEach(response => {
                        // Do something
                    });
                    let payload = {
                        "id_order": props.order.id
                    }
                    axios.post('/shipping?a=finalize_mobile_order', payload, config)
                    .then(response => {
                        props.setShowLoader(false)
                        window.location.reload()
                    })
                    .catch(error => {
                        let errorList = []
                        errorList.push({[t("error")]: error.toString() })
                        setListOfErrors(errorList);
                        setError(true);
                    })
                })
            .catch(error => {
                let errorList = []
                errorList.push({[t("error")]: error.toString() })
                setListOfErrors(errorList);
                setError(true);
                props.setShowLoader(false)
            });
        }});
    }

    const isValidImage = (image) => {
        let extension = image.name.split(".");
        extension = extension[extension.length-1]
        if(["jpg", "jpeg", "png", "gif", "heic"].includes(extension.toLowerCase())){
            return true
        }else{
            return false
        }
    }

    const uploadAllImages = (e) => {
        let payloads = [];

        for (let i = 0; i < e.target.files.length; i++) {
            if(isValidImage(e.target.files[i])){
                let payload = {
                    "id_order" : props.order.id,
                    "image" : e.target.files[i]
                }
                let form_data = new FormData();
                for ( var key in payload ) {
                    form_data.append(key, payload[key]);
                }
                payloads.push(form_data);
            }else{
                let errorList = []
                errorList.push({[t("error")]: t("please_upload_a_valid_image") })
                setListOfErrors(errorList);
                setError(true);
                return
            }
        }
        console.log(payloads)

        checkForLogin().then(function(token){
        if(token){
            props.setShowLoader(true)
            const config = token_config(token);

            const requests = payloads.map(payload => axios.post('/shipping?a=save_ticket_proof', payload, config));

            Promise.all(requests)
                .then(responses => {
                    responses.forEach(response => {
                        props.setShowLoader(false)        
                    });
                    let payload = {
                        "id_order": props.order.id
                    }
                    axios.post('/shipping?a=finalize_mobile_order', payload, config)
                    .then(response => {
                        props.setShowLoader(false)
                        window.location.reload()
                    })
                    .catch(error => {
                        let errorList = []
                        errorList.push({[t("error")]: error.toString() })
                        setListOfErrors(errorList);
                        setError(true);
                    })
                })
            .catch(error => {
                let errorList = []
                errorList.push({[t("error")]: error.toString() })
                setListOfErrors(errorList);
                setError(true);
                props.setShowLoader(false)
            });
        }});
    }

    const getTicketMasterScreenshotsUploadBlock = () => {
        return <div className={classes.TicketmasterUploadContainer}>
            <div className={classes.TicketmasterInputContainer}>
                <label htmlFor={"ticketmaster-input"} className={classes.Buttons}>
                    <input type="file" multiple id="ticketmaster-input" onChange={(e)=>uploadAllImages(e) } />
                    {t('upload_screenshot')}
                </label>
            </div>
        </div>
    }

    const getTicketMasterScreenshotsBlock = () => {
        return <div className={classes.TicketmasterScreenshots}>
            <div className={classes.TicketmasterContainer}>
                <div className={classes.ScreenshotsContainer}>
                    {props.order.associations.transfer_proof.map(function(ticket, index){
                        return <div className={classes.ScreenshotItem}
                                    onClick={()=>{props.setOpenImage(true);props.setImage(`${process.env.REACT_APP_DOMAIN}/img/proof/${ticket.proof}`)}}
                                >
                                <img className={classes.TMScreenshot} src={process.env.REACT_APP_DOMAIN+"/img/proof/"+ticket.proof} />
                            </div>
                    })}
                </div>
                <div className={classes.ChangeURLText}>
                    {t('add_screenshot_text')}<span className={classes.CustomerService} onClick={()=>props.setShowContactBox(true)}>{t('customer_service')}</span>
                </div>
            </div>
        </div>
    }

    return <div className={classes.TicketsMain} style={props.statuses.order_states[props.order.current_state].isPaymentDone && props.hideCompleted ? {display: "none"} : {}}>
                <p className={classes.TicketType}>{
                    props.order.ticket_type === "1" ?
                        t('E-ticket')
                    : props.order.ticket_type === "2" ?
                        t('Hardcopy_Ticket')
                    : props.order.ticket_type === "4" ?
                        t('mobile_ticket_eventim')
                    : props.order.ticket_type === "5" ?
                        t('mobile_ticket_ticketmaster')
                    : t('Ticket')
                }</p>
                <div className={classes.TicketTopDetails}>
                    <div className={classes.TicketTopDetailsDiv1}>
                        <div >
                            <label className={classes.Big}>{props.order.associations.order_rows[0].product_quantity}</label><br /><label>{props.order.associations.order_rows[0].product_quantity > 1 ? t('Tickets') : t('Ticket')}</label>
                            <label className={[classes.Big1, classes.PriceBig1].join(' ')}>
                                {
                                    parseFloat(props.order.associations.order_rows[0].payout)
                                    .toFixed(2).replace('.',',')
                                }€</label>
                        </div>
                    </div>
                    <div>
                        <label className={classes.Big1}>{props.order.associations.order_rows[0].product_name}</label><br />
                        {/*<label className={classes.Big1}></label><br />*/}
                        <p>{props.order.associations.order_rows[0].location}</p>
                        <label>{props.order.associations.order_rows[0].date}</label><br />
                        <label>{props.order.associations.order_rows[0].section}</label><br />
                        <label>{
                            props.order.ticket_type === "2" ?
                                t('delivery_method') +" : "+ props.ShippingMethods[props.order.shipping_method]
                            : null
                        }</label>
                    </div>
                    <div>
                        <label>{t('Order_ID')}</label><br />
                        <label className={classes.Big1}>{props.order.reference}</label><br />
                        <p> <label>{t('payout_price')}</label><br />
                            <label className={classes.Big1}>€ {
                                parseFloat(props.order.associations.order_rows[0].payout)
                                .toFixed(2).replace('.',',')}</label><br /></p>
                        <label>{t('sold_on')} : {props.order.date_add.split(' ')[0]}</label>
                    </div>
                    <div className={classes.TicketTopDetailsDiv2}>
                        <div className={classes.TicketLabel}>
                            <div className={classes.GoToEventButton}>
                                {/*<a
                                    className={classes.EventAnchor}
                                    href={
                                        EVENT_STRING+props.order.associations.order_rows[0].product_id}>
                                        {t('Go_to_Event')}
                                </a>*/}
                            </div>
                           <div className={classes.BlueLabelCreation}> <label className={classes.Blue} onClick={()=>props.openDetailPopup(props.order)}><u>{t('View_Details')}</u></label><br /></div>
                            <a  onClick={()=>{props.setShowContactBox(true); props.setPageDetails("Sales Support : "+props.order.reference+" - "); }}/*href={"mailto:info@kaufmeinticket.de?subject=props.order ID "+props.order.reference}*/><label className={classes.Blue}><u>{t('Contact_Support')}</u></label></a>
                        </div>
                    </div>
                </div>

                <div className={classes.SalesStatus}>
                    <div className={classes.ProgressBar}>
                        <div className={classes.Progress}>
                            <div className={getStepStatus(1)}
                                id={"shipindex"+props.index}
                                onClick={getOnClickActionByStep(1)}
                            >
                                <div className={getStepClassesByStep(1)}>
                                {getTitleIconByStep(1)}
                                <div className={classes.Text}>1. {getTitleTextByStep(1)} </div>
                                {getMoreInfoByStep(1)}
                                </div>
                                <div className={classes.StepGapArrow}><img src={RightArrow} /></div>
                            </div>
                            <div className={getStepStatus(2)}
                                id={"shipindex"+props.index}
                                onClick={getOnClickActionByStep(2)}
                            >
                                <div className={getStepClassesByStep(2)}
                                style={
                                    props.order.ticket_type === "1" ?
                                    {visibility: "hidden"} : {}
                                }
                                >
                                    {getTitleIconByStep(2)}
                                    { !props.statuses.order_states[props.order.current_state].isShippedError && !props.statuses.order_states[props.order.current_state].isCancelled ?
                                        <div className={classes.Text}>2. { getTitleTextByStep(2) }</div>
                                    : null }
                                    {getMoreInfoByStep(2)}
                                </div>
                                <div className={classes.StepGapArrow}><img src={RightArrow} /></div>
                            </div>
                            <div className={getStepStatus(3)}
                                id={"shipindex"+props.index}
                                onClick={getOnClickActionByStep(3)}
                            >
                                <div className={getStepClassesByStep(3)}>
                                    {getTitleIconByStep(3)}
                                    { !props.statuses.order_states[props.order.current_state].isShippedError && !props.statuses.order_states[props.order.current_state].isCancelled ?
                                        <div className={classes.Text}>{getTitleTextByStep(3)}</div>
                                    : null }
                                    {getMoreInfoByStep(3)}
                                </div>
                            </div>
                        </div>
                    </div>
                    { props.activeTabs['tab'+props.index] ?
                    <div className={classes.StatusDescription}>
                        {console.log(props.clickedStatus, "CLOCK STATTUS")}
                        { props.clickedStatus ? 
                        <div className={ props.clickedStatus === "shipped" && !props.statuses.order_states[props.order.current_state].isShippedError ? [classes.StatusBox, classes.RemoveLeftCorner].join(' ')
                                        : props.clickedStatus === "payment" && !props.statuses.order_states[props.order.current_state].isShippedError && !props.statuses.order_states[props.order.current_state].isPaymentDone ? [classes.StatusBox, classes.RemoveRightCorner].join(' ')
                                        : classes.StatusBox
                        } style={props.clickedStatus === "shipped" && props.statuses.order_states[props.order.current_state].isShippedError ? {marginTop: "30px"}
                                : props.clickedStatus === "payment" && props.statuses.order_states[props.order.current_state].isPaymentDone ? {marginTop: "30px"}
                                : null}>
                            <div className={classes.Text} style={
                                    props.statuses.order_states[props.order.current_state].isPaymentDone && props.clickedStatus === "payment" ?
                                        {color: "var(--green)", width: "80%", margin: "auto"}
                                    : props.order.ticket_type === "1" ?
                                        {width: "80%", margin: "auto"}
                                    : props.statuses.order_states[props.order.current_state].shipping && !props.statuses.order_states[props.order.current_state].isShipped && props.clickedStatus === "shipped" ?
                                        {textAlign: "left"}
                                    : {}
                                }>{props.dialogBox.message}{console.log(props.statuses.order_states[props.order.current_state])}
                            {
                                props.order.ticket_type === "4" ?
                                    props.clickedStatus === "shipped" && ( props.statuses.order_states[props.order.current_state].shipping && !props.statuses.order_states[props.order.current_state].isShipped ) ?
                                        getEventimURLBlock()
                                    : props.clickedStatus === "delivered" && ( props.statuses.order_states[props.order.current_state].isShipped || props.statuses.order_states[props.order.current_state].isDelivered ) ?
                                        getEventimURLDownloadBlock()
                                    : null
                                : props.order.ticket_type === "5" ?
                                    props.clickedStatus === "shipped" && ( props.statuses.order_states[props.order.current_state].shipping && !props.statuses.order_states[props.order.current_state].isShipped ) ?
                                        getTicketMasterScreenshotsUploadBlock()
                                    : props.clickedStatus === "delivered" && ( props.statuses.order_states[props.order.current_state].isShipped || props.statuses.order_states[props.order.current_state].isDelivered ) ?
                                        getTicketMasterScreenshotsBlock()
                                    : null
                                : null
                            }
                            </div>
                            <div className={classes.Buttons} style={
                                ( !props.dialogBox.buttonText && !props.dialogBox.buttonText2 ) ||
                                (props.statuses.order_states[props.order.current_state].isPaymentDone && props.clickedStatus === "payment") ?
                                { width: "0%"} : {}
                            }>
                                { props.dialogBox.buttonText ?
                                    <button
                                        className={ props.dialogBox.actionType === "disabled_Print_Label" ? classes.DisabledButton : classes.ActiveButton }
                                        onClick={() => props.dialogBox.actionType === "openLink" && props.order.associations.tracking ?
                                                                                window.open(props.order.associations.tracking[0].dp_link)
                                                                                : props.dialogBox.actionType === "disabled_Print_Label" ?
                                                                                props.setPrintLabelAlert(true)
                                                                                : props.dialogBox.actionType === "openTrackLink" && props.order.associations.tracking ?
                                                                                window.open(process.env.REACT_APP_DP_TRACK_LINK+props.order.associations.tracking[0].dp_voucher_id)
                                                                                : props.dialogBox.actionType === "whatsappLink" ?
                                                                                window.open(process.env.REACT_APP_WHATSAPP_LINK)
                                                                                : props.dialogBox.actionType === "DHLLink" ?
                                                                                window.open(process.env.REACT_APP_DHL_LINK)
                                                                                : props.dialogBox.actionType === "contactSupport" ?
                                                                                props.showContactBoxFunction(props.dialogBox.buttonText+" : "+props.order.reference+" - ")/*window.open("mailto:info@kaufmeinticket.de?subject=props.order ID "+props.order.reference)*/
                                                                                : null }><img src={props.dialogBox.buttonIcon} alt="" />&nbsp;{
                                                                                    props.dialogBox.buttonText.split('\n').map(function(line){return <>{line}<br /></>})
                                                                                }</button> : null }
                                                                                
                                { props.dialogBox.buttonText2 ?
                                    <button
                                        className={ props.dialogBox.actionType2 === "disabled_Print_Label" ? classes.DisabledButton : classes.ActiveButton }
                                        onClick={() => props.dialogBox.actionType2 === "openLink" && props.order.associations.tracking ?
                                                                                window.open(props.order.associations.tracking[0].dp_link)
                                                                                : props.dialogBox.actionType2 === "disabled_Print_Label" ?
                                                                                props.setPrintLabelAlert(true)
                                                                                : props.dialogBox.actionType2 === "openTrackLink" && props.order.associations.tracking ?
                                                                                window.open(process.env.REACT_APP_DP_TRACK_LINK+props.order.associations.tracking[0].dp_voucher_id)
                                                                                : props.dialogBox.actionType2 === "whatsappLink" ?
                                                                                window.open(process.env.REACT_APP_WHATSAPP_LINK)
                                                                                : props.dialogBox.actionType2 === "DHLLink" ?
                                                                                window.open(process.env.REACT_APP_DHL_LINK)
                                                                                : null }>
                                                                                <img src={props.dialogBox.buttonIcon2} alt="" />&nbsp;{
                                                                                    props.dialogBox.buttonText2.split('\n').map(function(line){return <>{line}<br /></>})
                                                                                }</button> : null }
                            </div>
                        </div> : null }
                    </div> : null }
                </div>
            { error &&
                <ErrorBox
                    data={{
                        "errorTitle": t("attention"),
                        errorSubTitle: "",
                        errors: listOfErrors,
                        acceptText: "OK",
                        acceptAction: () => { setError(false) }
                    }} />
            }
            </div>
}