import React, { useRef, useEffect } from "react";

import axios, {config} from '../../../../axiosBase.js';

import customerDetails from '../../../UserData.js';
import {checkForLogin} from '../../../PrivateRoute/PrivateRoute';

import classes from "./Paypal.module.css";
import { CreateOrdersData } from '../../../Profile/XMLData/XMLData';

import {createOrder, deleteCart} from './PostPayment';

import consoleLog from '../../../../log';

export default function Paypal(props) {
  const paypal = useRef();
  consoleLog(props, "IN PAYPAL")
  useEffect(() => {
    consoleLog(props.eventDetails.name[0].value + "-" + props.ticketDetails.name, "PYPL CONSOLE")

    sessionStorage.setItem("SP_Details",JSON.stringify({
      eventDetails: props.eventDetails,
      ticketDetails: props.ticketDetails,
      priceBreakdown: props.priceBreakdown
    }))

    props.putCartFunction().then(function(res){
      if(res){
          // Proceed to pay
          window.paypal
            .Buttons({
              createOrder: (data, actions, err) => {
                return actions.order.create({
                  intent: "CAPTURE",
                  purchase_units: [
                    {
                      description: props.eventDetails.name[0].value + "-" + props.ticketDetails.name,
                      amount: {
                        currency_code: "EUR",
                        value: parseFloat(props.price),
                      },
                    },
                  ],
                });
              },
              onApprove: async (data, actions) => {
                const order = await actions.order.capture();
                consoleLog(order);
                const cart_id = sessionStorage.getItem("cart")
                const address_id = sessionStorage.getItem("address")
                const shipping_method = sessionStorage.getItem("shipping_method")
                const ticketmaster_details = JSON.parse(sessionStorage.getItem("ticketmaster_details"))
                let customer_data = customerDetails.data;
                customer_data["ticketmaster_details"] = ticketmaster_details;
                
                const payload = CreateOrdersData("paypal", customer_data, props.eventDetails, props.ticketDetails, cart_id, address_id, shipping_method, props.price);
                consoleLog(payload)

                config['headers']['Content-Type'] = 'text/xml';

                createOrder(payload, props, cart_id);
              },
              onError: (err) => {
                props.loader();
                props.proceed("FAILED");
                deleteCart(sessionStorage.getItem("cart"));
              },
            })
            .render(paypal.current);
      }else{
        // Prevent user from proceeding
        props.cartFailed(true);
      }
    })

  }, []);

  return (
    <div style={{position: "absolute", left: "0", top: "0"}}>
      <div ref={paypal} className={classes.PayPalButtonStyle}></div>
    </div>
  );
}
/*import React from "react";
import ReactDOM from "react-dom"

try{
  const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });  
}
catch{
  const PayPalButton = '<></>';
}

function Paypal(props) {
  consoleLog(props)
  const createOrder = (data, actions) =>{
    return actions.order.create({
      purchase_units: [
        {
          description: props.ticketDetails.name,
          amount: {
            currency_code: "EUR",
            value: "0.00"//props.ticketDetails.price.split(' ')[1],
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture();
  };

  return (
    <PayPalButton
      createOrder={(data, actions) => createOrder(data, actions)}
      onApprove={(data, actions) => onApprove(data, actions)}
    />
  );
}
export default Paypal;*/