import React, { useRef, useState, useEffect } from "react";
import classes from './SearchResults.module.css'
import CustomSelect from '../UI/CustomSelect/CustomSelect';
import DownArrow from '../../assets/images/icons/down-chevron.png';
import CopyIcon from '../../assets/images/icons/copytoclip.png';

import CountriesJson from '../JsonData/CountriesByIndex.json';

import axiosInstance, { config } from '../../axiosBase';
import axios from 'axios';

import LogoLoader from '../UI/LogoLoader/LogoLoader';

import {SEARCHRESULTS, SELLFORM, HOWITWORKS, EVENT_STRING, LANG_ENGLISH, LANG_GERMAN, langURL} from '../../URLS';
import {ArtistNamePattern} from '../../Formats';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import customerDetails from '../UserData.js';
import AlertBox from '../UI/AlertBox/AlertBox';

import ContactUs from '../ContactUsBox/ContactUs';

import DepecheMode1 from '../../assets/images/eventshowcase/depechemode1.png';
import DepecheMode2 from '../../assets/images/eventshowcase/depechemode2.png';
import DepecheMode3 from '../../assets/images/eventshowcase/depechemode3.png';

import Germany from '../../assets/images/icons/germany_cropped.png';
import Austria from '../../assets/images/icons/austria_cropped.png';
import Netherlands from '../../assets/images/icons/netherlands_cropped.png';
//COUNTRY FLAGS
import consoleLog from '../../log';
import {sortEventsBy} from '../FunctionsLibrary/Sorting';
import {removePastEvents} from '../FunctionsLibrary/ValidateEvents';

const SearchResults = (props) => {

    const showCount = 5;
    const { t, i18n } = useTranslation();
    const [searchListA, setSearchListA] = useState([]);
    const [searchListE, setSearchListE] = useState([]);
    const [groupedArtists, setGroupedArtists] = useState([]);
    const [groupedEvents, setGroupedEvents] = useState([]);
    const [activeTabs, setActiveTabs] = useState({});
    const [masterData, setMasterData] = useState({});
    const [showContactBox, setShowContactBox] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showAlertBox, setShowAlertBox] = useState(false);
    const [listOpened, setListOpened] = useState(false);

    const [eventsShowCount, setEventsShowCount] = useState(showCount);
    const [artistsShowCount, setArtistsShowCount] = useState(showCount);

    const [filterList, setFilterList] = useState([])

    const queryParams = new URLSearchParams(window.location.search);
    let query = queryParams.get('q');
    let artistQuery = props.match.params.category;

    const event_id = queryParams.get('id');
    let unique_artist = queryParams.get('ua');

    //const expanded = window.location.pathname.includes('robbie-williams-karten') ? true : false;
    const eventsShowcaseImages = [DepecheMode1, DepecheMode2, DepecheMode3];

    const [countryLangIndex, setCountryLangIndex] = useState(localStorage.getItem("i18nextLng") === LANG_GERMAN ? 1 : 2);
    const linkLangCode = localStorage.getItem("i18nextLng") === LANG_GERMAN ? 4 : 7;

    const filterCountries = [1,2,13];
    const [countryFilter, setCountryFilter] = useState(0);

    const months = {
        "01": {
            "abbreviation": {
                "en": "JAN",
                "de": "JAN"
            },
            "name": "January"
        },
        "02": {
            "abbreviation": {
                "en": "FEB",
                "de": "FEB"
            },
            "name": "February"
        },
        "03": {
            "abbreviation": {
                "en": "MAR",
                "de": "MÄR"
            },
            "name": "March"
        },
        "04": {
            "abbreviation": {
                "en": "APR",
                "de": "APR"
            },
            "name": "April"
        },
        "05": {
            "abbreviation": {
                "en": "MAY",
                "de": "MAI"
            },
            "name": "May"
        },
        "06": {
            "abbreviation": {
                "en": "JUN",
                "de": "JUN"
            },
            "name": "June"
        },
        "07": {
            "abbreviation": {
                "en": "JUL",
                "de": "JUL"
            },
            "name": "July"
        },
        "08": {
            "abbreviation": {
                "en": "AUG",
                "de": "AUG"
            },
            "name": "August"
        },
        "09": {
            "abbreviation": {
                "en": "SEP",
                "de": "SEP"
            },
            "name": "September"
        },
        "10": {
            "abbreviation": {
                "en": "OCT",
                "de": "OKT"
            },
            "name": "October"
        },
        "11": {
            "abbreviation": {
                "en": "NOV",
                "de": "NOV"
            },
            "name": "November"
        },
        "12": {
            "abbreviation": {
                "en": "DEC",
                "de": "DEZ"
            },
            "name": "December"
        }
    }

    const get_date_position = (date, position) => {
        let split_by_hifun = date.split('-')

        if(split_by_hifun.length == 1){
            let split_by_dot = date.split('.')
            if(split_by_dot.length == 1){
                return null
            }else{
                return split_by_dot[position]
            }
        }else{
            return split_by_hifun[position]
        }
    }

    const country_flags = {
        "DE": Germany,
        "AT": Austria,
        "NL": Netherlands
    }

    useEffect(() => {
        let groupedE = {};
        let groupedA = {};

        let filters = [...filterList];

        if(searchListE.length){
        searchListE.map(function(res, index){

            if(!groupedE[res.location_country]){
                groupedE[res.location_country] = [];
            }
            if(!groupedA[res.category_name]){
                groupedA[res.category_name] = [];
            }

            /*let cheapest_ticket_price = 0.00;

            if(res.associations.combinations){
                cheapest_ticket_price = res.associations.combinations[0].price

                res.associations.combinations.map(function(ticket){
                    if(ticket.price < cheapest_ticket_price && ticket.quantity > 0){
                        cheapest_ticket_price = ticket.price
                    }
                })
            }

            res["cheapest_ticket_price"] = cheapest_ticket_price.toFixed(2).replace('.',',')*/
            groupedE[res.location_country].push(res)
            groupedA[res.category_name].push(res)

            if(!filters.includes(res.location_city) && res.location_country === "1"){
                filters.push(res.location_city)
            }

            if(index == searchListE.length-1){
                setGroupedEvents(groupedE)
                setGroupedArtists(groupedA)
                setFilterList(filters)
            }
        })
        }else{
            setGroupedEvents([])
            setGroupedArtists([])
        }

    },[searchListE])

    const checkArtistsPattern = (query) => {
        if(query.match(ArtistNamePattern)){
            query = query.replaceAll('-karten','')
            query = query.replaceAll('-',' ')
        }
        consoleLog(query, "INSIDE FUNC")
        return query;
    }


    useEffect(() => {
        if(event_id && event_id != ""){ // NOT BEING USED
            setShowLoader(true);
            axiosInstance.get('/products/'+event_id+'?output_format=JSON', config)
            .then(function(data){
                if(data.data.product){
                    setSearchListE([data.data.product])
                }
                setShowLoader(false);
            }).catch(function(err){
                setShowLoader(false);
            })
        }
        else if(unique_artist && unique_artist != ""){ // NOT BEING USED
            setShowLoader(true);
            unique_artist = checkArtistsPattern(unique_artist)

            axiosInstance.get('/searchevents?a=search&q='+unique_artist, config)
            .then(function(data){
                setShowLoader(false);
                let temp_events = [];
                let temp_artists= [];
                if(data.data.events){
                    temp_events = sortEventsBy(data.data.events,"event_date");
                    setSearchListE(temp_events)
                }
                if(data.data.categories){
                    //temp_artists = sortEventsBy(data.data.categories,"event_date");
                    setSearchListA(temp_artists)
                }
                setMasterData({
                    "artists": temp_artists,
                    "events": temp_events
                })
            }).catch(function(err){
                setShowLoader(false);
            })            
        }
        else if(artistQuery){
            setShowLoader(true);
            let artistQueryRaw = artistQuery
            let artistName = "";
            artistQuery = checkArtistsPattern(artistQuery)

            axiosInstance.get('/categories?output_format=JSON&filter[link_rewrite]=['+artistQueryRaw+']&filter[active]=[1]&display=full&limit=10', config)
            .then(function(data){
                if(data.data.categories){
                    let id_category = data.data.categories[0].id;
                    axiosInstance.get('/products?output_format=JSON&display=full&filter[active]=[1]&filter[id_category_default]=['+id_category+']', config)
                    .then(function(data1) {
                        setShowLoader(false);
                        let temp_events = [];
                        let temp_artists= [];
                        if(data1.data.products){
                            temp_events = removePastEvents(sortEventsBy(data1.data.products,"event_date"));
                            setSearchListE(temp_events)
                        }
                        /*if(data1.data.products){
                            temp_artists = sortEventsBy(data1.data.products,"event_date");
                            setSearchListA(temp_artists)
                        }*/
                        setMasterData({
                            "artists": temp_artists,
                            "events": temp_events
                        })
                    })
                    .catch(function(error){
                        setShowLoader(false)
                    })
                }else{
                    setShowLoader(false)
                }
            })
            .catch(function(err){
                setShowLoader(false)
            })

            /*axiosInstance.get('/searchevents?a=search&q='+artistQuery, config)
            .then(function(data){
                setShowLoader(false);
                let temp_events = [];
                let temp_artists= [];

                /*if(data.data.categories){
                    data.data.categories.map(function(item){
                        if(item.link_rewrite == artistQueryRaw){
                            artistName = item.name // Fetch Artist name from categories
                        }
                    })
                    setSearchListA(temp_artists)
                }

                if(data.data.events){
                    data.data.events.map(function(item){
                        if(item.category_name == artistName){
                            temp_events.push(item) // Pushing only items with artistName we fetched above
                        }
                    })
                    temp_events = sortEventsBy(temp_events,"event_date");
                    setSearchListE(temp_events)
                }

                if(data.data.events){
                    temp_events = sortEventsBy(data.data.events,"event_date");
                    setSearchListE(temp_events)
                 }

                 if(data.data.categories){
                    //temp_artists = sortEventsBy(data.data.categories,"event_date");
                    setSearchListA(temp_artists)
                 }
                setMasterData({
                    "artists": temp_artists,
                    "events": temp_events
                })
            }).catch(function(err){
                setShowLoader(false);
            })*/
        }
        else if(query && query != ""){
            setShowLoader(true);
            query = checkArtistsPattern(query)

            /*axios.all([
                axiosInstance.get('/products?output_format=JSON&limit=' + process.env.REACT_APP_SEARCH_RESULTS_LIMIT + '&display=full&filter[active]=[1]&filter[name]=[' + query + ']%', config), 
                axiosInstance.get('/products?output_format=JSON&limit=' + process.env.REACT_APP_SEARCH_RESULTS_LIMIT + '&display=full&filter[active]=[1]&filter[category_name]=[' + query + ']%', config)
            ])
            .then(axios.spread((data1, data2) => {
                consoleLog("q",data1,"2",data2)
                setShowLoader(false);
                let temp_events = [];
                let temp_artists= [];
                if(data1.data.products){
                    temp_events = sortEventsBy(data1.data.products,"event_date");
                    setSearchListE(temp_events)
                }
                if(data2.data.products){
                    temp_artists = sortEventsBy(data2.data.products,"event_date");
                    setSearchListA(temp_artists)
                }
                setMasterData({
                    "artists": temp_artists,
                    "events": temp_events
                })
            }))
            .catch(function(error){
                setShowLoader(false)
            })*/
            axiosInstance.get('/searchevents?a=search&q='+query, config)
            .then(function(data){
                setShowLoader(false);
                let temp_events = [];
                let temp_artists= [];
                if(data.data.events){
                    temp_events = removePastEvents(sortEventsBy(data.data.events,"event_date"));
                    setSearchListE(temp_events)
                }
                if(data.data.categories){
                    //temp_artists = sortEventsBy(data.data.categories,"event_date");
                    setSearchListA(temp_artists)
                }
                setMasterData({
                    "artists": temp_artists,
                    "events": temp_events
                })
            })
            .catch(function(error){
                setShowLoader(false)
            })
        }
    },[query])

    useEffect(()=>{
        Object.entries(groupedArtists).map(function([key, value], index){
            activeTabs['tab'+index] = false;
            if(index == Object.entries(groupedArtists).length - 1){
                setActiveTabs(activeTabs)
            }
        })
    },[groupedArtists])

    useEffect(()=>{
        if(Object.keys(groupedArtists).length === 1){
            let e = {"target": {"id": ""}}
            handleClick(e, 0)
        }
    },[groupedArtists])

    const openLoginBox = (data) => {
        setShowAlertBox(false)
        sessionStorage.setItem("sell_proceed_props",JSON.stringify(data));
        if(window.screen.width > 1100){
            document.getElementById("loginBtnId").click();
        }else{
            document.getElementById("loginBtnMobileId").click();
        }
    }


    useEffect(()=>{
        if(sessionStorage.getItem("sell_proceed_props") && customerDetails){
            const data = JSON.parse(sessionStorage.getItem("sell_proceed_props"));
            sessionStorage.removeItem("sell_proceed_props");
            props.history.push({
              pathname: SELLFORM,
              //search: '?query=abc',
              state: data.id
            })
        }
    })

    const handleClick = (e, index) => {
        if(e.target.id !== "SHAREIMG"){
            const currentState = activeTabs['tab'+index];
            for (var key in activeTabs) {
                activeTabs[key] = false;
            }
            if(!currentState){
                setActiveTabs({
                    ...activeTabs,
                    ['tab' + index]: true
                })
            }else{
                setActiveTabs({...activeTabs})
            }
        }
    }

    const sortCitiesBy = (cities_data, type) => {
        const data = cities_data;
        data.sort(function (a, b) {
            return a[type].trim().localeCompare(b[type].trim());
        });
        data.unshift({name: t("All"), "value": "ALL"})
        return data
    }

    const filterByCity = (city) => {
        if(city === "ALL"){
            setSearchListE(masterData.events);
            setSearchListA(masterData.artists);
        }else{
            let tempFilteredE = masterData.events.filter(list => city.includes(list.location_city));
            let tempFilteredA = masterData.artists.filter(list => city.includes(list.location_city));
            setSearchListE(tempFilteredE);
            setSearchListA(tempFilteredA);
        }
    }

    const filterByCountry = (country) => {
        if(country === "ALL"){
            setSearchListE(masterData.events);
            setSearchListA(masterData.artists);
            setCountryFilter(0);
        }else{
            let tempFilteredE = masterData.events.filter(list => country==list.location_country);
            let tempFilteredA = masterData.artists.filter(list => country==list.location_country);
            setSearchListE(tempFilteredE);
            setSearchListA(tempFilteredA);
            setCountryFilter(country);
        }
    }

    const copy = (url) => {
        const el = document.createElement('input');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        alert(t("Copied"));
    }

    const modifyArtistForURL = (artist_name) => {
        if(artist_name && artist_name != ""){
            artist_name = artist_name.replaceAll(' ','-')
            artist_name = artist_name + "-karten"
            return artist_name
        }else{
            return ""
        }
    }

    const getName = (ele) => {
        if(ele.name[0]){
            return ele.name[0].value
        }else if(ele.name["1"]){
            return ele.name["1"]
        }
    }

    const get_link_rewrite = (ele) => {
        if(ele.link_rewrite[0]){
            if(linkLangCode == 4){
                return ele.link_rewrite[1].value
            }else if(linkLangCode == 7){
                return ele.link_rewrite[2].value
            }
        }else if(ele.link_rewrite["1"]){
            return ele.link_rewrite[linkLangCode]
        }
    }

    return(
        <div className={classes.container}>
            <hr/>
            <div className={classes.para_container}>
                <div className={classes.HeadingText}>
                    <p>{t('Showing_Results_for')}</p>
                    <p>{query}</p>
                </div>
                <div className={classes.select_box}>
                    <CustomSelect
                        optionsList={
                            sortCitiesBy(filterList.map(function(city){
                                return {name:city, value:city}
                            }), "name")
                        }
                        styling={{
                            container: window.screen.width > 500 ? { width: "220px"} : {width: "auto"},
                            defaultText: { background: "var(--blue)",
                                            padding: "5px 30px",
                                            color: "var(--black)",
                                            borderRadius: listOpened === 1 ? "15px 15px 0 0" : "30px" },
                            ul: {},
                            li: { background: "var(--white)",
                                    color: "var(--black)",
                                    border: "1px solid var(--blue)"}
                        }}
                        changed={filterByCity}
                        defaultText={i18n.language === LANG_ENGLISH ? "Filter By City" : "Filtern nach Stadt"}
                        listOpened={(item)=>setListOpened(item)}
                        listOpenIndex={1}
                    />

                </div>
            </div>
            <div className={classes.CountryFilter}>
                {
                    filterCountries.map(function(value){
                        return <div 
                        className={countryFilter == value ? classes.CountrySelected : null}
                        onClick={countryFilter == value ? ()=>filterByCountry("ALL") : ()=>filterByCountry(value)}>
                            <img src={country_flags[CountriesJson.countries[value].iso_code]} />
                        </div>
                    })
                }
            </div>
            { Object.keys(groupedArtists).length ?
            <>
            <hr/>
            <div className={classes.EventsHeading}>
                { query ?
                    <p>"{query}" in {t('Artists')}({Object.keys(groupedArtists).length ? Object.keys(groupedArtists).length : 0}):</p>
                : null }
                <p>{t('Expand_to_see')}</p>
            </div></> : null }
            {
                Object.keys(groupedArtists).length ?
                Object.entries(groupedArtists).map(function([artist, value], index){
                    if(index < artistsShowCount){
                    return <div className={activeTabs['tab'+index] ? [classes.Open, classes.ArtistsTab].join(' ') : classes.ArtistsTab}>
                        <div className={classes.Tab} onClick={(e)=>handleClick(e, index)}>
                            {artist}
                            <span>
                                <b>|</b>
                                    {value[0].event_date.split(' ')[0]} - {value[value.length-1].event_date.split(' ')[0]}
                                <b>|</b>
                                <span>({value.length > 1? value.length+" Events" : "1 Event"})</span>
                                <img src={DownArrow} />
                            </span>
                            {/*<img id="SHAREIMG" src={CopyIcon} className={classes.ShareIcon} onClick={()=>copy(window.location.origin+EVENT_STRING+modifyArtistForURL(artist)) }/>*/}
                        </div>
                        <div className={classes.SearchedEventsContainer}>
                            { activeTabs['tab'+index] && artist === "Depeche Mode" ?
                            <div className={classes.EventsShowcase}>
                                {eventsShowcaseImages.map(function(image, index){
                                    return <div>
                                        <img src={image} />
                                    </div>
                                })}
                            </div> : null }
                            <div className={classes.SearchedEventsList}>
                            { activeTabs['tab'+index] ?
                                value.map((ele,index)=>
                                <div className={[classes.Artists, classes.outer_container].join(' ')}>
                                    <div className={classes.date_container}>
                                    {consoleLog(months, ele)}
                                        <p>{get_date_position(ele.event_date.split(' ')[0], 0)}</p>
                                        <p>{months[get_date_position(ele.event_date.split(' ')[0], 1)].abbreviation[i18n.language]}</p>
                                        <p>{get_date_position(ele.event_date.split(' ')[0], 2)}</p>
                                    </div>
                                    <div className={classes.inner_container}>
                                            <div className={classes.events_text}>
                                                <p><a href={langURL+get_link_rewrite(ele)}>{getName(ele)}</a></p>
                                                <p>{ele.location_city} | {ele.event_date.split(' ')[1]}
                                                { ele.cheapeast == "0" ? null : 
                                                <>
                                                    &nbsp;| <span className={classes.BoldGreen}>
                                                                {t('Vanaf')} {parseFloat(ele.cheapeast).toFixed(2).toString().replace('.',',')} €
                                                            </span>
                                                </>
                                                }
                                                </p>
                                            </div>
                                            <div className={classes.events_button}>
                                                <a href={langURL+get_link_rewrite(ele)}><button type="button">{t('Buy')}</button></a>
                                                { customerDetails ?
                                                <Link
                                                  to={{
                                                    pathname: SELLFORM,
                                                    //search: "?sort=name",
                                                    //hash: "#the-hash",
                                                    state: ele.id
                                                  }}
                                                ><button type="button">{t('sell')}</button></Link>
                                                : <><button type="button" onClick={()=>setShowAlertBox(true)}>{t('sell')}</button>
                                                    { showAlertBox ?
                                                        <AlertBox data={{
                                                            alertTitle:t("Login!"),
                                                            alertMessage:t("Please_login_first_to_sell_a_ticket"),
                                                            acceptText:"Okay",
                                                            denyText:t("Cancel"),
                                                            link: {
                                                                url: HOWITWORKS,
                                                                text: t("How_it_works_2")
                                                            },
                                                            acceptAction: ()=>openLoginBox(ele),
                                                            denyAction: ()=>setShowAlertBox(false)
                                                        }}
                                                        /> : null
                                                    }
                                                </>
                                                }
                                            </div>
                                    </div>
                                    
                                </div>
                                )
                            : null }
                            </div>
                        </div>
                    </div>}
                })
                : null
            }
            { Object.keys(groupedArtists).length <= artistsShowCount ? null
                : <div className={classes.ShowMore} onClick={()=>setArtistsShowCount(artistsShowCount+showCount)}>{t("Show_more")}</div>
            }
            { !artistQuery ?
            Object.keys(groupedEvents).length ?
            Object.entries(groupedEvents).map(function([country_id, value], index){
                return <>
                <hr/>
                <div className={classes.second_container}>
                   <div className={classes.events_location}>
                       <div className={classes.empty_box}>
                           <img src={country_flags[CountriesJson.countries[country_id].iso_code]} />
                       </div>
                       <p>EVENTS IN {CountriesJson.countries[country_id].name[countryLangIndex].value.toUpperCase()}</p>
                   </div>
                   {/*index === 0 ?
                    <div className={classes.select_box}>
                    <CustomSelect
                        optionsList={[
                            {name: "Sort By Date", value: "event_date"},
                            {name: "Sort By City", value: "location_city"}
                            ]}
                        styling={{
                            container: window.screen.width > 500 ? { width: "200px"} : {width: "auto"},
                            defaultText: { background: "var(--blue)",
                                            padding: "5px 30px",
                                            color: "var(--black)",
                                            borderRadius: "30px" },
                            ul: {},
                            li: { background: "var(--white)",
                                    color: "var(--black)",
                                    border: "1px solid var(--black)"}
                        }}
                        changed=
                        defaultText="Filter By City"
                    />

                    </div> : null */}
                </div>
                { index === 0 && query ?
                <div className={classes.EventsHeading}>
                    <p>"{query}" in Events({searchListE.length ? searchListE.length : 0}):</p>
                </div> : null }
                { value.length ?
                value.map(function(ele,index){
                if(index < eventsShowCount){
                return <div className={[classes.Events, classes.outer_container].join(' ')}>
                    <div className={classes.date_container}>
                        <p>{get_date_position(ele.event_date.split(' ')[0], 0)}</p>
                        <p>{months[get_date_position(ele.event_date.split(' ')[0], 1)].abbreviation[i18n.language]}</p>
                        <p>{get_date_position(ele.event_date.split(' ')[0], 2)}</p>
                    </div>
                    <div className={classes.inner_container}>
                            <div className={classes.events_text}>
                                <p><a href={langURL+get_link_rewrite(ele)}>{getName(ele)}</a></p>
                                <p>{ele.location_city} | {ele.event_date.split(' ')[1]}
                                { ele.cheapeast == "0" ? null : 
                                <>
                                    &nbsp;| <span className={classes.BoldGreen}>
                                                {t('Vanaf')} {parseFloat(ele.cheapeast).toFixed(2).toString().replace('.',',')} €
                                            </span>
                                </>
                                }
                                </p>
                            </div>
                            <div className={classes.events_button}>
                                <a href={langURL+get_link_rewrite(ele)}><button type="button">{t('Buy')}</button></a>
                                { customerDetails ?
                                    <Link
                                      to={{
                                        pathname: SELLFORM,
                                        //search: "?sort=name",
                                        //hash: "#the-hash",
                                        state: ele.id
                                      }}
                                    ><button type="button">{t('sell')}</button></Link>
                                    : <><button type="button" onClick={()=>setShowAlertBox(true)}>{t('sell')}</button>
                                        { showAlertBox ?
                                            <AlertBox data={{
                                                alertTitle:t("Login!"),
                                                alertMessage:t("Please_login_first_to_sell_a_ticket"),
                                                acceptText:"Okay",
                                                denyText:t("Cancel"),
                                                link: {
                                                    url: HOWITWORKS,
                                                    text: t("How_it_works_2")
                                                },
                                                acceptAction: ()=>openLoginBox(ele),
                                                denyAction: ()=>setShowAlertBox(false)
                                            }}
                                            /> : null
                                        }
                                    </>
                                }
                            </div>
                    </div>
                    
                </div>
                }}) : null }
                { value.length <= eventsShowCount ? null
                : <div className={classes.ShowMore} onClick={()=>setEventsShowCount(eventsShowCount+showCount)}>{t('Show_more')}</div>
                }
                </>
            }) : null 
            : null
        }
        { !Object.keys(groupedEvents).length && !Object.keys(groupedArtists).length ?
        <div className={classes.NoResults}>
            {t('no_results')}
        </div> : null }
        <div className={classes.EventNotFound}>
            {t('cant_find_an_event_1')}
            <span onClick={()=>setShowContactBox(true)}>{t('click_here')}</span>
            {t('cant_find_an_event_2')}
        </div>
        {
        showContactBox ?
            <ContactUs
                hideContact={()=>setShowContactBox(false)}
                pageDetails="Search Results + My Event Not Found - " // for internal use
            /> : null
        }
        { showLoader ? <LogoLoader /> : null }
        </div>
    )
}
export default SearchResults;