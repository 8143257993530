import React, { useState, useRef, useEffect } from 'react'
import classes from './EditListings.module.css'
import deleteIcon from '../../../assets/images/icons/delete.png'

import { useTranslation } from 'react-i18next';
import ViewIcon from '../../../assets/images/icons/viewetickets1.png'
import CheckIcon from '../../../assets/images/icons/check.png'
import ErrorBox from '../../UI/ErrorBox/ErrorBox';
import AlertBox from '../../UI/AlertBox/AlertBox';
import LogoLoader from '../../UI/LogoLoader/LogoLoader';
import Countries from '../../JsonData/Countries.json';
import CustomSelect from '../../UI/CustomSelect/CustomSelect';
import customerDetails from '../../UserData';
import {checkForLogin} from '../../PrivateRoute/PrivateRoute';
import {SELLFORM} from '../../../URLS';
import cancel from '../../../assets/images/icons/cancel.png'
import axios, {token_config} from '../../../axiosBase';
import consoleLog from '../../../log';
import { getEventDateMinus } from '../../SellTickets/ShippingData';

export default function EditListings(props) {
    const { t, i18n } = useTranslation();
    var statusId = props.statusId
    var index = props.index
    const eventState = props.eventState
    //consoleLog(eventState,"ES",props.blocks)
    const [isHardTicket, setIsHard] = useState(false)
    const fileNameRef = useRef(null);
    const toggleRef = useRef(false);
    const [error, setError] = useState(false);
    const [listOfErrors, setListOfErrors] = useState([]);
    const [showLoader, setShowLoader] = useState(false)
    const [listOpened, setListOpened] = useState(false);
    const [countryCodes, setCountryCodes] = useState([])

    const [chosenCountryCode, setChosenCountryCode] = useState("")

    const [salePrice, setSalePrice] = useState(eventState.price ? (parseFloat(eventState.price) / 1.07).toFixed(2) : 0);
    const [originalPrice, setOriginalPrice] = useState(eventState.org_price ? parseFloat(eventState.org_price).toFixed(2) : 0);
    const [commission, setCommision] = useState({
        receive: "0,00",
        buyer: "0,00"
    })
    const todays_date = new Date();
    const sortPhoneCodes = (data) => {
        data.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });
        return data
    }
    useEffect(() => {
        let phoneCodeList = [];

        Countries.countries.map(function(country){
            phoneCodeList.push({name: "+"+country.call_prefix, value: "+"+country.call_prefix})
        })
        setCountryCodes(sortPhoneCodes(phoneCodeList))
    },[])
    const getCurrentBlockId = () => {
    //SETTING BLOCK ID
    if(props.blocks){
        for(let i=0; i<props.blocks.length; i++){
            if(props.blocks[i].name == eventState.block_name){
                return props.blocks[i].blockID
            }
        }        
    }else{
        return 0
    }
    //SETTING BLOCK ID
    }

    const [quantity, setQuantity] = useState(eventState.quantity);
    const [splitting, setSplitting] = useState(eventState.split);
    const [blockName, setBlockName] = useState(getCurrentBlockId());
    const [businessDays, setBusinessDays] = useState(eventState.days);
    const [rowAd, setRowAd] = useState(eventState.row_ad);
    const [seats, setSeats] = useState(eventState.seats);
    const [adjacentSeats, setAdjacentSeats] = useState(eventState.together);

    const editingEnabled = eventState.editable == "1" && eventState.quantity > 0 ? true : false;

    const [_deleteListing, setDeleteListing] = useState(false);
    const [_deactivateListing, setDeactivateListing] = useState(false);

    const other_details_placeholder = t('other_details_placeholder');

    const addFileName = (e) => {
        const fileName = e.target.files[0].name;
        fileNameRef.current.innerHTML = fileName;
    }

    const checkPriceKeys = (e) => {
        const regex = /^[0-9,.]+$/;
        if (!regex.test(e.key)) {
          e.preventDefault();
        }
    }

    const updateListing = (e) => {
        e.preventDefault();
        let price = e.target.price.value.replace(',','.');
        let org_price = e.target.org_price.value.replace(',','.');

        let errorList = [];
        let payload = {
            id: eventState.id,
            block: blockName,
            row_list: rowAd,
            seats: seats,
            split: splitting,
            together: adjacentSeats
        }

        if(eventState.type === "2"){
            payload['days'] = businessDays
            payload['amount'] = quantity
        }

        if(splitting == 4){
            if(quantity % 2 != 0){
                errorList.push({
                    [t("Quantity")]: t("Quantity_invalid")
                })
            }
        }

        let detailsChecklist = "";
        /*if(e.target.limited_view.checked){
            detailsChecklist =  detailsChecklist + "0,"
        }
        if(e.target.side_view.checked){
            detailsChecklist =  detailsChecklist + "1,"
        }
        if(e.target.behind_stage_view.checked){
            detailsChecklist =  detailsChecklist + "2,"
        }*/
        /*if(e.target.hearing_place.checked){
            detailsChecklist =  detailsChecklist + "3,"
        }*/
        let other_details_field = e.target.other_details.value;
        if(other_details_field && other_details_field.includes('@')){
            errorList.push({
                [t("Other_Details")]: other_details_placeholder
            })
        }

        detailsChecklist = detailsChecklist.slice(0, -1);
        payload["details"] = detailsChecklist;
        payload["other_details"] = other_details_field;

        if(!isNaN(price) && !(price === "")){
            payload["sale_price"] = price;
        }else{
            errorList.push({"Price": "Please enter a valid price"})
        }

        if(!isNaN(org_price) && !(org_price === "")){
            payload["org_price"] = org_price;
        }else{
            errorList.push({"Price": "Please enter a valid price"})
        }

        if(payload["sale_price"] && payload["org_price"]){
            if(parseFloat(payload["sale_price"]) > 1.25*parseFloat(payload["org_price"])){
                errorList.push({
                    [t("Price")]: t("Sale_price_cannot_exceed_25_of_the_original_price")
                })
            }else{
                payload["sale_price"] = payload["sale_price"].replace('.',',');
                payload["org_price"] = payload["org_price"].replace('.',',')
            }
        }

        if(eventState.hasSale == true){
            payload = {
                id: eventState.id,
                sale_price: payload["sale_price"],
                org_price: payload["org_price"],
                amount: payload["amount"],
                other_details: payload["other_details"]
            }
        }

        if(errorList.length){
            setListOfErrors(errorList);
            setError(true);
            return;
        }

        checkForLogin().then(function(token){
        if(token){

        const config = token_config(token)

        consoleLog(payload)
        setShowLoader(true);

        axios.get('/products/'+props.event_id+'?output_format=JSON', config)
            .then(function (product_res) {
                let combs = product_res?.data?.product?.associations?.combinations
                if (combs){
                    combs.map(function(listing, index){
                        if(listing.id === eventState.id && listing.is_in_cart == "0"){
                            axios.put('/listing?a=update', payload, config)
                            .then(function(data){
                                setShowLoader(false);
                                window.location.reload()
                            })
                            .then(function(err){
                                setShowLoader(false);
                                consoleLog(err)
                            })
                        }
                        if(combs.length === index + 1){
                            setShowLoader(false);
                        }

                    })
                }else{
                    setShowLoader(false);
                }
            })

        }})
    }

    const deleteListing = () => {

        checkForLogin().then(function(token){
        if(token){

        const payload={
            id: eventState.id,
            deleted: 1
        }
        const config = token_config(token)

        setShowLoader(true);

        axios.get('/products/'+props.event_id+'?output_format=JSON', config)
            .then(function (product_res) {
                let combs = product_res?.data?.product?.associations?.combinations
                if (combs){
                    combs.map(function(listing, index){
                        if(listing.id === eventState.id && listing.is_in_cart == "0"){
                            axios.put('/listing?a=update', payload, config)
                            .then(function(data){
                                setShowLoader(false);
                                consoleLog(data)
                                window.location.reload()
                            })
                            .then(function(err){
                                setShowLoader(false);
                            })
                        }
                        if(combs.length === index + 1){
                            setShowLoader(false);
                            setDeleteListing(false);
                        }
                    })
                }else{
                    setShowLoader(false);
                }
            })

        }})
    }

    useEffect(()=>{
        let price = salePrice || eventState.price || 0;
        price = price.toString();

        if(price != ""){
            setCommision({
                receive: (parseFloat(price.replace(',','.'))*0.95).toFixed(2).toString().replace('.',','),
                buyer: (parseFloat(price.replace(',','.'))*1.07).toFixed(2).toString().replace('.',',')
            })
        }else{
            setCommision({
                receive: "0,00",
                buyer: "0,00"
            })  
        }
    },[salePrice])


    const reListTicket = () => {
        let event_state = eventState;
        event_state["block"] = blockName;

        props.previousProps.history.push({
            pathname: SELLFORM,
            state: props.event_id,
            editListingData: event_state
        })
    }

    const changeToggle = (accept) => {
        if(accept){
            toggleRef.current.checked = eventState.activated === "1" ? !accept : accept
        }
    }

    return (
        <div>

            <div className={classes.EditForm}>
                <form onSubmit={updateListing}>
                <p className={classes.EditFormp}> {t("EDITING_LISTING")} </p>
                <div className={classes.FormMain}>
                    { todays_date < getEventDateMinus(props.eventDate) && eventState.quantity > 0 ?
                    <div className={classes.ToggleStyle}>
                        <label class="switch">
                            <input type="checkbox" value={statusId} ref={toggleRef} defaultChecked={eventState.activated === "1" ? true : false} name="activated" onClick={(e)=>{e.preventDefault(); setDeactivateListing(true)}} />
                            <span class="slider round"></span>
                        </label>
                    </div> : null }
                    <div className={classes.CrossContainer} onClick={props.closeEditClick}>
                        <img src={cancel} alt="cross"/>
                    </div>
                    <div className={classes.Form1}>
                        <div className={classes.Form1_1}>
                            <div>
                                <span> {t("Ticket_Type")} </span>
                                {/*<select onChange={(e) => { e.target.value == "e-ticket" ? setIsHard(false) : setIsHard(true) }} >
                                    <option value="e-ticket/HardTicket" selected disabled hidden >E-ticket/Hard Ticket</option>
                                    <option value='e-ticket' >E-ticket</option>
                                    <option value='Hard-Ticket'>Hard-Ticket</option>
                                </select>*/}
                                <CustomSelect
                                    optionsList={[]}
                                    styling={{
                                        container: window.screen.width > 820 ? { width: "60%", display: "inline-block", marginBottom: "5px",marginLeft:"25px"} : window.screen.width > 370 ? { width: "210px", display: "inline-block", marginBottom: "5px"} : { width: "150px", display: "inline-block", marginBottom: "5px"},
                                        defaultText: { background: "var(--l-gray)",
                                                        padding: "5px",
                                                        color: "var(--gray)",
                                                        borderRadius: listOpened === 1 ? "10px 10px 0 0" : "10px",
                                                        border: "2px solid var(--blue)" },
                                        ul: {},
                                        li: { background: "var(--white)",
                                                color: "var(--black)",
                                                border: "1px solid var(--blue)"}
                                    }}
                                    defaultText={
                                        eventState.type === "1" ? "E-ticket"
                                        : eventState.type === "2" ? "Hard Ticket"
                                        : eventState.type === "4" ? "Mobile ticket Eventim"
                                        : eventState.type === "5" ? "Mobile ticket Ticketmaster"
                                        : "Ticket"
                                    }
                                    listOpened={(item)=>setListOpened(item)}
                                    listOpenIndex={1}
                                />
                            </div>
                            <div style={{display: "flex"}}>
                                <span>{t("Splitting")}:</span>
                                {/*<select><option value="" disabled selected hidden>Per ticket</option></select><br />*/}
                                {/*name: t("No_Splitting"), value: "2" <= No Splitting option removed },*/}
                                <CustomSelect
                                    optionsList={editingEnabled ? [
                                                    {name: t("Per_ticket"), value: "1"},
                                                    {name: t("Prevent_to_remain_with_1_ticket"), value: "3"},
                                                    {name: t("Only_sell_even_numbers"), value: "4"}
                                                ] : []}
                                    styling={{
                                        container: window.screen.width > 820 ? { width: "60%", display: "inline-block", marginBottom: "5px",marginLeft:"25px"} : window.screen.width > 370 ? { width: "210px", display: "inline-block", marginBottom: "5px"} : { width: "150px", display: "inline-block", marginBottom: "5px"},
                                        defaultText: { background: editingEnabled ? "var(--white)" : "var(--l-gray)",
                                                        padding: "5px 15px",
                                                        color: "var(--gray)",
                                                        borderRadius: listOpened === 2 ? "10px 10px 0 0" : "10px",
                                                        border: "2px solid var(--blue)" },
                                        ul: {},
                                        li: { background: "var(--white)",
                                                color: "var(--black)",
                                                border: "1px solid var(--blue)"}
                                    }}
                                    changed={(value) => setSplitting(value)}
                                    defaultText={eventState.split === "1" ? t("Per_ticket") : eventState.split === "2" ? t("No_Splitting") : eventState.split === "3" ? t("Prevent_to_remain_with_1_ticket") : eventState.split === "4" ? t("Only_sell_even_numbers") : null}
                                    listOpened={(item)=>setListOpened(item)}
                                    listOpenIndex={2}
                                />
                            </div>
                            <div>
                                <span>{t("Quantity")}:</span>
                                {/*<select>
                                    <option value="10" selected disabled hidden >10</option>
                                </select><br />*/}
                                <CustomSelect
                                    optionsList={editingEnabled && ( eventState.type === "2" || eventState.type === "4" || eventState.type === "5" ) ? 
                                                    Array(eventState.quantity).fill().map(function(value, index){
                                                        return {name: index, value: index}
                                                    })
                                                    : []
                                                }
                                    styling={{
                                        container: window.screen.width > 920 ? { width: "60%", display: "inline-block", marginBottom: "5px",marginLeft:"25px"} : window.screen.width > 370 ? { width: "210px", display: "inline-block", marginBottom: "5px"} : { width: "150px", display: "inline-block", marginBottom: "5px"},
                                        defaultText: { background: editingEnabled && ( eventState.type === "2" || eventState.type === "4" || eventState.type === "5" ) ? "var(--white)" : "var(--l-gray)",
                                                        padding: "5px",
                                                        color: "var(--gray)",
                                                        borderRadius: listOpened === 4 ? "10px 10px 0 0" : "10px",
                                                        border: "2px solid var(--blue)" },
                                        ul: {},
                                        li: { background: "var(--white)",
                                                color: "var(--black)",
                                                border: "1px solid var(--blue)"}
                                    }}
                                    changed={(value) => setQuantity(value)}
                                    defaultText={eventState.quantity}
                                    listOpened={(item)=>setListOpened(item)}
                                    listOpenIndex={4}
                                />
                            </div>
                        </div>
                        <div className={classes.Form1_2}>
                            <div>
                                <span>{t("Block")}:</span>
                                {/*<select><option value="" disabled selected hidden>Mitterlang Block A</option></select>*/}
                                <CustomSelect
                                    optionsList={editingEnabled && props.blocks ?
                                                props.blocks.map(function(value, index){
                                                    return {name: value.name, value: value.blockID}
                                                }) : []}
                                    styling={{
                                        container: window.screen.width > 820 ? { width: "60%", display: "inline-block", marginBottom: "5px",marginLeft:"25px"} : window.screen.width > 370 ? { width: "210px", display: "inline-block", marginBottom: "5px"} : { width: "150px", display: "inline-block", marginBottom: "5px"},
                                        defaultText: { background: editingEnabled ? "var(--white)" : "var(--l-gray)",
                                                        padding: "5px",
                                                        color: "var(--gray)",
                                                        borderRadius: listOpened === 3 ? "10px 10px 0 0" : "10px",
                                                        border: "2px solid var(--blue)" },
                                        ul: {},
                                        li: { background: "var(--white)",
                                                color: "var(--black)",
                                                border: "1px solid var(--blue)"}
                                    }}
                                    changed={(value) => setBlockName(value)}
                                    defaultText={eventState.block_name ? eventState.block_name : "NA"}
                                    listOpened={(item)=>setListOpened(item)}
                                    listOpenIndex={3}
                                />
                            </div>
                            {/*eventState.type !== "1" &&
                            <label className={classes.ShippedContainer} > <span > {t("Shipped_within")} </span>
                                {/*<select ><option value="" disabled selected hidden> {t("2_business_days")} </option></select>}
                            <CustomSelect
                                optionsList={editingEnabled ? Array(5).fill().map(function(value, index){
                                                        if(index === 0){
                                                            return {name: index+1+" "+t("sell_business_day"), value: index+1}
                                                        }else{
                                                            return {name: index+1+" "+t("sell_business_days"), value: index+1}
                                                        }
                                                    }) : []}
                                styling={{
                                    container: window.screen.width > 890 ? { width: "60%", display: "inline-block", marginBottom: "5px",marginLeft:"25px"}:window.screen.width > 600 ? { width: "210px", display: "inline-block", marginBottom: "5px",marginLeft:"65px"} : window.screen.width > 370 ? { width: "210px", display: "inline-block", marginLeft:"65px",marginBottom: "5px",marginTop:"-6px"} : { width: "150px", display: "inline-block", marginBottom: "5px",marginLeft:"65px"},
                                    defaultText: { background: editingEnabled ? "var(--white)" : "var(--l-gray)",
                                                    padding: "5px",
                                                    color: "var(--gray)",
                                                    borderRadius: listOpened === 5 ? "10px 10px 0 0" : "10px",
                                                    border: "2px solid var(--blue)" },
                                    ul: {},
                                    li: { background: "var(--white)",
                                            color: "var(--black)",
                                            border: "1px solid var(--blue)"}
                                }}
                                changed={(value) => setBusinessDays(value)}
                                defaultText={eventState.days +" "+ t("business_days")}
                                listOpened={(item)=>setListOpened(item)}
                                listOpenIndex={5}
                            /><br /></label>
                            */}
                            <div>
                                <span> {t("Row")}: </span>
                                <input type="text" placeholder="" className={ editingEnabled ? [classes.RowInput, classes.Allowed].join(' ') : classes.RowInput} value={rowAd} onChange={(e)=>setRowAd(e.target.value)} />
                            </div>
                            <div>
                                <span>{t("seat_number")}:</span>
                                <input type="text" placeholder="optional" className={ editingEnabled ? [classes.RowInput, classes.Allowed].join(' ') : classes.RowInput} name="seats" defaultValue={seats} onChange={(e)=>setSeats(e.target.value)}  />
                            </div>
                            {/*<select><option value="" disabled selected hidden> {t("Optional")}</option></select>*/}
                            {/*<CustomSelect
                                optionsList={[]}
                                styling={{
                                    container: window.screen.width > 820 ? { width: "135px", display: "inline-block", marginBottom: "5px"} : window.screen.width > 370 ? { width: "210px", display: "inline-block", marginBottom: "5px"} : { width: "150px", display: "inline-block", marginBottom: "5px"},
                                    defaultText: { background: "var(--white)",
                                                    padding: "5px",
                                                    color: "var(--gray)",
                                                    borderRadius: "10px",
                                                    border: "2px solid var(--m-gray)" },
                                    ul: {},
                                    li: { background: "var(--white)",
                                            color: "var(--black)",
                                            border: "1px solid var(--blue)"}
                                }}
                                defaultText="Optional"
                            />*/}
                           
                           <div className={classes.OtherContainer} >
                             <label className={classes.TableAdj1}>Adjacent Seats:</label>
                                <input className={classes.TableAdj} type="checkbox" defaultChecked={eventState.together === "1" ? true : false} onClick={(e) => { editingEnabled ? e.target.checked ? setAdjacentSeats(1) : setAdjacentSeats(0) : e.preventDefault(); e.stopPropagation(); }} />
                              
                            </div>
                        </div>
                        
                    </div>
                   
                    <div className={classes.TableHr1}></div>
                <p className={classes.TableOther}> {t("Other_ticket_details")} </p>
                  <div className={classes.InformContainer}>
                     <div className={classes.IClassContainer}><p>i</p></div>
                     <div className={classes.ParaContainer}>
                        <p>{t('other_details_warning_mylistings')}</p>
                        </div>
                  </div>
                    <div className={classes.EditFlexMain}>
                        
                        {/* <div className={classes.EditFlex}>
                           
                            <div >
                                <input className={classes.TableAdj} type="checkbox" defaultChecked={eventState.details.includes("1") ? true : false} name="side_view" value="1" onClick={ editingEnabled ? {} : (event)=>{event.preventDefault();event.stopPropagation();} } />
                                <label className={classes.TableAdj1}>{t("Side_View")}</label>
                            </div>
                            <div >
                                <input className={classes.TableAdj} type="checkbox" defaultChecked={eventState.details.includes("2") ? true : false} name="behind_stage_view" value="2" onClick={ editingEnabled ? {} : (event)=>{event.preventDefault();event.stopPropagation();} } />
                                <label className={classes.TableAdj1}>{t("Behind_Stage_View")}</label>
                            </div>
                            <div >
                                <input className={classes.TableAdj} type="checkbox" defaultChecked={eventState.details.includes("0") ? true : false} name="limited_view" value="0" onClick={ editingEnabled ? {} : (event)=>{event.preventDefault();event.stopPropagation();} } />
                                <label className={classes.TableAdj1}>{t("Limited_View")}</label>
                            </div>
                        </div> */}
                    </div>
                    <div className={[classes.Form1_1, classes.OtherDetailsTextArea].join(' ')}>
                       <textarea placeholder={"Optional: "+other_details_placeholder} name="other_details" disabled={!editingEnabled ? "disabled" : false} defaultValue={eventState.other_details}></textarea>
                    </div>
                    {error &&
                        <ErrorBox data={{ "errorTitle": t("Error(s)"), errorSubTitle: t("please_resolve_your_errors"), errors: listOfErrors, acceptText: "OK", acceptAction: () => { setError(false) } }} />
                    }

                    {eventState.type === "2" && eventState.ticket_image != "" ?
                        <div className={classes.HardTicket}>
                            <div className={classes.HardTicketText}>
                            <p>{t('Ticket_image')}:</p>
                            </div>
                            <div className={classes.HardTicketPhoto}>
                                <div>
                                    <img src={process.env.REACT_APP_DOMAIN+eventState.ticket_image} />
                                    {/*<label htmlFor="browse_id">
                                        <span className={classes.Browse}>{t("Browse")}</span>
                                        <input id="browse_id" className={classes.BrowseHide} type="file" onChange={(e) => addFileName(e)} />
                                        <div ref={fileNameRef} className={classes.FileNameText}></div>
                                    </label>*/}
                                </div>
                            </div>
                        </div>
                    : null }
                    { eventState.shipping_methods ?
                    <div className={classes.ShippingOptions}>
                        <div className={classes.ShippingValueContainer}>
                            <div className={classes.ShippingText}>
                                <p>{t('shipping_option')}:</p>
                                <div className={classes.ShippingInfo}>
                                    <div>
                                        <span>i</span>
                                        {t('shipping_method_info')}
                                    </div>
                                </div>
                            </div>
                            <div>
                            { eventState.shipping_methods.split(",").map(function(method){
                                return <div className={classes.ShippingMethodText}>
                                    <p>{t('shipping_'+method+'_title')}</p>
                                    <div className={classes.Help}>
                                        <div>i</div>
                                        <span className={classes.Tooltip}>{t('shipping_'+method+'_tooltip')}</span>
                                    </div>
                                </div>
                            }) }
                                <div className={classes.ShippingValue}>
                                    {
                                    eventState.shipping_methods.includes("3") ?
                                        <div className={classes.PickUp}>
                                            <div className={[classes.InputMethod,classes.PhoneInput].join(" ")}>
                                                <label className={classes.PhoneNumberField}>
                                                    <CustomSelect
                                                        optionsList={editingEnabled ? countryCodes : []}
                                                        changed={setChosenCountryCode}
                                                        styling={{
                                                            container:window.screen.width > 850 ? { width: "70px", display: "inline-block",position:"absolute",zIndex:1} : window.screen.width > 500 ? { width: "70px",position:"absolute",zIndex:1} : window.screen.width>400? { width: "70px",position:"absolute",zIndex:1}: {},
                                                            defaultText: { background: editingEnabled ? "var(--white)" : "var(--l-gray)",
                                                                            padding: "6px 10px",
                                                                            paddingLeft: "1px",
                                                                            color: "var(--black)",
                                                                            borderRadius: listOpened === 1 ? "10px 10px 0 0" : "10px 0px 0px 10px",
                                                                            fontSize: "14px",
                                                                            // height: "29px",
                                                                            boxSizing: "border-box",
                                                                            border: "2px solid var(--blue)" },
                                                            ul: {},
                                                            li: { background: "var(--white)",
                                                                    color: "var(--black)",
                                                                    paddingLeft: "5px",
                                                                    paddingRight: "5px",
                                                                    border: "1px solid var(--blue)"}
                                                        }}
                                                        defaultText={eventState.pick_up_phone && eventState.pick_up_phone !== "" ? eventState.pick_up_phone.split('-')[0] : ""}
                                                        listOpened={(item)=>setListOpened(item)}
                                                        listOpenIndex={1}
                                                    />
                                                    <input
                                                        type="text"
                                                        placeholder={t('Phone_Number')}
                                                        name="pick_up_phone"
                                                        defaultValue={eventState.pick_up_phone && eventState.pick_up_phone !== "" ? eventState.pick_up_phone.split('-')[1] : ""}
                                                        disabled={!editingEnabled ? "disabled" : false} />
                                                </label>
                                            </div>
                                        </div>
                                    : null
                                  }
                                </div>
                            </div>
                        </div>
                    </div> : null }
                    <div className={classes.Form2}>
                        <div className={classes.Form1}>
                            <div className={classes.Form2_1}>
                                <span>{t("Face_Value")}:</span>
                                <input type="text" placeholder="0.90" value={originalPrice} name="org_price" onChange={(e)=>{setOriginalPrice(e.target.value)}} disabled={!editingEnabled ? "disabled" : false} onKeyPress={(e)=>checkPriceKeys(e)} />
                            </div>
                            <div className={classes.Form2_2} >
                                <span>{t("Selling_Price")}:</span>
                                <input type="text" placeholder="1.00" value={salePrice} name="price" onChange={(e)=>{setSalePrice(e.target.value)}} disabled={!editingEnabled ? "disabled" : false}  onKeyPress={(e)=>checkPriceKeys(e)} />
                            </div>
                        </div>
                        <div className={classes.Form1}>
                            <div className={classes.PriceDiv1} >
                                <span> {t("You'll_receive_per_ticket")}:</span>
                                <span className={classes.Price}>€ {commission.receive}</span>
                            </div>
                            <div className={classes.PriceDiv2} >
                                <span> {t("Buyer_Price")}:</span>
                                <span className={classes.Price}>€ {commission.buyer}</span>
                            </div>

                        </div>
                    </div>
                    <div className={classes.EditButtons}>
                        {eventState.type === "1" && <span className={classes.TableButtonView}>
                        <button onClick={props.view} className={classes.TableButton1} type="button"><img className={classes.ViewBlack} src={ViewIcon} alt="" />{t("View_e-tickets")}</button></span>}
                        { editingEnabled && eventState.is_in_cart === "0" ?
                        <>
                        <button className={classes.TableButton2} type="submit"><img src={CheckIcon} alt="" />{t('Save_Edits')}</button>
                        </>
                        : todays_date < getEventDateMinus(props.eventDate) ?
                            <button className={classes.ReListButton} type="button" onClick={reListTicket}>{t('relist_ticket')}</button>
                            : null
                        }
                        { eventState.hasSale == false && eventState.is_in_cart === "0" ?
                        <button className={classes.TableButton3} type="button" onClick={()=>setDeleteListing(true)}><img src={deleteIcon} alt="" />{t('Delete_Listing')}</button>
                        : null }
                    </div>

                </div>
                </form>
            </div>
            { _deleteListing ? <AlertBox data={{
                alertTitle: t("delete"),
                alertMessage: t("delete_ad_message"),
                acceptText: t("yes"),
                denyText: t("no"),
                acceptAction: () => deleteListing(),
                denyAction: ()=> setDeleteListing(false)
            }} /> : null }
            { _deactivateListing ? <AlertBox data={{
                alertTitle: eventState.activated === "1" ? t("deactivate_listing") : t("activate_listing"),
                alertMessage: eventState.activated === "1" ? t("deactivate_ad_message") : t("activate_ad_message"),
                acceptText: t("yes"),
                denyText: t("Cancel"),
                acceptAction: () => {changeToggle(true); eventState.activated === "1" ? props.listingState(eventState, false) : props.listingState(eventState, true)},
                denyAction: ()=> {setDeactivateListing(false); changeToggle(false)}
            }} /> : null }
            { showLoader ? <LogoLoader /> : null }
        </div >
    )
}