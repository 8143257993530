import React, {useState, useEffect} from 'react';
import classes from './ResetPassword.module.css';
import axios, { config } from '../../axiosBase';
import LogoLoader from '../UI/LogoLoader/LogoLoader';
import ErrorBox from '../UI/ErrorBox/ErrorBox';
import {HOME} from '../../URLS';
import { useTranslation } from 'react-i18next';

import {PasswordPattern} from '../../Formats';

const ResetPassword = (props) => {

	const [showResetForm, setShowResetForm] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [errorList, setErrorList] = useState([])
	const [errorSubmit, setErrorSubmit] = useState(false)

    const { t, i18n } = useTranslation();

	const queryParams = new URLSearchParams(window.location.search);
	const customer_id = queryParams.get('id_customer');
	const token = queryParams.get('token');
	const reset_token = queryParams.get('reset_token');

	useEffect(()=>{
		if(customer_id && reset_token && token){
			setShowResetForm(true);			
		}
	},[])

	const submitResetPasswordForm = (e) => {
		e.preventDefault();
		const password = e.target.password.value;
		const confirm_password = e.target.confirm_password.value;

		const format = PasswordPattern;

		if(password.length > 7 && format.test(password) && password === confirm_password){
			const payload = {
			    "passwd":password,
			    "confirmation":confirm_password,
			    "token":token,
			    "id_customer":customer_id,
			    "reset_token":reset_token
			}
			setShowLoader(true);
			axios.post('/jwt?a=reset', payload, config)
			.then(function(res){
				setShowLoader(false);
				setSubmitted(true);
			})
			.catch(function(res){
				setErrorList([{"": t("some_error_occurred")}])
        		setErrorSubmit(true);
				setShowLoader(false);
			})
		}else{
			setErrorList([{"": t("invalid_password")}])
        	setErrorSubmit(true);
		}
	}

	return (
		<div className={classes.ResetPassword}>
		    <div className={classes.ResetPasswordContainer}>
                <div className={classes.WrapBox}>
                    {
                        showResetForm && submitted ?
                        <>
                        <div className={classes.Text}>{t("password_changed_successfully")} {t("go_to")} <a href={HOME}>{t("home_page")}</a> {t("to_login")}</div>
                        <div className={classes.ActionButtons}>
                            <button onClick={()=> window.location.href = HOME} type="button">OK</button>
                        </div>
                        </>
                        :
                        showResetForm ?
                        <>
                        <div className={classes.Text}>
	                        <div>{t("password_format_text")}</div>
                        </div>
                        <form onSubmit={(e) => submitResetPasswordForm(e)}>
                            <div className={classes.InputMethod}>
                                <input type="text" name="password" placeholder={t("New_password")} />
                                <input type="text" name="confirm_password" placeholder={t("Confirm_new_password")} />
                            </div>
                            <div className={[classes.ActionButtons, classes.TwoButtons].join(' ')}>
                                <button type="submit">{t("Save")}</button>
                                <button  onClick={(e)=>{e.preventDefault(); window.location.href = HOME;}} type="button">{t("Cancel")}</button>
                            </div>
                        </form>
                        </>
                        :
                        <>
                        <div className={classes.Text}>{t("invalid_link")}</div>
                        <div className={classes.ActionButtons}>
                            <button onClick={()=> window.location.href = HOME} type="button">OK</button>
                        </div>
                        </>
                        }
                </div>
            </div>
        {
            errorSubmit &&
            <ErrorBox data={{
                errorTitle: t("Error(s)"),
                errorSubTitle: t('please_resolve_your_errors'),
                errors: errorList,
                acceptText: "OK",
                acceptAction: () => { setErrorSubmit(false) }
            }} />

        }
        { showLoader ? <LogoLoader /> : null }
		</div>
	);

}

export default ResetPassword;