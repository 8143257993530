import React, { useRef, useState, useEffect } from 'react';
import classes from './MakePayment.module.css';

import PersonalInfo from '../../Profile/PersonalInfo/PersonalInfo';

import VerifiedIcon from '../../../assets/images/icons/verified.png';
import UserIcon from '../../../assets/images/icons/sellerprofile.png';
//import LeftChevron from '../../../assets/images/icons/left-chevron.png';
import SessionTimer from '../../../assets/images/icons/sessiontimer.png';
import LoadingIcon from '../../../assets/images/icons/loadingicon.png';

import PaypalIconBlue from '../../../assets/images/icons/paypal1.png';
import PaypalIconBlack from '../../../assets/images/icons/paypal2.png';
import SofortIconBlue from '../../../assets/images/icons/sofort1.png';
import SofortIconBlack from '../../../assets/images/icons/sofort2.png';

import Klarna from '../../../assets/images/icons/klarna.png';

import StripeIconBlue from '../../../assets/images/icons/stripe1.png';
import StripeIconBlack from '../../../assets/images/icons/stripe2.png';

import Step1Prog from '../../../assets/images/icons/step1.png';
import Step1Comp from '../../../assets/images/icons/step1filled.png';
import Step2Inc from '../../../assets/images/icons/step2incomplete.png';
import Step2IncET from '../../../assets/images/icons/step2etincomplete.png';
import Step2IncMT from '../../../assets/images/icons/step2mtincomplete.png';
import Step3Inc from '../../../assets/images/icons/step3incomplete.png';
import Step2Prog from '../../../assets/images/icons/step2inprogress.png';
import Step2ProgET from '../../../assets/images/icons/step2etinprogress.png';
import Step2ProgMT from '../../../assets/images/icons/step2mtinprogress.png';
import Step3Prog from '../../../assets/images/icons/step3inprogress.png';
import Step2Comp from '../../../assets/images/icons/step2done.png';
import Step2CompET from '../../../assets/images/icons/step2etdone.png';
import Step2CompMT from '../../../assets/images/icons/step2mtdone.png';
//import Step3Comp from '../../../assets/images/icons/step3done.png';
import DownArrow from '../../../assets/images/icons/down_arrow.png';
import UpArrow from '../../../assets/images/icons/upload.png';
import ppbutton from '../../../assets/images/icons/ppbutton.png';
import yourorder from '../../../assets/images/icons/yourorder.png';

import ContactUs from '../../ContactUsBox/ContactUs';

import PurchaseSuccess from '../../../assets/images/icons/purchasesuccess.png';
//import PurchaseFailed from '../../../assets/images/icons/purchaseerror.png';
import PurchaseTimeout from '../../../assets/images/icons/sessiontimedout.png';
import { TERMS, PRIVACYPOLICY, ORDERS, LANG_GERMAN } from '../../../URLS';
import { useTranslation } from 'react-i18next';
import Timer from '../Timer/Timer';
import {EmailPattern, NamePattern} from '../../../Formats';

import Paypal from './Payments/Paypal';
import Stripe from './Payments/Stripe/Stripe';
import Sofort from './Payments/Sofort/Stripe';
import consoleLog from '../../../log';
import CountriesByIndex from '../../JsonData/CountriesByIndex.json';
import { PutCart, PostCart } from '../../Cart/CartFunctions';
import { deleteCart } from './Payments/PostPayment';
import visa from '../../../assets/images/icons/visanew.png'
import americanexpress from '../../../assets/images/icons/americanexpressnew.png'
import paypal from '../../../assets/images/icons/paypalnew.png'
import mastercard from '../../../assets/images/icons/mastercard.png'
import klarnanew from '../../../assets/images/icons/klarnanew.png'
import {ShippingOptionsDefault, prepareShippingMethodsBuying, ShippingMethodsCode, getEventDateMinus} from '../../SellTickets/ShippingData';
import customerDetails from '../../UserData';
import axios, { config } from '../../../axiosBase';


const MakePayment = (props) => {

	const ticketDetails = props.ticketDetails;
	const [step, setStep] = useState(1);
	const agreementRef = useRef(null);
	const [agreementError, setAgreementError] = useState(false);
	const [showCart, setShowCart] = useState(true);
	const [showProfileNextButton, setShowProfileNextButton] = useState(false);
	const [expandMobileCart, setExpandMobileCart] = useState(false);
	const [chosenPaymentMethod, setChosenPaymentMethod] = useState(false);
	const [paymentLoader, setPaymentLoader] = useState(false);
	const { t, i18n } = useTranslation();
	const [showContactBox, setShowContactBox] = useState(false);

	const [paymentStatus, setPaymentStatus] = useState({});
	const hoursMinSecs = { hours: 0, minutes: 15, seconds: 0 }

	const [paypalButtonEnabled, enablePaypalButton] = useState(false)
    const [shippingOptionsData, setShippingOptionsData] = useState(JSON.parse(ShippingOptionsDefault))
    const [shippingMethod, setShippingMethod] = useState(null);
    const languageCode = localStorage.getItem("i18nextLng") === LANG_GERMAN ? 1 : 2;

	const [priceBreakdownShow, setPriceBreakdownShow] = useState(false)
	const [ticketmasterDetails, setTicketmasterDetails] = useState({
		tm_email: "",
		tm_f_name: "",
		tm_l_name: ""
	});
	const [ticketmasterDetailsCollected, setTicketmasterDetailsCollected] = useState(false);
	
	const timeBeforeSellerTransfersTickets = {
		"5": t('3_working_days'),
		"4": t('24_hours'),
		"1": t('1_hour')
	}

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setTicketmasterDetails({ ...ticketmasterDetails, [name]: value });

		const emailTest = EmailPattern.test(name === "tm_email" ? value : ticketmasterDetails.tm_email)
		const fnameTest = NamePattern.test(name === "tm_f_name" ? value : ticketmasterDetails.tm_f_name)
		const lnameTest = NamePattern.test(name === "tm_l_name" ? value : ticketmasterDetails.tm_l_name)

		if(emailTest && fnameTest && lnameTest){
			setTicketmasterDetailsCollected(true)
		}else{
			setTicketmasterDetailsCollected(false)
		}
	};

	const changeStep = (value) => {
		if (step + value > 0) {
			setStep(step + value)
		}
	}

	// SHIPPING PRICES
	const shipping_prices = {
		"Germany": "3.50",
		"All": "4.60",
		"DHL": "13.50",
		"ETicket": "0.00",
		"PickUpVenue": "0.00",
		"InternationalSeller": "0.00"
	}
	const shipping_texts = {
		"title": {
			"hard-ticket": t("International_Enroll"),
			"e-ticket": t("download_your_ticket")
		},
		"text": {
			"hard-ticket": "",
			"e-ticket": t("etickets_shipping_text")
		}
	}
	if (ticketDetails.allDetails) {
		shipping_texts["text"]["hard-ticket"] = t("hardtickets_shipping_text_1") + " " + ticketDetails.allDetails.days + " " + t("hardtickets_shipping_text_2")
	}
	const shipping_price = ticketDetails.ticketType === "hard-ticket" ?
			sessionStorage.getItem("address_country") == "1" ?
				shipping_prices["Germany"]
			: shipping_prices["All"]
		: shipping_prices["ETicket"];

	// SHIPPING PRICES

	// SHIPPING CARRIER ID
	const shipping_id_carriers = {
		"Germany": "26",
		"GermanyDHL": "30",
		"PickUpVenue": "32",
		"All": "29",
		"ETicket": "21"
	}
	let shipping_id_carrier = shipping_id_carriers["ETicket"];

	if (ticketDetails.ticketType == "hard-ticket") {
		if (sessionStorage.getItem("address_country") == "1") {
			shipping_id_carrier = shipping_id_carriers["Germany"];
		} else {
			shipping_id_carrier = shipping_id_carriers["All"];
		}
	}

	const [price_breakdown, set_price_breakdown] = useState({
		subtotal: "0.00",
		shipping: "0.00",
		total: "0.00",
		base_price: "0.00",
		service_fee: "0.00"
	})
	/*if (ticketDetails.price) {
		price_breakdown.subtotal = (parseFloat(ticketDetails.price.split(' ')[1]) * ticketDetails.quantity).toFixed(2).toString();
		price_breakdown.base_price = (parseFloat(price_breakdown.subtotal) / 1.07).toFixed(2).toString();
		price_breakdown.service_fee = (parseFloat(price_breakdown.subtotal) - parseFloat(price_breakdown.base_price)).toFixed(2).toString();
		price_breakdown.shipping = shipping_price;
		price_breakdown.total = (parseFloat(ticketDetails.price.split(' ')[1]) * ticketDetails.quantity + parseFloat(shipping_price)).toFixed(2).toString();
	}*/

	const TimerOut = () => {
		proceedAfterPayment("TIMEOUT")
	}

	const doPayment = () => {
		if (!agreementRef.current.checked) {
			setAgreementError(true)
			return;
		}

		PutCart().then(function (res) {
			if (res) {
				// Proceed to pay
				if (chosenPaymentMethod) {
					document.getElementById("payment-background").style.overflow = "unset";
					if (chosenPaymentMethod === "PAYPAL") {
						// Do nothing
					} else if (chosenPaymentMethod === "SOFORT") {
						sessionStorage.setItem("totalPrice", price_breakdown.total)
						document.getElementById("submitSofortPayment").click()
						setPaymentLoader(true)
					} else if (chosenPaymentMethod === "STRIPE") {
						document.getElementById("submitStripePayment").click()
						setPaymentLoader(true)
					}
				} else {
					alert(t('Please_select_your_payment_method'));
				}
			} else {
				// Prevent user from proceeding
				props.cartFailed(true);
			}
		})

	}

	const onChangeAgreement = () => {
		if (agreementRef.current.checked) {
			setAgreementError(false)
			enablePaypalButton(true)
		} else {
			setAgreementError(true)
			enablePaypalButton(false)
		}
	}

	const proceedAfterPayment = (payment_status) => {
		setShowCart(false);
		setStep(6);
		const paymentIs = payment_status;

		if (paymentIs === "SUCCESS") {
			let paymentStatusTitle = "Purchase_complete";
			if (window.screen.width < 700) {
				paymentStatusTitle = "Success"
			}
			setPaymentStatus({
				status: true,
				paymentStatusTitle: paymentStatusTitle,
				paymentStatusMessage: "Success_your_payment_is_complete",
				paymentStatusIcon: PurchaseSuccess,
				paymentStatusButtonText: "View_Purchases",
				paymentStatusButtonAction: () => { window.location.href = ORDERS }
			})
		} else if (paymentIs === "FAILED") {
			let paymentStatusTitle = "Purchase_unsuccessful";
			if (window.screen.width < 700) {
				paymentStatusTitle = "Unsuccessful"
			}
			setPaymentStatus({
				status: false,
				paymentStatusTitle: paymentStatusTitle,
				paymentStatusMessage: "Oops_there_was_an_error_due_to_which_your_purchase_was_unsuccessful",
				paymentStatusIcon: null,
				paymentStatusButtonText: "Retry_Purchase",
				paymentStatusButtonAction: props.retry
			})
		} else if (paymentIs === "TIMEOUT") {
			setPaymentStatus({
				status: false,
				paymentStatusTitle: "Session_expired",
				paymentStatusMessage: "Sorry_your_session_was_timed_out",
				paymentStatusIcon: PurchaseTimeout,
				paymentStatusButtonText: "Retry_Purchase",
				paymentStatusButtonAction: props.retry
			})
		}
	}


	const selectPaymentMethod = (e, payment_method) => {

		const totalPaymentMethods = 3;
		const imageIndex = 3;
		const taglineIndex = 4;
		const subTaglineIndex = 5;
		const labels = e.target.parentNode.parentNode.childNodes;
		const targetLabel = e.target.parentNode;

		for (let i = 0; i < totalPaymentMethods; i++) {
			labels[i].style.background = "var(--white)";
			labels[i].style.color = "var(--black)";
			labels[i].childNodes[subTaglineIndex].style.display = "none";

			// if (labels[i].className.includes('PAYPAL')) {
			// 	labels[i].childNodes[imageIndex].childNodes[0].src = PaypalIconBlue;
			// } else if (labels[i].className.includes('STRIPE')) {
			// 	labels[i].childNodes[imageIndex].childNodes[0].src = StripeIconBlue;
			// } else if (labels[i].className.includes('SOFORT')) {
			// 	labels[i].childNodes[imageIndex].childNodes[0].src = Klarna;//SofortIconBlue;
			// }
		}

		targetLabel.style.background = "var(--blue)";
		targetLabel.style.color = "var(--black)";
		targetLabel.childNodes[subTaglineIndex].style.display = "block";

		// if (targetLabel.className.includes("PAYPAL")) {
		// 	targetLabel.childNodes[imageIndex].childNodes[0].src = PaypalIconBlack;
		// } else if (targetLabel.className.includes("STRIPE")) {
		// 	targetLabel.childNodes[imageIndex].childNodes[0].src = StripeIconBlack;
		// } else if (targetLabel.className.includes("SOFORT")) {
		// 	targetLabel.childNodes[imageIndex].childNodes[0].src = Klarna;//SofortIconBlack;
		// }

		setChosenPaymentMethod(payment_method)
	}

	const LeavePayment = () => {
		const text = t("payment_warning");
		var r = window.confirm(text);
		if (r == true) {
			deleteCart(sessionStorage.getItem("cart"))
			props.close()
		} else {
			//Do nothing
		}
	}

	const finishPayment = () => {
		//window.location.href = window.location.pathname;
		const url = sessionStorage.getItem("event_url");
		window.location.href = url;
	}

	const setNewPricing = (shipping_price) => {
		if(ticketDetails.price){
			let subtotal = (parseFloat(ticketDetails.price.split(' ')[1]) * ticketDetails.quantity).toFixed(2)
			let base_price = (subtotal / 1.07).toFixed(2)
			let service_fee = (subtotal - base_price).toFixed(2)
			let total = (parseFloat(subtotal) + parseFloat(shipping_price)).toFixed(2)

			set_price_breakdown({
				"subtotal": subtotal,
				"base_price": base_price,
				"service_fee": service_fee,
				"shipping": shipping_price,
				"total": total
			})
		}
	}

	const setChosenShippingMethod = () => {
		if(props.ticketDetails.ticketType === "hard-ticket"){
			if(shippingMethod){
				sessionStorage.setItem("shipping_method", shippingMethod)
				if(shippingMethod == "2"){
					sessionStorage.setItem("shipping_id_carrier", shipping_id_carriers["GermanyDHL"])
				}else if(shippingMethod == "3"){
					sessionStorage.setItem("shipping_id_carrier", shipping_id_carriers["PickUpVenue"])
				}else{
					sessionStorage.setItem('shipping_id_carrier', shipping_id_carrier)
				}
			}
		}else if(props.ticketDetails.ticketType === "ticketmaster"){
			sessionStorage.setItem("ticketmaster_details", JSON.stringify(ticketmasterDetails))
			sessionStorage.setItem('shipping_id_carrier', shipping_id_carrier)
		}else{
			sessionStorage.setItem('shipping_id_carrier', shipping_id_carrier)
		}
	}

	const getChosenShippingMethod = (_shippingMethod) => {
		let shipping_method_name = "";

		if(_shippingMethod){
			if(_shippingMethod == "1"){
				if (sessionStorage.getItem("address_country") == "1") {
					shipping_method_name = t("shipping_1_title")
				}else{
					shipping_method_name = t("shipping_4_title")
				}
			}else{
				shipping_method_name = t("shipping_"+_shippingMethod+"_title")
			}

			shipping_method_name = "("+shipping_method_name+")"
		}
		return shipping_method_name;
	}

	const timeBeforeEventForMobileTicket = () => {
		const currentDate = new Date();
		const event_date = getEventDateMinus(props.eventDetails.event_date);

		const differenceInTime = event_date.getTime() - currentDate.getTime();
		const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

		const key = differenceInDays > 4 ? "5" : differenceInDays > 1 ? "4" : "1"
		return timeBeforeSellerTransfersTickets[key];
	}

	useEffect(() => {
		if (props.paymentStatus) {
			proceedAfterPayment(props.paymentStatus);
		}
	}, [])

	useEffect(() => {
		let shipping_price = "0,00";
		if(props.ticketDetails && props.ticketDetails.allDetails && props.ticketDetails.allDetails.shipping_from_country !== "1"){
			// Do nothing, the shipping price is 0 in this case for the buyer
		}
		else if(shippingMethod){
			if(shippingMethod == "1"){
				if (sessionStorage.getItem("address_country") == "1") {
					shipping_price = shipping_prices["Germany"]
				}else{
					shipping_price = shipping_prices["All"]
				}
			}else if(shippingMethod == "2"){
				shipping_price = shipping_prices["DHL"]
			}else if(shippingMethod == "3"){
				shipping_price = shipping_prices["PickUpVenue"]
			}
		}
		setNewPricing(shipping_price)
	}, [shippingMethod, ticketDetails.price])

	useEffect(() => {
        if(Object.keys(props.eventDetails).length && Object.keys(props.ticketDetails).length && props.ticketDetails.ticketType === "hard-ticket"){
        	axios.get('/addresses/?output_format=JSON&filter[id_customer]=['+customerDetails.data.id+']&filter[deleted]=[0]&display=full', config)
            .then(function (data) {
	        	let sellerCountry = props.ticketDetails.allDetails.shipping_from_country
	            let shippingOptions = {...shippingOptionsData};
	            const newShippingOptions = prepareShippingMethodsBuying(data.data.addresses[0].id_country, sellerCountry, props.eventDetails, shippingOptions, props.ticketDetails.allDetails);
	            setShippingOptionsData(newShippingOptions);
	        })
        }
    },[props.eventDetails, showProfileNextButton])

	return (
		<div className={classes.PaymentBackground} id="payment-background">
			<div className={classes.BackgroundContainer}>
				{step === 1 ?
					<div className={classes.Payment}>
						<div className={[classes.Container, classes.WrapBox].join(' ')}>
							<div className={classes.ProgressBar}>
								<div className={classes.Close} onClick={LeavePayment}>
									&times;
								</div>
								<div className={classes.Progress}>
									<div className={classes.Step1}>
										<img src={Step1Prog} alt="step1" />
										<div className={classes.StepOneGap}>...</div>
									</div>
									<div className={classes.Step2}>
										<img src={
											ticketDetails.ticketType === "hard-ticket" ? Step2Inc
											: ticketDetails.ticketType === "e-ticket" ? Step2IncET
											: ticketDetails.ticketType === "eventim" || ticketDetails.ticketType === "ticketmaster" ? Step2IncMT
											: Step2Inc
										} alt="step2" />
										<div className={classes.StepOneGap}>...</div>
									</div>
									<div className={classes.Step3}>
										<img src={Step3Inc} alt="step3" />
									</div>
								</div>
							</div>
							<div className={classes.Title}>1. {t('Personal_Information')}</div>
							<PersonalInfo
								customStyle={window.screen.width > 500 ? { width: '80%', marginTop: '0px' } : { width: '95%', marginTop: '0px' }}
								mainText={t("Below_you_can_find_the_personal_information_that_you_have_saved_to_kaufmeinticket")}
								addPaymentOption={true}
								onEditChange={(x) => setShowProfileNextButton(x)} />
							<div className={classes.NextButton}>
								{showProfileNextButton ?
									<button onClick={() => changeStep(1)}>{t('Next')}&nbsp;</button>
									: <div>{t('fill_in_your_address_details_to_proceed')}</div>
								}
							</div>
						</div>
					</div> :
					step === 2 ?
						<div className={classes.Payment}>
							<div className={[classes.Container, classes.WrapBox].join(' ')}>
								<div className={classes.ProgressBar}>
									<div className={classes.Close} onClick={LeavePayment}>
										&times;
									</div>
									<div className={classes.Progress}>
										<div className={classes.Step1}>
											<img src={Step1Comp} alt="step1" />
											<div className={[classes.StepOneGap, classes.GapFill].join(' ')}>...</div>
										</div>
										<div className={classes.Step2}>
											<img src={
												ticketDetails.ticketType === "hard-ticket" ? Step2Prog
												: ticketDetails.ticketType === "e-ticket" ? Step2ProgET
												: ticketDetails.ticketType === "eventim" || ticketDetails.ticketType === "ticketmaster" ? Step2ProgMT
												: Step2Prog
											} alt="step2" />
											<div className={classes.StepOneGap}>...</div>
										</div>
										<div className={classes.Step3}>
											<img src={Step3Inc} alt="step3" />
										</div>
									</div>
								</div>
								<div className={classes.Title}>2. {t('Shipping')}</div>
								<>
									{
										ticketDetails.ticketType === "hard-ticket" ?
											<>
												{shippingOptionsData["nonGerman"]["active"] ?
												<>
													<div className={
														ticketDetails.allDetails.shipping_methods && ticketDetails.allDetails.shipping_methods.includes(ShippingMethodsCode.registered_mail) ?
															shippingOptionsData["nonGerman"]["methods"][0]["active"] ?
																[classes.Shipping, classes.WrapBox, classes.ChangeColorContainer].join(' ')
															: [classes.Shipping, classes.WrapBox, classes.MakeLessOpacityInternational].join(" ")
														: classes.HideShippingOption
														}>
														<label className={classes.RadioContainerLabel}>
															<div className={classes.MainContainer}>
																<div className={classes.FirstContainer}>
																	<div className={shippingOptionsData["nonGerman"]["methods"][0]["active"] ? [classes.Radiocontainer, classes.ChangeViewContainer].join(" ") : [classes.Radiocontainer, classes.HideInput].join(" ")}>
																		<div className={classes.InfoContainer}>
																			<div className={classes.HeadlineContainer}>
																				<p>{t('shipping_4_title_buy')}</p>
																			</div>
																			<div className={classes.ParaContainer}>
																				<p>{
																					shippingOptionsData["nonGerman"]["methods"][0]["active"] ?
																						shippingOptionsData["buyer_country"] !== "1" && shippingOptionsData["seller_country"] === "1" ?
																							t('shipping_4_description_buy_de')
																						: shippingOptionsData["seller_country"] !== "1" ?
																							t('shipping_4_description_buy_non_de_to_de').replace("<COUNTRY>", CountriesByIndex["countries"][shippingOptionsData["seller_country"]]["name"][languageCode]["value"])
																						: t('shipping_4_description_buy_de')
																					: shippingOptionsData["buyer_country"] !== "1" && shippingOptionsData["seller_country"] === "1" ?
																							t('shipping_4_description_buy_de_unavailable')
																						: shippingOptionsData["seller_country"] !== "1" ?
																							t('shipping_4_description_buy_non_de_to_de_unavailable')
																						: t('shipping_4_description_buy_de_unavailable')
																				}</p>
																			</div>
																			<div className={shippingOptionsData["nonGerman"]["methods"][0]["active"] && shippingOptionsData["buyer_country"] !== "1" && shippingOptionsData["seller_country"] === "1" ? [classes.ShippingAbroadWarningText, classes.WarnBlackText].join(" ") : classes.ShippingAbroadWarningText}>
																				<p>{t('shipping_4_buy_warning')}</p>
																			</div>
																		</div>
																		{/*  disabled="true" for disabling the radio button functionality*/}
																		<input
																			type="radio"
																			disabled={!shippingOptionsData["nonGerman"]["methods"][0]["active"] ? "disabled" : null}
																			name="radio"
																			onChange={(e) => setShippingMethod(1)}
																			checked={shippingMethod === 1 ? "checked" : null}
																		/>
																		<span class={
																			shippingOptionsData["nonGerman"]["methods"][0]["active"] ?
																				classes.checkmark
																			: [classes.checkmark, classes.HideCheckMark].join(" ")
																		}></span>
																	</div>
																</div>
																{/*classes.HidePricing is the class to hide prices*/}
																<div className={shippingOptionsData["nonGerman"]["methods"][0]["active"] ? [classes.SecondContainer, classes.ChangeContainer].join(" ") : [classes.SecondContainer, classes.HidePricing].join(" ")}>
																	<p>€ {
																		props.ticketDetails && props.ticketDetails.allDetails && props.ticketDetails.allDetails.shipping_from_country !== "1" ?
																			shipping_prices["InternationalSeller"]
																		: shipping_prices["All"].replace('.', ',')
																	}</p>
																</div>
															</div>
														</label>
													</div>
													<div className={
														ticketDetails.allDetails.shipping_methods && ticketDetails.allDetails.shipping_methods.includes(ShippingMethodsCode.pickup_at_venue) ?
															shippingOptionsData["nonGerman"]["methods"][1]["active"] ?
																[classes.Shipping, classes.WrapBox, classes.ChangeColorContainer].join(' ')
															: [classes.Shipping, classes.WrapBox, classes.MakeLessOpacity].join(" ")
														: classes.HideShippingOption
														}>
														<label className={classes.RadioContainerLabel}>
															<div className={classes.MainContainer}>
																<div className={classes.FirstContainer}>
																	<div className={shippingOptionsData["nonGerman"]["methods"][1]["active"] ? [classes.Radiocontainer, classes.ChangeViewContainer].join(" ") : classes.Radiocontainer}>
																		<div className={classes.InfoContainer}>
																			<div className={classes.HeadlineContainer}>
																				<p>{t('shipping_3_title')}</p>
																			</div>
																			<div className={classes.ParaContainer}>
																				<p>{
																					shippingOptionsData["nonGerman"]["methods"][1]["active"] ?
																						t('shipping_3_description_buy_de')
																					: t('shipping_3_description_buy_de_unavailable')
																					}
																				</p>
																			</div>
																		</div>
																		<input
																			type="radio"
																			disabled={!shippingOptionsData["nonGerman"]["methods"][1]["active"] ? "disabled" : null}
																			name="radio"
																			onChange={(e) => setShippingMethod(3)}
																			checked={shippingMethod === 3 ? "checked" : null}
																		/>
																		<span class={
																			shippingOptionsData["nonGerman"]["methods"][1]["active"] ?
																				classes.checkmark
																			: [classes.checkmark, classes.HideCheckMark].join(" ")
																		}></span>
																	</div>
																</div>
																<div className={shippingOptionsData["nonGerman"]["methods"][1]["active"] ? [classes.SecondContainer, classes.FreeContainer, classes.ChangeContainer].join(" ") : [classes.SecondContainer, classes.FreeContainer].join(" ")}>
																	<p>{t('for_free')}</p>
																</div>
															</div>
														</label>
													</div>
												</>
												:
												<>
												<div className={
													ticketDetails.allDetails.shipping_methods && ticketDetails.allDetails.shipping_methods.includes(ShippingMethodsCode.registered_mail) ?
														shippingOptionsData["german"]["methods"][0]["active"] ?
															[classes.Shipping, classes.WrapBox, classes.ChangeColorContainer].join(' ')
														: [classes.Shipping, classes.WrapBox, classes.MakeLessOpacity].join(" ")
													: classes.HideShippingOption
													}>
													<label className={classes.RadioContainerLabel}>
														<div className={classes.MainContainer}>
															<div className={classes.FirstContainer}>
																<div className={shippingOptionsData["german"]["methods"][0]["active"] ? [classes.Radiocontainer, classes.ChangeViewContainer].join(" ") : classes.Radiocontainer}>
																	<div className={classes.InfoContainer}>
																		<div className={classes.HeadlineContainer}>
																			<p>{t('shipping_1_title')}</p>
																		</div>
																		<div className={classes.ParaContainer}>
																			<p>{
																				shippingOptionsData["german"]["methods"][0]["active"] ?
																					t('shipping_1_description_buy_de')
																				: t('shipping_1_description_buy_de_unavailable')
																				}
																			</p>
																		</div>
																	</div>
																	<input
																		type="radio"
																		name="radio"
																		disabled={!shippingOptionsData["german"]["methods"][0]["active"] ? "disabled" : null}
																		onChange={(e) => setShippingMethod(1)}
																		checked={shippingMethod === 1 ? "checked" : null}
																	/>
																	<span class={
																		shippingOptionsData["german"]["methods"][0]["active"] ?
																			classes.checkmark
																		: [classes.checkmark, classes.HideCheckMark]
																	}></span>
																</div>
															</div>
															<div className={shippingOptionsData["german"]["methods"][0]["active"] ? [classes.SecondContainer, classes.ChangeContainer].join(" ") : classes.SecondContainer}>
																<p>€ {shipping_prices["Germany"].replace('.', ',')}</p>
															</div>
														</div>
													</label>
												</div>
												<div className={ 
													ticketDetails.allDetails.shipping_methods && ticketDetails.allDetails.shipping_methods.includes(ShippingMethodsCode.DHL) ?
														shippingOptionsData["german"]["methods"][1]["active"] ?
															[classes.Shipping, classes.WrapBox, classes.ChangeColorContainer].join(' ')
															: [classes.Shipping, classes.WrapBox, classes.MakeLessOpacity].join(" ")
													: classes.HideShippingOption }>
													<label className={classes.RadioContainerLabel}>
														<div className={classes.MainContainer}>
															<div className={classes.FirstContainer}>
																<div className={shippingOptionsData["german"]["methods"][1]["active"] ? [classes.Radiocontainer, classes.ChangeViewContainer].join(" ") : classes.Radiocontainer}>
																	<div className={classes.InfoContainer}>
																		<div className={classes.HeadlineContainer}>
																			<p>{t('shipping_2_title')}</p>
																		</div>
																		<div className={classes.ParaContainer}>
																			<p>{
																				shippingOptionsData["german"]["methods"][1]["active"] ?
																					t('shipping_2_description_buy_de')
																				: shippingOptionsData["german"]["methods"][1]["status"] === "POST" && shippingOptionsData["german"]["methods"][2]["active"] ?
																					t('shipping_2_description_buy_de_unavailable')
																					: t('shipping_2_description_buy_de_unavailable_3_unavailable')
																				}
																			</p>
																		</div>
																	</div>
																	<input
																		type="radio"
																		name="radio"
																		disabled={!shippingOptionsData["german"]["methods"][1]["active"] ? "disabled" : null}
																		onChange={(e) => setShippingMethod(2)}
																		checked={shippingMethod === 2 ? "checked" : null}
																	/>
																	<span class={
																			shippingOptionsData["german"]["methods"][1]["active"] ?
																				classes.checkmark
																			: [classes.checkmark, classes.HideCheckMark].join(" ")
																		}></span>
																</div>
															</div>
															<div className={shippingOptionsData["german"]["methods"][1]["active"] ? [classes.SecondContainer, classes.ChangeContainer].join(" ") : classes.SecondContainer}>
																<p>€ {shipping_prices["DHL"].replace('.', ',')}</p>
															</div>
														</div>
													</label>
												</div>
												<div className={
													ticketDetails.allDetails.shipping_methods && ticketDetails.allDetails.shipping_methods.includes(ShippingMethodsCode.pickup_at_venue) ?
														shippingOptionsData["german"]["methods"][2]["active"] ?
															[classes.Shipping, classes.WrapBox, classes.ChangeColorContainer].join(' ')
														: [classes.Shipping, classes.WrapBox, classes.MakeLessOpacity].join(" ")
													: classes.HideShippingOption
													}>
													<label className={classes.RadioContainerLabel}>
														<div className={classes.MainContainer}>
															<div className={classes.FirstContainer}>
																<label className={shippingOptionsData["german"]["methods"][2]["active"] ? [classes.Radiocontainer, classes.ChangeViewContainer].join(" ") : classes.Radiocontainer}>
																	<div className={classes.InfoContainer}>
																		<div className={classes.HeadlineContainer}>
																			<p>{t('shipping_3_title')}</p>
																		</div>
																		<div className={classes.ParaContainer}>
																			<p>{
																				shippingOptionsData["german"]["methods"][2]["active"] ?
																					t('shipping_3_description_buy_de')
																				: t('shipping_3_description_buy_de_unavailable')
																				}
																			</p>
																		</div>
																	</div>
																	<input
																		type="radio"
																		name="radio"
																		disabled={!shippingOptionsData["german"]["methods"][2]["active"] ? "disabled" : null}
																		onChange={(e) => setShippingMethod(3)}
																		checked={shippingMethod === 3 ? "checked" : null}
																	/>
																	<span class={
																			shippingOptionsData["german"]["methods"][2]["active"] ?
																				classes.checkmark
																			: [classes.checkmark, classes.HideCheckMark].join(" ")
																		}></span>
																</label>
															</div>
															<div className={shippingOptionsData["german"]["methods"][2]["active"] ? [classes.SecondContainer, classes.FreeContainer, classes.ChangeContainer].join(" ") : [classes.SecondContainer, classes.FreeContainer].join(" ")}>
																<p>{t('for_free')}</p>
															</div>
														</div>
													</label>
												</div>
												</> }
											</>
											: ticketDetails.ticketType === "e-ticket" ?
											<div className={[classes.Shipping, classes.WrapBox, classes.ChangeColorContainer].join(' ')}>
												<div className={classes.MainContainer}>
													<div className={classes.FirstContainer}>
														<label className={[classes.Radiocontainer, classes.ChangeViewContainer].join(" ")}>
															<div className={classes.InfoContainer}>
																<div className={classes.HeadlineContainer}>
																	<p>{t('download_your_ticket')}</p>
																</div>
																<div className={classes.ParaContainer}>
																	<p>{t('etickets_shipping_text')}</p>
																</div>
															</div>
															{/*<input type="radio" checked="checked" name="radio" />
															<span class={classes.checkmark}></span>*/}
														</label>
													</div>
													<div className={[classes.SecondContainer, classes.ChangeContainer].join(" ")}>
														<p>€ {shipping_prices["ETicket"].replace('.', ',')}</p>
													</div>
												</div>
											</div>
											: ticketDetails.ticketType === "eventim" ?
											<div className={[classes.Shipping, classes.WrapBox, classes.ChangeColorContainer].join(' ')}>
												<div className={classes.MainContainer}>
													<div className={classes.FirstContainer}>
														<label className={[classes.Radiocontainer, classes.ChangeViewContainer].join(" ")}>
															<div className={classes.InfoContainer}>
																<div className={classes.HeadlineContainer}>
																	<p>{t('download_your_ticket_eventim')}</p>
																</div>
																<div className={classes.ParaContainer}>
																	<p>{t('download_your_ticket_eventim_description').replace('<TIMEFRAME>', timeBeforeEventForMobileTicket())}</p>
																</div>
															</div>
															{/*<input type="radio" checked="checked" name="radio" />
															<span class={classes.checkmark}></span>*/}
														</label>
													</div>
													<div className={[classes.SecondContainer, classes.ChangeContainer].join(" ")}>
														<p>€ {shipping_prices["ETicket"].replace('.', ',')}</p>
													</div>
												</div>
											</div>
											: ticketDetails.ticketType === "ticketmaster" ?
											<div className={[classes.Shipping, classes.WrapBox, classes.ChangeColorContainer].join(' ')}>
												<div className={classes.MainContainer}>
													<div className={classes.FirstContainer}>
														<label className={[classes.Radiocontainer, classes.ChangeViewContainer].join(" ")}>
															<div className={classes.InfoContainer}>
																<div className={classes.HeadlineContainer}>
																	<p>{t('download_your_ticket_ticketmaster')}</p>
																</div>
																<div className={classes.ParaContainer}>
																	<p>{t('download_your_ticket_ticketmaster_description').replace('<TIMEFRAME>', timeBeforeEventForMobileTicket())}</p>
																</div>
															</div>
															{/*<input type="radio" checked="checked" name="radio" />
															<span class={classes.checkmark}></span>*/}
														</label>
													</div>
													<div className={[classes.SecondContainer, classes.ChangeContainer].join(" ")}>
														<p>€ {shipping_prices["ETicket"].replace('.', ',')}</p>
													</div>
												</div>
												<div className={classes.TicketmasterDetails}>
													<div className={classes.TicketmasterFormContainer}>
														<div>
															<label>{t("your_name")}:</label>
															<div className={classes.TMInputContainer}>
																<div><input type="text" name="tm_f_name" placeholder={t("first_name")} onChange={(e)=>handleInputChange(e)} /></div>
																{/*<div><input type="text" name="tm_m_name" placeholder="Middle name" /></div>*/}
																<div><input type="text" name="tm_l_name" placeholder={t("last_name")} onChange={(e)=>handleInputChange(e)} /></div>
															</div>
														</div>
														<div>
															<label>{t("email")}:</label>
															<div className={classes.TMInputContainer}>
																<div><input type="email" name="tm_email" placeholder={t("email")} onChange={(e)=>handleInputChange(e)} /></div>
															</div>
														</div>
													</div>
												</div>
											</div>
											: null
									}
								</>
								<div className={classes.NextButton}>
									<button onClick={() => changeStep(-1)}>{t('Previous')}&nbsp;</button>
									{ shippingMethod // If shipping method is selected
										|| ticketDetails.ticketType === "e-ticket" // proceed if it is eTicket
										|| ticketDetails.ticketType === "eventim" // proceed if it is evetim ticket
										|| ticketmasterDetailsCollected ? // If customer details of ticketmaster account have been collected
											<button onClick={() =>
											{changeStep(1); setChosenShippingMethod();}
										}>{t('Next')}&nbsp;</button>
										: <button className={classes.GrayedButton}>{t('Next')}</button>
									}
								</div>
							</div>
						</div> :
						step === 3 ?
							<div className={classes.Payment}>
								<div className={[classes.Container, classes.WrapBox].join(' ')}>
									<div className={classes.ProgressBar}>
										<div className={classes.Close} onClick={LeavePayment}>
											&times;
										</div>
										<div className={classes.Progress}>
											<div className={classes.Step1}>
												<img src={Step1Comp} alt="step1" />
												<div className={[classes.StepOneGap, classes.GapFill].join(' ')}>...</div>
											</div>
											<div className={classes.Step2}>
												<img src={
													ticketDetails.ticketType === "hard-ticket" ? Step2Comp
													: ticketDetails.ticketType === "e-ticket" ? Step2CompET
													: ticketDetails.ticketType === "eventim" || ticketDetails.ticketType === "ticketmaster" ? Step2CompMT
													: Step2Comp
												} alt="step2" />
												<div className={[classes.StepOneGap, classes.GapFill].join(' ')}>...</div>
											</div>
											<div className={classes.Step3}>
												<img src={Step3Prog} alt="step3" />
											</div>
										</div>
									</div>
									<div className={classes.Title}>3. {t('Payment')}<br /><span className={classes.TitleSub}>{t('Please_select_your_payment_method')}</span></div>
									<div className={classes.PaymentMethods}>
										<label className={[classes.PaymentMethod, "PAYPAL"].join(' ')}>
											<input type="radio" name="payment_method" onChange={(e) => selectPaymentMethod(e, "PAYPAL")} />
											<span className={classes.Checkmark}></span>
											<div className={classes.Heading}>{t('Paypal')}</div>
											<div className={classes.Logo}><img src={paypal} alt="" /></div>
											<div className={classes.Tagline}>{t('Its_simple_and_secure')}</div>
											<div className={classes.Tagline2}>{t('paypal_text')}</div>
										</label>
										<label className={[classes.PaymentMethod, "STRIPE"].join(' ')}>
											<input type="radio" name="payment_method" onChange={(e) => selectPaymentMethod(e, "STRIPE")} />
											<span className={classes.Checkmark}></span>
											<div className={classes.Heading}>{t('credit_card')}</div>
											<div className={classes.PaymentLogos}>
												<div className={classes.VisaContainer}>
													<img src={visa} alt="visa" />
												</div>
												<div className={classes.MasterCardContainer}>
													<img src={mastercard} alt="mastercard" alt="mastercard" />
												</div>
												<div className={classes.AmericanContainer}>
													<img src={americanexpress} alt="american express" />
												</div>
											</div>

											<div className={classes.Tagline}>{t('Easy_pay_with_Credit_Card')}</div>
											<div className={classes.Tagline2}><Stripe {...props} loader={() => setPaymentLoader(false)} proceed={(param) => proceedAfterPayment(param)} price={price_breakdown.total} priceBreakdown={price_breakdown} /></div>
										</label>
										<label className={[classes.PaymentMethod, "SOFORT"].join(' ')}>
											<input type="radio" name="payment_method" onChange={(e) => selectPaymentMethod(e, "SOFORT")} />
											<span className={classes.Checkmark}></span>
											<div className={classes.Heading}>{t('SOFORT')}</div>
											<div className={[classes.Logo, classes.Klarna].join(' ')}><img src={klarnanew/*SofortIconBlue*/} alt="" /></div>
											<div className={classes.Tagline}>{t('Pay_directly_online')}</div>
											<div className={classes.Tagline2}>{t('klarna_text')}</div>
											<Sofort {...props} proceed={(param) => proceedAfterPayment(param)} price={price_breakdown.total} priceBreakdown={price_breakdown} />
										</label>
									</div>
									<div className={[classes.Agreement, classes.Newsletter].join(' ')}>
										<label className={classes.PaymentMethod}>
											<input type="checkbox" name="agreement_checkbox" />
											<span className={classes.Checkmark}></span>
											<div className={classes.NewsletterText}>{t('Subscribe_to_letter')}</div>
										</label>

										<label className={classes.PaymentMethod}>
											<input type="checkbox" name="agreement_checkbox" ref={agreementRef} onChange={() => onChangeAgreement()} />
											<span className={classes.Checkmark}></span>
											<div className={classes.NewsletterText}>{t('I_have_read,_understand_and_agree_to_the_BEFORE')} <a href={TERMS}>{t('terms_of_use')}</a> {t('and_payment')} <a href={PRIVACYPOLICY}>{t('privacy_policy_payment')}</a> {t('I_have_read,_understand_and_agree_to_the_AFTER')}</div>
										</label>
										{agreementError ?
											<div className={classes.ErrorMessage}>{t('Please_agree_to_the_terms_and_conditions_before_continuing')}</div>
											: null
										}
									</div>
									<div className={[classes.NextButton, classes.MakePayment].join(' ')}>
										<button onClick={() => changeStep(-1)}>{t('Previous')}&nbsp;</button>
										<button
											style={{ position: "relative" }}
											onClick={chosenPaymentMethod === "PAYPAL" && paypalButtonEnabled ?
												null
												: () => doPayment()
											}>
											{chosenPaymentMethod === "PAYPAL" && paypalButtonEnabled ?
												<>
													<Paypal
														{...props}
														loader={() => setPaymentLoader(false)}
														proceed={(param) => proceedAfterPayment(param)}
														price={price_breakdown.total}
														priceBreakdown={price_breakdown}
														putCartFunction={PutCart}
													/>
												</>
												: null}
											{t('Make_Payment')}
										</button>
									</div>
								</div>
								{
									paymentLoader ?
										<div className={[classes.Payment, classes.PaymentLoader].join(' ')}>
											<div className={[classes.Container, classes.WrapBox, classes.WaitBox].join(' ')}>
												<div className={classes.Title}>{t('Payment')}</div>
												<div className={classes.Heading}>{t('Please_Wait')}</div>
												<div className={classes.SubHeading}>{t('We_are_confirming_your_payment')}</div>
												<div className={classes.SubHeading}>{t('Please_do_NOT_close_or_refresh_this_page_until_your_payment_is_completed')}</div>
												<div className={classes.LoadingIcon}><img src={LoadingIcon} alt="" /></div>
											</div>
										</div> : null
								}
							</div> :
							step === 4 ?
								<div className={classes.Payment}>
									<div className={[classes.Container, classes.WrapBox, classes.WaitBox].join(' ')}>
										<div className={classes.Title}>{t('Payment')}</div>
										<div className={classes.Heading}>{t('Please_Wait')}</div>
										<div className={classes.SubHeading}>{t('We_are_confirming_your_payment')}</div>
										<div className={classes.SubHeading}>{t('Please_do_NOT_close_or_refresh_this_page_until_your_payment_is_completed')}</div>
										<div className={classes.LoadingIcon}><img src={LoadingIcon} alt="" /></div>
									</div>
								</div> :
								step === 5 ?
									<div className={classes.Payment}>
										<div className={[classes.Container, classes.WrapBox].join(' ')}>
											<div className={classes.ProgressBar}>
												<div className={classes.Close} onClick={LeavePayment}>
													&times;
												</div>
												<div className={classes.Progress}>
													<div className={classes.Step1}>
														<img src={Step1Comp} alt="step1" />
														<div className={[classes.StepOneGap, classes.GapFill].join(' ')}>...</div>
													</div>
													<div className={classes.Step2}>
														<img src={Step2Comp} alt="step2" />
														<div className={[classes.StepOneGap, classes.GapFill].join(' ')}>...</div>
													</div>
													<div className={classes.Step3}>
														<img src={Step3Prog} alt="step3" />
													</div>
												</div>
											</div>
											{
												chosenPaymentMethod === "PAYPAL" ? <Paypal {...props} loader={() => setPaymentLoader(false)} proceed={(param) => proceedAfterPayment(param)} price={price_breakdown.total} priceBreakdown={price_breakdown} /> : null
											}
										</div>
									</div> :
									step === 6 ?
										paymentStatus.status ?
											<div className={classes.Payment}>
												<div className={[classes.Container, classes.WrapBox, classes.PaymentStatus].join(' ')}>
													<div className={classes.Title}>{t(paymentStatus.paymentStatusTitle)}</div>
													<div className={classes.Close} onClick={finishPayment}>
														&times;
													</div>
													<div className={[classes.PaymentStatusMessage, classes.SuccessMessage].join(' ')}>
														{t(paymentStatus.paymentStatusMessage)}
													</div>
													<div className={[classes.TicketDetails, classes.SuccessMessage].join(' ')}>
														<div className={classes.TicketType}>
															{ticketDetails.quantity}&nbsp;X&nbsp;{
																ticketDetails.ticketType === "hard-ticket" ? t('Hard_Tickets')
																: ticketDetails.ticketType === "e-ticket" ? t('e-tickets')
																: ticketDetails.ticketType === "ticketmaster" ? t('mobile_ticket_ticketmaster')
																: ticketDetails.ticketType === "eventim" ? t('mobile_ticket_eventim')
																: ""
															}

														</div>
														<div className={classes.EventInfo}>
															<div className={classes.EventName}>
																{props.eventDetails.name[0].value}
															</div>
															<div className={classes.EventLocation}>{props.eventDetails.location}</div>&nbsp;
															<div className={classes.EventLocation}>{props.eventDetails.location_city}</div>
														</div>
														<div className={classes.TicketTimings}>
															<div className={classes.DateTime}>
																{props.eventDetails.event_date.split(' ')[0]} | {props.eventDetails.event_date.split(' ')[1]}
															</div>&nbsp;
															<div>{props.ticketDetails.allDetails.block_name}</div>
														</div>
													</div>
													<div className={classes.PaymentStatusImage}>
														<img src={paymentStatus.paymentStatusIcon} alt="" />
													</div>
													<div className={[classes.NextButton, classes.MakePayment].join(' ')}>
														<button
															onClick={
																paymentStatus.paymentStatusButtonAction ?
																	paymentStatus.paymentStatusButtonAction :
																	null}>
															{t(paymentStatus.paymentStatusButtonText)}
														</button>
													</div>
													{/*<div className={classes.Disclaimer}>
													Click the X icon at the top left corner to go back to the events page.
												</div>*/}
												</div>
											</div> :
											<div className={classes.Payment}>
												<div className={[classes.Container, classes.WrapBox, classes.PaymentStatus].join(' ')}>
													<div className={classes.Title}>{t(paymentStatus.paymentStatusTitle)}</div>
													<div className={classes.Close} onClick={finishPayment}>
														&times;
													</div>
													<div className={classes.PaymentStatusFailImage}>
														<img src={paymentStatus.paymentStatusIcon} alt="" />
													</div>
													<div className={classes.PaymentStatusMessage}>
														{t(paymentStatus.paymentStatusMessage)}
													</div>
													<div className={classes.PaymentStatusButtons}>
														<button onClick={props.retry}>{window.screen.width < 700 ? t("Retry") : t("Retry_Purchase")}</button>
														<a onClick={() => (setShowContactBox(true))}/*href="mailto:info@kaufmeinticket.de"*/><button>{window.screen.width < 700 ? t("Support") : t("Contact_Support")}</button></a>
													</div>
													{/*<div className={classes.Disclaimer}>
													Click the X icon at the top left corner to go back to the events page.
												</div>*/}
												</div>
											</div>
										: null
				}
				{showCart ?
					<div className={classes.Cart}>
						<div className={classes.WrapBox}>
							<div className={classes.Container}>
								<div className={classes.Title}>
									{t('Cart')}
								</div>
								<div className={classes.TicketOwner}>
									<div className={classes.OwnerImage}>
										<img src={UserIcon} alt="user icon" />
									</div>
									<div className={classes.OwnerInfo}>
										<div>{t('Sold_by')}</div>
										<div>{/*First Name Last Name of Seller*/}{t(ticketDetails.soldBy)} &nbsp;<img src={ppbutton} alt="verified icon" /></div>
									</div>
								</div>
								<div className={classes.TicketDetails}>
									<div className={classes.TicketType}>
										{ticketDetails.quantity}&nbsp;X&nbsp;{
																ticketDetails.ticketType === "hard-ticket" ? t('Hard_Tickets')
																: ticketDetails.ticketType === "e-ticket" ? t('e-tickets')
																: ticketDetails.ticketType === "ticketmaster" ? t('mobile_ticket_ticketmaster')
																: ticketDetails.ticketType === "eventim" ? t('mobile_ticket_eventim')
																: ""
															}
									</div>
									<div className={classes.EventInfo}>
										<div className={classes.EventName}>
											{props.eventDetails.name[0].value}
										</div>
										<div className={classes.EventLocation}>{props.eventDetails.location}</div>
										<div className={classes.EventLocation}>{props.eventDetails.location_city}</div>
									</div>
									<div className={classes.TicketTimings}>
										<div className={classes.DateTime}>
											{props.eventDetails.event_date.split(' ')[0]} | {props.eventDetails.event_date.split(' ')[1]}
										</div>
										<div>{ticketDetails.name}</div>
									</div>
								</div>
								<div className={classes.PriceDetails}>
									<div className={classes.PriceBlock}>
										<div>{t('Subtotal')}<img src={priceBreakdownShow ? UpArrow : DownArrow} className={classes.PriceToggleShow} onClick={() => setPriceBreakdownShow(!priceBreakdownShow)} />:</div><div>€ {price_breakdown.subtotal.replace('.', ',')}</div>
									</div>
									{priceBreakdownShow ?
										<div className={classes.PriceSubBlock}>
											<div className={classes.PriceBlock}>
												<div>{t('Seller_sale_price')}:</div><div>€ {price_breakdown.base_price.replace('.', ',')}</div>
											</div>
											<div className={classes.PriceBlock}>
												<div>{t('Service_fee')}:</div><div>€ {price_breakdown.service_fee.replace('.', ',')}</div>
											</div>
										</div> : null}
									<div className={classes.PriceBlock}>
										<div>{t('Shipping')} {getChosenShippingMethod(shippingMethod)}:</div><div>€ {price_breakdown.shipping.replace('.', ',')}</div>
									</div>
									<div className={[classes.PriceBlock, classes.TotalPrice].join(' ')}>
										<div>{t('Total_Price_incl._VAT')}:</div><div>€ {price_breakdown.total.replace('.', ',')}</div>
									</div>
								</div>
								<div className={classes.SessionTime}>
									<div>{t('Your_session_expires_in')}</div>
									<div>
										<img src={SessionTimer} alt="session timer" />
										<div className={classes.Time}>	<Timer hoursMinSecs={hoursMinSecs} timeOut={TimerOut} startDate={new Date()} /> </div>
										{/* <div className={classes.Time}><span>13</span>{t('Min')} <span>31</span>{t('Sec')}</div> */}
									</div>
								</div>
							</div>
						</div>
						<div className={classes.MobileCart}>
							<div className={classes.Container}>
								<div className={classes.Heading}><img src={yourorder} alt="" style={{ transform: "rotate(-22deg)" }} />&nbsp;{t('YOUR_ORDER')}</div>
								{expandMobileCart ?
									<div className={classes.WrapBox}>
										<div className={classes.CollapseCart} onClick={() => setExpandMobileCart(false)}>
											<img src={UpArrow} alt="" />
										</div>
										<div className={classes.TicketDetails}>
											<div className={classes.TicketType}>
												{ticketDetails.quantity}&nbsp;X&nbsp;{
																ticketDetails.ticketType === "hard-ticket" ? t('Hard_Tickets')
																: ticketDetails.ticketType === "e-ticket" ? t('e-tickets')
																: ticketDetails.ticketType === "ticketmaster" ? t('mobile_ticket_ticketmaster')
																: ticketDetails.ticketType === "eventim" ? t('mobile_ticket_eventim')
																: ""
															}
											</div>
											<div className={classes.EventInfo}>
												<div className={classes.EventName}>
													{props.eventDetails.name[0].value}
												</div>
												<div className={classes.EventLocation}>{props.eventDetails.location}</div>
												<div className={classes.EventLocation}>{props.eventDetails.location_city}</div>
											</div>
											<div className={classes.TicketTimings}>
												<div className={classes.DateTime}>
													{props.eventDetails.event_date.split(' ')[0]} | {props.eventDetails.event_date.split(' ')[1]}
												</div>
												<div>{ticketDetails.name}</div>
											</div>
										</div>
										<div className={classes.PriceDetails}>
											<div className={classes.PriceBlock}>
												<div>{t('Subtotal')}<img src={priceBreakdownShow ? UpArrow : DownArrow} className={classes.PriceToggleShow} onClick={() => setPriceBreakdownShow(!priceBreakdownShow)} />:</div><div>€ {price_breakdown.subtotal.replace('.', ',')}</div>
											</div>
											{priceBreakdownShow ?
												<div className={classes.PriceSubBlock}>
													<div className={classes.PriceBlock}>
														<div>{t('Seller_sale_price')}:</div><div>€ {price_breakdown.base_price.replace('.', ',')}</div>
													</div>
													<div className={classes.PriceBlock}>
														<div>{t('Service_fee')}:</div><div>€ {price_breakdown.service_fee.replace('.', ',')}</div>
													</div>
												</div> : null}
											<div className={classes.PriceBlock}>
												<div>{t('Shipping')} {getChosenShippingMethod(shippingMethod)}:</div><div>€ {price_breakdown.shipping.replace('.', ',')}</div>
											</div>
											<div className={[classes.PriceBlock, classes.TotalPrice].join(' ')}>
												<div>{t('Total_Price_incl._VAT')}:</div><div>€ {price_breakdown.total.replace('.', ',')}</div>
											</div>
										</div>
										<div className={classes.TicketOwner}>
											<div className={classes.OwnerImage}>
												<img src={UserIcon} alt="user icon" />
											</div>
											<div className={classes.OwnerInfo}>
												<div>{t('Sold_by')}</div>
												<div>{/*First Name Last Name of Seller*/}{t(ticketDetails.soldBy)} &nbsp;<img src={ppbutton} alt="verified icon" /></div>
											</div>
										</div>
									</div> :
									<div className={classes.CollapsedCart}>
										<div className={classes.ExpandCart} onClick={() => setExpandMobileCart(true)}>
											<img src={DownArrow} alt="" />
										</div>
										<div>
											<div className={classes.TicketType}>
												{ticketDetails.quantity}&nbsp;X&nbsp;{
																ticketDetails.ticketType === "hard-ticket" ? t('Hard_Tickets')
																: ticketDetails.ticketType === "e-ticket" ? t('e-tickets')
																: ticketDetails.ticketType === "ticketmaster" ? t('mobile_ticket_ticketmaster')
																: ticketDetails.ticketType === "eventim" ? t('mobile_ticket_eventim')
																: ""
															}
											</div>
											<div className={classes.EventInfo}>
												<div className={classes.EventName}>
													{props.eventDetails.name[0].value}
												</div>
											</div>
										</div>
										<div className={classes.PriceBlock}>
											<div>€ {price_breakdown.total.replace('.', ',')}
											</div>
										</div>
									</div>}
								<div className={classes.SessionTime}>
									<div>{t('Your_Session_Expires_in')}</div>
									<div>
										<img src={SessionTimer} alt="session timer" />
										<div className={classes.Time}>	<Timer hoursMinSecs={hoursMinSecs} timeOut={TimerOut} startDate={new Date()} /> </div>
										{/* <div className={classes.Time}><span>13</span>min <span>31</span>sec</div> */}
									</div>
								</div>
							</div>
						</div>
					</div> : null}
				{
					showContactBox ?
						<ContactUs
							hideContact={() => setShowContactBox(false)}
							pageDetails="Payment Error Support - " // for internal use
						/> : null
				}
			</div>
		</div>
	);
}

export default MakePayment;