import React from 'react';
import classes from './Impressum.module.css';
import { useState,useEffect } from 'react';
import { LANG_GERMAN } from '../../URLS';
import axios from '../../axiosBase';
import { config } from '../../axiosBase';

import { useTranslation } from 'react-i18next';

const Impressum = () => {

	const { t, i18n } = useTranslation();
	const [title, setTitle] = useState("");
	const [impressumContent,setImpressumContent]=useState("");
	const linkLangCode=localStorage.getItem("i18nextLng") === LANG_GERMAN ? 1 : 2;

	useEffect(() => {
    axios.get('/content_management_system/6?output_format=JSON',config)
	.then(function(data){
		setTitle(data.data.content.meta_title[linkLangCode].value)
		setImpressumContent(data.data.content.content[linkLangCode].value)
	})
	.catch(function(err){
		console.log(err)
	})
	
	  
	}, [impressumContent])

	return (
		<div className={classes.Impressum}>
			<div className={classes.Heading}><span>{title}</span></div>
			<div className={classes.MainContent} dangerouslySetInnerHTML={{__html: impressumContent}}></div>
		{/*	<div className={classes.Heading}><span>{t('IMPRESSUM')}</span></div>
			<div className={classes.MainContent}>
				<div className={[classes.SubHeading, classes.MT10].join(' ')}>{t('IMP_HEAD_1')}</div>
				<div className={classes.SubText}>
				{t('IMP_SUB_1').split('<br />').map(function(res){
					return <>{res}<br /></>
				})}
				</div>
				<div className={[classes.SubHeading, classes.MT10].join(' ')}>{t('IMP_HEAD_2')}</div>
				<div className={classes.SubText}>
				{t('IMP_SUB_2').split('<br />').map(function(res){
					return <>{res}<br /></>
				})}
				</div>
				<div className={[classes.SubText, classes.MT10].join(' ')}>{t('IMP_SUB_3')}</div>
				<div className={[classes.SubText, classes.MT10].join(' ')}>
				{t('IMP_SUB_4').split('<br />').map(function(res){
					return <>{res}<br /></>
				})}
				</div>
				<div className={[classes.SubHeading, classes.MT10].join(' ')}>{t('IMP_HEAD_3')} <span>{t('IMP_HEAD_3_SUB')}</span></div>
				<div className={classes.SubText}>{t('IMP_SUB_5')}</div>
				<div className={[classes.SubHeading, classes.MT10].join(' ')}>{t('IMP_HEAD_4')}</div>
				<div className={[classes.SubText, classes.MT10].join(' ')}>{t('IMP_SUB_6')} <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>.</div>
				<div className={[classes.SubHeading, classes.MT10].join(' ')}>{t('IMP_HEAD_5')}</div>
				<div className={classes.SubText}>{t('IMP_SUB_7')}</div>
				<div className={[classes.SubHeading, classes.MT10].join(' ')}>{t('IMP_HEAD_6')}</div>
				<div className={classes.SubText}>{t('IMP_SUB_8')}</div>
				<div className={[classes.SubHeading, classes.MT10].join(' ')}>{t('IMP_HEAD_7')}</div>
				<div className={classes.SubText}>{t('IMP_SUB_9')}</div>
			</div>*/}
		</div>
	);
}

export default Impressum;