import React from 'react';
import classes from './ErrorBox.module.css';

import consoleLog from '../../../log';

const ErrorBox = (props) => {
	consoleLog(props.errors)
	return (
		<div className={classes.ErrorBox}>
			<div className={classes.Container}>
				<div className={classes.WrapBox}>
					<div className={classes.ErrorTitle}>{props.data.errorTitle}</div>
					<div className={classes.ErrorSubTitle}>{props.data.errorSubTitle}</div>
					<div className={classes.Errors}>
						{ props.data.errors.map(function(error, index){
							return <div className={classes.ErrorMessage}>
									{Object.entries(error).map(([key, value]) => {
								        return (
								        	<>
											<div className={classes.ErrorContainer}>
												<span></span>{key !== "" ? <div style={{whiteSpace: "nowrap"}}>{key}&nbsp;:&nbsp;</div> : null}<div>{value}</div>
											</div>
											<br />
											</>
								        )
								    })}
									</div>;
						})}
					</div>
					<div className={classes.ErrorButtons}>
						<button className={classes.AcceptButton} onClick={props.data.acceptAction}>{props.data.acceptText}</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ErrorBox;


/* SAMPLE USAGE

            <ErrorBox data={{
                errorTitle: "Error(s)",
                errorSubTitle: "Please resolve the following errors and resubmit your sale.",
                errors: ["Warning: This action cannot be undone.Warning: This action cannot be undone.",
                            "Warning: This action cannot be undone.",
                            "Warning: This action cannot be undone.",
                            "Warning: This action cannot be undone."],
                acceptText: "OK",
                acceptAction: functionName
            }}/>

*/