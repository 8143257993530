import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CardElement } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Stripe = (props) => {

	const CARD_ELEMENT_OPTIONS = {
	  iconStyle: "solid",
	  hidePostalCode: true,
	  style: {
	    base: {
	      iconColor: "rgb(240, 57, 122)",
	      color: "rgb(240, 57, 122)",
	      fontSize: "16px",
	      fontFamily: '"Open Sans", sans-serif',
	      fontSmoothing: "antialiased",
	      "::placeholder": {
	        color: "#CFD7DF"
	      }
	    },
	    invalid: {
	      color: "#e5424d",
	      ":focus": {
	        color: "#303238"
	      }
	    }
	  }
	};

	function CardSection() {
	  return <CardElement options={CARD_ELEMENT_OPTIONS} />;
	}


	return (
	    <div style={{display: "none"}}>
	      <Elements stripe={stripePromise}>
	        <CheckoutForm {...props} />
	      </Elements>
	    </div>
	);
};

export default Stripe;
export {stripePromise};