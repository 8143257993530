export const sortEventsBy = (events, type) => {
    const data = events;
    let split_delimiter = '-';
    if(data[0] && data[0].event_date.includes('.')){
        split_delimiter = '.';
    }
    data.sort(function (a, b) {
        return a[type].split(' ')[0].split(split_delimiter).reverse().join('').localeCompare(b[type].split(' ')[0].split(split_delimiter).reverse().join(''));
    });
    return data
}
