import React from 'react';
import classes from './AlertBox.module.css';

const AlertBox = (props) => {

	return (
		<div className={classes.AlertBox}>
			<div className={classes.Container}>
				<div className={classes.WrapBox}>
					<div className={classes.AlertTitle}>{props.data.alertTitle}</div>
					<div className={classes.AlertMessage}>{props.data.alertMessage}</div>
					<div className={classes.AlertButtons}>
						{ props.data.acceptText ?
							<button className={classes.AcceptButton} onClick={props.data.acceptAction}>{props.data.acceptText}</button>
							: null
						}
						{ props.data.denyText ?
							<button className={classes.DenyButton} onClick={props.data.denyAction}>{props.data.denyText}</button>
							: null
						}
					</div>
					{
						props.data.link ?
							<div className={classes.AlertLink}><a href={props.data.link.url}>{props.data.link.text}</a></div>
						: null
					}
				</div>
			</div>
		</div>
	);
}

export default AlertBox;


/* SAMPLE USAGE

            <AlertBox data={{
                alertTitle: "Delete Ticket?",
                alertMessage: "Warning: This action cannot be undone.",
                acceptText: "Yes",
                denyText: "No",
                acceptAction: functionName,
                denyAction: functionName
            }} />


*/