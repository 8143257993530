import React, { useState } from 'react';
import classes from './Team.module.css';

import pdfFILE from '../../../assets/files/job_offer_timeless.pdf';
import P1 from '../../../assets/images/compressed/team/B_Anna.jpg'
import P2 from '../../../assets/images/compressed/team/B_Beate.jpg'
import P3 from '../../../assets/images/compressed/team/B_Chevy.jpg'
import P4 from '../../../assets/images/compressed/team/B_Imo.jpg'
import P5 from '../../../assets/images/compressed/team/B_Kevin.jpg'
import P6 from '../../../assets/images/compressed/team/B_Laura.jpg'
import P7 from '../../../assets/images/compressed/team/B_Leah.jpg'
import P8 from '../../../assets/images/compressed/team/B_Nadine.jpg'
import P9 from '../../../assets/images/compressed/team/B_Sharon.jpg'

import TeamPicture from '../../../assets/images/compressed/team/team.jpg';
import AddTeam from '../../../assets/images/compressed/team/add_to_team.jpg';

import { useTranslation } from 'react-i18next';

export default function Team() {
    const [photoList, setPhotoList] = useState([
        { name: "Anna", photo: P1 },
        { name: "Beate", photo: P2 },
        { name: "Chevy", photo: P3 },
        { name: "Imo", photo: P4 },
        { name: "Kevin", photo: P5 },
        { name: "Laura", photo: P6 },
        { name: "Leah", photo: P7 },
        { name: "Nadine", photo: P8 },
        { name: "Sharon", photo: P9 }
    ])

    const { t, i18n } = useTranslation();
    
    return (
        <div className={classes.Team}>
            <p className={classes.TeamHead}>Team</p>
            <div className={classes.TeamDiv1}>
                <img src={TeamPicture} alt="" />
            </div>
            <div className={classes.TeamHr}></div>
            <div className={classes.TeamPhotoMain}>
                {photoList.map((a, index) => {
                    return (
                        <div className={classes.TeamPhotoCont}>
                            <div className={classes.TeamPhoto}> <img src={a.photo} alt="" /> </div>
                            <p className={classes.TeamPhotoName}>{a.name}</p>
                        </div>
                    )
                })
                }
                <div className={classes.TeamPhotoCont}>
                    <a href={pdfFILE} target="_blank" rel="noreferrer">
                        <div className={classes.TeamPhoto}>
                                <img src={AddTeam} alt="" />
                                {/*<div className={classes.Text}>
                                {t('DAS_KONNTEST_DU_SIEN')}
                                </div>*/}
                        </div>
                        <p className={classes.TeamPhotoName}>{t('DAS_KONNTEST_DU_SIEN')}</p>
                    </a>
                    <p className={classes.TeamPhotoName}></p>
                </div>
            </div>
            <p className={classes.AddToTeamLink}>
                <a href={pdfFILE} target="_blank" rel="noreferrer">{t('Click_here_to_see_our_current_job_offers')}</a>
            </p>
        </div>
    )
}
