import React, { useState, useEffect, useRef } from 'react';
import classes from '../SellTickets.module.css';
import completion from './SaleCompletion.module.css'
import { LISTINGS, HOME, ABOUT, SELLFORM, SELL, LANG_GERMAN, langURL } from '../../../URLS';
import { useTranslation } from 'react-i18next';

import newListI from '../../../assets/images/icons/paymentstatus.png'
import right from '../../../assets/images/icons/right-chevronBlack.png'
import listI from '../../../assets/images/icons/list.png'
import { Link } from 'react-router-dom';
import axios, {token_config, config} from '../../../axiosBase';
import customerDetails from '../../UserData';

import LogoLoader from '../../UI/LogoLoader/LogoLoader';

import step2done from '../../../assets/images/icons/step2done.png'
import step3done from '../../../assets/images/icons/step3done.png'
import hard_ticket from '../../../assets/images/icons/hard-ticket.png'
const SaleCompletion = () => {

    const { t, i18n } = useTranslation();
    const [eventId, setEventId] = useState(undefined);
    const [eventState, setEventState] = useState([]);
    const [showLoader, setShowLoader] = useState(false)
    const linkLangCode = localStorage.getItem("i18nextLng") === LANG_GERMAN ? 4 : 7;

    useEffect(()=>{
        if(document.referrer && document.referrer.includes(SELLFORM) && sessionStorage.getItem("last_sold")){
            window.history.pushState(null, "", window.location.href);
            setEventId(sessionStorage.getItem("last_sold"));
            const last_sold_ticket_details = JSON.parse(sessionStorage.getItem("last_sold_ticket_details"));
            if(last_sold_ticket_details && Object.keys(last_sold_ticket_details).length){
                window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                window.dataLayer.push({
                    event: "purchase",
                    transaction_id: last_sold_ticket_details.listing_id,
                    value: parseFloat(last_sold_ticket_details.total_price)*0.12,
                    currency: "EUR",
                    enhanced_conversion_data: {
                        email: customerDetails.data.email
                    }
                })
            }
            window.history.pushState(null, "", window.location.href);
        }else{
            window.location.href = HOME;
        }
    },[])

    useEffect(()=>{
        setShowLoader(true);
        axios.get('/products/'+eventId+'?output_format=JSON', config)
        .then(function(data){
            if(data.data.product){
                setEventState(data.data.product)
            }
            setShowLoader(false);
        }).catch(function(err){
            setShowLoader(false);
        })
    },[eventId])

    const get_link_rewrite = (ele) => {
        if(ele.link_rewrite[0]){
            if(linkLangCode == 4){
                return ele.link_rewrite[1].value
            }else if(linkLangCode == 7){
                return ele.link_rewrite[2].value
            }
        }else if(ele.link_rewrite["1"]){
            return ele.link_rewrite[linkLangCode]
        }
    }


    return (
        <div className={classes.Sell}>
          <div className={classes.TableHr5}></div>
          {/*<div className={completion.TransitionContainer}>
                <div className={completion.StepOneContainer}>
                    <div className={completion.StepNumberOneTextContainer}>
                        <p>1</p>
                    </div>
                    <div className={completion.StepNumberOneImageContainer}>
                        <img src={hard_ticket} alt="ticket"/>
                    </div>
                </div>
                <div className={completion.FirstThreeDotsContainer}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={completion.StepTwoContainer}>
                    <div className={completion.StepTwoImageContainer}>
                        <img src={step2done} alt="step 2 incomplete"/>
                          </div>
                </div>
                <div className={completion.SecondThreeDotsContainer}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={completion.StepThreeContainer}>
                    <div className={completion.StepThreeImageContainer}>
                       <img src={step3done} alt="step 3 incomplete"/>
                    </div>
                </div>
           </div>*/}
            <div className={[classes.SellMain,completion.OuterPaddingContainer].join(" ")}>
                <div className={[classes.SellContSave,completion.BigHeading].join(" ")}>
                    <p className={classes.Savep}> {t("Congratulation")}</p>
                        <div className={completion.CongratsInfo}>
                            <p>{t('Congratulation_text')}<a href={ABOUT}>{t('Congratulation_text_link')}</a>.)</p>
                        </div>
                    <button onClick={() => window.location.href = LISTINGS } className={classes.SellSave}><img className={classes.newBlack} src={listI} alt="" />
                        {t("View_all_Listings")}  <img src={right} alt="" /></button><br />
                    <a href={eventState.link_rewrite ? langURL+get_link_rewrite(eventState) : "#"}>
                        <button className={classes.SellSave}><img src={newListI} alt="" /> {t("back_to_event")}  <img className={classes.Right} src={right} alt="" /></button>
                    </a>
                </div>
            </div>
            { showLoader ? <LogoLoader /> : null }
        </div>
    );
}

export default SaleCompletion;