import React, {useState, useEffect} from 'react';
import classes from './SellTicketsSearch.module.css';
import { useTranslation } from 'react-i18next';

import {SEARCHRESULTS, EVENT_STRING, SELLFORM, HOWITWORKS} from '../../URLS';
import { Link } from 'react-router-dom';

import customerDetails from '../UserData.js';
import AlertBox from '../UI/AlertBox/AlertBox';
// Search results list
// Currently temporary
const List = (props) => {
	const artists = props.artists;
	const events = props.events;
	const query = props.query;
	const [groupedResults, setGroupedResults] = useState([]);
	const [groupedArtists, setGroupedArtists] = useState([]);
	const [activeTabs, setActiveTabs] = useState({});
    const [showAlertBox, setShowAlertBox] = useState(false);
    const [element, setElement] = useState({});
	const { t, i18n } = useTranslation();

	const categories = [
		{
			"id": 1,
			"category": "Artists",
			"value": "category_name"
		},
		{
			"id": 2,
			"category": "Events",
			"value": "name"
		}];

	useEffect(()=>{
		let groupedList = {};
		let groupedArts = {};
		if(events && events.length){
			events.map(function(res, index){
				if(!groupedList[res.name["1"]]){
					groupedList[res.name["1"]] = []
				}
				groupedList[res.name["1"]].push(res)

				if(index == events.length-1){
					setGroupedResults(groupedList)
				}
			})					
		}else{
			setGroupedResults({})
		}

		if(artists && artists.length){
			artists.map(function(res, index){

				if(!groupedArts[res.name]){
					groupedArts[res.name] = res
				}

				if(index == artists.length-1){
					setGroupedArtists(groupedArts)
				}
			})					
		}else{
			setGroupedArtists({})
		}

	},[artists, events])

	useEffect(()=>{
		Object.entries(groupedResults).map(function([key, value], index){
			activeTabs['tab'+index] = false;
			if(index == Object.entries(groupedResults).length - 1){
				setActiveTabs(activeTabs)
			}
		})
	},[groupedResults])

    const handleClick = (index) => {
    	const currentState = activeTabs['tab'+index];
        for (var key in activeTabs) {
            activeTabs[key] = false
        }
        if(!currentState){
	        setActiveTabs({
	            ...activeTabs,
	            ['tab' + index]: true
	        })        	
        }else{
        	setActiveTabs({...activeTabs})
        }
    }

    const modifyArtistForURL = (artist_name) => {
    	if(artist_name && artist_name != ""){
    		artist_name = artist_name.replaceAll(' ','-')
    		artist_name = artist_name + "-karten"
    		return artist_name
    	}else{
    		return ""
    	}
    }


	return (
		<div>
			{categories.map((category, index) => (
				<div key={index}>
					<p className={classes.SearchNames}>{t(category.category)}</p>
					{ category.value === "category_name" ?
					artists !== null && artists !== undefined ?
					Object.keys(groupedArtists).length ? Object.entries(groupedArtists).map(function([key, value], index){
						return  <a href={EVENT_STRING+value.link_rewrite} key={index}><p className={classes.SearchNames1}>
										{key}
								</p></a>
							}) : <p className={classes.NoResults}>{t("no_results_search")}</p>
						: query != "" ? <p className={classes.NoResults}>{t('Loading')}</p> : <p className={classes.NoResults}>{t('no_results_search')}</p>
					
					: category.value === "name" ?
					events !== null && events !== undefined ?
					Object.keys(groupedResults).length ? Object.entries(groupedResults).map(function([key, value], index){
						return  <div key={index}>
								{value.length > 1 ?
								<p className={classes.SearchNames1} onClick={ ()=>handleClick(index) }>
									<div className={classes.EventTitle}>
										{key}
										<span className={classes.Count} onClick={()=>window.location.href=EVENT_STRING+modifyArtistForURL(value[0].category_name)}>
										{/* activeTabs['tab'+index] ?
											<button onClick={()=>window.location.href=SEARCHRESULTS+'?q='+key} className="myButton">
												{t('see_all')}
											</button> : null */}
										({value.length})</span>
									</div>
									{ activeTabs['tab'+index] ? <div>
										{value.map(function (res) {
											return customerDetails ?
			                                    <Link
			                                      		to={{
					                                        pathname: SELLFORM,
					                                        state: res.id
					                                      }}
			                                    ><p className={[classes.SearchNames1,classes.SubList].join(' ')}>
													{res.event_date.split(' ')[0]} - {res.location_city}
												</p></Link>
												:<p onClick={()=>{setShowAlertBox(true); setElement(res);}} className={[classes.SearchNames1,classes.SubList].join(' ')}>
													{res.event_date.split(' ')[0]} - {res.location_city}
												</p>
										})}
									</div> : null }
								</p>
								: 	customerDetails ?
	                                    <Link
	                                      		to={{
			                                        pathname: SELLFORM,
			                                        state: value[0].id
			                                      }}
	                                    ><p className={classes.SearchNames1}>
											{key}
										</p></Link>
									: <p onClick={()=>{setShowAlertBox(true); setElement(value[0]);}} className={classes.SearchNames1}>
										{key}
									</p>
								}
								</div>
							}) : <p className={classes.NoResults}>{t("no_results_search")}</p>
						: query != "" ? <p className={classes.NoResults}>{t('Loading')}</p> : <p className={classes.NoResults}>{t('no_results_search')}</p>
					: <p className={classes.NoResults}>{t("no_results_search")}</p>
					}
				</div>
			))}
			{ showAlertBox ?
                <AlertBox data={{
                    alertTitle:t("Login!"),
                    alertMessage:t("Please_login_first_to_sell_a_ticket"),
                    acceptText:"Okay",
                    denyText:t("Cancel"),
                    link: {
                	  url: HOWITWORKS,
                	  text: t("How_it_works_2")
              		},
                    acceptAction: ()=>{props.openLoginBox(element); setShowAlertBox(false)},
                    denyAction: ()=>setShowAlertBox(false)
                }}
                /> : null
    		}
		</div>);
};

export default List;