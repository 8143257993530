import React, { useState, useRef, useEffect } from 'react';
import classes from './SellTicketsSearch.module.css';
import searchIcon from '../../assets/images/icons/search_icon.png';
import UseOutsideAlerter from '../UseOutsideAlerter/UseOutsideAlerter'
import { useTranslation } from 'react-i18next';
import List from './List';
import axiosInstance, { config } from '../../axiosBase';
import axios from 'axios';
import {SEARCHRESULTS} from '../../URLS';
import {sortEventsBy} from '../FunctionsLibrary/Sorting';

import consoleLog from '../../log';

const SellTicketsSearch = () => {
	const { t, i18n } = useTranslation();
	const [searchInput, setSearchInput] = useState('');
	const [searchInputTrigger, setSearchInputTrigger] = useState('');
	const [searchListA, setSearchListA] = useState();
	const [searchListE, setSearchListE] = useState();
	const [showSearchDrop, setShowSearchDrop] = useState(false)
	var editRef = useRef(null);
	const [showSearchBar, setShowSearchBar] = useState(false)
	const [timeout, setTimeoutState] = useState(null);


	useEffect(()=>{
		if(searchInput != ""){
			/*axios.all([
				axiosInstance.get('/products?output_format=JSON&limit=' + process.env.REACT_APP_SEARCH_LIMIT + '&display=full&filter[active]=[1]&filter[name]=[' + searchInput + ']%', config), 
				axiosInstance.get('/products?output_format=JSON&limit=' + process.env.REACT_APP_SEARCH_LIMIT + '&display=full&filter[active]=[1]&filter[category_name]=[' + searchInput + ']%', config)
			])
			.then(axios.spread((data1, data2) => {
				consoleLog('data1', data1, 'data2', data2)
				if(data1.data.products){
					setSearchListE(sortEventsBy(data1.data.products, "event_date"))
				}else{
					setSearchListE([])
				}
				if(data2.data.products){
					setSearchListA(sortEventsBy(data2.data.products, "event_date"))
				}else{
					setSearchListA([])
				}
			}));*/
			axiosInstance.get('/searchevents?a=search&q='+searchInput, config)
			.then(function(res){

				if(res.data.events){
					setSearchListE(sortEventsBy(res.data.events, "event_date"))
				}else{
					setSearchListE([])
				}

				if(res.data.categories){
					setSearchListA(res.data.categories)
				}else{
					setSearchListA([])
				}
			})
			.catch(function(err){
				setSearchListA([])
				setSearchListE([])
			})
		}
	},[searchInputTrigger])

	// On change of input, display the search results
	// Search API to be called
	const inputChangedHandler = (event) => {
		setSearchInput(event.target.value);
		clearTimeout(timeout);

	    setTimeoutState(
		    setTimeout(function () {
		    	const val = event.target.value;
				if(val != ""){
					setSearchInputTrigger(val)
				}else{
					setSearchListE(null)
					setSearchListA(null)
				}

				if (searchInput != null) {
					setShowSearchDrop(true)
				}
				else {
					setShowSearchDrop(false)
				}
		    }, 800)
		)
	}

	//function called in UseoutsideAlerter to open and close the search bar
	const setEditF = (value) => {
		if (value) {
			setShowSearchDrop(value)
		}
		else {

			setTimeout(() => {
				setShowSearchDrop(value)
			}, 200)
		}
	}

	//to close the search bar on clicking outside it.
	UseOutsideAlerter(editRef, setEditF, false)

	const onEnter = (ele) => {
	    if(ele.keyCode === 13) {
	    	window.location.href = SEARCHRESULTS+'?q='+searchInput;
	    }
	}

    const openLoginBox = (data) => {
        sessionStorage.setItem("sell_search_proceed_props",JSON.stringify(data));
        if(window.screen.width > 1100){
            document.getElementById("loginBtnId").click();
        }else{
            document.getElementById("loginBtnMobileId").click();
        }
    }

	return (
		<React.Fragment>
			{/*<FadeIn>*/}
			<div className={[classes.animated, classes.fadeIn].join(' ')}>
				<div className={classes.SearchContainer} ref={editRef}>
					<input
						type="text"
						value={searchInput}
						placeholder={t("For_which_Event_do_you_want_to_sell_tickets?")}
						className={[classes.SearchInput, classes.Header].join(' ')}
						onChange={(event) => {inputChangedHandler(event); } }
						onKeyDown={onEnter} />
					<div style={{display: "inline-flex"}}>
					<a href={SEARCHRESULTS+'?q='+searchInput}><span className={classes.SearchIcon}><img src={searchIcon} alt="" /></span></a>
					</div>
					{showSearchDrop && <div className={classes.SearchDrop}>
						<List artists={searchListA} events={searchListE} openLoginBox={openLoginBox} query={searchInput} />
					</div>}
				</div>
			</div>

			{/*</FadeIn>*/}
		</React.Fragment>
	);
}


export default SellTicketsSearch;



/*	const [selectInput, setSelectInput] = useState({
		filter1: {
				options: [
					{
						value: 'filter1',
						displayValue: 'Filter 1'
					},
					{
						value: 'filter2',
						displayValue: 'Filter 2'
					},
					{
						value: 'filter3',
						displayValue: 'Filter 3'
					},
					{
						value: 'filter4',
						displayValue: 'Filter 4'
					}
				],
				value: ''
			},
		filter2: {
			options: [
				{
					value: 'filter1',
					displayValue: 'Filter 1'
				},
				{
					value: 'filter2',
					displayValue: 'Filter 2'
				},
				{
					value: 'filter3',
					displayValue: 'Filter 3'
				},
				{
					value: 'filter4',
					displayValue: 'Filter 4'
				}
			],
			value: ''},
		filter3: {
			options: [
				{
					value: 'filter1',
					displayValue: 'Filter 1'
				},
				{
					value: 'filter2',
					displayValue: 'Filter 2'
				},
				{
					value: 'filter3',
					displayValue: 'Filter 3'
				},
				{
					value: 'filter4',
					displayValue: 'Filter 4'
				}
			],
			value: ''
			}
		});
*/

/*	const selectChangedHandler = (event, id) => {
		const updatedSelect = {
			...selectInput,
			[id]: {
				...selectInput[id],
				value: event.target.value,
			}
		}
		consoleLog(updatedSelect,"US");
		setSelectInput(updatedSelect);
	}


	if (props.simpleSearch){
		return (
			<React.Fragment>
				<FadeIn>
				<div className={classes.SearchContainer}>
					<input
						type="text"
						value={searchInput.value}
						className={classes.SearchInput}
						onChange={(event) => inputChangedHandler(event)} />
					<span className={classes.SearchIcon}><img src={searchIcon} /></span>
				</div>
				<div className={classes.AdvancedSearchContainer} onClick={props.clicked}>
					<span className={classes.AdvancedSearch}>
						Advanced Search
					</span>&nbsp;&gt;
				</div>
				</FadeIn>
			</React.Fragment>
		);
	}else{

		let filterSearchArray = [];

		for (let key in selectInput){
			consoleLog(key);
			filterSearchArray.push({
				id: key,
				config: selectInput[key]
			});
		}

		let filters = filterSearchArray.map(formElement => (
			<select
				key={formElement.id}
				value={formElement.config.value}
				className={classes.FilterSelect}
				onChange={(event) => selectChangedHandler(event, formElement.id)} >
				{formElement.config.options.map(option => (
					<option key={option.value} value={option.value}>{option.displayValue}</option>
				))}
			</select>
		));

		return (
			<FadeIn>
			<div>
				<div className={classes.AdvancedSearchContainer} onClick={props.clicked}>
					<span className={classes.AdvancedSearch}>
							Back to simple search
					</span>&nbsp;&lt;
				</div>
				{filters}
				<button className={classes.AdvancedSearchButton}>Advanced Search&nbsp;<img src={searchIcon} /></button>
			</div>
			</FadeIn>
		);
	}*/
