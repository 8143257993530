import React, {useRef, useEffect} from 'react';
import classes from './LogoLoader.module.css';

const LogoLoader = () => {

	const AnimRef = useRef(null)

	const restartAnimation = (spans) => {
		setTimeout(function(){
			for(let i=0; i<spans.length; i++){
				spans[i].style.animation = 'none';
				setTimeout(function(){
					spans[i].style.animation = '';
				},300)
			}
			restartAnimation(spans)
		},4800)
	}

	useEffect(()=>{
		let spans = AnimRef.current.children;
		restartAnimation(spans)
	},[])

	return (
		<div className={classes.LogoLoader}>
			<div ref={AnimRef}>
				<span className={classes.Kaufmein}>k</span>
				<span className={classes.Kaufmein}>a</span>
				<span className={classes.Kaufmein}>u</span>
				<span className={classes.Kaufmein}>f</span>
				<span className={classes.Kaufmein}>m</span>
				<span className={classes.Kaufmein}>e</span>
				<span className={classes.Kaufmein}>i</span>
				<span className={classes.Kaufmein}>n</span>
				<span className={classes.Ticket}>t</span>
				<span className={classes.Ticket}>i</span>
				<span className={classes.Ticket}>c</span>
				<span className={classes.Ticket}>k</span>
				<span className={classes.Ticket}>e</span>
				<span className={classes.Ticket}>t</span>
				<span className={classes.Ticket}>.</span>
			</div>
		</div>
	);
}

export default LogoLoader;