import React from 'react'
import classes from './Vision.module.css'
import fbIcon from '../../../assets/images/icons/navbar_facebook.png'
import instaIcon from '../../../assets/images/icons/nav_insta.png'
import vorfanIcon from '../../../assets/images/icons/vorfans.png'
import forfanIcon from '../../../assets/images/icons/forfans.png'

import {Facebook, Instagram} from '../../../SocialURLs';
import {LANG_ENGLISH} from '../../../URLS';

import { useTranslation } from 'react-i18next';

export default function Vision() {

    const { t, i18n } = useTranslation();

    return (
        <div className={classes.ServiceCont}>
            <p className={classes.AboutHead}>Vision</p>
            <p>
                {t('vision_kmt_1')}
            </p>
            <p className={classes.Visionp}>
                {t('vision_kmt_head_1')}
            </p>
            <p>
                {t('vision_kmt_2')}
            </p>
            <div className={classes.AboutHr}></div>
            <div className={classes.VisionIcons}>
                <a href={Facebook} target="_blank" rel="noreferrer"><img src={fbIcon} alt="" /></a>
                <a href={Instagram} target="_blank" rel="noreferrer"><img src={instaIcon} alt="" /></a>
            </div>
            <div>
                <img className={classes.VisionLastImg} src={
                    i18n.language === LANG_ENGLISH ? forfanIcon : vorfanIcon} alt="" />
            </div>
        </div>
    )
}
