const EmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:[\.|\+]*[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
const PasswordPattern = /^(?=.*[0-9])[a-zA-Z0-9.,!@#$%^&*ÄäÖöÜüß]{8,128}$/;
const SecurePasswordPattern = /^(?=.*[0-9])(?=.*[.,!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9.,!@#$%^&*ÄäÖöÜüß]{6,128}$/;
const NamePattern = /^[a-zA-Z ,.'-ÄäÖöÜüß]+$/i;
//const NumberPattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const NumberPattern = /^[0-9]{6,20}$/;
const AlphaPattern = /^[a-zA-ZÄäÖöÜüß ]*$/;
const DigitPattern = /^[0-9]*$/;
const GeneralTextPattern = /^[a-zA-Z0-9ÄäÖöÜüß _.!~*'()-/@]*$/;
const PricePattern = /^([1-9]\d*|0)([.,]\d+)?$/;

const ArtistNamePattern = /^(.*-karten)$/;

const PostalCodePattern = (zip_code_format) => {
    // Carry out regex for all zipcodes
    let code_arr = [];
    let count = 0;
    let considering_letter = zip_code_format[0];

    for(let i=0; i<zip_code_format.length; i++){

        if(zip_code_format[i] !== considering_letter){
            code_arr.push(count.toString()+considering_letter)
            considering_letter = zip_code_format[i]
            count = 1
        }else if(i+1 === zip_code_format.length){
            count = count + 1
            code_arr.push(count.toString()+considering_letter)
        }else{
            count = count + 1
        }
    }
    let pattern = code_arr;
    let pre = "^";
    let post = "$";
    let num = "[0-9]";
    let code = "[A-Za-z0-9]";
    let hifun = "[-]";
    let space = "[ ]";
    let letter = "[A-Za-z]";
    let any = "[A-Za-z0-9- ]+"

    let exp = "";
    if(pattern.length){
        for(let i=0; i<pattern.length; i++){
            if(pattern[i].includes("N")){
                exp = exp+num+"{"+pattern[i][0]+"}"
            }else if(pattern[i].includes("C")){
                exp = exp+code+"{"+pattern[i][0]+"}"
            }else if(pattern[i].includes("L")){
                exp = exp+letter+"{"+pattern[i][0]+"}"
            }else if(pattern[i].includes("-")){
                exp = exp+hifun+"{"+pattern[i][0]+"}"
            }else if(pattern[i].includes(" ")){
                exp = exp+space+"*"
            }
        }
    }else{
        exp = any
    }
    var reg = new RegExp(pre+exp+post);
    return reg;
}

export {EmailPattern, PasswordPattern, NamePattern, NumberPattern, PostalCodePattern, AlphaPattern, DigitPattern, GeneralTextPattern, ArtistNamePattern, PricePattern};