import React, { useRef, useState, useEffect } from 'react';
import classes from './ChangePassword.module.css';
import Block from '../Block/Block';
import Save from '../SaveAlert/Save';
import { useTranslation } from 'react-i18next';
import EyeOpen from '../../../assets/images/icons/eye-open.png';
import EyeClosed from '../../../assets/images/icons/eye-closed.png';
import useOutsideAlerter from '../../UseOutsideAlerter/UseOutsideAlerter';
//import { useTranslation } from 'react-i18next';
import {ChangePasswordData} from '../XMLData/XMLData';
import axios, {config} from '../../../axiosBase';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';

import LogoLoader from '../../UI/LogoLoader/LogoLoader';
import customerDetails, {storage} from '../../UserData';

import {PasswordPattern} from '../../../Formats';
import consoleLog from '../../../log';

const ChangePassword = () => {
	const { t, i18n } = useTranslation();

	const img1 = useRef(null)
	const img2 = useRef(null)
	const img3 = useRef(null)

	const input1 = useRef(null)
	const input2 = useRef(null)
	const input3 = useRef(null)

	const formRef = useRef(null)

	const [userData, setUserData] = useState({})
	const [errorList, setErrorList] = useState([])
	const [errorSubmit, setErrorSubmit] = useState(false)

	const [showLoader, setShowLoader] = useState(false)
	const [eyeOpen, setEyeOpen] = useState([true, true, true])

	const showPassword = (show, inputRef, imgRef, index) => {
		if (show) {
			inputRef.current.type = "text";
			imgRef.current.src = EyeClosed;
			imgRef.current.style.padding = "5px 0";
			
			let arr = [...eyeOpen];
			arr[index] = false;
			setEyeOpen(arr);
		}
		else {
			inputRef.current.type = "password";
			imgRef.current.src = EyeOpen;
			imgRef.current.style.padding = "3px 0";

			let arr = [...eyeOpen];
			arr[index] = true;
			setEyeOpen(arr);
		}
	}

	const checkPassword = (value) => {
		const format = PasswordPattern;
		if(value.length > 7 && format.test(value)){
			return true;
		}else{
			return false;
		}
	}

	const formValidate = (e) => {
		const val = e.target.value;
		const errorColor = "var(--red)";
		const successColor = "var(--green)";

		if (checkPassword(val)) {
			e.target.style.borderColor = successColor;
		}
		else {
			e.target.style.borderColor = errorColor;
		}
	}

    const saveNewPassword = (e) => {
        e.preventDefault();

        const old_password = e.target.old_password.value;
        const new_password = e.target.new_password.value;
        const confirm_password = e.target.confirm_password.value;

        if((old_password === '')||(new_password === '')||(confirm_password === '')){
        	setErrorList([{"": t("Password_cannot_be_empty") }])
        	setErrorSubmit(true)
        	return false;
		}
        if(new_password === old_password){
        	setErrorList([{"": t("Password_cannot_be_same_as_old_password")}])
        	setErrorSubmit(true)
        	return false;
        }
        if(new_password !== confirm_password){
        	setErrorList([{"": t("Password_dont_match") }])
        	setErrorSubmit(true)
        	return false;
        }
        if(!checkPassword(new_password)){
        	setErrorList([{"": t("Password_Invalid")}])
        	setErrorSubmit(true)
        	return false;
        }
        if(!checkPassword(confirm_password)){
        	setErrorList([{"": t("Password_Invalid")}])
        	setErrorSubmit(true)
        	return false;
        }
        const passwords = {
        	"old_password": old_password,
        	"new_password": new_password
        }
        const payload = ChangePasswordData(userData, passwords);
        config['headers']['Content-Type'] = 'text/xml';
        consoleLog(payload)
        setShowLoader(true)
        axios.put('/customers/830?action=changepw', payload, config)
        .then(function(data){
        	setShowLoader(false)
            setShowSave(true);
            formRef.current.reset();
        })
        .catch(function(err){
        	setShowLoader(false)
        	setErrorList([{"": t("The_password_entered_does_not_match_the_current_password")}])
        	setErrorSubmit(true)
        });
    }

	const [showSave, setShowSave] = useState(false)
	var editRef = useRef(null);
	const setEditF = (value) => {
		if (value) {
			setShowSave(value)
		}
		else {
			setTimeout(() => {
				setShowSave(value)
			}, 200)
		}
	}
    useEffect(() => {
        axios.get('/customers/'+customerDetails.data.id+'?output_format=JSON', config)
            .then(function(data){
                consoleLog(data)
                setUserData(data)
            })
    },[Object.keys(userData).length])

	useOutsideAlerter(editRef, setEditF, false);

	return (
		<div>
			<p className={classes.Heading}></p>

			<Block>
				<div className={classes.ChangePasswordContainer}>
					<div className={classes.TitleText}>
						{t("password_format_text")}
					</div>
					<form onSubmit={(e)=>saveNewPassword(e)} ref={formRef}>
					<div className={classes.ChangePasswordForm}>
						<div className={classes.InputContainer}>
							<input
								className={["myInput", classes.Input].join(' ')}
								ref={input1}
								type="password"
								placeholder={t("Current_password")}
								name="old_password" />
							<span><img className={classes.InputIcon} src={eyeOpen[0] ? EyeOpen : EyeClosed} alt="" ref={img1} onClick={() => showPassword(eyeOpen[0], input1, img1, 0)} /></span>
						</div>
						<div className={classes.InputContainer}>
							<input
								className={["myInput", classes.Input].join(' ')}
								ref={input2}
								type="password"
								placeholder={t("New_password")}
								name="new_password"
								onChange={(event) => formValidate(event)} />
							<span><img className={classes.InputIcon} src={eyeOpen[1] ? EyeOpen : EyeClosed} alt="" ref={img2} onClick={() => showPassword(eyeOpen[1], input2, img2, 1)} /></span>
						</div>
						<div className={classes.InputContainer}>
							<input
								className={["myInput", classes.Input].join(' ')}
								ref={input3}
								type="password"
								placeholder={t("Confirm_new_password")}
								name="confirm_password"
								onChange={(event) => formValidate(event)} />
							<span><img className={classes.InputIcon} src={eyeOpen[2] ? EyeOpen : EyeClosed} alt="" ref={img3} onClick={() => showPassword(eyeOpen[2], input3, img3, 2)} /></span>
						</div>
					</div>
					<div className={classes.ButtonContainer}>
						<button type="submit" className={classes.Button}>{t("Save")}</button>
						<button type="button" onClick={() => formRef.current.reset()} className={classes.Button}>{t("Cancel")}</button>
					</div>
					</form>
				</div>
			</Block>
			{showSave &&
				<div className="backdropCont">
					<div>
						<Save refer={editRef} />
					</div>
				</div>}
            { errorSubmit ?
                <ErrorBox data={{
                    errorTitle: t("Error(s)"),
                    errorSubTitle: t('please_resolve_your_errors'),
                    errors: errorList,
                    acceptText: "OK",
                    acceptAction: () => { setErrorSubmit(false) }
                }} /> : null
            }
        { showLoader ? <LogoLoader /> : null }
		</div>
	);

}

export default ChangePassword;