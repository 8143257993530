import React from 'react'

export default function Timer({ hoursMinSecs, timeOut, startDate }) {
    const { hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
    const [[hrs, mins, secs], setTime] = React.useState([hours, minutes, seconds]);

    const tick = () => {

        if (hrs <= 0 && mins <= 0 && secs <= 0) {
            timeOut()
        }
        // reset()
        else if (mins === 0 && secs === 0) {
            setTime([hrs - 1, 59, 59]);
        } else if (secs === 0) {
            setTime([hrs, mins - 1, 59]);
        } else {
            setTime([hrs, mins, secs - 1]);
        }
    };


    // const reset = () => setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);


    React.useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    });

    React.useEffect(() => {
        if(startDate){
            // Compare timing with server
            var endDate   = new Date();
            var server_seconds = (endDate.getTime() - startDate.getTime())/1000;

            if((parseInt(server_seconds) + (mins*60+secs)) == minutes*60){
                // Let it be
            }else{
                var current_time = minutes*60 - server_seconds;
                setTime([hrs, parseInt(current_time/60), parseInt(current_time%60)])
            }
        }
    },[secs])

    return (
        <div>
            <p>{`${hrs.toString().padStart(2, '0')}:${mins
                .toString()
                .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`}
            </p>
        </div>
    );


}
