import CryptoJS from 'crypto-js';

export const encryptWithAES = (text) => {
  const passphrase = process.env.REACT_APP_SECRET_KEY;
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

export const decryptWithAES = (ciphertext) => {
  const passphrase = process.env.REACT_APP_SECRET_KEY;
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
