import React, {useState, useEffect, useRef} from 'react';
import classes from './Orders.module.css';
import zoom from '../../assets/images/icons/add-searching.png';
import copyUrl from '../../assets/images/icons/copytoclip_new.png';
import CardIcon from '../../assets/images/icons/card.png';
import TrackOrderIcon from '../../assets/images/icons/trackorder.png';
import SupportIcon from '../../assets/images/icons/support.png';
import DownloadIcon from '../../assets/images/icons/download_1.png';
import CompletedIcon from '../../assets/images/icons/CompletedIcon.png';
import AwaitingIcon from '../../assets/images/icons/AwaitingPaymentIcon.png';
import AwaitingShipment from '../../assets/images/icons/AwaitingShippingIcon.png';
import ShippedIcon from '../../assets/images/icons/ShippedIcon.png';
import CanceledIcon from '../../assets/images/icons/CancelledIcon.png';
import RunningPersonIcon from '../../assets/images/icons/running.png';
import MobileTicketIcon from "../../assets/images/icons/mobile-ticket-icon-3.png";

import UpIcon from '../../assets/images/icons/upload.png';

import Tick from '../../assets/images/icons/bluetick.png';
import Cross from '../../assets/images/icons/canceled.png';

import CrossIcon from '../../assets/images/icons/cancel.png';

import useOutsideAlerter from '../UseOutsideAlerter/UseOutsideAlerter';

import axios, { config, token_config } from '../../axiosBase';
import CustomSelect from '../UI/CustomSelect/CustomSelect';

import { LANG_ENGLISH, DOWNLOADTICKETS_STRING } from '../../URLS';

import LogoLoader from '../UI/LogoLoader/LogoLoader';

import ContactUs from '../ContactUsBox/ContactUs';

import { useTranslation } from 'react-i18next';
import customerDetails, {storage} from '../UserData';
import {checkForLogin} from '../PrivateRoute/PrivateRoute';

import consoleLog from '../../log';
import FullScreen from '../FullScreen/FullScreen';

const Orders = (props) => {


	const { t, i18n } = useTranslation();
	const [open, setOpen] = useState(false);
	const [image, setImage] = useState(false);
    const [showContactBox, setShowContactBox] = useState(false);
    const [pageDetails, setPageDetails] = useState("");
    // ORDER STATES
	const orderStates = {
		awaiting_payment: [1, 8, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 37, 39, 40, 41, 42, 43, 44, 45],
		awaiting_shipment: [2, 3, 9, 11, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34],
		shipped: [4, 33, 46],
		completed: [5, 36, 38, 48, 49, 50, 51, 52, 53],
		canceled: [6, 7, 35, 47]
	}
	const ShippingMethods = {
        "1": t('shipping_1_title'),
        "2": t('shipping_2_title'),
        "3": t('shipping_3_title'),
        "": t('shipping_1_title')
    }

	//ORDER STATES
    const [showLoader, setShowLoader] = useState(false)
	const [ticketDetails, setTicketDetails] = useState(false)

	const [shippingAddressData, setShippingAddressData] = useState({})
	const [ordersData, setOrdersData] = useState({
		tickets: [
			/*{
				ticketType: "eTicket",
				status: "completed"
			},
			{
				ticketType: "eTicket",
				status: "shipped"
			},
			{
				ticketType: "hardTicket",
				status: "awaiting_payment"
			},
			{
				ticketType: "hardTicket",
				status: "awaiting_shipment"
			},
			{
				ticketType: "eTicket",
				status: "canceled"
			}*/
		]
	})

	const [trackingData, setTrackingData] = useState({}) /*[
		{
			date: "Thursday, 10 Jun",
			statusDescription: [
				{
					time: "HH:MM",
					description: "Detail description of shipment status"
				}
			]
		},
		{
			date: "Thursday, 11 Jun",
			statusDescription: [
				{
					time: "HH:MM",
					description: "Detail description of shipment status"
				},
				{
					time: "HH:MM",
					description: "Detail description of shipment status"
				},
				{
					time: "HH:MM",
					description: "Detail description of shipment status"
				},
				{
					time: "HH:MM",
					description: "Detail description of shipment status"
				}
			]
		}
	]*/

	const [ordersList, setOrdersList] = useState([]);
	const [trackOrder, setTrackOrder] = useState(false);
	const editRef = useRef(null);
	const editRef1 = useRef(null);
	const editRef2 = useRef(null);

	const [mobileShippingAddress, setMobileShippingAddress] = useState(true);
	const [listOpened, setListOpened] = useState(false);
	const [showPopup,setShowPopup]=useState({
		"eventIm":false,
		"ticketmaster":false
	})

	const [currentType, setCurrentType] = useState(props.type);
	consoleLog(currentType,"tipe",props.type)
	useEffect(()=>{
		let tempList = [];
		if(props.type === "ALL"){
			tempList = ordersData.tickets;
		}else{
			ordersData.tickets.map(function(item){
				if(props.type.toLowerCase() === item.associations.order_rows[0].status){
					tempList.push(item)
				}
			})
		}
		setCurrentType(props.type)
		setOrdersList(tempList)
	},[props.type, ordersData])

	const activeTabJson = {};
	const [activeTabs, setActiveTabs] = useState({});
    const handleOpenTabs = (status, index) => {

        for (var key in activeTabs) {
            activeTabs[key] = false
        }
        setActiveTabs({
            ...activeTabs,
            ['tab' + index]: status ? true : false
        })
    }
    useEffect(() => {
        if(Object.keys(ordersList).length){
            ordersList.map(function (value, index) {
                activeTabJson['tab' + index] = false
            })
            setActiveTabs(activeTabJson)
        }
    }, [Object.keys(ordersList).length])


	const setFilter = (type) => {
		let tempList = [];
		if(type === "ALL"){
			tempList = ordersData.tickets;
		}else{
			ordersData.tickets.map(function(item){
				if(type.toLowerCase() === item.associations.order_rows[0].status){
					tempList.push(item)
				}
			})
		}
		setCurrentType(type)
		setOrdersList(tempList)
	}

	const trackOrderOpen = (order) => {
		setTrackOrder(true)
		setTrackingData(order)
		axios.get('/addresses?output_format=JSON&display=full&filter[id_customer]=['+customerDetails.data.id+']&filter[deleted]=[0]', config)
        .then(function(data){
        	consoleLog(data)
			setShippingAddressData(data.data.addresses[0])
		})
		.catch(function(err){
			//
		})
	}

	const collapseButtonChange = () => {
		setMobileShippingAddress(!mobileShippingAddress)
	}

	function base64ToArrayBuffer(data) {
	    var binaryString = window.atob(data);
	    var binaryLen = binaryString.length;
	    var bytes = new Uint8Array(binaryLen);
	    for (var i = 0; i < binaryLen; i++) {
	        var ascii = binaryString.charCodeAt(i);
	        bytes[i] = ascii;
	    }
	    return bytes;
	};

	const downloadURI = (uri, name) => {
        var blob = new Blob([uri], { type: 'application/pdf' });
        consoleLog(blob)
		var URL = window.URL || window.webkitURL;
		consoleLog(URL)
		var downloadUrl = URL.createObjectURL(blob);
		consoleLog(downloadUrl)
	    var link = document.createElement("a");
	    link.download = name;
	    link.href = downloadUrl;
	    document.body.append(link);
		link.click();

		//document.body.removeChild(link);
		//URL.revokeObjectURL(downloadUrl);
	}

	const downloadEticket = (tickets, id, reference) => {

		consoleLog(tickets, id, reference)
        checkForLogin().then(function(token){
        if(token){

        const config = token_config(token)
        config['responseType'] = "arraybuffer";

        tickets.split(',').map(function(number){
        const payload = {
        	"id":id,
    		"order_reference": reference,
    		"num": number
        }
        consoleLog(payload)

        axios.post('/listing?a=pdf_download', payload, config)
        .then(function (data) {
        	consoleLog(data, "PDF")
        	downloadURI(data.data, "ticket"+number+".pdf")
        })
        .catch(function(err){
        	consoleLog(err)
        })

        })

    	}})
	}

	const searchWithinOrders = (e) => {
		const query = e.target.value;
		let tempFilteredDetails = [];
		if(query == ""){
			tempFilteredDetails = ordersData.tickets;
		}
		else{
			tempFilteredDetails = ordersData.tickets.filter(order => order.associations.order_rows[0].product_name.toLowerCase().includes(query.toLowerCase()));
		}
		setOrdersList(tempFilteredDetails)
	}

    useEffect(() => {
    	setShowLoader(true)
        axios.get('/orders?output_format=JSON&display=full&filter[id_customer]=['+customerDetails.data.id+']', config)
            .then(function (data) {
            	setShowLoader(false)
            	consoleLog(data, "Orders data data data")
            	if(!data.data.orders){
            		return;
            	}
            	const tempList = [];
            	let current_state = 0;
            	for(let i=data.data.orders.length-1; i>=0; i--){
            		if(!data.data.orders[i].associations || !data.data.orders[i].associations.order_rows){
            			continue;
            		}
            		current_state = parseInt(data.data.orders[i].current_state)
            		if(orderStates.awaiting_payment.includes(current_state)){
            			data.data.orders[i].associations.order_rows[0]['status'] = "awaiting_payment";
            			if(data.data.orders[i].ticket_type === "4" || data.data.orders[i].ticket_type === "5"){
            				data.data.orders[i].associations.order_rows[0]['status'] = "awaiting_upload";
            			}
            		}
            		else if(orderStates.awaiting_shipment.includes(current_state)){
            			if(data.data.orders[i].ticket_type === "2"){
            				data.data.orders[i].associations.order_rows[0]['status'] = "awaiting_shipment";
            			}else if(data.data.orders[i].ticket_type === "4" || data.data.orders[i].ticket_type === "5"){
            				data.data.orders[i].associations.order_rows[0]['status'] = "awaiting_upload";
            			}
            		}
            		else if(orderStates.shipped.includes(current_state)){
            			data.data.orders[i].associations.order_rows[0]['status'] = "shipped";
            		}
            		else if(orderStates.completed.includes(current_state)){
            			data.data.orders[i].associations.order_rows[0]['status'] = "completed";
            		}
            		else if(orderStates.canceled.includes(current_state)){
            			data.data.orders[i].associations.order_rows[0]['status'] = "canceled";
            		}
            		tempList.push(data.data.orders[i])
            	}
                setOrdersData({tickets: tempList})
            })
            .catch(function(err){
           		setShowLoader(false)
           		consoleLog(err)
            })
    }, [Object.keys(ordersData).length])

/*    useEffect(() => {
    	axios.get('/order_states?output_format=JSON&display=full', config)
    	.then(function(data){
    		const tempList = {};
    		for(let i=0; i<data.data.order_states.length; i++){
    			tempList[data.data.order_states[i].id] = data.data.order_states[i].name;
    		}
    		setOrderStates(tempList)
    	})
    },[])*/
	function copyToClipboard(text) {
		navigator.clipboard.writeText(text)
		  .then(() => {
			alert('Copied!');
		  })
		  .catch((error) => {
			alert('Copy error');
		  });
	  }
	const setEditF = (value) => {
		setTrackOrder(false)
		
    }
	const setEditF1 = (value) => {
		setShowPopup({
			"eventIm":false,
			"ticketmaster":false
		})		
    }
	const setEditF2 = (value) => {
		setShowPopup({
			"eventIm":false,
			"ticketmaster":false
		})
    }
    // close login ,account and cart dropDown on outside click 
    useOutsideAlerter(editRef, setEditF, false);
    useOutsideAlerter(editRef1, setEditF1, false);
    useOutsideAlerter(editRef2, setEditF2, false);

	return (
		<React.Fragment>
			{open && <FullScreen image={image} setOpen={setOpen}/>}
		<div className={classes.Orders}>
            <div className={classes.OrdersSearch} >
                <input className={"myInput"} type="text" placeholder={t("Search_within_your_orders")} onChange={(e)=>searchWithinOrders(e)} />
            </div>
            <div className={classes.FiltersSelect}>
            	<div>{t('Filter_by')}</div>
            	<div>
            		<CustomSelect
                                optionsList={[
                                    {
                                        name: t('All_Orders'), value: "ALL"
                                    },
                                    {
                                        name: t('Awaiting_Upload'), value: "AWAITING_UPLOAD"
                                    },
                                    {
                                        name: t('Awaiting_Shipment'), value: "AWAITING_SHIPMENT"
                                    },
                                    {
                                        name: t('Completed'), value: "COMPLETED"
                                    },
                                    {
                                        name: t('Canceled'), value: "CANCELED"
                                    }
                                    ]}
                                changed={setFilter}
                                styling={{
                                    container: { width: "200px", margin: "auto"},
                                    defaultText: { background: "var(--blue)",
                                                    padding: "10px 20px",
                                                    color: "var(--black)",
                                                   	border: "2px solid var(--blue)",
                                                   	borderRadius: listOpened === 1 ? "10px 10px 0 0" : "10px" },
                                    ul: {},
                                    li: { background: "var(--white)",
                                            color: "var(--black)",
                                            border: "1px solid var(--blue)"}
                                }}
                                defaultText={ i18n.language == LANG_ENGLISH ? "All Orders" : "Alle Bestellungen" }
                                listOpened={(item)=>setListOpened(item)}
                                listOpenIndex={1}
                            />
            	</div>
            </div>
            <div className={classes.TitleText}>
            	{t('Hello')}, {customerDetails.data.firstname}
            	{t('Here_are_all_your_1')} { currentType === "ALL" ? t('orders')
            		: currentType === "CANCELED" ? t('canceled_orders')
            		: currentType === "AWAITING_UPLOAD" ? t('awaiting_upload')
            		: currentType === "AWAITING_SHIPMENT" ? t('awaiting_shipments')
            		: currentType === "COMPLETED" ? t('completed_orders')
            		: null
            	}
            	{t('Here_are_all_your_2')}:
            </div>

            <div className={classes.OrdersList}>
            {ordersList.map(function(order, index){
            	return	<div className={
            					order.associations.order_rows[0].status === "completed" ?
            							[classes.Order,classes.SuccessBorder].join(' ')
            						: order.associations.order_rows[0].status === "canceled" ?
            							[classes.Order, classes.FailBorder].join(' ')
            						: classes.Order
            					}>
    				<div className={classes.Title}>
    					{ order.ticket_type === "1" ?
    						"E-TICKET" 
    					: order.ticket_type === "2" ?
    						"HARDCOPY TICKET"
    					: order.ticket_type === "4" ?
    						t('mobile_ticket_eventim')
    					: order.ticket_type === "5" ?
    						t('mobile_ticket_ticketmaster')
    					: "Ticket"
    					}
    				</div>
            		<div className={classes.EventDescription}>
	            		<div className={classes.EventPicture}>
	            			<img src={CardIcon} alt="" />
	            		</div>
            			<div className={classes.Details}>
            				<div className={classes.EventTitle}>
            					<div>{order.associations.order_rows[0].product_name}</div>
            					<div className={classes.PriceBlock}>€ {parseFloat(order.associations.order_rows[0].product_price).toFixed(2).replace('.',',')}</div>
            					<div className={classes.MobileEventTitle}>
	            					<div>
	            						<div className={classes.TicketCount}>{order.associations.order_rows[0].product_quantity}</div>
	            						<div className={classes.TicketText}>{order.associations.order_rows[0].product_quantity != 1 ? "tickets" : "ticket"}</div>
	            					</div>
	            					<div className={classes.PriceBlock}>{parseFloat(order.associations.order_rows[0].product_price).toFixed(2).replace('.',',')} €</div>
            					</div>
            				</div>
            				<div className={classes.OrderId}><span>{t('Order_ID')} #</span>{order.reference}</div>
            				<div className={classes.Location}>
            					<div>
            						<div>{order.associations.order_rows[0].location}</div>
            						<div>{order.associations.order_rows[0].date} | {order.associations.order_rows[0].section}</div>
            						<div>{order.ticket_type === "2" ?
            								t('delivery_method') +" : "+ ShippingMethods[order.shipping_method]
            							: null
            						}</div>
            					</div>
            					<div>
            						<div className={classes.TicketCount}>{order.associations.order_rows[0].product_quantity}</div>
            						<div>{order.associations.order_rows[0].product_quantity > 1 ? t('Tickets') : t("Ticket") }</div>
            					</div>
            					<div className={classes.MobileEventLocation}>
            						<div>{order.associations.order_rows[0].product_name}</div>
            						<div>
            							<div>{order.associations.order_rows[0].location}</div>
            							<div>{order.associations.order_rows[0].date} | {order.associations.order_rows[0].section}</div>
            						</div>
            					</div>
            				</div>
            				<div className={classes.Buttons}>
            					<div className={classes.ActionButtons}>
            						{ order.ticket_type === "2" ?
            							<button
            								className={
            								order.associations.order_rows[0].status === "canceled" ?
            									[classes.Disabled,"myButton"].join(' ') : "myButton"}
            								onClick={
            								order.associations.order_rows[0].status !== "canceled" ?
            									order.shipping_method !== "3" ?
            										()=>trackOrderOpen(order)
            									: null
            								: null}>
            								<img src={TrackOrderIcon} alt="" />&nbsp;
            								{
            									order.shipping_method === "3" ? t('pin')+order.pin : t('track_order')
            								}
            							</button> : null }
            						<a  onClick={()=>{ setShowContactBox(true); setPageDetails("Orders Support : "+order.reference+" - "); }}/*href={"mailto:info@kaufmeinticket.de?subject=ORDER ID "+order.reference}*/><button className={"myButton"}><img src={SupportIcon} alt="" />&nbsp;{t('Support')}</button></a>
            					</div>
								
            					{ order.ticket_type === "1" && order.associations.order_rows[0].eticket == "1" && order.associations.order_rows[0].ticketnumbers != "" ?
            					<div className={ order.associations.order_rows[0].status === "canceled" ? [classes.Disabled, classes.DownloadButton].join(' ') : classes.DownloadButton}>
            						<a href={order.associations.order_rows[0].status === "canceled" ? null : DOWNLOADTICKETS_STRING+order.associations.order_rows[0].product_attribute_id+"-"+order.reference}>
            						<button>
									
            							<img src={DownloadIcon} alt="" />&nbsp;
            							<div>{i18n.language === 'de' ?
            									<>{t('E-ticket')}<br />{t('Download')}</>
            									:<>{t('Download')}<br />{t('E-ticket')}</>
            							}</div>
            						</button>
            						</a>
            					</div>
            					: order.ticket_type === "4" ?
									<div className={ order.associations.order_rows[0].status === "canceled" ? [classes.Disabled, classes.DownloadButton].join(' ') : classes.DownloadButton}>
									{ order.associations.order_rows[0].number && order.associations.order_rows[0].ticket_url !== "" && order.associations.order_rows[0].ticket_url !== null ?
										<button onClick={()=>{setShowPopup({"eventIm":true});setTicketDetails(order)}}>
											<img src={DownloadIcon} alt="" />&nbsp;
											<div>
												{t('eventim_download_btn_1')}<br />{t('eventim_download_btn_2')}
											</div>
										</button>
									: <>
										<span className={classes.MoreInfoMobileTickets} onClick={()=>handleOpenTabs( !activeTabs['tab'+index], index )}>
											<img className={ activeTabs['tab'+index] ? classes.Rotate180 : null } src={UpIcon} />{t('more_info')}
										</span>
										<button className={classes.Grayscale}>
												<img src={DownloadIcon} alt="" />&nbsp;
												<div>
													{t('eventim_download_btn_1')}<br />{t('eventim_download_btn_2')}<br />({t('available_soon')})*
												</div>
										</button>
									</>
									}
									</div>
								: order.ticket_type === "5" ?
									<div className={ order.associations.order_rows[0].status === "canceled" ? [classes.Disabled, classes.DownloadButton].join(' ') : classes.DownloadButton}>
										{  order.associations.transfer_proof ?
	            						<button onClick={()=>{setShowPopup({"ticketmaster":true});setTicketDetails(order)}}>
	            							<img src={DownloadIcon} alt="" />&nbsp;
	            							<div>
	            								{t('ticketmaster_download_btn_1')}<br />{t('ticketmaster_download_btn_2')}
	            							</div>
	            						</button>
	            						: <>
		            						<span className={classes.MoreInfoMobileTickets} onClick={()=>handleOpenTabs( !activeTabs['tab'+index], index )}>
												<img className={ activeTabs['tab'+index] ? classes.Rotate180 : null } src={UpIcon} />{t('more_info')}
											</span>
		            						<button className={classes.Grayscale}>
		            							<img src={DownloadIcon} alt="" />&nbsp;
		            							<div>
		            								{t('ticketmaster_download_btn_1')}<br />{t('ticketmaster_download_btn_2')}<br />({t('available_soon')})*
		            							</div>
		            						</button>
		            					</>
	            						}
	            					</div>
	            				: null
            					}

            				</div>
            				{ activeTabs['tab'+index] ?
            				<div className={classes.MobileTicketText}>
            					<div>
            						{
            							order.ticket_type === "4" ?
            								t("mobile_tickets_order_text_eventim").split("\n").map(function(line){
            									return <>{line}<br/></>
            								})
            							: order.ticket_type === "5" ?
            								t("mobile_tickets_order_text_ticketmaster").split("\n").map(function(line){
            									return <>{line}<br/></>
            								})
            							: null
            						}
            					</div>
            				</div> : null }
            			</div>
            			<div className={classes.EventStatus}>
            			<div>
            				<div className={classes.StatusTitle}>STATUS</div>
            				<div className={classes.StatusDescription}>
            				{ order.associations.order_rows[0].status === "completed" ?
            					<React.Fragment>
	            					<img src={CompletedIcon} alt="" />
	            					<div>{t('Completed')}</div>
            					</React.Fragment>
            				: order.associations.order_rows[0].status === "awaiting_upload" ?
            					order.shipping_method === "3" ?
	            					<React.Fragment>
	            						<img src={RunningPersonIcon} alt="" />
		            					<div>{t('on_the_way')}</div>
	            					</React.Fragment>
            					:
	            					<React.Fragment>
	            						<img src={MobileTicketIcon} alt="" />
		            					<div>{t('Awaiting_Upload')}</div>
	            					</React.Fragment>
            				: order.associations.order_rows[0].status === "awaiting_shipment" ?
            					order.shipping_method === "3" ?
	            					<React.Fragment>
	            						<img src={RunningPersonIcon} alt="" />
		            					<div>{t('on_the_way')}</div>
	            					</React.Fragment>
            					:
	            					<React.Fragment>
		            					<img src={AwaitingShipment} alt="" />
		               					<div>{t('Awaiting_Shipment')}</div>
	            					</React.Fragment>
            				: order.associations.order_rows[0].status === "canceled" ?
            					<React.Fragment>
	            					<img src={CanceledIcon} alt="" />
	            					<div>{t('Canceled')}</div>
            					</React.Fragment>
            				: order.associations.order_rows[0].status === "shipped" ?
            					order.shipping_method === "3" ?
	            					<React.Fragment>
	            						<img src={RunningPersonIcon} alt="" />
		            					<div>{t('on_the_way')}</div>
	            					</React.Fragment>
            					:
		        					<React.Fragment>
		            					<img src={ShippedIcon} alt="" />
		            					<div>{t('Shipped')}</div>
		        					</React.Fragment>
            				: null
            				}
            				</div>
            			</div>
            			</div>
            		</div>
            	</div>
            	})}
            </div>
		</div>
		{ trackOrder ? <div className={classes.TrackOrder}>
			<div className={classes.TrackOrderContainer}>
				<div className={classes.WrapBox} ref={editRef}>
					<div className={classes.Title}>
						<div>{t('Tracking_Order')}</div>
					</div>
					<div className={classes.Close} onClick={()=>setEditF(false)}><img src={CrossIcon} alt="" /><div>close</div></div>
					<div className={[classes.SubHeading, classes.TitleSubHeading].join(' ')}>
						<div>{t('Order_ID')}: #{trackingData.reference}</div><div>Status: <span>{trackingData.associations.tracking ? trackingData.associations.tracking[trackingData.associations.tracking.length-1].step : null}</span></div>
						<br /><div>{t('delivery_method') +" : "+ ShippingMethods[trackingData.shipping_method]}</div>
					</div>
					<div className={[classes.SubHeading, classes.MobileTicketReach].join(' ')}>{t('Your_tickets_will_reach_you_by')} <span>{trackingData.delivery_date}</span></div>
					<div className={classes.TrackOrderDetails}>
						<div className={classes.Container}>
							<div className={classes.OrderCurrentStatus}>
								<div className={ trackingData.associations.order_rows[0].status === "completed" ||
												trackingData.associations.order_rows[0].status === "awaiting_shipment" ||
												trackingData.associations.order_rows[0].status === "shipped" ?
								 [classes.State, classes.Active].join(' ') : classes.State}>{t('Order_Confirmed')}&nbsp;<img src={Tick} alt="" /></div>
								<div className={trackingData.associations.order_rows[0].status === "completed" ||
												trackingData.associations.order_rows[0].status === "awaiting_shipment" ||
												trackingData.associations.order_rows[0].status === "shipped" ?
									[classes.Process, classes.Active].join(' ') : classes.Process}>
									<div className={classes.Dot}></div><br />
									<div className={classes.Dot}></div><br />
									<div className={classes.Dot}></div><br />
								</div>
								<div className={trackingData.associations.order_rows[0].status === "completed" ||
												trackingData.associations.order_rows[0].status === "shipped" ?
									[classes.State, classes.Active].join(' ') : classes.State}>{t('Shipping')}&nbsp;<img src={Tick} alt="" /></div>
								<div className={trackingData.associations.order_rows[0].status === "completed" ||
												trackingData.associations.order_rows[0].status === "shipped" ?
									[classes.Process, classes.Active].join(' ') : classes.Process}>
									<div className={classes.Dot}></div><br />
									<div className={classes.Dot}></div><br />
									<div className={classes.Dot}></div><br />
								</div>
								<div className={trackingData.associations.order_rows[0].status === "completed" ?
									[classes.State, classes.Active].join(' ') : classes.State}>{t('Delivery')}&nbsp;<img src={Tick} alt=""/></div>
							</div>
						</div>
						{
						Object.keys(shippingAddressData).length ?
						mobileShippingAddress ?
							<div className={classes.OrderShippingAddress}>
								<div className={classes.WrapBox}>
									<div className={classes.Title}>{t('Shipping_To')}:</div>
									<div>
										{shippingAddressData.address1}<br />
										{shippingAddressData.address2}<br />
										{shippingAddressData.address3}<br />
									</div>
									<div>
										{shippingAddressData.city}<br />
										{shippingAddressData.postcode}<br />
									</div>
									<div className={classes.CollapseButton} onClick={()=>collapseButtonChange()}><img src={UpIcon} alt="" /></div>
								</div>
							</div> :
							<div className={classes.OrderShippingAddress}>
								<div className={classes.WrapBox}>
									<div className={classes.Title}>{t('Shipping_To')}:</div>
									<div>
										{shippingAddressData.address1}<br />
									</div>
									<div className={[classes.CollapseButton, classes.ExpandButton].join(' ')}  onClick={()=>collapseButtonChange()}><img src={UpIcon} alt="" /></div>
								</div>
							</div> : null
						}
						<div className={classes.OrderPostDetails}>
							<div className={classes.WrapBox}>
								<div className={classes.Title}>
									{ trackingData.shipping_method === "2" ? t('Delivery_by_dhl') : t('Delivery_by_German_Post')}<br/>
									{t('Tracking_ID')}:
									{ trackingData.associations.tracking ?
										trackingData.shipping_method === "2" ?
										<a href={process.env.REACT_APP_DHL_TRACK_LINK+trackingData.shipping_number} target="_blank"> {trackingData.shipping_number}
										</a>
										:
										<a href={process.env.REACT_APP_DP_TRACK_LINK+trackingData.associations.tracking[0].dp_voucher_id} target="_blank"> {trackingData.associations.tracking[0].dp_voucher_id}
										</a>
									: null }
								</div>
								<div className={classes.SubDetails}>
								{
									trackingData.associations.tracking ?
									trackingData.associations.tracking.map(function(detail){
										return <React.Fragment>
										<div className={classes.Date}>
											{detail.date_add ? detail.date_add.split(' ')[0] : null}
										</div>
										<div className={classes.DetailDescription}>
											<div>{ detail.date_add ? detail.date_add.split(' ')[1] : null}&nbsp;|&nbsp;</div>
											<div>{detail.description ? detail.description : t("No_Information_Available") }</div>
										</div>
										</React.Fragment>
									}) : null
								}
								</div>
							</div>
						</div>
					</div>
					<div className={[classes.TrackingOrderData, classes.Buttons].join(' ')}>
						{/* COMMENTED TEMP
						{/*<div className={[classes.SubHeading,classes.DesktopTicketReach].join(' ')}>{t('Your_tickets_will_reach_you_by')} <span>"ETA"</span></div>
						<div className={classes.Support}>{/*<span>Do you want to cancel your order?&nbsp;</span><a  onClick={()=> {setShowContactBox(true); setPageDetails("Orders Support : "+trackingData.reference+" - "); }} /*href={"mailto:info@kaufmeinticket.de?subject=ORDER ID "+trackingData.reference}>{t('Support')}</a></div>
						<div className={[classes.Support, classes.MobileSupport].join(' ')}><a  onClick={()=>{setShowContactBox(true); setPageDetails("Orders Support : "+trackingData.reference+" - "); }}/*href={"mailto:info@kaufmeinticket.de?subject=ORDER ID "+trackingData.reference}>{t('Support')}</a>{/*<span>If you wish to cancel your order.</span></div>
						COMMENTED TEMP */}
				        <div className={classes.ActionButtons}>
    						{ trackingData.associations.order_rows[0].eticket == "0" ?
    							<a href={
    								trackingData.shipping_method === "1" ? process.env.REACT_APP_DP_TRACK_LINK+trackingData.associations.tracking[0].dp_voucher_id
    								: trackingData.shipping_method === "2" ? process.env.REACT_APP_DHL_TRACK_LINK+trackingData.shipping_number
    								: "#" }
    							target="_blank">
    							<button
    								className={"myButton"}>
    								<img src={TrackOrderIcon} alt="" />&nbsp;
    								{
    									trackingData.shipping_method === "3" ? t('pin')+trackingData.pin : t('track_order')
    								}
    							</button></a> : null }
    						<a  onClick={()=>{ setShowContactBox(true); setPageDetails("Orders Support : "+trackingData.reference+" - "); }}/*href={"mailto:info@kaufmeinticket.de?subject=ORDER ID "+order.reference}*/><button className={"myButton"}><img src={SupportIcon} alt="" />&nbsp;{t('Support')}</button></a>
    					</div>
					</div>
				</div>
			</div>
		</div>: null }
		
		 { showPopup.eventIm ? 
		 
		<div className={classes.TrackOrder}>
			<div className={classes.TrackOrderContainer}>
				<div className={classes.WrapBox} ref={editRef1}>
					<div className={classes.UrlsUpperContainer}>
						<div>
							<span>{ticketDetails.associations.order_rows[0].product_name}</span>
							<p>{ticketDetails.associations.order_rows[0].location}</p>
						</div>
						<div className={classes.BackButton}
						 onClick={()=>setShowPopup({
							"eventIm":false,
							"ticketmaster":false
						})}>
							<button
								className={"myButton"}>
								{t('back')}
							</button>

						</div>

					</div>
					<div className={classes.LowerContainer}>
					<div className={classes.UrlContainer}>
						{
							 ticketDetails.associations.order_rows.map((ticket,i)=>{
								return(
									<div className={classes.Urls}>
										<div>
											<a href={ticket.ticket_url} target="_blank">
												<span>URL Ticket {ticket.number}</span>
											</a>
											<p>{t('Available')}</p>
											</div>
											<div className={classes.UrlCopy} onClick={()=>copyToClipboard(ticket.ticket_url)}>
												<img src={copyUrl} alt="urllink"/>
											</div>
									</div>
								)
							 })
								
							
						}
						
					</div>
					<div className={[classes.TrackingOrderData, classes.Buttons].join(' ')}>
				        <div className={classes.ActionButtons}>
    						<p>{t('Attention_mobile_tickets')}</p>
    						<a  onClick={()=>{ setShowContactBox(true); setPageDetails("Orders Support : "+trackingData.reference+" - "); }}><button className={"myButton"}><img src={SupportIcon} alt="" />&nbsp;{t('Support')}</button></a>
    					</div>
					</div>
					</div>
				</div>
			</div>
		</div>
		: showPopup.ticketmaster ?
		<div className={classes.TrackOrder}>
			<div className={classes.TrackOrderContainer}>
				<div className={classes.WrapBox} ref={editRef2}>
					<div className={classes.UrlsUpperContainer}>
						<div>
						<span>{ticketDetails.associations.order_rows[0].product_name}</span>
							<p>{ticketDetails.associations.order_rows[0].location}</p>
						</div>
						<div className={classes.BackButton}
						onClick={()=>setShowPopup({
							"eventIm":false,
							"ticketmaster":false
						})}>
							<button
	    						className={"myButton"}>
	    						{t('back')}
	    					</button>
						</div>
					</div>
					<div className={[classes.LowerContainer, classes.LowerContainerTicket].join(" ")}>
						<div className={classes.TitleTicketMaster}>
							<p>{t('Title_TicketMaster').replace("<account>", ticketDetails.associations.ticketmaster_info[0].ticketmaster_account_email)}</p>
						</div>
					<div className={classes.ConfirmImage}>
							{
                                ticketDetails.associations.transfer_proof.map((ticket,index)=>{
                                    return (
                                        <div className={classes.ConfirmImageDiv}
                                           style={{ backgroundImage: `url(${process.env.REACT_APP_DOMAIN}/img/proof/${ticket.proof})` }}
										   onClick={()=>{setOpen(true);setImage(`${process.env.REACT_APP_DOMAIN}/img/proof/${ticket.proof}`)}}>
                                            <img src={zoom} alt="zoom"/>
                                        </div>
                                    )
                                })
                            }
					</div>
					<div className={[classes.TrackingOrderData, classes.Buttons].join(' ')}>
				        <div className={classes.ActionButtons}>
				        	<p>{t('Attention_mobile_tickets')}</p>
    						<a  onClick={()=>{ setShowContactBox(true); setPageDetails("Orders Support : "+trackingData.reference+" - "); }}/*href={"mailto:info@kaufmeinticket.de?subject=ORDER ID "+order.reference}*/><button className={"myButton"}><img src={SupportIcon} alt="" />&nbsp;{t('Support')}</button></a>
    					</div>
					</div>
					</div>
				</div>
			</div>
		</div>
		: null } 
		
        {
            showContactBox ?
                <ContactUs
                    hideContact={()=>setShowContactBox(false)}
                    pageDetails={pageDetails}
                /> 
				
				: null
        }
        { showLoader ? <LogoLoader /> : null }
		</React.Fragment>
	);

}

export default Orders;