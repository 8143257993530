import jwt_decode from "jwt-decode";
import {decryptWithAES} from './Encrypt/AES';
import Cookies from 'universal-cookie';

let customerDetails = null;
let token = null;
let refresh_token = null;
let storage = null;
const cookies = new Cookies();

if(localStorage.getItem("userId") && cookies.get('loginTime')){
	const tokens = decryptWithAES(localStorage.getItem("userId"));
    token = tokens.split(":")[0];
    refresh_token = tokens.split(":")[1];
    customerDetails = jwt_decode(token);
    storage = localStorage;
}/*else if(sessionStorage.getItem("userId")){
	const tokens = decryptWithAES(sessionStorage.getItem("userId"));
    token = tokens.split(":")[0];
    refresh_token = tokens.split(":")[1];
    customerDetails = jwt_decode(token);
    storage = sessionStorage;
}*/

export default customerDetails;
export { token, refresh_token, storage };