import React, { useState, useEffect } from 'react';
import classes from './Banner.module.css';

import LocationIcon from '../../../assets/images/icons/location.png';
import CalendarIcon from '../../../assets/images/icons/date.png';
import MicIcon from '../../../assets/images/icons/mic.png';

import BannerImage from '../../../assets/images/background/banner-full.png';
import BannerImageMobile from '../../../assets/images/background/banner-collapse.png';


import { useTranslation } from 'react-i18next';
import {SELLFORM, HOWITWORKS} from '../../../URLS';
import { Link } from 'react-router-dom';
import AlertBox from '../../UI/AlertBox/AlertBox';
import customerDetails from '../../UserData.js';

import consoleLog from '../../../log';

const Banner = (props) => {

	consoleLog(props,"BANNER")
	const [styling, setStyling] = useState({});
	const [reverseStyling, setReverseStyling] = useState({});
  const [showAlertBox, setShowAlertBox] = useState(false);
  const bannerImage = window.innerWidth < 769 ? BannerImageMobile : BannerImage;
  let bannerURL = props.eventDetails.image.includes(process.env.REACT_APP_DOMAIN_ONLY) ? "/" + props.eventDetails.image.split('/').slice(1).join('/') : props.eventDetails.image;
  bannerURL = process.env.REACT_APP_DOMAIN + bannerURL;
	const [bannerStyle, setBannerStyle] = useState({
		background: "url('"+bannerURL+"')",
		backgroundSize: "cover",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		height: "100%",
		width: "100%",
		position: "absolute",
		top: "0",
		zIndex: "-1",
		filter: "brightness(50%)"
	});
	const [extraSpacing, setExtraSpacing] = useState({});

	const { t, i18n } = useTranslation();

	const [actionButtonsText, setActionButtonsText] = useState({
		buy: 'Buy',
		sell: 'sell',
		waitlist: 'Waiting_List'
	});
/*	const calculateScrollDistance = () => {
		const scrollTop = window.pageYOffset;
		/*setStyling({
			marginLeft: -scrollTop / 2 + '%'
		})
		setReverseStyling({
			marginLeft: scrollTop / 2 + '%'
		})*
		if (scrollTop > 40) {
			if(window.screen.width >= 1200){
				setStyling({
					position: 'absolute',
					left: 25,
					textAlign: 'left',
				})
				setReverseStyling({
					position: 'absolute',
					right: 10,
				})
				setBannerStyle({
					position: 'fixed',
					paddingTop: '80px',
					height: '100px'
				})
				setExtraSpacing({
					paddingTop: '250px',
				})
			}else{
				setBannerStyle({
					position: 'fixed',
					paddingTop: '80px',
					height: '150px'
				})
				setExtraSpacing({
					paddingTop: '250px',
				})
			}
		}
		else if (scrollTop <= 40) {
			setExtraSpacing({})
			setBannerStyle({})
			setStyling({})
			setReverseStyling({})
		}
	}

	const listenToScrollEvent = () => {
		document.addEventListener("scroll", () => {
			requestAnimationFrame(() => {
				// Calculates the scroll distance
				calculateScrollDistance();
			});
		});
	}*/

	/*const smoothScroll = (h) => {
		let i = h || 0;
		if (i < 100) {
			setTimeout(() => {
				window.scrollTo(0, i);
				smoothScroll(i + 10);
			}, 10);
		}
	}*/
  const openLoginBox = (data) => {
      setShowAlertBox(false)
      sessionStorage.setItem("sell_proceed_props",JSON.stringify(data));
      if(window.screen.width > 1100){
          document.getElementById("loginBtnId").click();
      }else{
          document.getElementById("loginBtnMobileId").click();
      }
  }


  useEffect(()=>{
      if(sessionStorage.getItem("sell_proceed_props") && customerDetails){
          const data = JSON.parse(sessionStorage.getItem("sell_proceed_props"));
          sessionStorage.removeItem("sell_proceed_props");
          consoleLog(data)
          props.previousProps.history.push({
            pathname: SELLFORM,
            //search: '?query=abc',
            state: data.id
          })
      }
  })

	return (
		<React.Fragment>
			<div className={classes.Banner}>
				<div style={bannerStyle}></div>
				<div className={classes.EventDetailsContainer}>
					<div className={classes.EventDetails} >
						<div style={styling}>
							<div className={classes.Title}>
								{props.eventDetails.name[0].value.length > 39 ? props.eventDetails.name[0].value.toUpperCase().split('-').map(function(text, index){
									return <>
										{text}
										{index === 0 ? <br /> : null}
									</>
								}) : props.eventDetails.name[0].value.toUpperCase()}
							</div>
							<div className={classes.MobileEventVenue}>
								<img className={classes.Icon} src={LocationIcon} alt="location" />{props.eventDetails.location_city}
							</div>
							<div className={classes.Location}>
								<div>
									<img className={classes.Icon} src={LocationIcon} alt="location" />{props.eventDetails.location_city}
								</div>
								<div className={classes.EventVenue}>
									<img className={classes.Icon} src={MicIcon} alt="location" />{props.eventDetails.location}
								</div>
								<div>
									<img className={classes.Icon} src={CalendarIcon} alt="date" />{props.eventDetails.event_date.replaceAll('.','-')}
								</div>
							</div>
						</div>
						<div className={classes.ActionCalls} style={reverseStyling}>
							{/*<div className={classes.EventStats}>
								<div className={classes.StatsContainer}>
									<div><span>1234</span> VERFUGBAR</div>
									<div><span>1234</span> VERKAUFT</div>
									<div><span>1234</span> GESUCHT</div>
								</div>
							</div>*/}
							{ customerDetails ?
							<Link
                to={{
                  pathname: SELLFORM,
                  //search: "?sort=name",
                  //hash: "#the-hash",
                  state: props.eventDetails.id
                }}
               >
     					<button className={[classes.SellButton,"myButton"].join(' ')}>{t(actionButtonsText.sell)}</button>
      				</Link> :
      				<button className={[classes.SellButton,"myButton"].join(' ')} onClick={()=>setShowAlertBox(true)}>{t(actionButtonsText.sell)}</button> }
							<button onClick={props.click} className={["myButton", classes.WaitlistButton].join(' ')}>{t(actionButtonsText.waitlist)}</button>
						</div>
					</div>
				</div>
			</div>
			<div style={extraSpacing}></div>
			{ showAlertBox ?
          <AlertBox data={{
              alertTitle:t("Login!"),
              alertMessage:t("Please_login_first_to_sell_a_ticket"),
              acceptText:"Okay",
              denyText:t("Cancel"),
              link: {
                  url: HOWITWORKS,
                  text: t("How_it_works_2")
              },
              acceptAction: ()=>openLoginBox(props.eventDetails),
              denyAction: ()=>setShowAlertBox(false)
          }}
          /> : null
      }
		</React.Fragment>

	);

}

export default Banner;