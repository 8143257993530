import classes from './Save.module.css'
import { useTranslation } from 'react-i18next';
export default function Save(props) {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <div className={classes.Inner} ref={props.refer}>
                <p>{ props.text ? t(props.text) : t("Your_changes_have_been_saved") }</p>
                <p> {t("Click_anywhere_to_close_this_window.")} </p>
            </div>
        </div>
    )
}
