import React, { useEffect, useState, useRef } from 'react'
import classes from './MyListings.module.css'
//import ToggleButton from 'react-toggle-button'
import EditListings from './EditListings/EditListings'
import listIcon from "../../assets/images/icons/list.png"
import down from '../../assets/images/icons/down-chevron.png'
import down1 from '../../assets/images/icons/upload.png'
import edit from '../../assets/images/icons/edit.png'
//import deleteIcon from '../../assets/images/icons/delete.png'
import leftIcon from '../../assets/images/icons/left-arrow.png'
import ErrorBox from '../UI/ErrorBox/ErrorBox'
import ViewIcon from '../../assets/images/icons/viewetickets1.png'
import CheckIcon from '../../assets/images/icons/check.png'
import newListI from '../../assets/images/icons/plus.png'
//import downloadIcon from '../../assets/images/icons/download_1.png'
import SearchPlusIcon from '../../assets/images/icons/add-searching.png'
import { useTranslation } from 'react-i18next';
import LogoLoader from '../UI/LogoLoader/LogoLoader';

import dateFormat from 'dateformat';
import useOutsideAlerter from '../UseOutsideAlerter/UseOutsideAlerter';
import caution from '../../assets/images/icons/caution.png'
import { SELL, EVENT_STRING, LANG_GERMAN, langURL } from '../../URLS';
import consoleLog from '../../log';

import customerDetails from '../UserData';

import axios, {token_config, config} from '../../axiosBase';
import {checkForLogin} from '../PrivateRoute/PrivateRoute';

import {AlphaPattern} from '../../Formats';
import {sortEventsBy} from '../FunctionsLibrary/Sorting';
import search_icon from '../../assets/images/icons/search_icon.png'
import {ShippingOptionsDefault, prepareShippingMethods, get_date_position} from '../SellTickets/ShippingData';

export default function MyListings(props) {


    const { t, i18n } = useTranslation();

    const [allListings, setAllListings] = useState([])
    const [listingsData, setLisitingsData] = useState([])
    const [show, setShow] = useState(false)//expand and contract on toggle
    const [bg, setBg] = useState("var(--blue)") //set bg color of toggle button
    const [openEditForm, setOpenEditForm] = useState(false) // to open edit form in listings
    const [fetchAgain, setFetchAgain] = useState(true)
    const [emptyJson, setEmptyJson] = useState({
        "emptyActive": 1,
        "emptyInactive": 1
    })
    const linkLangCode = localStorage.getItem("i18nextLng") === LANG_GERMAN ? 1 : 2;
    //const [toggleValue, setToggleValue] = useState(false) //to handle toggle button
    // const [isHardTicket, setIsHard] = useState(false)
    const [NoOfTicketDetails, setTicketDetails] = useState(false)
    const [isActive, setIsActive] = useState(true)
    var editRef = useRef(null);

    const ticketStatusJson = {}
    const [ticketInactive, setTicketInactive] = useState(ticketStatusJson)
    // const [selectedVal, setSelected] = useState("e-ticket/HardTicket")
    const [userAddressesData, setUserAddressesData] = useState({})
    //avoid opening all the tickets on opening one ticket
    const expandDivJson = {}
    const [showLoader, setShowLoader] = useState(false)
    //avoid opening all the edit listing on opening one edit list
    const expandEditDivJson = {}
    const [ticketImageData, setTicketImageData] = useState("");

/*    const addShippingMethods = (tickets) => {
        return tickets
    }*/

    useEffect(()=>{
        if(allListings.length){
            let emptyJsonTemp = {}
            let emptyActive = 1
            let emptyInactive = 1
            let newListings = []
            let displayedEventsCount = []

            allListings.map((outer, index1) => {

                let active = 0
                let inactive = 0
                if(outer.associations.combinations){
                    outer.associations.combinations.map((inner, index2) => {
                        ticketStatusJson[outer.id + "_" + inner.id] = true;
                        if(inner.deleted === "0"){
                            if(inner.type == "1" && inner.eticketuploaded == "0"){
                                // SKIP
                            }
                            else{
                                if(inner.activated === "1"){
                                    active = 1
                                    newListings.push(inner)
                                }else{
                                    inactive = 1
                                    newListings.push(inner)
                                }
                            }
                        }

                        /*if(inner.quantity == 0 && inner.activated == 1){
                            changeStatusListing(inner, 0)
                        }*/
                    })
                    // Order listings in the given order > active only > upcoming ones > past events
                    //newListings = addShippingMethods(newListings)

                    allListings[index1].associations.combinations = newListings // Apply listings

                    newListings = []
                    emptyJsonTemp[index1] = {
                        active: active,
                        inactive: inactive
                    }
                    if(active){
                        displayedEventsCount.push(index1)
                        emptyActive = 0
                    }
                    if(inactive){
                        displayedEventsCount.push(index1)
                        emptyInactive = 0
                    }
                }
            })
            emptyJsonTemp["emptyActive"] = emptyActive
            emptyJsonTemp["emptyInactive"] = emptyInactive
            setEmptyJson(emptyJsonTemp)
            setTicketInactive(ticketStatusJson)
            //setAllListings(allListings)
            let openTabs = false;

            if(displayedEventsCount.length < 5){
                openTabs = true;
                allListings.map((value, index) => {
                    expandDivJson['div' + index] = openTabs;
                })
            }else{
                allListings.map((value, index) => {
                    expandDivJson['div' + index] = openTabs;
                })
                expandDivJson['div' + displayedEventsCount[0]] = true;
            }

            allListings.map((value, index) => {
                expandEditDivJson['edit' + index] = false;
            })
            setExpanDiv(expandDivJson)
        }
    },[allListings.length])
    const [expandDiv, setExpanDiv] = useState(expandDivJson)
    const [expandEdit, setExpanEdit] = useState(expandEditDivJson)

    const setEditF = (value) => {
        setTicketDetails(value)
    }

    //close the filter list on outside click
    useOutsideAlerter(editRef, setEditF, false);
    // const fileNameRef = useRef(null);

    // const addFileName = (e) => {
    //     const fileName = e.target.files[0].name;
    //     fileNameRef.current.innerHTML = fileName;
    // }


    //handle expand and collapse of tickets div
    const handleShowClick = (index, state) => {

        for (var key in expandDiv) {
            expandDiv[key] = false
        }
        setExpanDiv({
            ...expandDiv,
            ['div' + index]: state
        })

    }
    //handle open and close of edit listing
    const handleEditShowClick = (index, state) => {
        setOpenEditForm(true)

        for (var key in expandEdit) {
            expandEdit[key] = false
        }
        setExpanEdit({
            ...expandEdit,
            ['edit' + index]: state
        })

    }
    useEffect(() => {
        setShow(!show)
    }, [expandDiv])

    useEffect(() => {
        if (!show)
            setBg("inherit")
        else
            setBg("var(--blue")
    })

    const searchWithinListings = (e) => {
        const query = e.target.value;
        let tempFilteredDetails = [];
        if(query === ""){
            tempFilteredDetails = listingsData;
        }
        else if(query.match(AlphaPattern)){
            tempFilteredDetails = listingsData.filter(listing => listing.name[1].value.toLowerCase().includes(query.toLowerCase()));
        }
        setAllListings(tempFilteredDetails)
    }


    useEffect(()=>{
        checkForLogin().then(function(token){
        if(token){

        const config = token_config(token);
        setShowLoader(true)
        axios.get('/products?display=full&output_format=JSON&filter[active]=[1]', config)
            .then(function (data) {
                consoleLog(data.data)
                if(data.data && data.data.products){
                    setAllListings([])
                    let sorted_data = sortEventsBy(data.data.products, "event_date")
                    setLisitingsData(sorted_data)
                    setAllListings(sorted_data)
                }else{
                    setAllListings([])
                }
                setShowLoader(false)
            })
            .catch(function(err){
                setShowLoader(false)
                setAllListings([])
            })
        }})

    }, [fetchAgain])

    const changeStatusListing = (eventState, status) => {
        checkForLogin().then(function(token){
        if(token){

        const config = token_config(token)

        const payload = {
            "id": eventState.id,
            //"sale_price":110,
            //"org_price":110,
            "activated": status
            //"deleted":1,
        }
        axios.post('/listing?a=update', payload, config)
            .then(function(data){
                consoleLog(data)
                setFetchAgain(!fetchAgain)
            })
            .catch(function(err){
                consoleLog(err)
            })

        }})
    }


    const handleToggleList = (key, value) => {
        setTimeout(() => {
            setTicketInactive({
                ...ticketInactive,
                [key]: value
            })
        }, 300)
    }

    const handleViewTicket = (eventData, ticketData) => {
        setTicketDetails({
            eventData: eventData,
            ticketData: ticketData
        })
    }

    const getImage = (id, ticketNumber) => {

        checkForLogin().then(function(token){
        if(token){

        let payload = {
            id: id,
            num: ticketNumber
        }
        const config = token_config(token)

        setShowLoader(true)
        axios.post('/listing?a=pdf_image', payload, config)
            .then(function(data){
                setShowLoader(false)
                consoleLog(data)
                if(data.data.image){
                    setTicketImageData(data.data.image.replaceAll('\/','/'))
                }
            })
            .catch(function(err){
                consoleLog(err)
            })

        }})
    }

    useEffect(() => {
        axios.get('/addresses/?output_format=JSON&filter[id_customer]=['+customerDetails.data.id+']&filter[deleted]=[0]&display=full', config)
            .then(function (data) {
                if(!(data.data instanceof Array) && Object.keys(data.data).length){
                    setUserAddressesData(data)
                }
            })
    }, [])

    /*const checkIfEditable = (shipping_methods, eventState) => {
        if(Object.keys(userAddressesData).length && Object.keys(eventState).length){
            let shippingOptions = {...ShippingOptionsDefault};
            let userType = userAddressesData.data.addresses[0].id_country === "1" ? "german" : "nonGerman";

            if(shipping_methods){
                for(let i=0; i<shipping_methods.length; i++){
                    shippingOptions[userType]["methods"][shipping_methods[i]-1]["active"] = true;
                }
                let today = new Date();
                let preparedShippingMethods = prepareShippingMethods(userAddressesData, eventState, shippingOptions);

                if(userType == "german"){

                    if(shippingOptions["german"]["methods"][0]["active"] && !shippingOptions["german"]["methods"][1]["active"] && !shippingOptions["german"]["methods"][2]["active"]){
                        if(preparedShippingMethods["german"]["methods"][0]["active"]){
                            return true;
                        }else{
                            return false;
                        }
                    }else if(shippingOptions["german"]["methods"][1]["active"] && !shippingOptions["german"]["methods"][2]["active"]){
                        return false;
                    }else if(shippingOptions["german"]["methods"][2]["active"]){
                        if(new Date(get_date_position(eventState.event_date.split(' ')[0], 2) // year
                                    ,get_date_position(eventState.event_date.split(' ')[0], 1)-1 // month
                                    ,get_date_position(eventState.event_date.split(' ')[0], 0) // date
                            ) < today){
                            return false;
                        }else{
                            return true;
                        }
                    }
                }else if(userType == "nonGerman"){
                    if(shippingOptions["german"]["methods"][0]["active"] && !shippingOptions["german"]["methods"][1]["active"]){
                        if(preparedShippingMethods["german"]["methods"][0]["active"]){
                            return true;
                        }else{
                            return false;
                        }
                    }else if(shippingOptions["german"]["methods"][1]["active"]){
                        if(new Date(get_date_position(eventState.event_date.split(' ')[0], 2) // year
                                    ,get_date_position(eventState.event_date.split(' ')[0], 1)-1 // month
                                    ,get_date_position(eventState.event_date.split(' ')[0], 0) // date
                            ) < today){
                            return false;
                        }else{
                            return true;
                        }
                    }
                }else{
                    return true;
                }
            }else{
                return true;
            }
        }else{
            return false;
        }
    }*/


    return (
        <div className={classes.Listings}>
            <div className={classes.AccountHr}>
            </div>

          

            {/* <div className={classes.ActiveInactive}>
                <button onClick={() => { setIsActive(true) }} className={isActive ? classes.Selected : classes.Nonselected} >{t("Active")}</button>
                <button onClick={() => { setIsActive(false) }} className={!isActive ? classes.Selected : classes.Nonselected} >
                    {t("Inactive")}</button>
            </div> */}
            <div className={classes.FirstContainer}>
            <div className={classes.NewListingLink}>
                <a href={SELL}>
                    <img className={classes.NewIcon} src={newListI} alt="" />
                    <span> {t("New_Listing")} </span>
                </a>
            </div>
            {/* <div className={classes.NewListingLink2}>
                <a href={SELL}>
                    <img className={classes.NewIcon} src={newListI} alt="" />
                    <span>{t('Create_New_Listing')}</span>
                </a>
            </div> */}
            <div className={classes.RightContainer}>
              <div className={classes.AccountHeading}>
                <img src={listIcon} alt="" />
                <span> {t("MY_LISTINGS")} </span>
            </div>
            <div className={classes.ListingSearch} >
                <input  type="text" placeholder={t("Search_within_your_listings")} onChange={(e)=>searchWithinListings(e)} />
                <img src={search_icon} alt="search"/>
            </div>
            </div>
            </div>
            <div>
                {   (isActive && emptyJson["emptyActive"]) || (!isActive && emptyJson["emptyInactive"]) ?
                        <div className={classes.EmptyListings}>
                            { isActive ?
                                t('no_active_listings')
                                : t('no_inactive_listings')
                            }
                        </div>
                    : allListings.map((outer, index1) => {
                    return (
                        <div className={classes.ListingsHead} /*style={ isActive && emptyJson[index1] && emptyJson[index1].active || !isActive && emptyJson[index1] && emptyJson[index1].inactive ? {} : {display: "none"}  }*/>

                            <div className={classes.ListingsCont}>

                                <div className={classes.Listings1}>
                                    <div>
                                        <p className={classes.EventNameLink}>
                                            <a href={langURL+outer.link_rewrite[linkLangCode].value}>{outer.name[0].value}</a>
                                        </p>
                                        <p>{outer.location_city}</p>
                                    </div>
                                    <div className={classes.DateTime}>{outer.event_date.split(' ')[0]}</div>
                                </div>
                                {expandDiv['div' + index1] ?
                                    <div onClick={() => { handleShowClick(index1, false); setOpenEditForm(false) }} className={classes.Listings2} > <p className={classes.Listings2pC}><label className={classes.Hide}>{t("Collapse")} </label>  <img className={classes.Collapse} src={down1} alt="" /></p>
                                    </div> :
                                    <div onClick={() => { handleShowClick(index1, true); setOpenEditForm(false) }} className={classes.Listings2_2} ><p className={classes.Listings2p}> <label className={classes.Hide}>{t("Expand")}</label>   <img className={classes.Expand} src={down} alt="" /></p>
                                    </div>}
                            </div>
                            {
                                expandDiv['div' + index1] ?
                                outer.associations.combinations ?
                                outer.associations.combinations.map((inner, index2) => {
                               
                                    /*if(inner.type == "1" && inner.eticketuploaded == "0"){
                                        // Do not display
                                    }else{*/
                                    return (
                                        (isActive && inner.deleted === "0") || (!isActive && inner.deleted === "0") ?
                                        expandEdit['edit' + index1 + index2] && openEditForm ?
                                            <EditListings
                                                index={index2}
                                                statusId={outer.id + "_" + inner.id}
                                                view={() => handleViewTicket(outer, inner)}
                                                listingState={(id, state)=>changeStatusListing(id, state)}
                                                closeEditClick={() => handleEditShowClick(index1.toString()+index2.toString(), false)}
                                                eventState={inner}
                                                event_id={outer.id}
                                                blocks={outer.associations ? outer.associations.blocks : []}
                                                previousProps={props}
                                                eventDate={outer.event_date}
                                                /> :
                                            <div>
                                                {/*FadeDiv is the class which we have to add for inactive tickets*/ }
                                                <div className={ inner.editable == "1" && inner.quantity > 0 && inner.activated === "1" ? classes.CollapsedDiv : [classes.CollapsedDiv, classes.FadeDiv].join(' ') }>
                                                    <div className={classes.CollapsedHeading}>{
                                                        inner.type === "1" ?
                                                            "E-Tickets"
                                                        : inner.type === "2" ?
                                                            t("Hard_Ticket")
                                                        : inner.type === "4" ?
                                                            t("mobile_ticket_eventim")
                                                        : inner.type === "5" ?
                                                            t("mobile_ticket_ticketmaster")
                                                        : "Ticket"
                                                    }
                                                        </div>
                                                    {/*ticketInactive[outer.id + "_" + inner.id] ?*/}
                                                        <div>
                                                            <div className={classes.CollapsedInner}>
                                                                 {/*write the condition here for displaying caution icon*/ }
                                                                {
                                                                inner.editable == "0" || inner.quantity <= 0 || inner.activated === "0" ?
                                                                    <div className={classes.CautionImageContainer}>
                                                                        <div className={classes.Help}>
                                                                            <img src={caution} alt="caution"/>
                                                                        
                                                                            {/*<div>?</div>*/}
                                                                            <span className={classes.Tooltip}>{t('listing_alert_hover')}</span>
                                                                        </div>
                                                                    </div>
                                                                : null
                                                                }
                                                                <div className={classes.TicketCountContainer}>
                                                                    <div className={classes.TicketHeading}><p>Tickets</p></div>
                                                                    <div className={classes.CountContainer}>
                                                                        <div className={classes.BookedContainer}>
                                                                            <p>{inner.quantity}</p>
                                                                            <p>{t('for_sale')}</p>
                                                                        </div>
                                                                        <span>/</span>
                                                                        <div className={classes.AvailableContainer}>
                                                                            <p>{inner.quantity_sold}</p>
                                                                            <p>{t('sold')}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={classes.Border}></div>
                                                                <div ><p className={classes.CollapsedInner3p}>{outer.event_date.split(' ')[0].replaceAll('.','/')}</p><p>{outer.event_date.split(' ')[1]}</p> </div>
                                                                <div className={classes.Border}></div>
                                                                <div ><p>{inner.block_name}</p><p className={classes.MobilePriceListing}>€ {inner.price.toFixed(2).replace('.',',')}</p></div>
                                                                <div className={[classes.Border, classes.MobileHide].join(' ')}></div>
                                                                <div ><p className={[classes.CollapsedInner5p, classes.MobileHide].join(' ')}>€ {inner.price.toFixed(2).replace('.',',')}</p></div>
                                                                <div onClick={() => handleEditShowClick(index1.toString()+index2.toString(), true)} className={classes.CollapsedInner1}>
                                                                    <img src={inner.editable == "1" && inner.quantity > 0 ? edit : ViewIcon} alt="" />
                                                                </div>
                                                            </div>

                                                            { inner.type === "1" ? <div className={classes.ViewButton}>  <button onClick={() => { handleViewTicket(outer, inner) }} > <img className={classes.ViewBlack} src={ViewIcon} alt="" />{t("View_e-tickets")}</button></div> : null }
                                                        </div>



                                                        {/*:
                                                        <div className={classes.CollapsedInner}>
                                                            <div className={classes.InactiveP}><p> {t("This_listing_has_been_marked_INACTIVE.")} </p></div>
                                                            <div >
                                                                <label class="switch">
                                                                    <input type="checkbox" value={outer.id + "_" + inner.id} onChange={() => {handleToggleList(outer.id + "_" + inner.id, true); changeStatusListing(inner, 1);} } />
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </div>

                                                        </div>*/}
                                                </div>

                                            </div>
                                            : null
                                    )
                                }) : <p className={classes.NoTickets}>No Tickets Available</p>
                                : null
                            }
                        </div>
                    )
                })
                }
            </div>

            {
                NoOfTicketDetails && <div className="backdropCont1">
                    <div className={classes.NTicketCont} ref={editRef}>
                        <div className={classes.NticketHead}>
                            <div>
                                <p className={classes.NticketHeadp}>{NoOfTicketDetails.eventData.name[0].value}</p>
                                <p>{NoOfTicketDetails.eventData.location_city}</p>
                                <p>{NoOfTicketDetails.ticketData.block_name}</p>
                            </div>
                            <div>
                                <p className={classes.NticketHeadp}>{t("Available_Etickets")} {NoOfTicketDetails.ticketData.quantity}</p>
                                <button onClick={() => { setTicketDetails(false); setTicketImageData(""); }} className='myButton'>{t("Back_to_Listing")}</button>
                            </div>
                        </div>
                        <div className={classes.NticketHeadMedia}>
                            <p className={classes.NHeadp_1}>{t('Viewing_etickets')}</p>
                            <div className={classes.MHr}></div>
                            <div className={classes.NMedia2}>
                                <div>
                                    <p className={classes.NticketHeadp1}>{NoOfTicketDetails.eventData.name[0].value}</p>
                                    <p className={classes.NLocation}>{NoOfTicketDetails.eventData.location_city}</p>
                                </div>
                                <div className={classes.BackButton} onClick={() => { setTicketDetails(false) }} >
                                    <p className={classes.NLocation}> <img src={leftIcon} width="15px" /> </p>
                                    <span className={classes.NLocation}> {t('back')} </span>
                                </div>
                            </div>
                        </div>
                        {/*<div className={classes.Nticket2}>
                            <p className={classes.Nticket2_1}>{t("Tickets_in_Listing")}</p>
                            <p className={classes.Nticket2_2}>{t("You_can_download,_view,_or_retreive_tickets_from_your_listings_below.")}</p>
                        </div>*/}

                        { ticketImageData !== "" ?
                        <div className={classes.TicketDisplay}>
                            <img src={ticketImageData} alt="" className={classes.TicketImage} />
                            <button className="myButton" onClick={()=>setTicketImageData("")}>{t('close')}</button>
                        </div> :
                        <div className={classes.ETicketsContainer}>
                        { NoOfTicketDetails.ticketData.ticketNumbers !== "" ? NoOfTicketDetails.ticketData.ticketNumbers.split(",").map(function(data, index){
                            return <div className={classes.NCont}>
                                <div className={classes.NticketList}>
                                    <div className={classes.NticketListDiv1}><p>Ticket {index+1}</p><p>{t("Available")}</p></div>
                                    {/*<div className={classes.NticketListDiv2}>
                                        <button className={classes.DeleteHover} ><img src={deleteIcon} alt="" /></button>
                                        <span className={classes.DeleteLabel}>{t("delete")}</span>
                                        {/* <button className={classes.DownloadHover} ><img src={downloadIcon} /></button> */}
                                        {/* <span className={classes.DownloadLabel}>Download</span>
                                    </div>*/}
                                </div>
                                <div className={classes.NCont2}>
                                    <button className={classes.SearchHover} onClick={()=>getImage(NoOfTicketDetails.ticketData.id, data)}><img src={SearchPlusIcon} alt="" /></button>
                                    <span className={classes.SearchLabel}>{t("view_eticket")}</span>
                                </div>
                            </div>
                        }) : null}
                        </div>
                        }
                        {/*<div className={classes.NCont}>
                            <div className={classes.NticketList + " " + classes.NticketListR}>
                                <div className={classes.NticketListDiv1}><p style={{ textAlign: '-webkit-left' }}>Ticket 1</p><p>{t("Retrieved_on")} DD/MM/YYYY</p></div>
                                <div className={classes.NticketListDiv2}>

                                    {/* <button className={classes.DownloadHover + " " + classes.DownloadHover1} ><img src={downloadIcon} /></button> */}
                                    {/* <span className={classes.DownloadLabel1}>Download</span> *}
                                </div>
                            </div>
                            <div className={classes.NCont2 + " " + classes.NticketListR}>
                                <button className={classes.SearchHover} ><img src={SearchPlusIcon} alt="" /></button>
                                <span className={classes.SearchLabel}>Search</span>
                            </div>
                        </div>*/}
                    </div>
                </div>
            }
        { showLoader ? <LogoLoader /> : null }
        </div >
    )
}

