import React from 'react';
import classes from './BackgroundBlock.module.css';

const BackgroundBlock = (props) => {

	return (<React.Fragment>
	{props.visible ?
	<div className={classes.Container}>
		<div className={classes.Block} ref={props.reference} style={props.style}>
			{props.children}
		</div>
	</div>: null
	}
	</React.Fragment>);
}

export default BackgroundBlock;