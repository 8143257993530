import React, { useState, useEffect } from "react"
import BackgroundBlock from '../BackgroundBlock/BackgroundBlock';
import MakePayment from '../MakePayment/MakePayment';

import {SEARCHRESULTS, EVENT_STRING, FESTIVAL_STRING, THEATER_STRING, SPORT_STRING, HOME} from '../../../URLS';
import customerDetails from '../../UserData';

import consoleLog from '../../../log';

export default function OrderCompletion(props) {

    const [backgroundBlockVisible, setBackgroundBlockVisible] = useState(false);
    const [backgroundBlockData, setBackgroundBlockData] = useState('');
    const [backgroundBlockStyle, setBackgroundBlockStyle] = useState({});

    const url = sessionStorage.getItem("event_url");

    const setBackgroundBlock = (visibility, styling, component) => {
        setBackgroundBlockVisible(visibility);
        setBackgroundBlockStyle(styling);
        setBackgroundBlockData(component);
    }

    const discardPayment = () => {
    }

/*    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
          transaction_id: "T_12345",
          affiliation: "Google Merchandise Store",
          value: 25.42,
          tax: 4.90,
          shipping: 5.99,
          currency: "USD",
          coupon: "SUMMER_SALE",
          items: [
           {
            item_id: "SKU_12345",
            item_name: "Stan and Friends Tee",
            affiliation: "Google Merchandise Store",
            coupon: "SUMMER_FUN",
            currency: "USD",
            discount: 2.22,
            index: 0,
            item_brand: "Google",
            item_category: "Apparel",
            item_category2: "Adult",
            item_category3: "Shirts",
            item_category4: "Crew",
            item_category5: "Short sleeve",
            item_list_id: "related_products",
            item_list_name: "Related Products",
            item_variant: "green",
            location_id: "L_12345",
            price: 9.99,
            quantity: 1
          },
          {
            item_id: "SKU_12346",
            item_name: "Google Grey Women's Tee",
            affiliation: "Google Merchandise Store",
            coupon: "SUMMER_FUN",
            currency: "USD",
            discount: 3.33,
            index: 1,
            item_brand: "Google",
            item_category: "Apparel",
            item_category2: "Adult",
            item_category3: "Shirts",
            item_category4: "Crew",
            item_category5: "Short sleeve",
            item_list_id: "related_products",
            item_list_name: "Related Products",
            item_variant: "gray",
            location_id: "L_12345",
            price: 20.99,
            promotion_id: "P_12345",
            promotion_name: "Summer Sale",
            quantity: 1
          }]
      }
    });
*/

    useEffect(()=>{
        const SP_Details = JSON.parse(sessionStorage.getItem("SP_Details"));
        const url_string = window.location.href;
        const url = new URL(url_string);
        const order_state = url.searchParams.get("order-state");
        const order_id = url.searchParams.get("order-id");
        const retryBlock = JSON.parse(sessionStorage.getItem("RetryBlock"))

        if(order_state
            && order_id
            && document.referrer 
            && ( document.referrer.includes(EVENT_STRING) || document.referrer.includes(FESTIVAL_STRING) || document.referrer.includes(THEATER_STRING) || document.referrer.includes(SPORT_STRING) )
            && SP_Details
        ){
            setBackgroundBlock(true, {}, <MakePayment eventDetails={SP_Details.eventDetails} ticketDetails={SP_Details.ticketDetails} close={discardPayment} retry={callRetryBG} paymentStatus={order_state} />)
            setBackgroundBlock(true, {}, <MakePayment eventDetails={SP_Details.eventDetails} ticketDetails={SP_Details.ticketDetails} close={discardPayment} retry={callRetryBG} paymentStatus={order_state} />)
            sessionStorage.removeItem("SP_Details");

            let price = 0.0;
            if (SP_Details.priceBreakdown && SP_Details.priceBreakdown.base_price){
                price = parseFloat((parseFloat(SP_Details.priceBreakdown.base_price) * 0.12).toFixed(2))
            }
            window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            window.dataLayer.push({
                event: "purchase",
                transaction_id: order_id,
                value: price,
                currency: "EUR",
                enhanced_conversion_data: {
                    email: customerDetails.data.email
                }
            })
            window.history.pushState(null, "", window.location.href);
        }else{
            window.location.href = HOME;
        }
    },[])

    const callRetryBG = () => {
        const retryBlock = JSON.parse(sessionStorage.getItem("RetryBlock"))
        const event_id = sessionStorage.getItem("event_id")

        sessionStorage.setItem("event_proceed_detail", JSON.stringify(retryBlock['details']))
        sessionStorage.setItem("event_proceed_index", retryBlock['id'])
        sessionStorage.setItem("event_id", event_id)

        window.location.href = url;
    }

    return (
        <BackgroundBlock
            style={backgroundBlockStyle}
            visible={backgroundBlockVisible}
            >
            {backgroundBlockData}
        </BackgroundBlock>
   )
}