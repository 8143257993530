import React, {useState} from 'react'
import classes from './Footer.module.css'
import Icon1 from '../../assets/images/icons/Group-104-2.png'
import Icon2 from '../../assets/images/icons/Group-105-2.png'
import Icon3 from '../../assets/images/icons/Group-1051-2.png'
import Icon4 from '../../assets/images/icons/Group-1041-2.png'
import Icon5 from '../../assets/images/icons/Group-103.png'
import Icon6 from '../../assets/images/icons/Group_103.png'
import Icon7 from '../../assets/images/icons/Layer-1849.png'
import logo from '../../assets/images/logo/kaufmeinticket_logo.png';

import FacebookIcon from '../../assets/images/icons/navbar_facebook.png';
import InstagramIcon from '../../assets/images/icons/nav_insta.png';

import ContactUs from '../ContactUsBox/ContactUs';

import { useTranslation } from 'react-i18next';

import {ABOUT, TERMS, PRIVACYPOLICY, FAQS, IMPRESSUM, HOWITWORKS, HOME} from '../../URLS';
import {Facebook, Instagram} from '../../SocialURLs';

export default function Footer() {

    const { t, i18n } = useTranslation();
    const [showContactBox, setShowContactBox] = useState(false);
    const [cookieDisplay, setCookieDisplay] = useState(localStorage.getItem("cookieDisplay"));

    const cookieFunction = (action) => {
        if(action === "OK"){
            localStorage.setItem("cookieDisplay",true);
            setCookieDisplay(true)
        }else if(action === "show_more"){
            window.location.href = PRIVACYPOLICY;
        }
    }

    return (
        <div className={classes.Footer} id="FooterId">
            {/*<p className={classes.Helpful}>{t('Helpful_Links')}</p>*/}
            <div className={classes.Flex}>
                <div className={classes.Div0}>
                    <div className={classes.FooterLogo}>
                        <div><a href={HOME}><img className={classes.Logo} src={logo} alt="" /></a></div>
                        <div>{t('fair_secure_transparent')}</div>
                    </div>
                    <div>
                        <ul>
                            <li><a href={TERMS}>{t('Terms_&_Conditions')}</a></li>
                            <li><a href={PRIVACYPOLICY}>{t('privacy_policy_home')}</a></li>
                        </ul>
                    </div>
                </div>
                <div className={classes.Div1}>
                    <ul>
                        <li>{t('Helpful_Links')}</li>
                        <li><a onClick={()=>(setShowContactBox(true))}>{t('Contact_Us')}</a></li>
                        <li><a href={ABOUT}>{t('About_Us')}</a></li>
                        <li><a href={HOWITWORKS}>{t('How_it_works_2')}</a></li>
                        <li><a href={FAQS}>{t('FAQ')}</a></li>
                        <li><a href={IMPRESSUM}>{t('Impressum')}</a></li>
                    </ul>
                </div>
                <div className={classes.Div2}>
                    <div><img src={Icon1} /></div>
                    <div><img src={Icon2} /></div>
                    <div><img src={Icon3} /></div>
                    <div><img src={Icon4} /></div>
                    <div><img src={Icon5} /></div>
                    <div><img src={Icon6} /></div>
                </div>
                <div className={classes.Div3}>
                    {/*<div>
                        <a href="https://www.shopvote.de/bewertung_kaufmeinticket_de_14500.html" target="_blank">
                            <label>{t('Rated')} 4.77/5 {t('by')}</label><br />
                            <label>{t('Shop_Vote')}</label><br />
                            <img src={Icon7} /><br />
                        </a>
                        <span className={classes.Powered} >{t('Website_Powered_by')}&nbsp;<label><a href="https://www.weboasis.io/">Weboasis</a></label> </span>
                    </div>*/}
                    <div className={classes.MobileSocials}>
                        <div>{t('social_media')}</div>
                        <div><a href={Facebook} target="_blank"><span>Facebook</span><img src={FacebookIcon} alt="" /></a></div>
                        <div><a href={Instagram} target="_blank"><span>Instagram</span><img src={InstagramIcon} alt="" /></a></div>
                    </div>
                </div>

            </div>

            <div className={classes.Div4}>
                <img src={Icon1} />
                <img src={Icon2} />
                <img src={Icon3} />
                <img src={Icon4} />
                <img src={Icon5} />
                <img src={Icon6} />
            </div>

            <div className={classes.Div5}>
                <div>
                    <ul>
                        <li><a href={TERMS}>{t('Terms_&_Conditions')}</a></li>
                        <li><a href={PRIVACYPOLICY}>{t('privacy_policy_home')}</a></li>
                    </ul>
                </div>
            </div>

            <span className={classes.Powered1} >Website Powered by&nbsp;<label><a href="https://www.weboasis.io/">Weboasis</a></label> </span>
            {
                showContactBox ?
                    <ContactUs
                        hideContact={()=>setShowContactBox(false)}
                    /> : null
            }
            {/* cookies box code */}
            { !cookieDisplay ?
            <div className={classes.CookiesBox}>
                <div>
                    {t('cookies_text_message')}
                </div>
                <div>
                    <button onClick={()=>cookieFunction('OK')}>{t('OK')}</button>
                    <button onClick={()=>cookieFunction('show_more')}>{t('Show_more')}</button>
                </div>
            </div>
            : null }
            {/* cookies box code */}
        </div>
    )
}

