import axios, {config, token_config} from '../../../../axiosBase.js';
import {checkForLogin} from '../../../PrivateRoute/PrivateRoute';
import customerDetails from '../../../UserData.js';

import {ORDERCONFIRM} from '../../../../URLS';
import consoleLog from '../../../../log';


const createOrder = (payload, props, cart_id) => {  
  config['headers']['Content-Type'] = 'text/xml';

  checkForLogin().then(function(token){
  if(token){
  
  const order_id = props.eventDetails.id+"-"+props.ticketDetails.allDetails.id+"-"+customerDetails.data.id;

  axios.post('/orders', payload, config)
      .then(function (data) {
        consoleLog(data);
        //deleteCart(cart_id, "SUCCESS", order_id); // When order is a success do not delete cart
        sessionStorage.removeItem("cart"); // do not delete from cart, instead just remove from storage in case of a successful order
        window.location.href = ORDERCONFIRM+"?order-state=SUCCESS&order-id="+order_id;
        props.loader();
        //props.proceed("SUCCESS");
      })
      .catch(function (err) {
        consoleLog(err);
        deleteCart(cart_id, "FAILED", order_id);
        props.loader();
        //props.proceed("FAILED");
      });
  }})
}

const deleteCart = (id, order_state=false, order_id=false) => {
  const payload = {
    "id_cart": id
  }

  checkForLogin().then(function(token){
  if(token){
    const config = token_config(token)

    axios.post('/listing?a=cart_delete', payload, config)
      .then(function(success){
        consoleLog(success)
        if(order_id && order_state){
          window.location.href = ORDERCONFIRM+"?order-state="+order_state+"&order-id="+order_id;
        }
      })
      .catch(function(err){
        consoleLog(err)
        if(order_id && order_state){
          window.location.href = ORDERCONFIRM+"?order-state="+order_state+"&order-id="+order_id;
        }
      })
  }})
}

export {createOrder, deleteCart};