import React, {useState, useEffect} from 'react';
import classes from './Deregister.module.css';
import axios, { config } from '../../axiosBase';
import LogoLoader from '../UI/LogoLoader/LogoLoader';
import ErrorBox from '../UI/ErrorBox/ErrorBox';
import {HOME} from '../../URLS';
import { useTranslation } from 'react-i18next';

import {PasswordPattern} from '../../Formats';

const Deregister = (props) => {

	const [submitted, setSubmitted] = useState(false);
	const [showLoader, setShowLoader] = useState(false);

	const [prompted, setPrompted] = useState(true);

    const { t, i18n } = useTranslation();

    const call_deregister = () => {
		const hash = props.match.params.hash;
		if(!hash){
			setSubmitted(false);
		}else{
			setShowLoader(true)
			const payload = {
				"hash": hash
			}
			axios.post('/waitinglist?a=deregister', payload, config)
			.then(function(res){
				setShowLoader(false);
				setSubmitted(true);
				setPrompted(false);
			})
			.catch(function(res){
				setShowLoader(false);
				setSubmitted(false);
				setPrompted(false);
			})
		}
	}

	return (
		<div className={classes.Deregister}>
		    <div className={classes.DeregisterContainer}>
		    	{ !showLoader ?
                <div className={classes.WrapBox}>
                    {
						prompted ?
		                        <>
		                        <div className={classes.Text}>{t('deregister_prompt_text')}</div>
		                        <div className={classes.ActionButtons}>
		                            <button onClick={()=> call_deregister()} type="button">{t('yes')}</button>
		                            <button onClick={()=> window.location.href = HOME} type="button">{t('Cancel')}</button>
		                        </div>
		                        </>
	                        :
		                        submitted ?
		                        <>
		                        <div className={classes.Text}>{t('successfully_deregistered')}</div>
		                        <div className={classes.ActionButtons}>
		                            <button onClick={()=> window.location.href = HOME} type="button">OK</button>
		                        </div>
		                        </>
		                        :
		                        <>
		                        <div className={classes.Text}>{t("invalid_link")}</div>
		                        <div className={classes.ActionButtons}>
		                            <button onClick={()=> window.location.href = HOME} type="button">OK</button>
		                        </div>
		                        </>
                    }
                </div>
                : null }
            </div>
        { showLoader ? <LogoLoader /> : null }
		</div>
	);

}

export default Deregister;