import React, { useState, useRef } from 'react';
import classes from './Login.module.css';
import SocialSignup from '../SocialSignup/SocialSignup';
import { useTranslation } from 'react-i18next';
import { TERMS, PRIVACYPOLICY } from '../../URLS';

import { RegistrationData } from '../Profile/XMLData/XMLData';
import axios, { config } from '../../axiosBase';
import ErrorBox from '../UI/ErrorBox/ErrorBox';

import LogoLoader from '../UI/LogoLoader/LogoLoader';
import {encryptWithAES} from '../Encrypt/AES';

import {EmailPattern, PasswordPattern, NamePattern} from '../../Formats';
import consoleLog from '../../log';
import { HOME, REGISTER, langURL } from '../../URLS';

import EyeOpen from '../../assets/images/icons/eye-open.png';
import EyeClosed from '../../assets/images/icons/eye-closed.png';

import Cookies from 'universal-cookie';

export default function Login(props) {

    const { t, i18n } = useTranslation();

    const [showLogin, setLogin] = useState(true)    // Toggle between login and signup form
    const [formList, setForm] = useState([          // Signup form items
        { name: "first_name", value: '', type: "text" },
        { name: "last_name", value: '', type: "text" },
        { name: "email", value: '', type: "email" },
        { name: "create_password", value: '', type: "password" },
        { name: "confirm_password", value: '', type: "password" }
    ])
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [formErrors, setFormErrors] = useState({})
    const [errorList, setErrorList] = useState([])
    const [ErrorSubmit, setErrorSubmit] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const pattern = EmailPattern;
    // Temporary store login info to localstorage

    const cookies = new Cookies();

    const imgR = useRef(null)
    const inputR = useRef(null)
    const [eyeOpen, setEyeOpen] = useState(true);

    const showPassword = (show, inputRef, imgRef) => {
        if (show) {
            inputRef.current.type = "text";
            imgRef.current.src = EyeClosed;
            imgRef.current.style.padding = "4px 0 6px 0";
            setEyeOpen(false);
        }
        else {
            inputRef.current.type = "password";
            imgRef.current.src = EyeOpen;
            imgRef.current.style.padding = "0 0 6px 0";
            setEyeOpen(true);
        }
    }

    const attemptLogin = (remember_me ,payload) => {
        axios.post('/jwt?a=login&output_format=JSON', payload, config)
            .then(function (data) {
                consoleLog(data.data.success, typeof(data.data.success), typeof(data.data.token), data.data.token)
                if (data.data.success) {
                    let today = new Date();

                    if(remember_me){
                        today.setDate(today.getDate() + 180);
                    }else{
                        today.setDate(today.getDate() + 1);
                    }

                    localStorage.setItem("userId", encryptWithAES(data.data.token+":"+data.data.refresh_token))
                    cookies.set('loginTime', today, { path: '/', expires: today });
                    localStorage.setItem("showWelcomeLogin", true)

                    const pathname = window.location.pathname;
                    if(pathname === langURL + REGISTER){
                        window.location.href = HOME
                    }else{
                        window.location.reload()                        
                    }
                } else {
                    setShowLoader(false)
                    setErrorSubmit(true)
                    setErrorList([{"":t("Email_or_Password_is_incorrect")}])
                }
            })
            .catch(function (err) {
                setShowLoader(false)
                setErrorSubmit(true)
                setErrorList([{"":t("Email_or_Password_is_incorrect")}])
            })
    }

    const handleLogin = (e) => {
        e.preventDefault()
        const formEmail = e.target.email.value;
        const formPassword = e.target.password.value;
        const payload = {
            "email": formEmail,
            "password": formPassword
        }
        if(formEmail === '' || formPassword === ''){
            setErrorSubmit(true)
            setErrorList([{"":t("Please_enter_email_and_password")}])
            return false;
        }
        if(!pattern.test(formEmail)){
            setErrorSubmit(true)
            setErrorList([{"":t("Please_enter_a_valid_e-mail")}])
            return false;
        }
        setShowLoader(true)
        attemptLogin(e.target.remember_me.checked, payload)
    }

    const validateField = (fieldName, fieldValue) => {


        let validationError = formErrors


        if (fieldValue === "") {
            validationError[fieldName] = true
            setFormErrors(validationError)
        }
        else {
            validationError[fieldName] = false
            setFormErrors(validationError)
        }

        const passFormat = PasswordPattern;

        switch (fieldName) {
            case 'first_name':
                validationError[fieldName] = !fieldValue.match(NamePattern)
                break;
            case 'last_name':
                validationError[fieldName] = !fieldValue.match(NamePattern)
                break;
            case 'email':
                validationError[fieldName] = !pattern.test(fieldValue)
                break;
            case 'create_password':
                validationError[fieldName] = !(fieldValue.length > 7 && passFormat.test(fieldValue))
                break;
            case 'confirm_password':
                validationError[fieldName] = !(fieldValue.length > 7 && passFormat.test(fieldValue) && formList[3].value === fieldValue)
                break;
        }
        setFormErrors(validationError)
    }

    const changeInputValue = (e) => {
        validateField(e.target.name, e.target.value)
        let oldList = [...formList];
        consoleLog(e.target.name)
        for (let i = 0; i < oldList.length; i++) {
            if (oldList[i].name === e.target.name) {
                oldList[i].value = e.target.value;
                setForm(oldList);
                break;
            }
        }
    }

    const checkForExistingUser = (email) => {
        return axios.get('/customers?output_format=JSON&filter[email]=[' + email + ']', config)
            .then(function (data) {
                if (data.data.customers) {
                    setErrorList([{"":t("User_Already_Exists")}])
                    setErrorSubmit(true)
                    return true;
                } else {
                    return false;
                }
            })
    }
    function fromSubmitCheck() {

        var result = false
        // consoleLog(formErrors)
        Object.entries(formErrors).forEach(([key, value]) => {

            if (value) {
                result = true;
            }
        switch (key) {
            case 'first_name':
                if(value){
                    setErrorList([{"":t("Please_enter_a_valid")+t("first_name")}])
                    setErrorSubmit(true)
                }
                break;
            case 'last_name':
                if(value){
                    setErrorList([{"":t("Please_enter_a_valid")+t("last_name")}])
                    setErrorSubmit(true)
                }
                break;
            case 'email':
                if(value){
                    setErrorList([{"":t("Please_enter_a_valid_e-mail")}])
                    setErrorSubmit(true)
                }
                break;
            case 'create_password':
                if(value){
                    setErrorList([{"":t("password_format_text")}])
                    setErrorSubmit(true)
                }
                break;
            case 'confirm_password':
                if(value){
                    setErrorList([{"":t("password_format_text")}])
                    setErrorSubmit(true)
                }
                if(formList[3].value != formList[4].value){
                    setErrorList([{"":t("Password_dont_match")}])
                    setErrorSubmit(true)   
                }
                break;
        }
        })
        return result;
    }

    const isEmpty = () => {
        var result = false
        formList.forEach(temp => {
            if (temp.value === '') {
                result = true
                setErrorList([{"":t("Please_fill_in_all_the_fields")}])
            }
        })
        return result
    }
    const submitRegistration = (e) => {
        e.preventDefault();

        var myBool = fromSubmitCheck()
        if(myBool){
            return false
        }
        if (isEmpty()) {
            setErrorList([{"":t("Please_fill_in_all_the_fields")}])
            setErrorSubmit(true)
        }
        else {

            let userDataFields = [...formList];
            const payload = RegistrationData(userDataFields);
            config['headers']['Content-Type'] = 'text/xml';

            setShowLoader(true)
            checkForExistingUser(userDataFields[2].value).then(function (existing) {
                if (!existing) {
                    axios.post('/customers', payload, config)
                        .then(function (data) {
                            consoleLog(data)
                            setShowLoader(false)
                            attemptLogin(false, {
                                "email": userDataFields[2].value,
                                "password": userDataFields[3].value
                            })
                        })
                        .catch(function (err) {
                            setShowLoader(false)
                            setErrorSubmit(true)
                            setErrorList([{"":t("error")}])
                        });
                }else{
                    setShowLoader(false)
                }
            })
        }
    }


    const resetRegisterForm = () => {

        let oList = [...formList];

        oList.map((t, index) => {
            t.value = ""
        })

        setForm(oList)
    }


    const submitForgotPasswordEmail = (e) => {
        e.preventDefault();
        const email = e.target.email.value;
        const pattern = EmailPattern;
        if(pattern.test(email)){
            setShowLoader(true)
            axios.post('/jwt?a=reset', {"email": email}, config).then(function(res){
                setShowLoader(false)
                setSubmitted(true)
            }).catch(function(res){
                consoleLog(res)
                setShowLoader(false)
            })
        }else{
            setErrorSubmit(true)
            setErrorList([{"":t("Please_enter_a_valid_e-mail")}])
        }
    }

    return (

        <div className={classes.Login} >

            {showLogin ? <div >

                {props.mobileV && <p className={classes.Head}>Log in</p>}
                <form onSubmit={(e) => handleLogin(e)}>
                    <div className={classes.LoginCont}>
                        <p><input className={classes.LoginInput} type="text" placeholder={t('email')} name="email" /></p>
                        <p className={classes.PasswordInputContainer}>
                            <input className={classes.LoginInput} type="password" placeholder={t('password')} name="password" ref={inputR} />
                            <span>
                                <img className={classes.InputIcon} src={eyeOpen ? EyeOpen : EyeClosed} alt="" ref={imgR} onClick={() => showPassword(eyeOpen, inputR, imgR)} />
                            </span>
                        </p>
                        <p className={classes.LoginF} >
                            <a onClick={()=>setShowForgotPassword(true)} className={props.mobileV ? classes.LoginA1 : classes.LoginA} >{t('forgot_password')}</a>
                            <div className={classes.RememberMe}>
                                <label>
                                    <input type="checkbox" className={classes.Radio} name="remember_me" /> {t('remember_me')}
                                    <span></span>
                                </label>
                            </div>
                        </p>
                    </div>
                    <div className={props.mobileV ? classes.LoginButton1 : classes.LoginButton}><button type="submit">{t('login_small')}</button></div>
                </form>
                <p className={classes.LoginF}>{t('continue_with')}</p>
                <SocialSignup mobileVer={props.mobileV ? true : false} />

                <p className={classes.LoginHead}>{t('dont_have_account')} <a id="registerBtnId" className={props.mobileV ? classes.LoginA1 : classes.LoginA} onClick={() => { setLogin(!showLogin); resetRegisterForm() }}>{t('register')}</a></p>
                {!props.mobileV && <p className={classes.LoginP}>{t('by_continuing_you_agree')}<span><a href={TERMS}>{t('terms_of_use')}</a></span> {t('and')} <span><a href={PRIVACYPOLICY}>{t('privacy_policy')}</a></span>.</p>}

            </div>
                :
                <div className={classes.Login}>
                    {props.mobileV && <p className={classes.Head}>{t('register')}</p>}
                    <form onSubmit={(e) => submitRegistration(e)} className={classes.RegisterForm}>
                        {formList.map((a, index) => {
                            return (
                                <p>
                                    <input className={formErrors[a.name] === undefined ? classes.LoginInput : formErrors[a.name] ? [classes.LoginInput, classes.Error].join(" ") : [classes.LoginInput, classes.Success].join(' ')} type={a.type} name={a.name} value={a.value} placeholder={t(a.name)} onChange={(e) => changeInputValue(e)} />
                                </p>
                            )
                        })}
                        <div className={props.mobileV ? classes.LoginButton1 : classes.LoginButton}><button type="submit">{t('register')}</button></div>
                    </form>
                    <p className={classes.LoginF}>{t('register_with')}</p>
                    <SocialSignup mobileVer={props.mobileV ? true : false} />

                    <p className={classes.LoginHead}>{t('existing_user')}<a className={props.mobileV ? classes.LoginA1 : classes.LoginA} onClick={() => { setLogin(!showLogin) }}> {t('login_small')}</a></p>

                    {!props.mobileV && <p className={classes.LoginP}>{t('by_continuing_you_agree')}<span><a href={TERMS}> {t('terms_of_use')}</a></span> {t('and')}<span><a href={PRIVACYPOLICY}> {t('privacy_policy')}</a></span>.</p>}
                </div>}

            {ErrorSubmit &&
                <ErrorBox data={{
                    errorTitle: t("Error(s)"),
                    errorSubTitle: t("please_resolve_your_errors"),
                    errors: errorList,
                    acceptText: "OK",
                    acceptAction: () => { setErrorSubmit(false) }
                }} />


            }
        {
            showForgotPassword ?
            <div className={classes.ForgotPassword}>
                <div className={classes.ForgotPasswordContainer}>
                    <div className={classes.WrapBox}>
                        {
                            submitted ?
                            <>
                            <div className={classes.Text}>{t("If_your_email_is_registered_with_us")}</div>
                            <div className={classes.ActionButtons}>
                                <button onClick={()=>{setShowForgotPassword(false); setSubmitted(false);}} type="button">OK</button>
                            </div>
                            </>
                            :
                            <>
                            <div className={classes.Text}>{t("Enter_an_email_id_that_is_registered_with_us")}</div>
                            <form onSubmit={(e) => submitForgotPasswordEmail(e)}>
                                <div className={classes.InputMethod}>
                                    <input type="email" name="email" placeholder={t("E-mail_ID")} />
                                </div>
                                <div className={classes.ActionButtons}>
                                    <button type="submit">{t('send')}</button>
                                    <button onClick={()=>setShowForgotPassword(false)} type="button">{t('Cancel')}</button>
                                </div>
                            </form>
                            </>
                            }
                    </div>
                </div>
            </div> : null
        }
        { showLoader ? <LogoLoader /> : null }
       </div>
    )
}