import React, { useState, useRef, useEffect } from 'react';
import classes from './ChangeEmail.module.css';
import Block from '../Block/Block';
import Save from '../SaveAlert/Save';
import useOutsideAlerter from '../../UseOutsideAlerter/UseOutsideAlerter';
import { useTranslation } from 'react-i18next';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';
import { CustomersData } from '../XMLData/XMLData';
import axios, { config } from '../../../axiosBase';
import LogoLoader from '../../UI/LogoLoader/LogoLoader';

import ContactUs from '../../ContactUsBox/ContactUs';

import customerDetails, {storage} from '../../UserData';
import {EmailPattern} from '../../../Formats';

import consoleLog from '../../../log';

export default function ChangeEmail() {

    const { t, i18n } = useTranslation();
    const [showContactBox, setShowContactBox] = useState(false);

    const [showSave, setShowSave] = useState(false)
    const [userData, setUserData] = useState({})
    const [error, setError] = useState(false)
    const [errorAlert, setErrorAlert] = useState(false)
    const [Nemail, setNEmail] = useState("")
    const [confirmEmail, setConfirmEmail] = useState("")
    const [showLoader, setShowLoader] = useState(false)

    var editRef = useRef(null);
    const setEditF = (value) => {
        if (value) {
            setShowSave(value)
        }
        else {

            setTimeout(() => {
                setShowSave(value)
            }, 200)
        }
    }

    const [fields, setFields] = useState([
        { name: 'First_Name', value: '' },
        { name: 'Last_Name', value: '' },
        { name: 'Email', value: '' }
    ])

    const handleChnageInput = (e) => {
        if (e.target.value === '')
            setError(true);

        var pattern = EmailPattern;
        if (!pattern.test(e.target.value) || (e.target.name === 'confirm_email' && Nemail != e.target.value) || (e.target.name === 'email' && confirmEmail != e.target.value) ) {
            setError(true);
        }
        else {
            setError(false);
        }
    }

    const saveNewEmail = (e) => {
        e.preventDefault();

        if (error || Nemail === "" || confirmEmail === "" || Nemail !== confirmEmail) {
            setErrorAlert(true)
        }
        else {
            let userDataFields = [...fields];
            userDataFields[2].value = e.target.email.value;

            const payload = CustomersData(userData, userDataFields);
            config['headers']['Content-Type'] = 'text/xml';
            consoleLog(payload)
            setShowLoader(true);
            axios.put('/customers/830', payload, config)
                .then(function (data) {
                    setShowLoader(false);
                    setShowSave(true);
                    resetAll();
                })
                .catch(function (err) {
                    consoleLog(err)
                });
        }
    }

    const setFieldsDefault = () => {
        if (Object.keys(userData).length) {
            setFields([
                { name: 'First_Name', value: userData.data.customer.firstname },
                { name: 'Last_Name', value: userData.data.customer.lastname },
                { name: 'Email', value: '' }
            ])
        }
    }
    useEffect(() => {
        axios.get('/customers/'+customerDetails.data.id+'?output_format=JSON', config)
            .then(function (data) {
                consoleLog(data)
                setUserData(data)
            })
        setFieldsDefault()
    }, [Object.keys(userData).length, showSave])

    const resetAll = () => {
        setNEmail("");
        setConfirmEmail("");
        setError(false)
    }
    useOutsideAlerter(editRef, setEditF, false);

    return (
        <div>
            <p className={classes.Heading}></p>

            <Block>
                <div className={classes.ChangeEmail}>
                    <p className={classes.EmailP1}>{t("You_are_currently_registered_with")}</p>
                    {Object.keys(userData).length ?
                        <p className={classes.EmailP2}>{/*t("New_E-mail_Address")*/userData.data.customer.official_email} </p>
                        : null}
                    <p className={classes.ContactSupport}>{t('settings_contact')}<a onClick={()=>(setShowContactBox(true))}>{t('settings_support')}</a>{t('to_change_your_email_ID')}</p>
                    {/*<form className={classes.ChangeInput} onSubmit={(e) => saveNewEmail(e)}>
                        <input className={error ? ["myInput", classes.Error].join(" ") : "myInput"} name="email" type="text" placeholder={t("New_E-mail_Address")} value={Nemail} onChange={(e) => { setNEmail(e.target.value); handleChnageInput(e) }} ></input><br />
                        <input className={error ? ["myInput", classes.Error].join(" ") : "myInput"} name="confirm_email" type="text" placeholder={t("Confirm_new_E-mail_address")} value={confirmEmail} onChange={(e) => { setConfirmEmail(e.target.value); handleChnageInput(e) }}></input><br />
                        <button type="submit" className={["myButton", classes.EmailButton].join(' ')}> {t("Save")}</button>
                        <button type="button" onClick={resetAll} className={["myButton", classes.EmailButton].join(' ')}>{t("Cancel")}</button>
                    </form>*/}
                </div>
            </Block>

            {
                showSave &&
                <div className="backdropCont">
                    <div>
                        <Save refer={editRef} />
                    </div>
                </div>
            }


            {
                errorAlert &&
                <ErrorBox data={{
                    errorTitle: t("Error(s)"),
                    errorSubTitle: t('please_resolve_your_errors'),
                    errors: [],
                    acceptText: "OK",
                    acceptAction: () => { setErrorAlert(false) }
                }} />

            }
        { showLoader ? <LogoLoader /> : null }
        {
            showContactBox ?
                <ContactUs
                    hideContact={()=>setShowContactBox(false)}
                    pageDetails="Change Email Request - " // for internal use
                /> : null
        }
       </div >
    )
}