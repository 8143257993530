import React, { useState, useEffect, useRef } from 'react'
import classes from './SellTickets.module.css'
import sellIcon from '../../assets/images/icons/sellicon.png'
import deleteIcon from '../../assets/images/icons/cancel.png'
import CheckIcon from '../../assets/images/icons/check.png'
import MobileTicketStepDone from '../../assets/images/icons/step2mtdone.png'
import MobileTicketStepInProgress from '../../assets/images/icons/step2mtinprogress.png'
import MobileTicketStepIncomplete from '../../assets/images/icons/step2mtincomplete.png'
import SearchIcon from '../../assets/images/icons/add-searching.png'
import vieweticketsI from '../../assets/images/icons/viewetickets.png'
import newListI from '../../assets/images/icons/newlisting.png'
import right from '../../assets/images/icons/right-chevronBlack.png'
import PlusIcon from '../../assets/images/icons/plus.png'
import ErrorBox from '../UI/ErrorBox/ErrorBox'
import listI from '../../assets/images/icons/list.png'
import LogoLoader from '../UI/LogoLoader/LogoLoader';
import ContactUs from '../ContactUsBox/ContactUs';

import { LISTINGS, TERMS, PRIVACYPOLICY, LANG_ENGLISH, HOME, SETTINGS, SALECONFIRM, CONTACT } from '../../URLS';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../UI/CustomSelect/CustomSelect';
import AlertBox from '../UI/AlertBox/AlertBox';

import customerDetails from '../UserData';
import {checkForLogin} from '../PrivateRoute/PrivateRoute';

import axiosInstance, {token_config, config} from '../../axiosBase';
import axios from 'axios';

import {EmailPattern, NumberPattern} from '../../Formats';
import { PaymentData, PhoneNumberData } from '../Profile/XMLData/XMLData';

import Countries from '../JsonData/Countries.json';

import heic2any from "heic2any";
import step1sell from '../../assets/images/icons/step1sell.png'
import step1selldone from '../../assets/images/icons/step1selldone.png'
import step2incomplete from '../../assets/images/icons/step2incomplete.png'
import step2inprogress from '../../assets/images/icons/step2inprogress.png'
import step2done from '../../assets/images/icons/step2done.png'
import step3inprogress from '../../assets/images/icons/step3inprogress.png'
import step3incomplete from '../../assets/images/icons/step3incomplete.png'
import step2etincomplete from '../../assets/images/icons/step2etincomplete.png'
import step2etinprogress from '../../assets/images/icons/step2etinprogress.png'
import step2etdone from '../../assets/images/icons/step2etdone.png'
import step3done from '../../assets/images/icons/step3done.png'
import LoadingIcon from '../../assets/images/icons/loadingicon.png';
import consoleLog from '../../log';

import {ShippingOptionsDefault, prepareShippingMethods} from './ShippingData';

export default function SellTickets(props) {

    let event_id = props.location.state;

    const { t, i18n } = useTranslation();

    const [eventState, setEventState] = useState([]);

    const [error, setError] = useState(false);
    const [listOfErrors, setListOfErrors] = useState([]);

    const [isHardTicket, setIsHard] = useState(true)
    const [save, setSave] = useState(true)
    const [showLoader, setShowLoader] = useState(false)

    const [paymentMethod, setPaymentMethod] = useState("IBAN")

    const [userCustomerData, setUserCustomerData] = useState({})
    const [userAddressesData, setUserAddressesData] = useState({})
    const [chosenCountryCode, setChosenCountryCode] = useState("")
    const [defaultCountryCode, setDefaultCountryCode] = useState("+49")
    const [countryCodes, setCountryCodes] = useState([])
    const [internationalShipment,setInternationShipment]=useState(false)
    const [iban_value, setIBANValue] = useState("");
    const [paypal_email_value, setPaypalEmailValue] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [removeButton, showRemoveButton] = useState(false)

    const [invalidRequest, setInvalidRequest] = useState(false)
    const [priceError, setPriceError] = useState(false)
    const [listOpened, setListOpened] = useState(false);

    const [quantity, setQuantity] = useState(1);
    const [ticketType, setTicketType] = useState(0);
    const [splitting, setSplitting] = useState(0);
    const [block, setBlock] = useState("");
    //const [blockName, setBlockName] = useState("");
    const [businessDays, setBusinessDays] = useState(0);
    const [ticketFile, setTicketFile] = useState("");

    const [eTicketsUploaded, setETicketsUploaded] = useState({});
    const [allPDFImages, setAllPDFImages] = useState([])
    const [selectedImages, setSelectedImages] = useState([]);

    const [contactNotAdded, setContactNotAdded] = useState(false)
    const [agreementChecked, setAgreementChecked] = useState(false)

    const [displayImage, setDisplayImage] = useState(-1);
    const [commission, setCommision] = useState({
        receive: "0,00",
        buyer: "0,00"
    })
    const [itemSoldDetails, setItemSoldDetails] = useState({});
    const [maximumPrice, setMaximumPrice] = useState("0,00")
    const [showContactBox, setShowContactBox] = useState(false);
    const [showTicketRetailer, setShowTicketRetailer] = useState(false);

    const SellTicketSteps = {
        "CreateAd": 1,
        "ShippingOptions": 2,
        "UploadETickets": 3,
        "PaymentInfo": 4,
        "MobileTickets":5,
    }
    const [APIPayloadStack, setAPIPayloadStack] = useState({});
    const [shippingOptionsData, setShippingOptionsData] = useState(JSON.parse(ShippingOptionsDefault))

    const [currentStep, setCurrentStep] = useState(SellTicketSteps.CreateAd);

    const other_details_placeholder = t('other_details_placeholder');
    const [editListingData, setEditListingData] = useState(props.location.editListingData ? props.location.editListingData : false);
    console.log(editListingData, "EDLDAT")
    let today = new Date();
    today.setDate(today.getDate() + 5);
    //let dd = String(today.getDate()).padStart(2, '0');
    //let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    //let yyyy = today.getFullYear();
    //today = dd + '.' + mm + '.' + yyyy;

    useEffect(() => {
        if(!eventState){
            setInvalidRequest(true)
        }
    },[invalidRequest])

    useEffect(() => {
        window.scrollTo(0,0);
    }, [currentStep]);

    useEffect(()=>{
        for(let i=0;i<selectedImages.length;i++){
            document.getElementById("eTicketId"+selectedImages[i]).innerHTML=i+1;
        }
    },[selectedImages])

    useEffect(()=>{
        setShowLoader(true);
        axiosInstance.get('/products/'+event_id+'?output_format=JSON', config)
        .then(function(data){
            if(data.data.product){
                setEventState(data.data.product)
            }
            setShowLoader(false);
        }).catch(function(err){
            setShowLoader(false);
        })
    },[])

    const get_date_position = (date, position) => {
        let split_by_hifun = date.split('-')

        if(split_by_hifun.length == 1){
            let split_by_dot = date.split('.')
            if(split_by_dot.length == 1){
                return null
            }else{
                return split_by_dot[position]
            }
        }else{
            return split_by_hifun[position]
        }
    }

    const pushToSelectedImages = (num, action) => {
        if(selectedImages.length == quantity && action == "add"){
            let errorList = []
            errorList.push({[t("invalid_count")]: t("cannot_add_more_1")+" "+quantity == 1 ? quantity+" "+t("cannot_add_more_3") : quantity+" "+t("cannot_add_more_2") })
            setListOfErrors(errorList);
            setError(true);
            return;
        }
        if(action == "add"){
            if(!selectedImages.includes(num)){
                setSelectedImages([...selectedImages, num])
            }
        }else if(action == "remove"){
            if(selectedImages.includes(num)){
                let arr = [...selectedImages];
                arr.splice(arr.indexOf(num), 1);
                setSelectedImages(arr)
                document.getElementById("eTicketId"+num).innerHTML="";
            }
        }
    }

    const checkPriceKeys = (e) => {
        const regex = /^[0-9,.]+$/;
        if (!regex.test(e.key)) {
          e.preventDefault();
        }
    }

    const displayFullImage = (index) => {
        setDisplayImage(index)
    }

    const calculateCommision = (price) => {
        price = price.replace(',','.');
        let original_price = document.getElementById("original_price").value.replace(',','.');
        
        if(original_price != "" && price > original_price*1.25){
            setPriceError(true)
            setCommision({
                receive: "0,00",
                buyer: "0,00"
            })
        }else if(priceError){
            setPriceError(false)
        }

        if(price != ""){
            setCommision({
                receive: (parseFloat(price.replace(',','.'))*0.95).toFixed(2).toString().replace('.',','),
                buyer: (parseFloat(price.replace(',','.'))*1.07).toFixed(2).toString().replace('.',',')
            })
        }else{
            setCommision({
                receive: "0,00",
                buyer: "0,00"
            })  
        }
    }

    const calculateMaximumPrice = (price) => {
        price = price.replace(',','.');
        setMaximumPrice(price*1.25);
    }


    const uploadImages = () => {
        let errorList = []
        if(selectedImages.length != quantity){
            errorList.push({[t("invalid_count")]: t("cannot_add_more_1")+" "+quantity+" "+t("cannot_add_more_2")})
            setListOfErrors(errorList);
            setError(true);
            return;
        }
        if(!document.getElementById("agreement_id").checked){
            setListOfErrors([
                {"":t("Please_agree_to_the_terms_and_conditions_before_continuing")}
            ]);
            setError(true);
            return;
        }

        setCurrentStep(SellTicketSteps.PaymentInfo);
    }

    const uploadETicketAdAPIs = () => {
        return checkForLogin().then(function(token){
        if(token){

        const config = token_config(token);

        let payload = {
            "id": eTicketsUploaded.id
        }
        for(let i=1; i<=selectedImages.length; i++){
            payload["pos_"+i.toString()] = selectedImages[i-1];
        }
        var form_data = new FormData();
        consoleLog(payload)
        for ( var key in payload ) {
            consoleLog(key)
            form_data.append(key, payload[key]);
        }
        consoleLog(form_data)
        setShowLoader(true);
        axiosInstance.post('/listing?a=upload', form_data, config)
        .then(function(data){
            consoleLog(data)
            setShowLoader(false);
            setCurrentStep(SellTicketSteps.PaymentInfo)
        })
        .catch(function(err){
            consoleLog(err);
            setShowLoader(false);
        }) // End image upload

        }}) // End check for token
    }


    const addAnotherFile = (file) => {
        if (file.size > 16 * 1024 * 1024) {
            setListOfErrors([{
                [t("image")]: t('big_file_upload')
            }])
            setError(true)
            return;
        }

        checkForLogin().then(function(token){
            if(token){

            const config = token_config(token);
            setShowLoader(true);

            const payload = {
                "id": eTicketsUploaded.id,
                "cur_amount": allPDFImages.length,
                "eticket": file
            }
            var form_data = new FormData();

            for ( var key in payload ) {
                form_data.append(key, payload[key]);
            }

            let imagesList = [...allPDFImages];
            
            axiosInstance.post('/listing?a=extra_pdf', form_data, config)
                .then(function(data){
                    if(data.data){
                        const newETicketsData = {
                            count: data.data.newetickets.length + allPDFImages.length,
                            id: eTicketsUploaded.id
                        }
                        chooseImages(newETicketsData)
                    }
                })
                .catch(function(err){
                    setListOfErrors([{
                        "": err.toString()
                    }])
                    setError(true)
                    setShowLoader(false);
                })
            }
        })

    }

    const chooseImages = (eTicketsData) => {

        checkForLogin().then(function(token){
        if(token){

        const config = token_config(token);
        setShowLoader(true);
        let imagesList = [...allPDFImages];
        let promises = [];

        for(let i=1+allPDFImages.length; i<=eTicketsData.count; i++){
            consoleLog(i)
            let payload = {
                id: eTicketsData.id,
                num: i
            }
            promises.push(axiosInstance.post('/listing?a=pdf_image', payload, config))
                /*.then(function(data){
                    consoleLog(data)
                    imagesList.push({
                        id: i,
                        url: data.data.image.replaceAll("\/","/")
                    })
                    if(i == eTicketsData.count){
                        setAllPDFImages(imagesList)
                        setShowLoader(false);
                    }
                })
                .catch(function(err){
                    consoleLog(err);
                    setShowLoader(false);
                })*/
        }
        Promise.all(promises)
        .then((result) => {
            result.map(function(data, index){
                consoleLog(data, "IMAGE DATA")
                imagesList.push({
                    id: index+1+allPDFImages.length,
                    url: data.data.image.replaceAll("\/","/")
                })
                if(index+1+allPDFImages.length == eTicketsData.count){
                    setAllPDFImages(imagesList)
                    setShowLoader(false);
                }
            })
        })
        .catch(error => {
            consoleLog(error)
            setShowLoader(false)
        });

        }})

    }
    // const handleTicketTypeSelect = (e) => {
    //     setSelected(e.target.value)
    //     var a = e.target.value
    //     if (a === 'e-ticket')
    //         setIsHard(false)
    //     else
    //         setIsHard(true)
    // }
    const redirectAction = (type) => {
        if(type === "back"){
            props.history.go(-1);
        }else if(type === "home"){
            window.location.href='/';
        }
    }

    /*const onChangePDF = (pdf) => {
        if(isValidPDF(pdf)){
            let reader = new FileReader();
            let count = 0;
            reader.readAsBinaryString(pdf)
            reader.onloadend = function(){
                count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                consoleLog(count,"aagya")
                setPageCount(count)
            }
        }
    }*/

    const isValidPDF = (pdf) => {
        let extension = pdf.name.split(".");
        extension = extension[extension.length-1]

        if(extension.toLowerCase() === "pdf" && pdf.type.toLowerCase() === "application/pdf"){
            return true
        }else{
            return false
        }
    }

    const isValidImage = (image) => {
        let extension = image.name.split(".");
        extension = extension[extension.length-1]
        if(["jpg", "jpeg", "png", "gif", "heic"].includes(extension.toLowerCase())){
            return true
        }else{
            return false
        }
    }

    const getExtension = (image) => {
        let extension = image.name.split(".");
        extension = extension[extension.length-1]
        return extension.toLowerCase()
    }

    async function convertHEICTOJPG (blob) {
        const image = await heic2any({
            blob: blob,
            toType: "image/jpg",
        })
        return image
    }

    const fileNameRef = useRef(null);

    const removePicture = () => {
        document.getElementById("browse_id").value = "";
        fileNameRef.current.innerHTML = "";
        showRemoveButton(false);
    }

    const addFileName = (e) => {
        const fileName = e.target.files[0].name;
        // consoleLog(fileNameRef.current.innerHTML)
        setTicketFile(e.target.files[0]);
        fileNameRef.current.innerHTML = fileName;
        showRemoveButton(true);
    }

    const getBlockName = (block) => {
        if(eventState && eventState.associations && eventState.associations.blocks){
            for(var i=0; i<eventState.associations.blocks.length; i++){
                if(block == eventState.associations.blocks[i].blockID){
                    return eventState.associations.blocks[i].name
                }
            }
        }
    }


    const createAd = (e) => {
        e.preventDefault()
        consoleLog(e)
        let errorList = [];
        let payload = {};

        payload["id_product"] = eventState.id;
        payload["row_list"] = e.target.row_list.value;
        payload["seats"] = e.target.seats.value;
        payload["together"] = e.target.together.checked ? 1 : 0;

        if(quantity){
            payload["quantity"] = quantity;
        }else{
            errorList.push({
                [t("Quantity")] : t("Please_set_quantity")
            })
        }

        if(ticketType){
            var ticketCode = ticketType.charCodeAt(0);
            if (ticketCode > 47 && ticketCode < 58){
                payload["type"] = ticketType;
            }else{
                errorList.push({
                    [t("Ticket_Type")]: t("Please_choose_ticket_type")
                })                
            }
        }else{
            errorList.push({
                [t("Ticket_Type")]: t("Please_choose_ticket_type")
            })
        }

        if(splitting){
            payload["split"] = splitting;
            if(splitting == 4){
                if(quantity % 2 != 0){
                    errorList.push({
                        [t("Quantity")]: t("Quantity_invalid")
                    })
                }
            }
        }else{
            errorList.push({
                [t("Split")]: t("Please_select_the_splitting_type")
            })
        }

        if(block !== ""){
            payload["block"] = block;
            var block_name = getBlockName(block);
            payload["block_name"] = block_name;
        }else{
            errorList.push({
                [t("Block")]: t("Please_choose_a_block")
            })
        }

        /*if(ticketType === "2" && businessDays){
            payload["days"] = businessDays;
        }else if(ticketType === "2" && !businessDays){
            errorList.push({
                [t("Days")]: t("Please_enter_the_time_for_delivery")
            })
        }*/
        let price_entered = false;
        let min_price = 1;
        let s_price = e.target.price.value.replace(',','.');
        if(!isNaN(s_price) && !(s_price === "") && (parseFloat(s_price) > min_price)){
            payload["price"] = s_price;
        }else{
            price_entered = true;
            errorList.push({
                [t("Price")]: t("Please_enter_a_valid_price")
            })
        }
        let o_price = e.target.org_price.value.replace(',','.');
        if(!isNaN(o_price) && !(o_price === "") && (parseFloat(o_price) > min_price)){
            payload["org_price"] = o_price;
        }else{
            if(!price_entered){
                errorList.push({
                    [t("Price")]: t("Please_enter_a_valid_price")
                })
            }
        }

        if(payload["price"] && payload["org_price"]){
            if(parseFloat(payload["price"]) > 1.25*parseFloat(payload["org_price"])){
                errorList.push({
                    [t("Price")]: t("Sale_price_cannot_exceed_25_of_the_original_price")
                })
            }else{
                payload["price"] = payload["price"].replace('.',',');
                payload["org_price"] = payload["org_price"].replace('.',',')
            }
        }

        let detailsChecklist = "";
        /*if(e.target.limited_view.checked){
            detailsChecklist =  detailsChecklist + "0,"
        }
        if(e.target.side_view.checked){
            detailsChecklist =  detailsChecklist + "1,"
        }
        if(e.target.behind_stage_view.checked){
            detailsChecklist =  detailsChecklist + "2,"
        }
        /*if(e.target.hearing_place.checked){
            detailsChecklist =  detailsChecklist + "3,"
        }*/
        let other_details_field = e.target.other_details.value;
        if(other_details_field && other_details_field.includes('@')){
            errorList.push({
                [t("Other_Details")]: other_details_placeholder
            })
        }
        detailsChecklist = detailsChecklist.slice(0, -1);
        payload["details"] = detailsChecklist;
        payload["other_details"] = other_details_field;

        /*if(ticketType === "1" && ticketFile !== ""){
            if(isValidPDF(ticketFile)){
                payload["eticket"] = ticketFile // FOR ETICKET
            }else{
                errorList.push({
                    [t("E-ticket")]: t("please_upload_a_valid_pdf")
                })
            }
        }else if(ticketType === "1"){
            errorList.push({
                [t("E-ticket")]: t("Please_upload_a_pdf_containing_all_your_eTickets")
            })
        }*/

        if (ticketFile.size > 16 * 1024 * 1024) {
            errorList.push({
                [t("image")]: t('big_file_upload')
            })
        }


        let HEICCase = false;

        if(ticketType === "2" && ticketFile !== ""){
            if(isValidImage(ticketFile)){
                if(getExtension(ticketFile) == "heic"){
                    HEICCase = true;
                    convertHEICTOJPG(ticketFile)
                    .then(function(resultBlob){
                        const file = new File([resultBlob], "image.jpg",{type:"image/jpeg", lastModified:new Date().getTime()});
                        consoleLog(file, ticketFile, resultBlob)
                        payload["image"] = file
                        if(!errorList.length/* && e.target.agreement.checked*/){
                            setAPIPayloadStack({"submit_hardticket_listing": payload})
                            setEditListingData(payload)
                            setCurrentStep(SellTicketSteps.ShippingOptions)
                        }
                    })
                    .catch(function(err){})
                }else{
                    payload["image"] = ticketFile
                }
            }else{
                errorList.push({
                    [t("Hard_Ticket")]: t("please_upload_a_valid_image")
                })
            }
        }

        consoleLog(payload, errorList);
        if(errorList.length){
            setListOfErrors(errorList);
            setError(true);
            return;
        }
        /*if(ticketType === "1"){
            if(!e.target.agreement.checked){
                setListOfErrors([
                    {"":t("Please_agree_to_the_terms_and_conditions_before_continuing")}
                ]);
                setError(true);
                return;
            }
        }*/
        if(!HEICCase){
            setAPIPayloadStack({"submit_hardticket_listing": payload})
            setEditListingData(payload)
            
            if(ticketType === "1"){
                setCurrentStep(SellTicketSteps.UploadETickets)
            }else if(ticketType === "2"){
                setCurrentStep(SellTicketSteps.ShippingOptions)
            }else if(ticketType === "4" || ticketType === "5"){
                setCurrentStep(SellTicketSteps.MobileTickets)
            }
        }
    }

    const submitAd = (payload) => {
        var form_data = new FormData();

        for ( var key in payload ) {
            form_data.append(key, payload[key]);
        }
        
        return checkForLogin().then(function(token){
        if(token){

        const config = token_config(token);
        setShowLoader(true);
        return axiosInstance.post('/listing?a=create', form_data, config)
            .then(function(data){
                consoleLog(data)
                setShowLoader(false);
                setItemSoldDetails({
                    "listing_id": data.data.ID,
                    "total_price": parseFloat(payload["price"])*parseInt(payload["quantity"])
                })
                /*if(ticketType == "1"){
                    const eTicketsData = {
                        count: data.data.EticketsUploaded,
                        id: data.data.ID
                    }
                    setETicketsUploaded(eTicketsData);
                    setCurrentStep(SellTicketSteps.UploadETickets)
                    chooseImages(eTicketsData);
                }*/
                return data
            })
            .catch(function(err){
                consoleLog(err)
                setListOfErrors([
                    {"": err.toString()}
                ]);
                setError(true);
                setShowLoader(false);
            })

        }})

    }

    const submitShippingOptions = (e) => {
        e.preventDefault()

        const shippingMethods = [];
        let pick_up_phone = "";
        let pickupphone = "";
        let shippingOptionsForm = e.target.shipping_options;

        if(shippingOptionsForm.length){
            for(let i=0; i<shippingOptionsForm.length; i++){
                if(shippingOptionsForm[i].checked){
                    shippingMethods.push(parseInt(shippingOptionsForm[i].value))
                    if(shippingOptionsForm[i].value == "3"){
                        pick_up_phone = e.target.pick_up_phone ? e.target.pick_up_phone.value : "";
                    }
                }
            }
        }
        else{
            if(shippingOptionsForm.checked){
                shippingMethods.push(parseInt(shippingOptionsForm.value));
                if(shippingOptionsForm.value == "3"){
                    pick_up_phone = e.target.pick_up_phone ? e.target.pick_up_phone.value : "";
                }            
            }
        }
        if(!shippingMethods.length){
            setListOfErrors([{"": t("please_choose_one_option")}])
            setError(true)
            return false;
        }
        if(shippingMethods.includes(3)){
            if(pick_up_phone == ""){
                setListOfErrors([{"": t("Please_enter_a_valid")+" "+t("phone_number")}])
                setError(true)
                return false;
            }else{
                let pattern = NumberPattern
                if (!pattern.test(pick_up_phone)){
                    setListOfErrors([{"": t("Please_enter_a_valid")+" "+t("phone_number")}])
                    setError(true)
                    return false;
                }
            }

            if(chosenCountryCode === ""){
                pickupphone = defaultCountryCode +"-"+ pick_up_phone
            }else{
                pickupphone = chosenCountryCode +"-"+ pick_up_phone
            }
        }
        const payload = {
            "id_listing" : "",
            "shipping" : shippingMethods,
            "pick_up_phone" : pickupphone
        }
        setAPIPayloadStack({...APIPayloadStack,["submit_shipping_options"]: payload})
        setCurrentStep(SellTicketSteps.PaymentInfo)
    }

    const uploadETicketAd = (e) => {
        if (e.target.files[0].size > 16 * 1024 * 1024) {
            setListOfErrors([{
                [t("image")]: t('big_file_upload')
            }])
            setError(true)
            return;
        }

        checkForLogin().then(function(token){
        if(token){
            const _config = token_config(token);
            
            let hard_ticket_payload = APIPayloadStack["submit_hardticket_listing"];
            hard_ticket_payload["eticket"] = e.target.files[0];
            submitAd(hard_ticket_payload).then(function(data){
                const eTicketsData = {
                    count: data.data.EticketsUploaded,
                    id: data.data.ID
                }
                setETicketsUploaded(eTicketsData);
                chooseImages(eTicketsData);
            })
            .catch(function(err){
                setListOfErrors([{
                    "": err.toString()
                }])
                setError(true)
                return;
            })
        }
        })
    }

    const ticketTypeHandler = (value) => {
        setTicketType(value);
        if(value === "2"){
            setIsHard(true);
        }else{
            setIsHard(false);
        }

        if(value === "M" || value === "4" || value === "5"){
            setShowTicketRetailer(true)
        }else{
            setShowTicketRetailer(false)
        }
    }

    const sortPhoneCodes = (data) => {
        data.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });
        return data
    }


    useEffect(() => {
        axiosInstance.get('/customers/'+customerDetails.data.id+'?output_format=JSON', config)
            .then(function (data) {
                if(!(data.data instanceof Array) && Object.keys(data.data).length){
                    setIBANValue(data.data.customer.iban)
                    setPaypalEmailValue(data.data.customer.paypal_email)
                    setUserCustomerData(data)
                    if(
                        (data.data.customer.iban == "" && data.data.customer.paypal_email == "")
                    ||  (!data.data.customer.iban && !data.data.customer.paypal_email)){
                        setContactNotAdded(true)
                    }
                    if(data.data.customer.paypal_email != ""){
                        setPaymentMethod("PAYPAL")
                    }
                }else{
                    setContactNotAdded(true)
                }
            })
    }, [])
    useEffect(() => {
        axiosInstance.get('/addresses/?output_format=JSON&filter[id_customer]=['+customerDetails.data.id+']&filter[deleted]=[0]&display=full', config)
            .then(function (data) {
                if(!(data.data instanceof Array) && Object.keys(data.data).length){
                    if(data.data.addresses[0].phone_mobile && data.data.addresses[0].phone_mobile != ""){
                        setPhoneNumber(data.data.addresses[0].phone_mobile.split('-')[1])
                        setDefaultCountryCode(data.data.addresses[0].phone_mobile.split('-')[0])
                        setUserAddressesData(data)
                    }else{
                        setContactNotAdded(true)
                    }
                }else{
                    setContactNotAdded(true)
                }
            })
    }, [])

    useEffect(() => {
        let phoneCodeList = [];

        Countries.countries.map(function(country){
            phoneCodeList.push({name: "+"+country.call_prefix, value: "+"+country.call_prefix})
        })
        setCountryCodes(sortPhoneCodes(phoneCodeList))
    },[])

    useEffect(() => {
        if(Object.keys(userAddressesData).length && Object.keys(eventState).length){
            let shippingOptions = shippingOptionsData;
            const newShippingOptions = prepareShippingMethods(userAddressesData, eventState, shippingOptions);
            setShippingOptionsData(newShippingOptions);
        }
    },[userAddressesData, eventState])

    useEffect(() => {
        if(editListingData){
            ticketTypeHandler(editListingData.type)
            setSplitting(editListingData.split)
            setBlock(editListingData.block)
            /*if(eventState && eventState.associations && eventState.associations.blocks){
                eventState.associations.blocks.map(function(value, index){
                    if(editListingData.block == value.blockID){
                        setBlockName(value.name)
                    }
                })
            }*/
            setQuantity(editListingData.quantity)
        }
    },[editListingData, eventState])


    const submitPaymentMethod = () => {

        let method = '';
        let IBAN_num = '';
        let PAYPAL_email = '';
        let PAYPAL_verified = 0;

        if (paymentMethod === "PAYPAL" && paypal_email_value != "") {
            method = 2;
            PAYPAL_verified = 1;
            PAYPAL_email = paypal_email_value;
            var pattern = EmailPattern;
            if (!pattern.test(PAYPAL_email)){
                setListOfErrors([{"": t("Please_enter_a_valid")+" "+t("E-mail_ID_in")}])
                setError(true)
                return false;
            }
        }
        else if (paymentMethod === "IBAN" && iban_value !== "") {
            method = 1;
            IBAN_num = iban_value;
        }
        else {
            setListOfErrors([{"": t("add_a_payment_method")}])
            setError(true)
            return false;
        }

        if(phoneNumber == ""){
            setListOfErrors([{"": t("Please_enter_a_valid")+" "+t("phone_number")}])
            setError(true)
            return false;
        }else{
            let pattern = NumberPattern
            if (!pattern.test(phoneNumber)){
                setListOfErrors([{"": t("Please_enter_a_valid")+" "+t("phone_number")}])
                setError(true)
                return false;
            }
        }

        let phonenumber = "";
        if(chosenCountryCode === ""){
            phonenumber = defaultCountryCode +"-"+ phoneNumber
        }else{
            phonenumber = chosenCountryCode +"-"+ phoneNumber
        }


        let paymentInfo = {
            method: method,
            IBAN_num: IBAN_num,
            PAYPAL_email: PAYPAL_email,
            PAYPAL_verified: PAYPAL_verified
        }

        let userDataFields = {};

        let customers_payload = PaymentData(userCustomerData, userDataFields, paymentInfo);

        config['headers']['Content-Type'] = 'text/xml';

        let address_payload = PhoneNumberData(userAddressesData, phonenumber);

        setShowLoader(true)
        sessionStorage.setItem("last_sold", event_id)

        checkForLogin().then(function(token){
        if(token){

        const _config = token_config(token);

            if(ticketType === "2" || ticketType === "4" || ticketType === "5"){
                // Add shipping data to create ad API
                let hard_ticket_payload = APIPayloadStack["submit_hardticket_listing"];
                if(ticketType === "2"){
                    hard_ticket_payload["shipping_method"] = APIPayloadStack["submit_shipping_options"].shipping
                    hard_ticket_payload["seller_phone"] = APIPayloadStack["submit_shipping_options"].pick_up_phone
                    hard_ticket_payload["shipping"] = APIPayloadStack["submit_shipping_options"].shipping
                    hard_ticket_payload["pick_up_phone"] = APIPayloadStack["submit_shipping_options"].pick_up_phone
                }
                // Add shipping data to create ad API
                submitAd(hard_ticket_payload).then(function(data){
                    sessionStorage.setItem("last_sold_ticket_details", JSON.stringify({
                        "listing_id": data.data.ID,
                        "total_price": parseFloat(APIPayloadStack["submit_hardticket_listing"].price)*parseInt(APIPayloadStack["submit_hardticket_listing"].quantity)
                    }))

                    /*if(ticketType === "2"){ // Only incase of hard ticket we need to call shipping methods
                        let payload = {
                            "id_listing" : data.data.ID,
                            "shipping" : APIPayloadStack["submit_shipping_options"].shipping,
                            "pick_up_phone" : APIPayloadStack["submit_shipping_options"].pick_up_phone
                        }
                        axiosInstance.post('/shipping?a=save', payload, _config)
                            .then(function(data){
                                axios.all([
                                        axiosInstance.put('/customers', customers_payload, config),
                                        axiosInstance.put('/addresses', address_payload, config)
                                    ])
                                    .then(axios.spread((data1, data2) => {
                                        setShowLoader(false);
                                        if(data1){
                                            setPaymentMethod(null);
                                        }
                                        window.location.href = SALECONFIRM;
                                    }))
                                    .catch(function(error){
                                        setShowLoader(false);
                                    })
                            })
                            .catch(function(err){
                                consoleLog(err)
                                setListOfErrors([
                                    {"": err.toString()}
                                ]);
                                setError(true);
                                setShowLoader(false);
                            })
                    }else{*/
                        axios.all([
                            axiosInstance.put('/customers', customers_payload, config),
                            axiosInstance.put('/addresses', address_payload, config)
                        ])
                        .then(axios.spread((data1, data2) => {
                            setShowLoader(false);
                            if(data1){
                                setPaymentMethod(null);
                            }
                            window.location.href = SALECONFIRM;
                        }))
                        .catch(function(error){
                            setShowLoader(false);
                        })
                    //}
                })
            }else if(ticketType === "1"){
                uploadETicketAdAPIs().then(function(res){
                    axios.all([
                        axiosInstance.put('/customers', customers_payload, config),
                        axiosInstance.put('/addresses', address_payload, config)        
                    ])
                    .then(axios.spread((data1, data2) => {
                        setShowLoader(false);
                        sessionStorage.setItem("last_sold_ticket_details", JSON.stringify(itemSoldDetails))
                        if(data1){
                            setPaymentMethod(null);
                        }
                        window.location.href = SALECONFIRM;
                    }))
                    .catch(function(error){
                        setShowLoader(false);
                    })
                }).catch(function(err){
                    setShowLoader(false);
                })
            }
        }})
    }

    const checkAgreement = (e) => {
        if(ticketType === "4" || ticketType === "5"){
            const url_agree=document.getElementById("url_agreement_id")
            const terms_agree=document.getElementById("agreement_id")
            if(terms_agree.checked && url_agree.checked ){
                setAgreementChecked(true)
            }else{
                setAgreementChecked(false)
            }
        }
        else{
            if(e.target.checked){
                setAgreementChecked(true)
            }else{
                setAgreementChecked(false)
            }
        }
        
    }
    const changeStepMobileTicket=()=>{
        
        if(agreementChecked ){
             setCurrentStep(SellTicketSteps.PaymentInfo)
        }
        else {
            openAgreementAlert()
        }
    }

    const openAgreementAlert = () => {
        setListOfErrors([
            {"":t("Please_agree_to_the_terms_and_conditions_before_continuing")}
        ]);
        setError(true);
    }

    const getBlocksInOrder = (allBlocks) => {
        const tempBlocks = [...allBlocks]
        const itemsToShift = [
            "Stehplatz",
            "Innenraum"
        ]
        let result = []

        allBlocks.map(function(item, index){
            if(item.name.includes(itemsToShift[0]) || item.name.includes(itemsToShift[1]) ){
                result.push(item)
                tempBlocks.splice(index, 1)
            }
        })
        result = result.concat(tempBlocks)

        return result;
    }

    return (
        <div className={classes.OuterContainer}>
        <React.Fragment>
        { Object.keys(eventState).length ?
        <div className={classes.Sell}>
            { invalidRequest ? <AlertBox data={{
                alertTitle: t("invalid_request"),
                alertMessage: "",
                acceptText: t("back"),
                denyText: "Home",
                acceptAction: () => redirectAction("back"),
                denyAction: ()=> redirectAction("home")
            }} /> : null }
            {
                contactNotAdded ? <AlertBox data={{
                    alertTitle: t("update_your_details_alert"),
                    alertMessage: "",
                    acceptText: t("OK"),
                    denyText: t("Cancel"),
                    acceptAction: () => { window.location.href = SETTINGS },
                    denyAction: () => { props.history.goBack() }
                }} /> :
            <>
            <div className={classes.AccountHr}></div>
           <div className={classes.TransitionContainer}>
                <div className={currentStep === SellTicketSteps.ShippingOptions ||  currentStep === SellTicketSteps.PaymentInfo?[classes.FirstBlueContainer,classes.StepOneContainer].join(" "):classes.StepOneContainer}>
                    <div className={classes.StepNumberOneImageContainer}>
                    {
                        currentStep === SellTicketSteps.PaymentInfo || currentStep === SellTicketSteps.ShippingOptions || currentStep === SellTicketSteps.UploadETickets ?
                            <img src={step1selldone} alt="ticket"/>
                        : <img src={step1sell} alt="ticket"/>
                    }
                    </div>
                </div>
                <div className={currentStep === SellTicketSteps.ShippingOptions || currentStep === SellTicketSteps.PaymentInfo ?[classes.FirstThreeDotsContainer,classes.FirstDotsBlue].join(" "):classes.FirstThreeDotsContainer}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={classes.StepTwoContainer}>
                    <div className={classes.StepTwoImageContainer}>
                        {
                            currentStep === SellTicketSteps.PaymentInfo ?
                                ticketType === "1" ?
                                    <img src={step2etdone} alt="step 2 incomplete"/>    
                                : ticketType === "4" || ticketType === "5" ?
                                    <img src={MobileTicketStepDone} alt="step 2 incomplete"/>
                                : <img src={step2done} alt="step 2 incomplete"/>
                            : currentStep === SellTicketSteps.ShippingOptions ?
                                <img src={step2inprogress} alt="step 2 incomplete"/>:
                                currentStep === SellTicketSteps.MobileTickets?
                                <img src={MobileTicketStepInProgress} alt="step 2 incomplete"/>
                            : currentStep === SellTicketSteps.UploadETickets ?
                                <img src={step2etinprogress} alt="step 2 incomplete"/>
                            : ticketType === "1" ?
                                <img src={step2etincomplete} alt="step 2 incomplete"/>
                            : ticketType === "4" || ticketType === "5" ?
                                <img src={MobileTicketStepIncomplete} alt="step 2 incomplete"/>
                            : <img src={step2incomplete} alt="step 2 incomplete"/>
                        }
                    </div>
                </div>
                <div className={ currentStep === SellTicketSteps.PaymentInfo?[classes.SecondThreeDotsContainer,classes.SecondDotsBlue].join(" "):classes.SecondThreeDotsContainer}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={classes.StepThreeContainer}>
                    <div className={classes.StepThreeImageContainer}>
                        {
                            currentStep === SellTicketSteps.PaymentInfo?
                            <img src={step3inprogress} alt="step 3 incomplete"/>:
                        <img src={step3incomplete} alt="step 3 incomplete"/>
                        }
                    </div>
                </div>
           </div>
            <div className={classes.AccountHeading}><span></span>  <span>{
                currentStep === SellTicketSteps.CreateAd ||
                currentStep === SellTicketSteps.UploadETickets ?
                    "1. " + t('sell_tickets_title')
                : currentStep === SellTicketSteps.ShippingOptions ?
                    "2. " + t('shipping_options_title')
                    : currentStep === SellTicketSteps.MobileTickets ?
                    "2. " + t('MobileTicket_agreement_title')
                : currentStep === SellTicketSteps.PaymentInfo ?
                    "3. " + t('payment_method_title')
                : null
            }</span></div>
            <p className={classes.Sellp}>{
                currentStep === SellTicketSteps.CreateAd ||
                currentStep === SellTicketSteps.UploadETickets 
                ?
                    t('sell_form_description')
                : currentStep === SellTicketSteps.ShippingOptions ?
                <div className={classes.SellShippingText}>
                    <p>{t('sell_page_shipping_description')}</p>
                </div>: currentStep === SellTicketSteps.MobileTickets ?
                    <div className={classes.SellShippingText}>
                    <p>{t('MobileTicket_agreement_desc_1')+" "}<a href={CONTACT} className={classes.Link}>{t('customer_service')}</a>{" "+t('MobileTicket_agreement_desc_2')}</p>
                </div>:currentStep === SellTicketSteps.PaymentInfo?<div className={classes.PaymentInfo}>
                    <p>{t('sell_page_payment_method_description')}</p>
                </div>:null
            }</p>
            <div className={classes.SellMain}>
               <div className={classes.SellMainHead}>
                {
                    currentStep === SellTicketSteps.ShippingOptions || currentStep === SellTicketSteps.MobileTickets || currentStep === SellTicketSteps.PaymentInfo || currentStep === SellTicketSteps.UploadETickets || currentStep === SellTicketSteps.CreateAd  ?
                    <div className={classes.ShippingDescription}>
                            <p className={classes.SellHeadp1}>{eventState.name[0].value}</p>
                            <p className={classes.SellHeadp1}>{eventState.location_city}</p>
                            <p className={classes.SellHeadp1}>{eventState.event_date}</p>
                    </div>
                    : null
                }
                </div>

                {save ? <div className={classes.SellCont}>
                    <div className={classes.SellContInner}>
                        {
                         currentStep === SellTicketSteps.MobileTickets ?
                         <div className={classes.MobileTicketContainer}>
                            <p> {t('MobileTickets_read_and_agree_title')}</p>
                            {/* <span className={classes.TextBlue}>{getHightValue(t('MobileTickets_read_and_agree_title',{"hight":"hight"}))}</span> */}
                            <div className={classes.OuterConditionContainer}>
                            <div className={classes.UpperContainer}>
                                <ul className={classes.ConditionContainer}>
                                    <div  className={[classes.AgreementPolicy, classes.AgreementCondition].join(" ")} >
                                        <label htmlFor="url_agreement_id" className={classes.SquareLabelContainer}>
                                            <input type="checkbox" name="agreement" id="url_agreement_id" onChange={checkAgreement} />
                                            <span className={classes.SquareCheckmark}></span>
                                            <p>{ ticketType === "4" ?
                                                    t('uploading_url_agreement')
                                                : t('transfer_ticket_agreement')}
                                            </p>                                   
                                        </label>
                                    </div>
                                    <li>{t('url_promise_pre')}<span className={classes.TextBlue}>{t('deadline_days')}</span>{t("deadline_post")}<span className={classes.TextBlue}>{t("timeline_more")}</span> {t("timeline_post")}</li>
                                    <li>{t('url_promise_pre')} <span className={classes.TextBlue}>{t('deadline_hours')}</span> {t("deadline_post")}<span className={classes.TextBlue}>{t("timeline_less")}</span> {t("timeline_post")}</li>
                                    <li>{t('url_promise_pre')} <span className={classes.TextBlue}>{t('deadline_hour')}</span>{t("deadline_post")}<span className={classes.TextBlue}>{t("timeline_sameDay")}</span>{t("timeline_post_same")}</li>
                                </ul>
                            </div>
                            <div className={classes.AgreementCondition}>
                                <label htmlFor="agreement_id" className={classes.SquareLabelContainer}>
                                    <input type="checkbox" name="agreement" id="agreement_id" onChange={checkAgreement} defaultChecked={ agreementChecked ? "checked" : false } />
                                    <span
                                      className={
                                        [classes.SquareCheckmark, classes.AgreementConditionCheckmark].join(" ")
                                      }
                                    ></span>
                                        <p> {t('listings_agreement_pre')}
                                            <span className={classes.TextBlue}>
                                                <a href={TERMS} target="_blank">{t('terms_of_use')}</a>
                                            </span>
                                            {" "+t('and')+" "}
                                            <span className={classes.TextBlue}>
                                                <a href={PRIVACYPOLICY} target="_blank">{t('privacy_listings')}</a>
                                            </span>
                                        {t('listings_agreement_post')}</p>
                                </label>                               
                            </div>
                            </div>
                            <div className={[classes.EditButtons, classes.NoMarginLeft].join(" ")}>
                                <div className={[classes.BackShopContainer,classes.TicketBackContainer].join(" ")} onClick={()=>setCurrentStep(SellTicketSteps.CreateAd)}>
                                    <button type='button'>
                                    <img src={right} alt="arrow"/>
                                        {t('back_to_ticket_info')}
                                    </button>
                                </div>
                                <button
                                    type={ agreementChecked ? "submit" : "button" }
                                    className={ agreementChecked ? classes.TableButton2 : [classes.TableButton2, classes.Disabled].join(' ')}
                                    onClick={changeStepMobileTicket}
                                > {t("continue")} <img src={right} alt="" /></button>
                            </div>
                         </div>:
                        currentStep === SellTicketSteps.PaymentInfo ?
                        <div className={classes.PaymentMethod}>
                           
                            <div className={classes.PaymentMethodContainer}>
                                <div className={classes.WrapBox}>
                                    <div className={classes.Text}>{t('payment_method')}:
                                        <div className={classes.Help}>
                                            <div>?</div>
                                            <span className={classes.Tooltip}>{t('payment_method_help_text')}</span>
                                        </div>
                                    </div>
                                    <form>
                                        <div className={[classes.InputMethod,classes.PaymentRadio].join(" ")}>
                                            <label htmlFor="payment1">
                                                <input type="radio" className={classes.PaymentInputRadio} name="choosePayment" id="payment1" checked={paymentMethod === "IBAN" ? true : false } onClick={()=>setPaymentMethod("IBAN")} />
                                                <input type="text" name="paymentMethod" placeholder="IBAN" name="IBAN" value={iban_value} onChange={paymentMethod === "IBAN" ? (e)=>setIBANValue(e.target.value) : null} />
                                            </label>
                                            <label htmlFor="payment2">
                                                <input type="radio" className={classes.PaymentInputRadio} name="choosePayment" id="payment2" checked={paymentMethod === "PAYPAL" ? true : false } onClick={()=>setPaymentMethod("PAYPAL")} />
                                                <input type="text" name="paymentMethod" placeholder={t("PAYPAL_EMAIL_ADDRESS")} name="PAYPAL" value={paypal_email_value} onChange={paymentMethod === "PAYPAL" ? (e)=>setPaypalEmailValue(e.target.value) : null} />
                                            </label>
                                        </div>
                                    </form>
                                </div>
                                <div className={[classes.WrapBox,classes.PhoneWrap].join(" ")}>
                                    <div className={[classes.Text,classes.PhoneText].join(" ")}>{t('contact_details')}:
                                        <div className={classes.Help}>
                                            <div>?</div>
                                            <span className={classes.Tooltip}>{t('contact_details_help_text')}</span>
                                        </div>
                                    </div>
                                    <div className={classes.PhoneContainer}>
                                    <form>
                                        <div className={[classes.InputMethod,classes.PhoneInput].join(" ")}>
                                            <label className={[classes.PhoneNumberField,classes.PaymentNumber].join(" ")}>
                                                <CustomSelect
                                                    optionsList={countryCodes}
                                                    changed={setChosenCountryCode}
                                                    styling={{
                                                        container:window.screen.width > 850 ? { width: "70px", display: "inline-block",position:"absolute",zIndex:1} : window.screen.width > 500 ? { width: "70px",position:"absolute",zIndex:1} : window.screen.width>400? { width: "70px",position:"absolute",zIndex:1}:{},
                                                        defaultText: { background: "var(--white)",
                                                                        padding: "5px 10px",
                                                                        paddingLeft: "1px",
                                                                        color: "var(--black)",
                                                                        borderRadius: listOpened === 1 ? "10px 10px 0 0" : "10px 0px 0px 10px",
                                                                        fontSize: "14px",
                                                                        height: "29px",
                                                                        boxSizing: "border-box",
                                                                        border: "2px solid var(--blue)" },
                                                        ul: {},
                                                        li: { background: "var(--white)",
                                                                color: "var(--black)",
                                                                paddingLeft: "5px",
                                                                paddingRight: "5px",
                                                                border: "1px solid var(--blue)"}
                                                    }}
                                                    defaultText={defaultCountryCode}
                                                    listOpened={(item)=>setListOpened(item)}
                                                    listOpenIndex={1}
                                                />
                                                <input type="text"   className={classes.PaymentInput} name="paymentMethod" placeholder="PHONE" name="phone_number" value={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} />
                                            </label>
                                        </div>
                                    </form>
                                    </div>
                                </div>
                                <div className={classes.TableHr}></div>
                                <div className={ ticketType === "1" ? [classes.PaymentSave, classes.PaymentSaveMargin].join(" ") : classes.PaymentSave}>
                                {/* ticketType === "2" ? <div className={[classes.PaymentBack].join(" ")} onClick={()=>setCurrentStep(SellTicketSteps.ShippingOptions)}>
                                    <button type='button'><img src={right} alt="arrow"/>{t('back_to_shipping_options')}</button>
                                </div> : null */}
                                    <button className={classes.SaveButton} onClick={submitPaymentMethod} > {t("createad")} </button>
                                </div>
                            </div>
                        </div>
                        : currentStep === SellTicketSteps.UploadETickets ?
                        <div className={classes.ChooseTickets}>
                            <div className={classes.Container}>
                                <div className={classes.EventInfo}>
                                    { Object.keys(eTicketsUploaded).length > 0 ?
                                        t("please_choose") + quantity + t("from_the_following")
                                    : t("upload_eticket_pdf") }
                                </div>
                                <div className={classes.AllTickets}>
                                    {
                                        allPDFImages.map(function(url, index){
                                            return <div className={classes.ImageContainer} key={url.id}>
                                                <img
                                                    className={ selectedImages.includes(index+1) ? classes.Selected : null}
                                                    src={url.url}
                                                    alt="" />
                                                <span id={"eTicketId"+(index+1)}></span>
                                                <div className={classes.ImageOptions}>
                                                    <div className={classes.CheckImage}>
                                                    { selectedImages.includes(index+1) ?
                                                        <img src={CheckIcon} alt="" onClick={()=>pushToSelectedImages(index+1, "remove")} />
                                                        : <img className={classes.HiddenImage} src={CheckIcon} alt="" onClick={()=>pushToSelectedImages(index+1, "add")} />
                                                    }
                                                    </div>
                                                    <div><img src={SearchIcon} alt="" onClick={()=>displayFullImage(index)} /></div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                { Object.keys(eTicketsUploaded).length > 0 ?
                                <div className={[classes.Agreement, classes.AgreementFirstPage].join(' ')}>
                                    <label htmlFor="agreement_id">
                                        <input type="checkbox" name="agreement" id="agreement_id" />
                                        <div>
                                            {t('listings_agreement_pre')}
                                            <a href={TERMS} target="_blank">{t('terms_of_use')}</a>
                                            &nbsp;{t('and')}&nbsp;
                                            <a href={PRIVACYPOLICY} target="_blank">{t('privacy_listings')}</a>
                                            {t('listings_agreement_post')}
                                        </div>
                                    </label>
                                </div> : null }
                                <div className={classes.UploadButton}>
                                    { Object.keys(eTicketsUploaded).length > 0 ?
                                    <>
                                    <button onClick={()=>uploadImages()}>{t('upload')}</button>
                                    <label htmlFor="another-file-id" className={classes.AddAnotherFile}>
                                        <input type="file" id="another-file-id" onChange={(e)=>addAnotherFile(e.target.files[0])} />
                                        {t('add_another_file')}
                                    </label>
                                    </>
                                    : <label htmlFor="browse_id" className={classes.AddAnotherFile}>
                                        <div>
                                            <span>{t("Upload_e-ticket(s)")}</span>
                                        </div>
                                        <input id="browse_id" className={classes.BrowseHide} name="ticket_file" type="file" onChange={(e) => {uploadETicketAd(e);}} />
                                    </label> }
                                </div>
                                <div className={classes.BackShopContainer} onClick={()=>setCurrentStep(SellTicketSteps.CreateAd)}>
                                    <button type='button'>
                                    <img src={right} alt="arrow"/>
                                        {t('back_to_ticket_info')}
                                    </button>
                                </div>
                            </div>
                            {
                                displayImage >= 0 ?
                                <div className={classes.FullSizeImage}>
                                    <div className={classes.Container}>
                                        <div className={classes.CloseImage}>
                                            <img src={deleteIcon} alt="" onClick={()=>setDisplayImage(-1)}/>
                                        </div>
                                        <img className={classes.DisplayImage} src={allPDFImages[displayImage].url} alt="" />
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                        : currentStep === SellTicketSteps.CreateAd ?
                        <form onSubmit={createAd}>
                            <div className={classes.FormMain}>
                                <div className={classes.FormContainer}>
                                    <div className={classes.FormOneContainer}>
                                <div className={classes.Form1_1}>
                                    <span>
                                        {t("Ticket_Type")}:
                                        <div className={classes.Help}>
                                            <div>?</div>
                                            <span className={classes.Tooltip}>{t('ticket_type_tooltip')}</span>
                                        </div>
                                    </span>
                                    <CustomSelect
                                        optionsList={ /*
                                        eventState.event_date ?
                                            new Date(get_date_position(eventState.event_date.split(' ')[0], 2) // year
                                                    ,get_date_position(eventState.event_date.split(' ')[0], 1)-1 // month
                                                    ,get_date_position(eventState.event_date.split(' ')[0], 0) // date
                                                    ) < today ?
                                        [
                                            {name: t("E-ticket"), value: "1"}
                                        ]
                                        :*/
                                        [
                                            {name: t("E-ticket"), value: "1"},
                                            {name: t("Hard_Ticket"), value: "2"},
                                            {name: "Mobile", value: "M"}
                                        ]
                                        /*: null*/}
                                        styling={{
                                            container: window.screen.width > 850 ? { width: "72%", display: "inline-block", marginBottom: "5px"} : window.screen.width > 500 ? { width: "72%", display: "inline-block", marginBottom: "5px"} : { width: "60%", display: "inline-block", marginBottom: "5px"},
                                            defaultText: { background: "var(--white)",
                                                            padding: "5px",
                                                            color: "var(--black)",
                                                            borderRadius: listOpened === 2 ? "10px 10px 0 0" : "10px",
                                                            border: "2px solid var(--blue)" },
                                            ul: {},
                                            li: { background: "var(--white)",
                                                    color: "var(--black)",
                                                    border: "1px solid var(--blue)"}
                                        }}
                                        changed={(value) => ticketTypeHandler(value)}
                                        defaultText={
                                            editListingData ?
                                                editListingData.type == "1" ?
                                                    t("E-ticket")
                                                : editListingData.type == "2" ?
                                                    t("Hard_Ticket")
                                                :  editListingData.type == "4" || editListingData.type == "5" ?
                                                    "Mobile"
                                                : ""
                                            : i18n.language === LANG_ENGLISH ? "Please select" : "bitte auswählen"
                                        }
                                        listOpened={(item)=>setListOpened(item)}
                                        listOpenIndex={2}
                                    />
                                </div>
                                { showTicketRetailer ?
                                <div className={classes.Form1_1}>
                                    <span>
                                        {t("ticket_retailer")}:
                                        {/*<div className={classes.Help}>
                                            <div>?</div>
                                            <span className={classes.Tooltip}>{t('ticket_type_tooltip')}</span>
                                        </div>*/}
                                    </span>
                                    <CustomSelect
                                        optionsList={
                                        [
                                            {name: t("mobile_ticket_eventim"), value: "4"},
                                            {name: t("mobile_ticket_ticketmaster"), value: "5"},
                                        ]}
                                        styling={{
                                            container: window.screen.width > 850 ? { width: "72%", display: "inline-block", marginBottom: "5px"} : window.screen.width > 500 ? { width: "72%", display: "inline-block", marginBottom: "5px"} : { width: "60%", display: "inline-block", marginBottom: "5px"},
                                            defaultText: { background: "var(--white)",
                                                            padding: "5px",
                                                            color: "var(--black)",
                                                            borderRadius: listOpened === 2 ? "10px 10px 0 0" : "10px",
                                                            border: "2px solid var(--blue)" },
                                            ul: {},
                                            li: { background: "var(--white)",
                                                    color: "var(--black)",
                                                    border: "1px solid var(--blue)"}
                                        }}
                                        changed={(value) => ticketTypeHandler(value)}
                                        defaultText={
                                            editListingData ?
                                                editListingData.type == "4"?
                                                    t("mobile_ticket_eventim")
                                                : editListingData.type == "5"?
                                                    t("mobile_ticket_ticketMaster")
                                                : ""
                                            : i18n.language === LANG_ENGLISH ? "Please select" : "bitte auswählen"
                                        }
                                        listOpened={(item)=>setListOpened(item)}
                                        listOpenIndex={2}
                                    />
                                </div> : null }
                                <div className={classes.Form1_1}>
                                    <span>{t("Splitting")}:
                                        <div className={classes.Help}>
                                            <div>?</div>
                                            <span className={classes.Tooltip}>{t('splitting_tooltip')}</span>
                                        </div>
                                    </span>
                                    {/*name: t("No_Splitting"), value: "2"},  <= No Splitting option removed */}
                                    <CustomSelect
                                        optionsList={[
                                                {name: t("Per_ticket"), value: "1"},
                                                {name: t("Prevent_to_remain_with_1_ticket"), value: "3"},
                                                {name: t("Only_sell_even_numbers"), value: "4"}
                                            ]}
                                        styling={{
                                            container: window.screen.width > 850 ? { width: "72%", display: "inline-block", marginBottom: "5px"} : window.screen.width > 500 ? { width: "72%", display: "inline-block", marginBottom: "5px"} : { width: "60%", display: "inline-block", marginBottom: "5px"},
                                            defaultText: { background: "var(--white)",
                                                            padding: "5px",
                                                            color: "var(--black)",
                                                            borderRadius: listOpened === 5 ? "10px 10px 0 0" : "10px",
                                                            border: "2px solid var(--blue)" },
                                            ul: {},
                                            li: { background: "var(--white)",
                                                    color: "var(--black)",
                                                    border: "1px solid var(--blue)"}
                                        }}
                                        changed={(value) => setSplitting(value)}
                                        defaultText={
                                            editListingData ?
                                                editListingData.split == "1" ?
                                                    t("Per_ticket")
                                                : editListingData.split == "3" ?
                                                    t("Prevent_to_remain_with_1_ticket")
                                                : editListingData.split == "4" ?
                                                    t("Only_sell_even_numbers")
                                                : ""
                                            : i18n.language === LANG_ENGLISH ? "Please select" : "bitte auswählen"
                                        }
                                        listOpened={(item)=>setListOpened(item)}
                                        listOpenIndex={5}
                                    />
                                </div>
                                <div className={classes.Form1_1}>
                                    <span>{t("Quantity")}:</span>
                                    <CustomSelect
                                        optionsList={
                                                Array(10).fill().map(function(value, index){
                                                    return {name: index+1, value: index+1}
                                                })
                                            }
                                        styling={{
                                            container: window.screen.width > 850 ? { width: "72%", display: "inline-block", marginBottom: "5px"} : window.screen.width > 500 ? { width: "72%", display: "inline-block", marginBottom: "5px"} : window.screen.width>400? { width: "60%", display: "inline-block", marginBottom: "5px"}:{},
                                            defaultText: { background: "var(--white)",
                                                            padding: "5px",
                                                            color: "var(--black)",
                                                            borderRadius: listOpened === 4 ? "10px 10px 0 0" : "10px",
                                                            border: "2px solid var(--blue)" },
                                            ul: {},
                                            li: { background: "var(--white)",
                                                    color: "var(--black)",
                                                    border: "1px solid var(--blue)"}
                                        }}
                                        changed={(value) => setQuantity(value)}
                                        defaultText={ editListingData ? editListingData.quantity : "1" }
                                        listOpened={(item)=>setListOpened(item)}
                                        listOpenIndex={4}
                                    />
                                </div>                              
                             
                                </div>
                                <div className={classes.FormTwoContainer}>
                                <div className={classes.Form1_1}>
                                    <span>{t("Block")}:</span>
                                    <CustomSelect
                                        optionsList={
                                            eventState.associations.blocks ?
                                                getBlocksInOrder(eventState.associations.blocks).map(function(value, index){
                                                    return {name: value.name, value: value.blockID}
                                                })
                                            : []
                                        }
                                        styling={{
                                            container: window.screen.width > 850 ? { width: "72%", display: "inline-block", marginBottom: "5px"} : window.screen.width > 500 ? { width: "72%", display: "inline-block", marginBottom: "5px"} : { width: "60%", display: "inline-block", marginBottom: "5px"},
                                            defaultText: { background: "var(--white)",
                                                            padding: "5px",
                                                            color: "var(--black)",
                                                            borderRadius: listOpened === 6 ? "10px 10px 0 0" : "10px",
                                                            border: "2px solid var(--blue)" },
                                            ul: {},
                                            li: { background: "var(--white)",
                                                    color: "var(--black)",
                                                    border: "1px solid var(--blue)"}
                                        }}
                                        changed={(value) => setBlock(value)}
                                        defaultText={
                                            editListingData ? editListingData.block_name :
                                            i18n.language === LANG_ENGLISH ? "Please select" : "bitte auswählen"
                                        }
                                        listOpened={(item)=>setListOpened(item)}
                                        listOpenIndex={6}
                                    />
                                </div>
                                <div className={classes.Form1_1}>
                                    <span>{t("Row")}:</span>
                                    <input type="text" placeholder="optional" name="row_list" defaultValue={ editListingData ?
                                                                                                                editListingData.row_ad ? editListingData.row_ad
                                                                                                                : editListingData.row_list ? editListingData.row_list
                                                                                                                : null
                                                                                                                : null } />
                                </div>
                                <div className={classes.LastForm}>
                                <div className={classes.Form1_1}>
                                    <span>{t("seat_number")}:</span>
                                    <input type="text" placeholder="optional" name="seats" defaultValue={editListingData ? editListingData.seats : null} />
                                </div>
                                <div className={classes.HelpTwoForm} >
                                    <label className={classes.TableAdj1}>
                                            {t("Adjacent_seats")}
                                            <div className={classes.Help}>
                                                <div>?</div>
                                                <span className={classes.Tooltip} style={{left: "unset", right: "20px", borderRadius: "10px 0px 10px 10px"}}>{t('adjacent_seats_tooltip')}</span>
                                            </div>
                                    </label>
                                    <input className={classes.TableAdj} type="checkbox" name="together" checked={ editListingData && editListingData.together == "1" ? "checked" : null } />
                                </div>
                                </div>
                                
                                </div>
                               
                                </div>
                                <div className={classes.EditFlexMain}>
                                    <div className={classes.EditFlex}>
                                        {/* <div className={classes.HelpTwoForm} >
                                        <label className={classes.TableAdj1}>
                                                {t("Adjacent_seats")}
                                                <div className={classes.Help}>
                                                    <div>?</div>
                                                    <span className={classes.Tooltip} style={{left: "unset", right: "20px", borderRadius: "10px 0px 10px 10px"}}>{t('adjacent_seats_tooltip')}</span>
                                                </div>
                                            </label>
                                            <input className={classes.TableAdj} type="checkbox" name="together" />
                                            
                                        </div> */}
                                        {/* <div >
                                            <input className={classes.TableAdj} type="checkbox" name="side_view" value="1" />
                                            <label className={classes.TableAdj1}>{t("Side_View")}</label>
                                        </div> */}
                                        {/* <div >
                                            <input className={classes.TableAdj} type="checkbox" name="behind_stage_view" value="2" />
                                            <label className={classes.TableAdj3}> {t("Behind_Stage_View")} </label>
                                        </div> */}
                                        {/* <div >
                                            <input className={classes.TableAdj} type="checkbox" name="limited_view" value="0" />
                                            <label className={classes.TableAdj1}> {t("Limited_View")} </label>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={classes.TableHr}></div>

                                <p className={classes.TableOther}> {t("Other_ticket_details")} </p>
                                <div className={classes.ExtraInfoContainer}>
                                    <div className={classes.PointerContainer}>
                                        <div>i</div>
                                    </div>
                                    <div className={classes.ExtraInfoText}>
                                        <p>{t('other_details_warning_mylistings')}</p>
                                    </div>
                                </div>
                               
                                <div className={[classes.Form1_1, classes.OtherDetailsTextArea].join(' ')}>
                                    {/* <span>{t("Other_Details")}:</span> */}
                                    <textarea placeholder={"Optional: "+other_details_placeholder} name="other_details" defaultValue={editListingData ? editListingData.other_details : ""}>
                                    </textarea>
                                </div>

                                <div className={classes.TableHr1}></div>
                                <div className={classes.BelowContainer}>
                                  <div className={[classes.Form1,classes.BelowFormContainer].join(" ")}>
                                     <div className={classes.Form1_1}>
                                        <span> {t("Face_Value")}:
                                        <div className={classes.Help}>
                                            <div>?</div>
                                            <span className={classes.Tooltip}>{t('face_value_help_text')}</span>
                                        </div>
                                        </span>
                                        <input type="text" placeholder="0.90" name="org_price" id="original_price" onChange={(e)=>calculateMaximumPrice(e.target.value)} defaultValue={editListingData ? parseFloat(editListingData.org_price).toFixed(2) : null} onKeyPress={(e) => checkPriceKeys(e) } />
                                     </div>
                                    {
                                        priceError ?
                                        <div className={classes.PriceError}>{t("Sale_price_cannot_exceed_25_of_the_original_price")}</div>
                                        : null
                                    }
                                    <div className={classes.Form1_1}>
                                        <span> {t("Selling_Price")} :
                                        <br />
                                        
                                        </span>
                                        <input type="text" placeholder="1.00" name="price" onChange={(e)=>calculateCommision(e.target.value)} defaultValue={editListingData ? parseInt(editListingData.price).toFixed(2) : null} onKeyPress={(e) => checkPriceKeys(e) } />
                                    </div>
                                    <span className={classes.MaxPrice}>{t("max_price")} : €&nbsp;{parseFloat(maximumPrice).toFixed(2).toString().replace('.',',')}</span>
                                  </div>
                                <div className={[classes.Form1,classes.BelowFormContainerTwo].join(" ")}>
                                    <div className={[classes.PriceDiv1, classes.TextBlue].join(" ")} >
                                        <span> {t("You'll_receive_per_ticket")}: </span>
                                        <span className={classes.Price}>€ {commission.receive}</span>
                                    </div>
                                    <div className={[classes.PriceDiv2, classes.TextBlue].join(" ")} >
                                        <span> {t("Buyer_Price")}:</span>
                                        <span className={classes.Price}>€ {commission.buyer}</span>
                                    </div>
                                </div>
                                </div>
                                    {/*<p className={[classes.Tablep, classes.Upload].join(" ")} >
                                        {/*<label className={classes.MediaHide}> {t("Upload_e-ticket(s)")}</label>*}
                                        <label htmlFor="browse_id">
                                            {/*<span className={classes.Browse}> {t("Browse")} </span>*}
                                            <div className={classes.BrowseMedia} ><span>{t("Upload_e-ticket(s)")}</span></div>
                                            <input id="browse_id" className={classes.BrowseHide} name="ticket_file" type="file" onChange={(e) => {addFileName(e); }} />
                                        </label>
                                        {/*<label className={classes.MediaHide}> <img className={classes.ViewI} src={vieweticketsI} alt="" />  <u> {t("View_e-tickets")} &gt; </u></label>*}
                                        <div ref={fileNameRef} className={[classes.FileNameText, classes.EText].join(' ')}></div>
                                    </p>*/}
                                {isHardTicket ?
                                    <div className={classes.HardTicket}>
                                        <p> {t("Upload_a_photo_of_your_ticket(s)_(optional)")} </p>
                                        <div className={classes.HardTicketPhoto}>
                                            <div>
                                                <label htmlFor="browse_id">
                                                    <span className={classes.Browse}>{t("Browse")}</span>
                                                    <input id="browse_id" className={classes.BrowseHide} name="ticket_file" type="file" onChange={(e) => addFileName(e)} />
                                                </label>
                                                <div ref={fileNameRef} className={[classes.FileNameText, classes.HText].join(' ')}></div>
                                                {
                                                    removeButton ?
                                                        <div onClick={removePicture} className={classes.RemovePicture}>{t("remove")}</div>
                                                    : <p className={classes.SizeWarning}>( max. 2 MB )</p>
                                                }
                                            </div>
                                        </div>
                                    </div> : null }
                                <div className={classes.TableHr3}></div>
                                <div className={classes.EditButtons}>
                                   <div className={classes.BackShopContainer}>
                                    <button type='button' onClick={()=>redirectAction("back")}>
                                       <img src={right} alt="arrow"/>
                                        {t('back_to_shop')}
                                    </button>
                                    </div>
                                    <button className={classes.TableButton2} > {t("continue")} <img src={right} alt="" /></button>
                                </div>
                            </div>
                        </form>
                        : currentStep === SellTicketSteps.ShippingOptions ?
                        <div className={classes.ShippingOptions}>
                            <form onSubmit={submitShippingOptions}>
                            {
                            shippingOptionsData["nonGerman"]["active"] ?
                            <div className={classes.Shipping}>
                                {/*<div className={classes.Title}>3. {t('Payment')}<br /><span className={classes.TitleSub}>{t('Please_select_your_payment_method')}</span></div>*/}
                                <div className={classes.ShippingMethods}>
                                { shippingOptionsData["nonGerman"]["methods"][0]["active"] ?
                                 <label className={classes.ShippingMethod}>
                                    <label className={classes.container}>
                                      <input type="checkbox" name="shipping_options" value="1" defaultChecked="checked" />
                                      <span className={classes.checkmark}></span>
                                    </label>
                                    <div>
                                        <div className={[classes.Heading, classes.Text].join(' ')}>{t('shipping_4_title')}
                                            <div className={classes.Help}>
                                                <div>?</div>
                                                <span className={classes.Tooltip}>{t('shipping_1_help')}</span>
                                            </div>
                                        </div>
                                        <div className={classes.Tagline}>{t('shipping_4_description')}</div>
                                        <div className={classes.Tagline2}>{t('ticket_live_until')} {shippingOptionsData["nonGerman"]["methods"][0]["active_until"]}</div>
                                    </div>
                                  </label>
                                  : <label className={[classes.ShippingMethod,classes.MakeLessOpacity].join(" ")}>
                                        <div>
                                            <div className={classes.Heading}>{t('shipping_4_title')}</div>
                                            <div className={classes.Tagline}>{t('shipping_4_description_unavailable')}</div>
                                        </div>
                                    </label> }
                            
                                { shippingOptionsData["nonGerman"]["methods"][1]["active"] ?
                                    <label className={classes.ShippingMethod}>
                                      <label className={classes.container}>
                                      <input type="checkbox" name="shipping_options" value="3" />
                                      <span className={classes.checkmark}></span>
                                    </label>
                                    <div>
                                        <div className={[classes.Heading, classes.Text].join(' ')}>{t('shipping_3_title')}
                                            <div className={classes.Help}>
                                                <div>?</div>
                                                <span className={classes.Tooltip}>{t('shipping_3_help')}</span>
                                            </div>
                                        </div>
                                        <div className={classes.Tagline}>{t('shipping_3_description')}</div>
                                        <div className={[classes.PaymentMethodContainer, classes.Tagline2].join(' ')}>
                                                <div className={[classes.InputMethod, classes.ShippingNumber].join(" ")}>
                                                    <label className={[classes.PhoneNumberField,classes.ShippingNumber].join(" ")}>
                                                        <CustomSelect
                                                            optionsList={countryCodes}
                                                            changed={setChosenCountryCode}
                                                            styling={{
                                                                container: { width: "70px", position: "absolute", zIndex: 1},
                                                                defaultText: { background: "var(--white)",
                                                                                padding: "5px 10px",
                                                                                paddingLeft: "1px",
                                                                                color: "var(--black)",
                                                                                borderRadius: listOpened === 1 ? "10px 10px 0 0" : "10px 0px 0px 10px",
                                                                                fontSize: "14px",
                                                                                height: "29px",
                                                                                boxSizing: "border-box",
                                                                                border: "2px solid var(--blue)" },
                                                                ul: {},
                                                                li: { background: "var(--white)",
                                                                        color: "var(--black)",
                                                                        paddingLeft: "5px",
                                                                        paddingRight: "5px",
                                                                        border: "1px solid var(--blue)"}
                                                            }}
                                                            defaultText={defaultCountryCode}
                                                            listOpened={(item)=>setListOpened(item)}
                                                            listOpenIndex={1}
                                                        />
                                                        <input type="text" placeholder="PHONE" name="pick_up_phone" />
                                                    </label>
                                                </div>
                                              <div className={classes.Tagline2}>{t('ticket_live_until')} {shippingOptionsData["nonGerman"]["methods"][1]["active_until"]}</div>
                                            </div>
                                        </div>
                                    </label>
                                    : <label className={[classes.ShippingMethod,classes.MakeLessOpacity].join(" ")}>
                                        <div>
                                            <div className={classes.Heading}>{t('shipping_3_title')}</div>
                                            <div className={classes.Tagline}>{t('shipping_3_description_unavailable')}</div>
                                        </div>
                                    </label> }                                   
                                </div>
                            </div> : shippingOptionsData["german"]["active"] ?
                            <div className={classes.Shipping}>
                                {/*<div className={classes.Title}>3. {t('Payment')}<br /><span className={classes.TitleSub}>{t('Please_select_your_payment_method')}</span></div>*/}
                                <div className={classes.ShippingMethods}>
                                    {
                                        shippingOptionsData["german"]["methods"][0]["active"] ?
                                        <label className={classes.ShippingMethod}>
                                            <label className={classes.container}>
                                                <input type="checkbox" name="shipping_options" value="1" defaultChecked="checked" />
                                                <span className={classes.checkmark}></span>
                                            </label>
                                            <div>
                                                <div className={[classes.Heading, classes.Text].join(' ')}>{t('shipping_1_title')}
                                                    <div className={classes.Help}>
                                                        <div>?</div>
                                                        <span className={classes.Tooltip}>{t('shipping_1_help')}</span>
                                                    </div>
                                                </div>
                                                <div className={classes.Tagline}>{t('shipping_1_description')}</div>
                                                <div className={classes.Tagline2}>{t('ticket_live_until')} {shippingOptionsData["german"]["methods"][0]["active_until"]}</div>
                                            </div>
                                        </label>
                                        : <label className={[classes.ShippingMethod,classes.MakeLessOpacity].join(" ")}>
                                            <div>
                                                <div className={classes.Heading}>{t('shipping_1_title')}</div>
                                                <div className={classes.Tagline}>{t('shipping_1_description_unavailable')}</div>
                                            </div>
                                        </label>
                                    }  
                                    { 
                                        shippingOptionsData["german"]["methods"][1]["active"] ?
                                        <label className={classes.ShippingMethod}>
                                            <label className={classes.container}>
                                              <input type="checkbox" name="shipping_options" value="2" />
                                              <span className={classes.checkmark}></span>
                                            </label>
                                            <div>
                                                <div className={[classes.Heading, classes.Text].join(' ')}>{t('shipping_2_title')}
                                                    <div className={classes.Help}>
                                                        <div>?</div>
                                                        <span className={classes.Tooltip}>{t('shipping_2_help')}</span>
                                                    </div>
                                                </div>
                                                <div className={classes.Tagline}>{t('shipping_2_description')}</div>
                                                <div className={classes.Tagline2}>{t('ticket_live_until')} {shippingOptionsData["german"]["methods"][1]["active_until"]}</div>
                                            </div>
                                        </label>
                                        : <label className={[classes.ShippingMethod,classes.MakeLessOpacity].join(" ")}>
                                            <div>
                                                <div className={classes.Heading}>{t('shipping_2_title')}</div>
                                                <div className={classes.Tagline}>{
                                                    shippingOptionsData["german"]["methods"][1]["status"] === "POST" ?
                                                        t('shipping_2_description_unavailable_before_event')
                                                    : t('shipping_2_description_unavailable')
                                                }</div>
                                            </div>
                                        </label>
                                    }
                                    {
                                        shippingOptionsData["german"]["methods"][2]["active"] ?
                                        <label className={classes.ShippingMethod}>
                                               <label className={classes.container}>
                                                  <input type="checkbox" name="shipping_options" value="3" />
                                                  <span className={classes.checkmark}></span>
                                               </label>
                                            <div>
                                                <div className={[classes.Heading, classes.Text].join(' ')}>{t('shipping_3_title')}
                                                    <div className={classes.Help}>
                                                        <div>?</div>
                                                        <span className={classes.Tooltip}>{t('shipping_3_help')}</span>
                                                    </div>
                                                </div>
                                                <div className={classes.Tagline}>{t('shipping_3_description')}</div>
                                                <div className={[classes.PaymentMethodContainer, classes.Tagline2].join(' ')}>
                                                        <div className={[classes.InputMethod, classes.ShippingNumber].join(" ")}>
                                                            <label className={[classes.PhoneNumberField,classes.ShippingNumber].join(" ")}>
                                                                <CustomSelect
                                                                    optionsList={countryCodes}
                                                                    changed={setChosenCountryCode}
                                                                    styling={{
                                                                        container:window.screen.width > 850 ? { width: "70px", display: "inline-block",position:"absolute",zIndex:1} : window.screen.width > 500 ? { width: "80px",position:"absolute",zIndex:1} : window.screen.width>400? { width: "150px",position:"absolute",zIndex:1}:{},
                                                                        defaultText: { 
                                                                                        padding: "5px 10px",
                                                                                        paddingLeft: "1px",
                                                                                        color: "var(--black)",
                                                                                        borderRadius: listOpened === 1 ? "10px 10px 0 0" : "10px 0px 0px 10px",
                                                                                        fontSize: "14px",
                                                                                        height: "29px",
                                                                                        boxSizing: "border-box",
                                                                                        border: "2px solid var(--blue)" },
                                                                        ul: {},
                                                                        li: { background: "var(--white)",
                                                                                color: "var(--black)",
                                                                                paddingLeft: "5px",
                                                                                paddingRight: "5px",
                                                                                border: "1px solid var(--blue)"}
                                                                    }}
                                                                    defaultText={defaultCountryCode}
                                                                    listOpened={(item)=>setListOpened(item)}
                                                                    listOpenIndex={1}
                                                                />
                                                                <input type="text" placeholder="PHONE" name="pick_up_phone" />
                                                            </label>
                                                        </div>
                                                    <div className={classes.Tagline2}>{t('ticket_live_until')} {shippingOptionsData["german"]["methods"][2]["active_until"]}</div>
                                                </div>
                                            </div>
                                        </label>
                                        : <label className={[classes.ShippingMethod,classes.MakeLessOpacity].join(" ")}>
                                             <div>
                                                  <div>
                                                    <div className={classes.Heading}>{t('shipping_3_title')}</div>
                                                    <div className={classes.Tagline}>{t('shipping_3_description_unavailable')}</div>
                                                 
                                                </div>
                                            </div>
                                        </label>
                                    }
                                </div>
                            </div> : null
                         }
                            <div className={classes.Agreement}>
                                <label htmlFor="agreement_id">
                                    <input type="checkbox" name="agreement" id="agreement_MobileTicket" onChange={checkAgreement} defaultChecked={ agreementChecked ? "checked" : null } />
                                    <div>
                                        {t('listings_agreement_pre')}
                                        <a href={TERMS} target="_blank">{t('terms_of_use')}</a>
                                        &nbsp;{t('and')}&nbsp;
                                        <a href={PRIVACYPOLICY} target="_blank">{t('privacy_listings')}</a>
                                        {t('listings_agreement_post')}
                                    </div>
                                </label>
                            </div>
                            <div className={classes.TableHr1}></div>
                            <div className={classes.EditButtons}>
                                <div className={[classes.BackShopContainer,classes.TicketBackContainer].join(" ")} onClick={()=>setCurrentStep(SellTicketSteps.CreateAd)}>
                                    <button type='button'>
                                    <img src={right} alt="arrow"/>
                                        {t('back_to_ticket_info')}
                                    </button>
                                </div>
                                <button
                                    type={ agreementChecked ? "submit" : "button" }
                                    className={ agreementChecked ? classes.TableButton2 : [classes.TableButton2, classes.Disabled].join(' ')}
                                    onClick={ agreementChecked ? null : ()=>openAgreementAlert() }
                                > {t("continue")} <img src={right} alt="" /></button>
                            </div>
                            </form>
                        </div>
                        : null }
                    </div>
                </div>
                    :
                    <div className={classes.SellContSave}>
                        <p className={classes.Savep}> {t("Congrats!_Your_listing_is_now_for_sale.")}</p>

                        <button onClick={() => window.location.href = LISTINGS} className={classes.SellSave}><img className={classes.newBlack} src={listI} alt="" />
                            {t("View_all_Listings")}  <img src={right} alt="" /></button><br />
                        <button onClick={() => window.location.reload()} className={classes.SellSave}><img src={newListI} alt="" /> {t("New_Listing")}  <img className={classes.Right} src={right} alt="" /></button>
                    </div>
                }
            </div>
            </>
            }
        {error &&
            <ErrorBox data={{ "errorTitle": t("attention"), errorSubTitle: "", errors: listOfErrors, acceptText: "OK", acceptAction: () => { setError(false) } }} />
        }
        { showLoader ?
            <div className={[classes.Payment, classes.PaymentLoader].join(' ')}>
                <div className={[classes.Container, classes.WrapBox, classes.WaitBox].join(' ')}>
                    <div className={classes.Title}></div>
                    <div className={classes.Heading}>{t('Please_Wait')}</div>
                    <div className={classes.SubHeading}></div>
                    <div className={classes.SubHeading}>{t('listing_ticket_loader_desc')}</div>
                    <div className={classes.LoadingIcon}><img src={LoadingIcon} alt="" /></div>
                </div>
            </div> : null }
        { showContactBox ?
            <ContactUs
                hideContact={()=>setShowContactBox(false)}
                pageDetails="Sell Page Contact - " // for internal use
            /> : null
        }
        </div>
        : null }
        </React.Fragment>
        </div>
    )
}