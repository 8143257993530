import React, { useState, useEffect } from 'react'
import classes from './Sales.module.css'
import checkIcon from '../../assets/images/icons/check.png'
import cancel from '../../assets/images/icons/cancel.png'
import plusIcon from '../../assets/images/icons/newlisting.png'
import rightIcon from '../../assets/images/icons/right-chevronBlack.png'

import DownArrow from '../../assets/images/icons/down_arrow.png';
import RightArrow from '../../assets/images/icons/right-arrow.png';

import PrintIcon from '../../assets/images/icons/printicon.png';
import WhatsappIcon from '../../assets/images/icons/whatsapp.png';
import DelayIcon from '../../assets/images/icons/informdelay.png';

import TrackIcon from '../../assets/images/icons/trackorder.png';
import SupportIcon from '../../assets/images/icons/support.png';

import SalesIcon from '../../assets/images/icons/salesicon.png';

import statuses from './states.json';
import { useTranslation } from 'react-i18next';
import {EVENT_STRING, LANG_ENGLISH} from '../../URLS';

import ContactUs from '../ContactUsBox/ContactUs';

import LogoLoader from '../UI/LogoLoader/LogoLoader';

import customerDetails from '../UserData';
import {checkForLogin} from '../PrivateRoute/PrivateRoute';

import axios, {token_config} from '../../axiosBase';
import {AlphaPattern, DigitPattern} from '../../Formats';
import consoleLog from '../../log';
import AlertBox from '../UI/AlertBox/AlertBox';

import SalesBox from './SalesBox';
import FullScreen from '../FullScreen/FullScreen';

export default function Sales() {

    const { t, i18n } = useTranslation();
    const [showContactBox, setShowContactBox] = useState(false);
    const [pageDetails, setPageDetails] = useState("");
    const [open, setOpen] = useState("Shipping")
    const [openImage, setOpenImage] = useState(false);
    const [image, setImage] = useState(false);
/*    const [shippingStates, setShippingStates] = useState({
        shipping: true,
        isShipped: true,
        isShippedError: false,
        isDelivered: true,
        isPaymentDone: true,
        isCancelled: false
    })*/
    const [showLoader, setShowLoader] = useState(false)

    const [hideCompleted, setHideCompleted] = useState(false);
    const [userData, setUserData] = useState({})
    const [salesList, setSalesList] = useState({})

    const isEmpty = false;
    const [dialogBox, setDialogBox] = useState(null)
    const [clickedStatus, setClickedStatus] = useState(false)

    const [detailPopup, setDetailPopup] = useState(false)
    const [popupData, setPopupData] = useState({})

    const activeTabJson = {};
    const [activeTabs, setActiveTabs] = useState({})
    const [showPrintLabelAlert, setPrintLabelAlert] = useState(false);

    const [collectiveTicketsStatus, setCollectiveTicketStatus] = useState({
        "totalEarnings": 0,
        "totalSold": 0
    })
    const ShippingMethods = {
        "1": t('shipping_1_title'),
        "2": t('shipping_2_title'),
        "3": t('shipping_3_title'),
        "": t('shipping_1_title')
    }
    const handleOpenTabs = (status, index) => {

        for (var key in activeTabs) {
            activeTabs[key] = false
        }
        setActiveTabs({
            ...activeTabs,
            ['tab' + index]: status ? true : false
        })
    }

    const getEShippingMessage = (event_name) => {
        return t("shipping_message_1")+customerDetails.data.firstname+t('shipping_message_2')+event_name+t('shipping_message_3')
    }

    const getHShippingMessage = (saleOrder) => {
        if(saleOrder.shipping_method === "1"){
            return t('shipping_message_registeredmail')
        }else if(saleOrder.shipping_method === "2"){
            let dhl_text = t('shipping_message_dhl')
            if(saleOrder.associations.buyer_info && saleOrder.associations.buyer_info.length){
                for(let i=0; i<Object.keys(saleOrder.associations.buyer_info[0]).length; i++){
                    let key_name = Object.keys(saleOrder.associations.buyer_info[0])[i]
                    dhl_text = dhl_text.replace("{"+key_name+"}", saleOrder.associations.buyer_info[0][key_name])
                }
            }
            return dhl_text;
        }else if(saleOrder.shipping_method === "3"){
            let pick_up_text = t('shipping_message_pickup')
            if(saleOrder.associations.buyer_info && saleOrder.associations.buyer_info.length){
                pick_up_text = pick_up_text.replace("{buyer_phone}", saleOrder.associations.buyer_info[0]["buyer_phone"])
            }
            return pick_up_text;

        }else{
            return t('shipping_message_registeredmail')
        }
    }

    const onClickStatus = (status, index, current_state) => {
        let eTicket = false;
        let eventimTicket = false;
        let ticketmasterTicket = false;
        let hardTicket = false;
        let event_name = "[event]";

        if(salesList.orders[index].associations && salesList.orders[index].associations.order_rows){
            if(salesList.orders[index].ticket_type === "1"){
                eTicket = true;
                event_name = salesList.orders[index].associations.order_rows[0].product_name;
            }else if(salesList.orders[index].ticket_type === "4"){
                eventimTicket = true;
            }else if(salesList.orders[index].ticket_type === "5"){
                ticketmasterTicket = true;
            }else if(salesList.orders[index].ticket_type === "2"){
                hardTicket = true;
            }
        }
        const shipped_message = t('shipped_message');
        const eventim_message = t('eventim_message');
        const ticketmaster_message = t('ticketmaster_message');
        const mobileticket_shipped_message = t('mobileticket_shipped_message');
        let shipping_message = eTicket ? getEShippingMessage(event_name) : hardTicket ? getHShippingMessage(salesList.orders[index]) : eventimTicket ? eventim_message : ticketmasterTicket ? ticketmaster_message : null;
        const payment_completion_message = t('payment_completion_message');
        const payment_process_message = t('payment_process_message');
        const delivered_message = t('delivered_message');
        const delivery_message = t('delivery_message');
        const eventim_uploaded_message = t('eventim_uploaded_message');
        const ticketmaster_uploaded_message = t('ticketmaster_uploaded_message');
        const ticket_master_uploaded_screenshot = t('ticket_master_uploaded_screenshot');

        shipping_message = shipping_message.split('\n').map(function(line){
            if(line.split('{kmt_email}').length > 1){
                return <>
                    {
                        line.split('{kmt_email}')[0].split('{kmt_phone}').length > 1 ?
                            <>{line.split('{kmt_email}')[0].split('{kmt_phone}')[0]}
                                <a href={"tel:"+process.env.REACT_APP_KMT_PHONE}>{process.env.REACT_APP_KMT_PHONE}</a>
                            {line.split('{kmt_email}')[0].split('{kmt_phone}')[1]}
                            </>
                        : line.split('{kmt_email}')[0]
                    }
                    <a href={"mailto:"+process.env.REACT_APP_KMT_EMAIL}>
                        {process.env.REACT_APP_KMT_EMAIL}
                    </a>
                    {console.log(line.split('{kmt_email}')[1].split('{kmt_phone}')[0])}
                    {
                        line.split('{kmt_email}')[1].split('{kmt_phone}').length > 1 ?
                            <>{line.split('{kmt_email}')[1].split('{kmt_phone}')[0]}
                                <a href={"tel:"+process.env.REACT_APP_KMT_PHONE}>{process.env.REACT_APP_KMT_PHONE}</a>
                            {line.split('{kmt_email}')[1].split('{kmt_phone}')[1]}
                            </>
                        : line.split('{kmt_email}')[1]
                    }
                </>
            }
            return <>{line}<br /></>
        })

        if(statuses.order_states[current_state].isShippedError){
            setDialogBox({
                message: shipping_message,
                buttonText: t("Contact_Support"),
                actionType: "contactSupport"
            })
        }else if(statuses.order_states[current_state].isShipped && status === "shipped"){
            let prop = {
                message: shipped_message.split('\n').map(function(line){return <>{line}<br /></>})
            }
            if(hardTicket){
                if(salesList.orders[index].shipping_method === "3"){
                    prop["buttonText"] = t("whatsapp_button")
                    prop["actionType"] = "whatsappLink"
                    prop["buttonIcon"] = WhatsappIcon
                }else if(salesList.orders[index].shipping_method === "2"){
                    prop["buttonText"] = t("dhl_express_button")
                    prop["actionType"] = "DHLLink"
                    prop["buttonIcon"] = PrintIcon
                }else{
                    prop["buttonText"] = t("Print_Label")
                    if(salesList.orders[index].associations && salesList.orders[index].associations.tracking && salesList.orders[index].associations.tracking[0].dp_link !== ""){
                        prop["actionType"] = "openLink"
                    }else{
                        prop["actionType"] = "disabled_Print_Label"
                    }
                    prop["buttonIcon"] = PrintIcon
                }
            }else if(eventimTicket || ticketmasterTicket){
                prop["message"] = mobileticket_shipped_message;
            }
            setDialogBox(prop)
        }else if(statuses.order_states[current_state].shipping && status === "shipped"){
            let prop = {
                message: shipping_message
            }
            if(hardTicket){
                prop["buttonText"] = t("Inform_Delay")
                prop["buttonIcon"] = DelayIcon
                prop["actionType"] = "contactSupport"

                if(salesList.orders[index].shipping_method === "3"){
                    prop["buttonText2"] = t("whatsapp_button")
                    prop["actionType2"] = "whatsappLink"
                    prop["buttonIcon2"] = WhatsappIcon
                }else if(salesList.orders[index].shipping_method === "2"){
                    prop["buttonText2"] = t("dhl_express_button")
                    prop["actionType2"] = "DHLLink"
                    prop["buttonIcon2"] = PrintIcon
                }else{
                    prop["buttonText2"] = t("Print_Label")
                    if(salesList.orders[index].associations && salesList.orders[index].associations.tracking && salesList.orders[index].associations.tracking[0].dp_link !== ""){
                        prop["actionType2"] = "openLink"
                    }else{
                        prop["actionType2"] = "disabled_Print_Label"
                    }
                    prop["buttonIcon2"] = PrintIcon
                }
            }
            setDialogBox(prop)
        }else if(statuses.order_states[current_state].isDelivered && status === "delivered"){
            if(hardTicket){
                setDialogBox({
                    message: delivered_message
                })
            }else if(eventimTicket){
                setDialogBox({
                    message: eventim_uploaded_message
                })
            }else if(ticketmasterTicket){
                setDialogBox({
                    message: ticket_master_uploaded_screenshot
                })
            }
        }else if(statuses.order_states[current_state].isShipped && status === "delivered"){
            setDialogBox({
                message: delivery_message,
                buttonText: t("Track"),
                buttonIcon: TrackIcon,
                actionType: "openTrackLink"
            })
        }else if(statuses.order_states[current_state].isPaymentDone && status === "payment"){
            setDialogBox({
                message: payment_completion_message
            })
        }else if(statuses.order_states[current_state].isDelivered && status === "payment"){
            setDialogBox({
                message: payment_process_message,
                buttonText: t("Report_Issue"),
                buttonIcon: SupportIcon,
                actionType: "contactSupport"
            })
        }
        setClickedStatus(status)
        handleOpenTabs(status, index)
    }

    const showContactBoxFunction = (message) => {
        setShowContactBox(true);
        setPageDetails(message);
    }

    useEffect(() => {

        checkForLogin().then(function(token){
        if(token){

        const config = token_config(token);
        setShowLoader(true)
        axios.get('/orders?mode=sales&output_format=JSON&display=full', config)
            .then(function (data) {
                setShowLoader(false)
                consoleLog(data.data)
                let sorted_data = sortOrdersBy(data.data.orders, "reference")
                setUserData({"orders": sorted_data})
                setSalesList({"orders": sorted_data})
            })
            .catch(function(err){
                setShowLoader(false)
                setUserData([])
                setSalesList([])
            })
        }})
        
    }, [Object.keys(userData).length])

    const sortOrdersBy = (data, type) => {
        data.sort(function (a, b) {
            return a[type].localeCompare(b[type]);
        });
        return data.reverse()
    }


    useEffect(() => {
        if(Object.keys(userData).length){
            consoleLog(userData)
            userData.orders.map(function (value, index) {
                activeTabJson['tab' + index] = false
            })
            setActiveTabs(activeTabJson)            
        }
    }, [Object.keys(userData).length])



    useEffect(() => {
        if(Object.keys(userData).length){
            let quantity = 0;
            let earnings = 0;
            for(let i=0; i< userData.orders.length; i++){
                if(statuses.order_states[userData.orders[i].current_state].isPaymentDone){
                    let current_quantity = parseInt(userData.orders[i].associations.order_rows[0].product_quantity);
                    quantity = quantity + parseInt(userData.orders[i].associations.order_rows[0].product_quantity);
                    earnings = earnings + parseFloat(userData.orders[i].associations.order_rows[0].payout);
                }
            }
            setCollectiveTicketStatus({
                "totalEarnings": earnings.toFixed(2).replace('.',','),
                "totalSold": quantity
            })            
        }
    },[Object.keys(userData).length])
    const changeHideStatus = (e) => {
        if(e.target.checked){
            setHideCompleted(true);
        }else{
            setHideCompleted(false)
        }
    }

    const openDetailPopup = (order) => {
        setDetailPopup(true)
        setPopupData(order)
    } 

    const searchWithinSales = (e) => {
        const query = e.target.value;
        let tempFilteredDetails = [];
        if(!Object.keys(userData).length){
            return;
        }
        if(query === ""){
            tempFilteredDetails = userData.orders;
        }
        else if(query.match(AlphaPattern)){
            tempFilteredDetails = userData.orders.filter(order => order.associations.order_rows[0].product_name.toLowerCase().includes(query.toLowerCase()));
        }
        else if(query.match(DigitPattern)){
            tempFilteredDetails = userData.orders.filter(order => order.reference.includes(query));
        }
        setSalesList({ orders: tempFilteredDetails})
    }

    useEffect(() => {
        if(document.getElementById("shipIndex0") && Object.keys(salesList).length){
            document.getElementById("shipIndex0").click()
        }
    },[Object.keys(salesList).length])

    return (
        <div className={classes.Sales}>
            <div className={classes.AccountHr}></div>
            <div className={classes.AccountHeading}><img src={SalesIcon} alt="" /><span>{t('MY_SALES')}</span></div>
            <div className={classes.ListingSearch} >
                <input className={[classes.ListingSearch, "myInput"].join(" ")} type="text" placeholder={t('Start_typing_here_to_search_your_sales')} onChange={(e)=>searchWithinSales(e)} />
            </div>
            <div className={classes.SalesTop}>
                <div className={classes.Top1}>
                    <div><p className={classes.Blue}>{t('Your_total_earnings')}:</p><p className={classes.Big}>€ {collectiveTicketsStatus.totalEarnings}</p></div>
                    <div><p className={classes.Blue}>{t('Total_tickets_sold')}:</p><p className={classes.Big} >{collectiveTicketsStatus.totalSold}</p></div>
                </div>
                <div className={[classes.ListingSearch, classes.MobileSearch].join(' ')}>
                    <input className={[classes.ListingSearch, "myInput"].join(" ")} type="text" placeholder={t('Start_typing_here_to_search_your_sales')} onChange={(e)=>searchWithinSales(e)} />
                </div>
                <label className={classes.HideSoldTickets} htmlFor="hideSoldTicketsId"><input type="checkbox" onChange={(e) => changeHideStatus(e)} id="hideSoldTicketsId"/><span>&nbsp;</span><div className={classes.DesktopCheckbox}>{t('Check_this_box_to_hide_orders_that_are_completed')}</div><div className={classes.MobileCheckbox}>{t('Hide_fully_sold_tickets')}</div></label>
            </div>
            { isEmpty ?
            <div className={classes.Empty}>
                <p>{t('Looks_like_you_have_no_sales_at_the_moment')}</p>
                <button><img src={plusIcon} />{t('New_Listing')}<img src={rightIcon} /></button>
            </div> : null }

            {Object.keys(salesList).length && salesList.orders.map(function(order, index){
            return <SalesBox 
                        statuses={statuses}
                        order={order}
                        index={index}
                        ShippingMethods={ShippingMethods}
                        hideCompleted={hideCompleted}
                        openDetailPopup={openDetailPopup}
                        setShowContactBox={setShowContactBox}
                        setPageDetails={setPageDetails}
                        clickedStatus={clickedStatus}
                        onClickStatus={onClickStatus}
                        activeTabs={activeTabs}
                        dialogBox={dialogBox}
                        showContactBoxFunction={showContactBoxFunction}
                        setPrintLabelAlert={setPrintLabelAlert}
                        setShowLoader={setShowLoader}
                        setOpenImage={setOpenImage}
                        setImage={setImage}
                    />
            })}
            { detailPopup ?
            <div className={classes.SalesDetails}>
                <div className={classes.Container}>
                    <div className={classes.WrapBox}>
                        <div className={classes.EventDetails}>
                            <div>
                                <label className={classes.Big1}>{popupData.associations.order_rows[0].product_name}</label><br />
                                {/*<label className={classes.Big1}>Event Title Name 1 Line 2</label><br />*/}
                                <div>{popupData.associations.order_rows[0].location}</div>
                                <div>{popupData.associations.order_rows[0].date.split(' ')[0]}<br />{popupData.associations.order_rows[0].date.split(' ')[1]}</div>
                                <div>{popupData.associations.order_rows[0].section}</div>
                            </div>
                            <div>
                                <div className={classes.TicketCount}>{popupData.associations.order_rows[0].product_quantity}</div>
                                <div className={classes.TicketText}>{popupData.associations.order_rows[0].product_quantity > 1 ? t('Tickets') : t('Ticket')}</div>
                            </div>
                        </div>
                        <div className={classes.SaleDetails}>
                            <div>{t('Order_ID')}: <span>{popupData.reference}</span></div>
                            <div>{t('Placed_on')}: {popupData.date_add}</div>
                        </div>
                        <div className={classes.Extras}>
                            {popupData.associations.tracking ?
                            popupData.associations.tracking[0].description ?
                            <>
                            <div>{t('Extra_Information')}</div>
                            <div className={classes.ExtraContent}>
                                {popupData.associations.tracking ? popupData.associations.tracking[0].description : "Not Available"}
                            </div>
                            </> : null
                            : null
                            }
                        </div>
                        <div className={classes.PricingInfo}>
                            {t('price_per_ticket')} <span>€ {
                                (parseFloat(popupData.associations.order_rows[0].payout)/popupData.associations.order_rows[0].product_quantity)
                                .toFixed(2).replace('.',',')}</span>
                        </div>

                        <div className={classes.AcceptButton}>
                            <button className="myButton" onClick={()=>setDetailPopup(false)}>OK</button>
                        </div>
                    </div>
                </div>
            </div> : null }
            {
                showContactBox ?
                    <ContactUs
                        hideContact={()=>setShowContactBox(false)}
                        pageDetails={pageDetails}
                    /> : null
            }
            { showPrintLabelAlert ? <AlertBox data={{
                alertTitle: t("print_not_available"),
                alertMessage: t("print_not_available_description"),
                acceptText: t("Contact_Support"),
                acceptAction: () => {setShowContactBox(true); setPrintLabelAlert(false);},
            }} /> : null }
            { showLoader ? <LogoLoader /> : null }
            {openImage && <FullScreen image={image} setOpen={setOpenImage}/>}
        </div>
    )
}