import React, { useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import axios, {config, token_config} from '../../../../../axiosBase.js';
import {checkForLogin} from '../../../../PrivateRoute/PrivateRoute';
import customerDetails from '../../../../UserData.js';

import { CreateOrdersData } from '../../../../Profile/XMLData/XMLData';
import consoleLog from '../../../../../log';

import {createOrder, deleteCart} from '../PostPayment';

export default function CheckoutForm(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();



  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
      checkForLogin().then(function(token){
      if(token){

      const config = token_config(token)

      const payload = {
        amount: parseInt(parseFloat(props.price)*100)
      }
      axios.post('/payment?a=create',payload,config)
      .then(res => {
        consoleLog("FETCHED",res)
        return res
      })
      .then(data => {
        setClientSecret(data.data.clientSecret.client_secret);
      });

      }})
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });

    if(error || disabled){
      props.loader();
      setProcessing(false);
      return;
    }

    sessionStorage.setItem("SP_Details",JSON.stringify({
      eventDetails: props.eventDetails,
      ticketDetails: props.ticketDetails,
      priceBreakdown: props.priceBreakdown
    }))

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
      props.loader();
      props.proceed("FAILED");
      deleteCart(sessionStorage.getItem("cart"))
    } else {
      const cart_id = sessionStorage.getItem("cart")
      const address_id = sessionStorage.getItem("address")
      const shipping_method = sessionStorage.getItem("shipping_method")
      const ticketmaster_details = JSON.parse(sessionStorage.getItem("ticketmaster_details"))
      let customer_data = customerDetails.data;
      customer_data["ticketmaster_details"] = ticketmaster_details;

      const payload = CreateOrdersData("stripe", customer_data, props.eventDetails, props.ticketDetails, cart_id, address_id, shipping_method, props.price);
      consoleLog(payload)

      setError(null);
      setProcessing(false);
      setSucceeded(true);

      createOrder(payload, props, cart_id);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div style={{background: "var(--white)"}}>
      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      <button
        disabled={processing || succeeded}
        id="submitStripePayment"
        style={{display: "none"}}
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Pay now"
          )}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {/*error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )*/}
      </div>
      {/* Show a success message upon completion */}
      {/* succeeded ?
      <p>
        Payment succeeded, see the result in your
        <a
          href={`https://dashboard.stripe.com/test/payments`}
          style={{color: "var(--blue-dark)"}}
        >
          {" "}
          Stripe dashboard.
        </a> Refresh the page to pay again.
      </p> : null */}
    </form>
  );
}
