import React from 'react';
import { useState,useEffect } from 'react';
import { LANG_GERMAN } from '../../URLS';
import axios from '../../axiosBase';
import { config } from '../../axiosBase';
import classes from './TermsConditions.module.css';

import { useTranslation } from 'react-i18next';

const TermsConditions = () => {

	const { t, i18n } = useTranslation();
	const [title, setTitle] = useState("");
	const [termsConditions,setTermsConditions]=useState("");
	const linkLangCode=localStorage.getItem("i18nextLng") === LANG_GERMAN ? 1 : 2;

	useEffect(() => {
    axios.get('/content_management_system/3?output_format=JSON',config)
	.then(function(data){
		setTitle(data.data.content.meta_title[linkLangCode].value)
		setTermsConditions(data.data.content.content[linkLangCode].value)
	})
	.catch(function(err){
		console.log(err)
	})
	
	  
	}, [termsConditions])

	return (
		<div className={classes.TermsContainer}>
			<div className={classes.Heading}><span>{title}</span></div>
			<div className={classes.MainContent} dangerouslySetInnerHTML={{__html: termsConditions}}></div>
			{/*<div className={classes.Heading}><span>{t('TERMSANDCONDITIONS')}</span></div>
			<div className={classes.MainContent}>
				<p className={classes.SubHeading}><strong>{t('T_LINE_1')}</strong></p>
				{t('T_LINE_2').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_1')}</strong></p>
				{t('T_LINE_3').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_2')}</strong></p>
				{t('T_LINE_4').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_3')}</strong></p>
				{t('T_LINE_5').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_4')}</strong></p>
				{t('T_LINE_6').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_5')}</strong></p>
				{t('T_LINE_7').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_6')}</strong></p>
				{t('T_LINE_8').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_7')}</strong></p>
				{t('T_LINE_9').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_8')}</strong></p>
				{t('T_LINE_10').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_9')}</strong></p>
				{t('T_LINE_11').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_10')}</strong></p>
				{t('T_LINE_12').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_11')}</strong></p>
				{t('T_LINE_13').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_12')}</strong></p>
				{t('T_LINE_14').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_13')}</strong></p>
				{t('T_LINE_15').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_14')}</strong></p>
				{t('T_LINE_16').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_15')}</strong></p>
				{t('T_LINE_17').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_16')}</strong></p>
				{t('T_LINE_18').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
				<p className={classes.SubHeading}><strong>{t('T_HEAD_17')}</strong></p>
				{t('T_LINE_19').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})}
			</div>*/}
		</div>
	);
}

export default TermsConditions;