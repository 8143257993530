import React, {useRef} from "react"
import classes from './FullScreen.module.css'
import cross from '../../../src/assets/images/icons/cross.png'
import useOutsideAlerter from '../UseOutsideAlerter/UseOutsideAlerter'

const FullScreen=(props)=>{
    const editRef3 = useRef(null);
    const setEditF3 = (value) => {
        props.setOpen(false)
    }
    useOutsideAlerter(editRef3, setEditF3, false);
    return (
        <div className={classes.OuterContainer}>
            <div className={classes.InnerContainer}>
                <div className={classes.MainContainer}>
                <div className={classes.ArtImage} ref={editRef3}>
                    <img src={props.image} alt="Art" />            
                </div>
            <div className={classes.ArrowContainer} onClick={()=>props.setOpen(false)}>
            <img src={cross} alt="cross" />
            </div>
                </div>
            </div>
        </div>
    )
}
export default FullScreen;