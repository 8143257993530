import React, { useEffect, useState } from "react";
import classes from "./Events.module.css";
import rightBlack from "../../assets/images/icons/right-chevronBlack.png";
import ppbutton from "../../assets/images/icons/ppbutton.png";
import eTicket from "../../assets/images/icons/eticket_new.png";
import physicalTicket from "../../assets/images/icons/physical-ticket-2.png";
import mobileTicket from "../../assets/images/icons/mobile-ticket-icon-2.png";
import CustomSelect from "../UI/CustomSelect/CustomSelect";
import Waiting from "../../assets/images/icons/Layer-610.png";
import Tickets from "../../assets/images/icons/Layer-622.png";
import Calendar from "../../assets/images/icons/Layer-639.png";

import EventimLogo from "../../assets/images/icons/eventim-logo.png";
import TicketmasterLogo from "../../assets/images/icons/ticketmaster-logo-2.png";

import consoleLog from "../../log";
import { SELLFORM, EVENT_STRING } from "../../URLS";
import { Link } from "react-router-dom";
import customerDetails from "../UserData.js";

import { useTranslation } from "react-i18next";

const MyList = (props) => {
  const { t, i18n } = useTranslation();
  
  const callCart = () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const ct = url.searchParams.get("cart_ticket");
    if (ct) {
      if (document.getElementById(ct)) {
        document.getElementById(ct).click();
      }
    }
  };

  const sellPage = () => {
    if (customerDetails) {
      props.previousProps.history.push({
        pathname: SELLFORM,
        state: props.linkDetails.sellPageId,
      });
    } else {
      props.setMyListAlertBox(true);
    }
  };

  const allFromArtist = () => {
    window.location.href = EVENT_STRING + props.linkDetails.artistCategory;
  };
  const [listOpened, setListOpened] = useState(false);

  return (
    <div
      className={
        props.showFilter
          ? [classes.Events2Bottom, classes.Events2Div].join(" ")
          : classes.Events2Div
      }
    >
      {props.list.length ? (
        props.list.map((detail, index) => {
          return (
            <div
              className={
                customerDetails && customerDetails.data.id == detail.allDetails.id_customer ?
                  [classes.EventsList, classes.MobileEventList, classes.WhiteBorder].join(" ")
                : [classes.EventsList, classes.MobileEventList].join(" ")
              }
              key={index}
              id={"event_item_" + detail.allDetails.id}
            >
              <div
                className={customerDetails && customerDetails.data.id == detail.allDetails.id_customer ? [classes.EventsList5, classes.WhiteBackground].join(' ') : classes.EventsList5}
                onClick={() => props.buyTicket(detail, detail.index, true)}
              >
                <div>
                  <img
                    src={
                      detail.ticketType === "e-ticket" ?
                        eTicket
                      : detail.ticketType === "eventim" || detail.ticketType === "ticketmaster" ?
                        mobileTicket
                      : physicalTicket
                    }
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className={classes.EventsList1}>
                  <div className={classes.EventsList2}>
                    <p className={classes.FilterTitle}>
                      {detail.name}&nbsp;
                      <img
                        className={classes.soldByIcon}
                        src={ppbutton}
                        alt=""
                      />
                    </p>
                    <div className={classes.RowSeats}>
                      {detail.allDetails.row_ad !== "" ? (
                        <p className={classes.FilterTitle}>
                          {t("Row")} {detail.allDetails.row_ad}
                        </p>
                      ) : null}
                      {detail.allDetails.seats !== "" ? (
                        <p className={classes.FilterTitle}>
                          {t("Seats")} {detail.allDetails.seats}
                        </p>
                      ) : null}
                    </div>
                    {detail.allDetails.details !== "" ||
                    detail.allDetails.together ? (
                      <div className={classes.CheckDetails}>
                        <div>
                          {/* {detail.allDetails.details.includes("0") ? (
                            <div>- {t("Limited_View")}</div>
                          ) : null} */}
                          {detail.allDetails.together == "1" ? 
                            <div className={classes.AdjacentSeats}>{t("Adjacent_seats")}</div>
                          : null}
                        </div>
                        <div>
                          {
                            detail.shipping_methods_available ?
                              detail.shipping_methods_available.split(",").map(function(method){
                                return <div className={classes.ShippingMethodTitle}>- {t("shipping_"+method+"_title")}
                                  { method == "3" ? <div className={classes.Help}>
                                    <div>?</div>
                                    <span className={classes.Tooltip}>{t('shipping_method_3_help_text')}</span>
                                  </div> : null }
                                </div>
                              })
                            : null
                          }
                          {/* {detail.allDetails.details.includes("1") ? (
                            <div>- {t("Side_View")}</div>
                          ) : null} */}
                          {/* {detail.allDetails.details.includes("2") ? (
                            <div>- {t("Behind_Stage_View")}</div>
                          ) : null} */}
                        </div>
                      </div>
                    ) : null}
                    <p className={classes.ShippingETA}>
                      { detail.ticketType === "e-ticket" ? (
                        <div>E-Ticket</div>
                      ) :
                      detail.ticketType === "eventim" ? (
                        <div>{t('mobile_ticket')} <img src={EventimLogo} /></div>
                      ) :
                      detail.ticketType === "ticketmaster" ? (
                        <div>{t('mobile_ticket')} <img src={TicketmasterLogo} />
                        <div className={classes.TicketmasterDisclaimer}>{t('ticketmaster_disclaimer')}</div>
                        </div>
                      ) : null
                      }
                    </p>
                  </div>
                  <div className={classes.EventsList3}>
                    <p className={classes.Price}>
                      {" "}
                      {detail.price.replace(".", ",")}
                    </p>
                    <p className={classes.SmallFont}>{t("per_seat")}</p>
                    <div className={classes.QuantityButton}>
                      <CustomSelect
                        optionsList={props.quantityDisplay[detail.index]}
                        changed={(q) =>
                          props.changeQuantity(
                            detail.index,
                            q,
                            detail.maxQuantity,
                            detail.allDetails.split,
                            ""
                          )
                        }
                        styling={{
                          container:
                            window.screen.width > 500
                              ? {
                                  width: "150px",
                                  borderRadius: "20px",
                                  margin: "auto",
                                }
                              : {
                                  width: "auto",
                                  borderRadius: "20px",
                                  margin: "auto",
                                },
                          defaultText: {
                            background: "var(--blue)",
                            padding: "5px 20px",
                            color: "var(--black)",
                            fontFamily: "GothamRounded-Medium",
                            borderRadius:
                              listOpened === index ? "15px 15px 0 0" : "30px",
                          },
                          ul: {},
                          li: {
                            background: "var(--white)",
                            color: "var(--black)",
                            border: "1px solid var(--blue)",
                            padding: "5px 20px",
                          },
                        }}
                        defaultText={
                          props.quantityDisplay[detail.index][0].name
                        }
                        listOpened={(item) => setListOpened(item)}
                        listOpenIndex={index}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={[
                    classes.QuantityButton,
                    classes.MobileQuantityButton,
                  ].join(" ")}
                >
                  <CustomSelect
                    optionsList={props.quantityDisplay[detail.index]}
                    changed={(q) =>
                      props.changeQuantity(
                        detail.index,
                        q,
                        detail.maxQuantity,
                        detail.allDetails.split,
                        ""
                      )
                    }
                    styling={{
                      container: {
                        width: "150px",
                        borderRadius: "20px",
                        margin: "auto",
                      },
                      defaultText: {
                        background: "var(--blue)",
                        padding: "5px 20px",
                        color: "var(--black)",
                        fontFamily: "GothamRounded-Medium",
                        borderRadius:
                          listOpened === index ? "15px 15px 0 0" : "30px",
                      },
                      ul: {},
                      li: {
                        background: "var(--white)",
                        color: "var(--black)",
                        border: "1px solid var(--blue)",
                        padding: "5px 20px",
                      },
                    }}
                    defaultText={props.quantityDisplay[detail.index][0].name}
                    listOpened={(item) => setListOpened(item)}
                    listOpenIndex={index}
                  />
                </div>
                <div className={classes.LowerBorder}>
                  {detail.allDetails.other_details
                    ? detail.allDetails.customer_name.trimStart().split(" ")[0] +
                      " : " +
                      detail.allDetails.other_details
                    : t("Seller") +
                      " : " +
                      detail.allDetails.customer_name.trimStart().split(" ")[0]}
                </div>
              </div>
              <div
                className={customerDetails && customerDetails.data.id == detail.allDetails.id_customer ? [classes.EventsList4, classes.WhiteBackground].join(' ') : classes.EventsList4}
                onClick={() => props.buyTicket(detail, detail.index, true)}
                id={detail.allDetails.id}
              >
                <img src={rightBlack} alt="" />
              </div>
            </div>
          );
        })
      ) : (
        <div className={classes.NotAvailable}>
          {props.filterApplied ? (
            t("your_selected_filter_has_no_tickets")
          ) : (
            <div className={classes.ActionsOnNoEvents}>
              <div className={classes.NoEventsContainer}>
                <div onClick={props.waitlistBox}>
                  <img src={Waiting} />
                  {t("register_in_waiting_list")}
                </div>
                <div onClick={sellPage}>
                  <img src={Tickets} />
                  {t("sell_your_tickets_text")}
                </div>
                <div onClick={allFromArtist} className={classes.HideOnMobile}>
                  <img src={Calendar} />
                  {t("Explore_all_concerts")} {props.categoryName}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MyList;
