import React from 'react';
import classes from './BuyTickets.module.css';

import VerifiedIcon from '../../../assets/images/icons/verified.png';
import UserIcon from '../../../assets/images/icons/sellerprofile.png';
import LeftChevron from '../../../assets/images/icons/left-chevron.png';
import ppbutton from '../../../assets/images/icons/ppbutton.png';

import { useTranslation } from 'react-i18next';
import {TERMS, PRIVACYPOLICY} from '../../../URLS';

import LoadingIcon from '../../../assets/images/icons/loadingicon.png';
import customerDetails from '../../UserData';
import TicketInsideBox from '../../../assets/images/icons/ticket-inside-box.png';
import consoleLog from '../../../log';

const BuyTickets = (props) => {
	consoleLog(props, "BuyT")
	const ticketDetails = props.ticketDetails;

	const { t, i18n } = useTranslation();

    const openLoginBox = () => {
    	props.backClick(false);
    	if(window.screen.width > 1100){
	    	document.getElementById("loginBtnId").click();
	    }else{
	    	document.getElementById("loginBtnMobileId").click();
	    }
    }

	return (
		<div className={classes.BuyTickets}>
			<div className={classes.Container}>
				<div className={classes.Title}>
					<img src={LeftChevron} alt="Left Chevron" onClick={()=>props.backClick(false)}/>
					{t('Purchase_Ticket(s)')}
				</div>
				{ props.loading ?
					<div className={classes.LoadingIcon}>
						<div className={classes.Heading}>Please Wait</div>
						<div className={classes.SubHeading}>{t('Checking_the_availability_of_your_ticket(s)')}</div>
						<img src={LoadingIcon} alt="" />
					</div>
				:
				<React.Fragment>
					<div className={classes.TicketOwner}>
						<div className={classes.OwnerImage}>
							<img src={UserIcon} alt="user icon" />
						</div>
						<div className={classes.OwnerInfo}>
							<div>{/*t('Sold_by')*/}</div>
							<div>{/*First Name Last Name of Seller*/}{t(ticketDetails.soldBy)} &nbsp;<img src={ppbutton} alt="verified icon" /></div>
						</div>
					</div>
					<div className={classes.Ticket}
						style={{ background: "url("+TicketInsideBox+")",
										backgroundSize: "120px",
										backgroundRepeat: "no-repeat",
									    backgroundPosition: "right 10px" }
							  }>
						<div className={classes.Title}>{
							ticketDetails.ticketType==="hard-ticket" ?
								t('HARDCOPY_TICKET')
							: ticketDetails.ticketType==="e-ticket" ?
								t('e-tickets')
							: ticketDetails.ticketType==="eventim" ?
								t('mobile_ticket_eventim')
							: ticketDetails.ticketType==="ticketmaster" ?
								t('mobile_ticket_ticketmaster')
							: "tickets"
						}
						</div>
						<div className={classes.TicketInfo}>
							<div className={classes.EventName}>{props.eventDetails.name[0].value}</div>
							<div className={classes.EventLocation}>{props.eventDetails.location}</div>
							<div className={classes.EventLocation}>{props.eventDetails.location_city}</div>
						</div>
						<div className={classes.TicketTimings}>
							<div className={classes.DateTime}>
								{props.eventDetails.event_date.replaceAll('.','-').split(' ')[0]} | {props.eventDetails.event_date.split(' ')[1]}
							</div>
							<div>{ticketDetails.name}</div>
						</div>
						{ ticketDetails.ticketType==="hard-ticket" && ticketDetails.ticket_image != "" ?
								<>
								<div className={classes.TicketImage}>
									<img src={process.env.REACT_APP_DOMAIN+ticketDetails.ticket_image} alt="" />
								</div>
								<div className={classes.TicketImageDisclaimer}>{t('picture_made_by_seller')}</div>
								</>
						: null}
						<div className={classes.Quantity}>
							<div className={classes.Number}>
								{ticketDetails.quantity}
								{ticketDetails.quantity=="1" ?
									<div>Ticket</div>
									:<div>Tickets</div>
								}
							</div>
						</div>
					</div>
					<div className={classes.MobileTicket}>
						<div className={classes.Title}>
							<div>{ticketDetails.quantity}X {ticketDetails.ticketType==="hard-ticket" ? t('HARDCOPY_TICKET') : t('e-tickets')}</div>
						</div>
						<div className={classes.TicketInfo}>
							<div className={classes.EventName}>{props.eventDetails.name[0].value}</div>
							<div className={classes.EventLocation}>{props.eventDetails.location}</div>|
							<div className={classes.EventLocation}>{props.eventDetails.location_city}</div>
						</div>
						<div className={classes.TicketTimings}>
							<div className={classes.DateTime}>
								{props.eventDetails.event_date.replaceAll('.','-').split(' ')[0]} | {props.eventDetails.event_date.split(' ')[1]} |
							</div>
							<div>{ticketDetails.name}</div>
						</div>
						{ ticketDetails.ticketType==="hard-ticket" && ticketDetails.ticket_image != "" ?
								<>
								<div className={classes.TicketImage}>
									<img src={process.env.REACT_APP_DOMAIN+ticketDetails.ticket_image} alt="" />
								</div>
								<div className={classes.TicketImageDisclaimer}>{t('picture_made_by_seller')}</div>
								</>
						: null }
					</div>
					<div className={classes.TicketPrice}>
						<div className={classes.PriceText}>
							{t('Total_Price')}
							<div>{t('(incl._VAT._excl._Shipment)')}</div>
						</div>
						<div className={classes.Price}>
							€&nbsp;
							{
								ticketDetails.price ?
								(parseFloat(ticketDetails.price.split(' ')[1])*parseInt(ticketDetails.quantity)).toFixed(2).toString().replace('.',',')
								: null
							}
						</div>
					</div>
					<div className={classes.BuyButton}>
						{/*<div className={[classes.MobileShipperInfo, classes.ShipperInfo].join(' ')}>
							{t('This_order_is_shipped_by_Dutch_Post')}
						</div>*/}
						{ customerDetails && customerDetails.data.id ?
							<button onClick={()=>props.callPayment(ticketDetails)}>{t('Proceed_to_Pay')}</button>
							: <><span className={classes.LoginBeforeProceed}>{t('Please_Login_to_proceed')}</span>
							<div className={classes.LoginBeforeProceedButton}>
								<button onClick={openLoginBox}>{t('login_buy')}</button>
							</div>
							</>
						}
						{/*<div className={classes.ShipperInfo}>
							{t('This_order_is_shipped_by_Dutch_Post')}
						</div>*/}
					</div>
					<div className={classes.Agreement}>
						{t('by_continuing_you_agree')} <a href={TERMS}>{t('terms_of_use')}</a> & <a href={PRIVACYPOLICY}>{t('privacy_policy')}</a> {t('by_continuing_you_agree_2')}.
					</div>
				</React.Fragment>}
			</div>
		</div>
	);
}

export default BuyTickets;