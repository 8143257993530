import React, {useState, useEffect, useRef} from "react";
import classes from './CustomSelect.module.css';

import useOutsideAlerter from '../../UseOutsideAlerter/UseOutsideAlerter';

import consoleLog from '../../../log';

const CustomSelect = (props) => {

  const [defaultSelectText, setDefaultSelectText] = useState(props.defaultText);
  const [showOptionList, setShowOptionList] = useState(false);
  const optionsList = props.optionsList;

  let editRef = useRef(null);
  const setEditF = (value, event) => {
    if (value) {
        setShowOptionList(value)
        if(props.listOpened){
          props.listOpened(false)
        }
    }
    else {
        setTimeout(() => {
          if(props.listOpened){
            if(!event.target.className.includes("selectedText")){
              props.listOpened(false)
            }
          }
          setShowOptionList(value)
        }, 200)
    }
  }
  useOutsideAlerter(editRef, setEditF, false);
  /*useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        setDefaultSelectText(props.defaultText);
  },[])*/

/*  componentWillUnmount() {
    // Remove the event listner on component unmounting
    document.removeEventListener("mousedown", this.handleClickOutside);
  }*/

  // This method handles the click that happens outside the
  // select text and list area
/*  const handleClickOutside = e => {
    if (
      !e.target.classList.contains(classes.customSelectOption) &&
      !e.target.classList.contains(classes.selectedText)
    ) {
      setShowOptionList(false)
    }
  }*/
  useEffect(()=>{
    setDefaultSelectText(props.defaultText)
  },[props.defaultText])
  // This method handles the display of option list
  const handleListDisplay = () => {
    let status = showOptionList;
    setShowOptionList(!status);
    if(status && props.listOpened){
      props.listOpened(false)
    }else if(props.listOpened){
      props.listOpened(props.listOpenIndex)
    }
  }

  // This method handles the setting of name in select text area
  // and list display on selection
  const handleOptionClick = (e) => {
    consoleLog(e)
    setDefaultSelectText(e.target.getAttribute("data-name"))
    setShowOptionList(false);
    if(props.changed){
      props.changed(e.target.getAttribute("data-value"))
    }
    if(props.listOpened){
      props.listOpened(false)
    }
  }

  return (
    <div className={classes.customSelectContainer} style={props.styling.container}>
      <div
        className={showOptionList ?
                      props.darkBackground ? [classes.selectedText,classes.Active,classes.DarkBackground].join(' ') : [classes.selectedText,classes.Active].join(' ')
                  :   props.darkBackground ? [classes.selectedText,classes.DarkBackground].join(' ') : classes.selectedText}
        onClick={handleListDisplay} style={props.styling.defaultText}
      >
        {defaultSelectText}
      </div>
      {showOptionList && (
        <ul className={classes.selectOptions} ref={editRef} style={props.styling.ul}>
          {optionsList.map((option, index) => {
            return (
              <li
                className={ props.listOpened && index === optionsList.length - 1 ? [classes.customSelectOption, classes.RoundedList].join(' ') : classes.customSelectOption }
                style={props.styling.li}
                data-name={option.name}
                data-value={option.value}
                key={option.id}
                onClick={(e) => handleOptionClick(e)}
              >
                {option.name}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default CustomSelect;