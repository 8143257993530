import React, {useState, useEffect, useRef} from 'react';
import classes from './DownloadTickets.module.css';

import { useTranslation } from 'react-i18next';

import downloadIcon from '../../assets/images/icons/download_1.png'
import leftIcon from '../../assets/images/icons/left-arrow.png'
import SearchPlusIcon from '../../assets/images/icons/add-searching.png'

import LogoLoader from '../UI/LogoLoader/LogoLoader';

import customerDetails, {storage} from '../UserData';
import axios, {config, token_config} from '../../axiosBase';
import {checkForLogin} from '../PrivateRoute/PrivateRoute';

import {HOME} from '../../URLS';
import consoleLog from '../../log';

const DownloadTickets = () => {


    const { t, i18n } = useTranslation();
    const [showContactBox, setShowContactBox] = useState(false);
    const [ticketDetails, setTicketDetails] = useState(false)
    
    const referrer = document.referrer != "" ? document.referrer : false;
    consoleLog(referrer, "REF")
    const [showLoader, setShowLoader] = useState(false)
    const [ticketImageData, setTicketImageData] = useState("");

    const downloadURI = (uri, name) => {
        var blob = new Blob([uri], { type: 'application/pdf' });
        var URL = window.URL || window.webkitURL;
        var downloadUrl = URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.download = name;
        link.href = downloadUrl;
        document.body.append(link);
        link.click();
    }

    const downloadEticket = (tickets, id, reference) => {

        consoleLog(tickets, id, reference)
        checkForLogin().then(function(token){
        if(token){

        const config = token_config(token)
        config["responseType"] = "arraybuffer";

        tickets.split(',').map(function(number){
        const payload = {
            "id":id,
            "order_reference": reference,
            "num": number
        }
        consoleLog(payload)

        axios.post('/listing?a=pdf_download', payload, config)
        .then(function (data) {
            consoleLog(data, "PDF")
            downloadURI(data.data, "ticket"+number+".pdf")
        })
        .catch(function(err){
            consoleLog(err)
        })

        })

        }})
    }

    const getImage = (id, ticketNumber) => {

        checkForLogin().then(function(token){
        if(token){

        let payload = {
            id: id,
            num: ticketNumber
        }
        const config = token_config(token);

        setShowLoader(true)
        axios.post('/listing?a=pdf_image', payload, config)
            .then(function(data){
                setShowLoader(false)
                consoleLog(data)
                if(data.data.image){
                    setTicketImageData(data.data.image.replaceAll('\/','/'))
                }
            })
            .catch(function(err){
                consoleLog(err)
            })

        }})
    }

    const openLoginBox = () => {
        if(window.screen.width > 1100){
            document.getElementById("loginBtnId").click();
        }else{
            document.getElementById("loginBtnMobileId").click();
        }
    }


    useEffect(() => {
        let attr_id = "";
        let reference_num = "";
        
        try{
            let url = window.location.pathname;
            attr_id = url.split('-')[2];
            reference_num = url.split('-')[3];
        }catch{
            consoleLog("Invalid URL")
        }

        checkForLogin().then(function(token){
        if(token){
            axios.get('/orders?output_format=JSON&display=full&filter[id_customer]=['+customerDetails.data.id+']', config)
                .then(function (data) {
                	consoleLog(data)
                	if(!data.data.orders){
                		return;
                	}
                	const tempList = [];
                	let current_state = 0;
                	for(let i=0; i<data.data.orders.length; i++){
                		if(!data.data.orders[i].associations){
                			continue;
                		}else{
                            let order = data.data.orders[i]
                            if(order.reference == reference_num && order.associations.order_rows[0].product_attribute_id == attr_id){
                                setTicketDetails(order)
                                break;
                            }
                        }
                	}
                })
        }else{
            openLoginBox();            
        }
        })
    }, [])

    const goBack = () => {
        window.location.href = referrer;
    }


    return (
            <>
                {
                ticketDetails && <div className={classes.Container}>
                    <div className={classes.NTicketCont}>
                        <div className={classes.NticketHead}>
                            <div>
                                <p className={classes.NticketHeadp}>{ticketDetails.associations.order_rows[0].product_name}</p>
                                <p>{ticketDetails.associations.order_rows[0].location}</p>
                            </div>
                            <div>
                                <p className={classes.NticketHeadp}>{t("Available_Etickets")} {ticketDetails.associations.order_rows[0].product_quantity}</p>
                                { referrer ? <button onClick={goBack} className='myButton'>{t("back")}</button> : null }
                            </div>
                        </div>
                        <div className={classes.NticketHeadMedia}>
                            <p className={classes.NHeadp_1}>{t('Viewing_etickets')}</p>
                            <div className={classes.MHr}></div>
                            <div className={classes.NMedia2}>
                                <div>
                                    <p className={classes.NticketHeadp1}>{ticketDetails.associations.order_rows[0].product_name}</p>
                                    <p className={classes.NLocation}>{ticketDetails.associations.order_rows[0].location}</p>
                                </div>
                                { referrer ? 
                                <div className={classes.BackButton} onClick={goBack}>
                                    <p className={classes.NLocation}> <img src={leftIcon} width="15px" /> </p>
                                    <span className={classes.NLocation}> {t('back')} </span>
                                </div>
                                : null }
                            </div>
                        </div>
                        { ticketImageData !== "" ?
                        <div className={classes.TicketDisplay}>
                            <img src={ticketImageData} alt="" className={classes.TicketImage} />
                            <button className="myButton" onClick={()=>setTicketImageData("")}>{t('close')}</button>
                        </div> :
                        ticketDetails.associations.order_rows[0].ticketnumbers !== "" ? ticketDetails.associations.order_rows[0].ticketnumbers.split(",").map(function(data, index){
                            return <div className={classes.NCont}>
                                <div className={classes.NticketList}>
                                    <div className={classes.NticketListDiv1}><p>Ticket {index+1}</p><p>{t("Available")}</p></div>
                                </div>
                                <div className={classes.NCont2}>
                                    <button className={classes.DownloadHover} onClick={
                                                    ()=>downloadEticket(
                                                        data,
                                                        ticketDetails.associations.order_rows[0].product_attribute_id,
                                                        ticketDetails.reference
                                                    )}><img src={downloadIcon} alt="" /></button>
                                    <span className={classes.DownloadLabel}>{t("Download")}</span>
                                </div>
                            </div>
                        }) : null}
                    </div>
                </div>
                }
                { showLoader ? <LogoLoader /> : null }
            </>
            );
}

export default DownloadTickets;