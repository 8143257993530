import React, { useState } from 'react';
import Search from '../Search/Search';
import classes from './Jumbotron.module.css';
import logo from '../../assets/images/logo/kaufmeinticket_logo.png';
//import downArrow from '../../assets/images/icons/down_arrow.png';

import { useTranslation } from 'react-i18next';

const Jumbotron = () => {

	const { t, i18n } = useTranslation();

	const [simpleSearch, setSimpleSearch] = useState(true);	// For toggling between simple and advanced search
	// Advanced search will be removed once finalized


	// Toggle between simple and advanced search
	const searchTypeToggle = () => {
		setSimpleSearch(!simpleSearch);
	}
	//const scrollNextRef = useRef();

	/*const smoothScroll = (h) => {
		let i = h || 0;
		if (i < scrollNextRef.current.offsetTop) {
			setTimeout(() => {
				window.scrollTo(0, i);
				smoothScroll(i + 10);
			}, 10);
		}
	}*/


	return (
		<div className={classes.Jumbotron}>
			<div className={classes.Jumbotron2}>
				<div className={classes.Jumbotron3}>
					<img className={classes.Logo} src={logo} alt="" />
					<div className={classes.FlipContent}>
						<div>
							<ul className={classes.flip2}>
								<li>{t('fair_secure_transparent')}</li>
								<li>{t('fan_to_fan_ticket')}</li>
								<li>{t('fair_secure_transparent')}</li>
								<li>{t('fan_to_fan_ticket')}</li>
							</ul>
						</div>
					</div>
					<Search
						jumbotron={true} />
				</div>
			</div>
			{/* <div className={classes.BrowseEvents} ref={scrollNextRef} onClick={() => smoothScroll()}>
				<div className={classes.BrowseEventsContainer}>
					<p>	{t('browse_all_events')}</p>
					<img className={classes.BrowseEventsIcon} src={downArrow} alt="" />
				
				</div>
			</div> */}
		</div>
	);
}

export default Jumbotron;