import React, { useState, useRef, useEffect } from 'react';
import editIcon from '../../../assets/images/icons/edit-icon-edge.png'
import classes from './PersonalInfo.module.css'

import { useTranslation } from 'react-i18next';
import Block from '../Block/Block';
import Save from '../SaveAlert/Save';
import useOutsideAlerter from '../../UseOutsideAlerter/UseOutsideAlerter';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';
import { AddressesPutData, AddressesPostData, PaymentData } from '../XMLData/XMLData';
import axios, { config } from '../../../axiosBase';
import CustomSelect from '../../UI/CustomSelect/CustomSelect';
import LogoLoader from '../../UI/LogoLoader/LogoLoader';

import customerDetails, {storage} from '../../UserData';
import Countries from '../../JsonData/Countries.json';
import CountriesByIndex from '../../JsonData/CountriesByIndex.json';

import {checkForLogin} from '../../PrivateRoute/PrivateRoute';

import {LANG_ENGLISH} from '../../../URLS';

import {EmailPattern, NamePattern, NumberPattern, PostalCodePattern, GeneralTextPattern} from '../../../Formats';

import consoleLog from '../../../log';

function PersonalInfo(props) {

    const { t, i18n } = useTranslation();

    const [showPaymentMethod, setShowPaymentMethod] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState("IBAN")
    const [userData, setUserData] = useState({})
    const [userCustomerData, setUserCustomerData] = useState({})
    const [countriesData, setCountriesData] = useState([])

    const [countryCodes, setCountryCodes] = useState([])
    const germanyPayload = CountriesByIndex.countries["1"];
    const [countryLangIndex, setCountryLangIndex] = useState(localStorage.getItem("i18nextLng") === LANG_ENGLISH ? 2 : 1);

    const [defaultCountry, setDefaultCountry] = useState("")

    const defaultCountryText = germanyPayload.name[countryLangIndex].value
    const [chosenCountry, setChosenCountry] = useState(germanyPayload.id)
    const [chosenCountryCode, setChosenCountryCode] = useState("+"+germanyPayload.call_prefix)

    const [countriesOptionList, setCountriesOptionList] = useState([])
    const [statesData, setStatesData] = useState([])
    const [showSave, setShowSave] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [allFormErrors, setAllFormErrors] = useState({})
    const [ErrorSubmit, setErrorSubmit] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    const [iban_value, setIBANValue] = useState("");
    const [paypal_email_value, setPaypalEmailValue] = useState("");
    const [hideCancelButton, setHideCancelButton] = useState(true);
    const [listOpened, setListOpened] = useState(false);
    //    const [langName, setLangName] = useState(localStorage.getItem("i18nextLng") || 'de')
    var editRef = useRef(null);
    const setEditF = (value) => {
        if (value) {
            setShowSave(value)
        }
        else {

            setTimeout(() => {
                setShowSave(value)
            }, 200)
        }
    }

    const [edit, setEdit] = useState(false);
    useOutsideAlerter(editRef, setEditF, false);
    consoleLog("userData",userData)
    const proceedToPayment = (userData) => {
        if(props.onEditChange){
            if(Object.keys(userData).length){
                if(userData.data.addresses[0].phone_mobile != "" && userData.data.addresses[0].id_country != ""){
                    props.onEditChange(true)
                }
            }
        }
    }

    const handleEdit = () => {
        setEdit(true);
        if(props.onEditChange){
            props.onEditChange(false);
        }
    }

    const [fields, setFields] = useState([
        { name: 'First_Name', value: '' },
        { name: 'Last_Name', value: '' },
        { name: 'Company', value: '' },
        { name: 'Street_Name', value: '' },
        { name: 'House_Number', value: '' },
        { name: 'Addition', value: '' },
        { name: 'Postal_Code', value: '' },
        { name: 'City', value: '' },
        { name: 'Phone_Number', value: '' }
    ])

    function getFilteredErrors(errors) {
        let filteredErrors = {};

        Object.entries(errors).forEach(([key, value]) => {
            if (value !== '') {
                filteredErrors[key] = value
            }
        })
        return filteredErrors;
    }


    const validateField = (fieldName, fieldValue) => {
        consoleLog(allFormErrors, "Form Errors")
        let validationError = allFormErrors
        if(fieldName === "Company" || fieldName === "Addition"){
            return true;
        }
        if (fieldValue === "") {
            validationError[t(fieldName)] = t('Please_enter_a_valid') + t(fieldName)
            setFormErrors(validationError)
        }
        else {
            validationError[t(fieldName)] = ''
            setFormErrors(validationError)
        }
/*        if(chosenCountry === 0 && defaultCountry === defaultCountryText){
            validationError[t("Country")] = t(defaultCountryText)
        }else{
            validationError[t("Country")] = ''
        }*/
        
        switch (fieldName) {
            case 'First_Name':
                var fNameValid = fieldValue.match(NamePattern)
                validationError[t(fieldName)] = fNameValid ? '' : t('Please_enter_a_valid') + t("first_name_in")
                break;
            case 'Last_Name':
                var lNameValid = fieldValue.match(NamePattern)
                validationError[t(fieldName)] = lNameValid ? '' : t('Please_enter_a_valid') + t("last_name_in")
                break;
            case 'Phone_Number':
                var p_number = fieldValue.match(NumberPattern)
                validationError[t(fieldName)] = p_number ? '' : t('Please_enter_a_valid') + t("phone_number")
                break;
            case 'Postal_Code':
                var postcode = chosenCountry != "" ? fieldValue.match(PostalCodePattern(CountriesByIndex.countries[chosenCountry].zip_code_format)) : false
                validationError[t(fieldName)] = postcode ? '' : t('Please_enter_a_valid') + t("postal_code_in")
                break;
            case 'City':
                validationError[t(fieldName)] = fieldValue != "" ? '' : t('Please_enter_a_valid') + t("city_in")
                break;
            case 'Street_Name':
                validationError[t(fieldName)] = fieldValue != "" ? '' : t('Please_enter_a_valid_') + t("street_name_in")
                break;
            case 'House_Number':
                validationError[t(fieldName)] = fieldValue != "" ? '' : t('Please_enter_a_valid') + t("house_number_in")
                break;
        }
        setAllFormErrors(validationError)
        setFormErrors(getFilteredErrors(validationError))
    }

    /*const askPayment = (method) => {
        if (method) {
            setPaymentMethod(method)
        }
    }*/
    useEffect(() => {
        let validationError = allFormErrors
        validationError[t('Postal_Code')] = ''
        setFormErrors(getFilteredErrors(validationError))
    },[chosenCountry])


    useEffect(() => {
        axios.get('/addresses/?output_format=JSON&filter[id_customer]=['+customerDetails.data.id+']&filter[deleted]=[0]&display=full', config)
            .then(function (data) {
                consoleLog(data,"ADDRESSESE")
                if(!(data.data instanceof Array) && Object.keys(data.data).length){
                    consoleLog(data,"add")
                    setUserData(data)
                    setCountriesData(Countries.countries)
                    proceedToPayment(data);
                    setFieldsDefault(data)

                    if(props.addPaymentOption){
                        sessionStorage.setItem("address",data.data.addresses[0].id)
                        sessionStorage.setItem("address_country",data.data.addresses[0].id_country)
                    }
                }else{
                    setUserData({})
                    setEdit(true)
                    if(!props.addPaymentOption){
                        setShowPaymentMethod(true)
                    }
                    setCountriesData(Countries.countries)
                }
            })
    }, [Object.keys(userData).length, showSave])


    useEffect(() => {
        axios.get('/customers/'+customerDetails.data.id+'?output_format=JSON', config)
            .then(function (data) {
                consoleLog(data,"cus")
                setUserCustomerData(data)
                const iban = data.data.customer.iban;
                const paypal_email = data.data.customer.paypal_email;
                consoleLog("I AM RUNNING", iban, paypal_email)
                if(iban){
                    setIBANValue(iban)
                }else{
                    setIBANValue('')
                }
                if(paypal_email){
                    setPaypalEmailValue(paypal_email)
                }else{
                    setPaypalEmailValue('')
                }

                if((paypal_email && paypal_email !== "") || (iban && iban !== "")){
                    setHideCancelButton(false)
                }
                if(paypal_email && paypal_email != ""){
                    consoleLog("PAYPAL selected")
                    setPaymentMethod("PAYPAL")
                }else if(iban && iban != ""){
                    consoleLog("IBAN selected")
                    setPaymentMethod("IBAN")
                }
            })
    }, [Object.keys(userCustomerData).length, showSave])

    function fromSubmitCheck() {

        var result = false
        Object.entries(allFormErrors).forEach(([key, value]) => {
            if (value !== '') {
                result = true;
            }
        })
        return result;
    }

    const submitUserData = (e) => {
        e.preventDefault();
        consoleLog(fields, "FIELDERS")
        fields.map(function(field){
            validateField(field.name, field.value)
        })

        var myBool = fromSubmitCheck()

        if (myBool) {
            setErrorSubmit(true)
        }
        else {
            setEdit(false);

            let userDataFields = [...fields];
            let numberWithoutCode = userDataFields[8].value;
            let phonenumber = chosenCountryCode +"-"+ numberWithoutCode;

            userDataFields[8].value = phonenumber;

            userDataFields.push({ 'country': chosenCountry })
            config['headers']['Content-Type'] = 'text/xml';
            if(Object.keys(userData).length){

                const payload = AddressesPutData(userData, userDataFields);

                consoleLog(payload, "PAYLOAD PI")
                checkForLogin().then(function(token){
                if(token){

                setShowLoader(true)
                axios.put('/addresses', payload, config)
                    .then(function (data) {
                        setShowLoader(false);
                        setShowSave(true);
                        proceedToPayment(data);
                    })
                    .catch(function (err) {
                        setShowLoader(false);
                        userDataFields[8].value = numberWithoutCode;
                    });
                }})
            }else{
                const payload = AddressesPostData(customerDetails.data.id, userDataFields);

                consoleLog(payload,"POSTING")
                checkForLogin().then(function(token){
                if(token){

                setShowLoader(true)
                axios.post('/addresses', payload, config)
                    .then(function (data) {
                        consoleLog(data)
                        setShowLoader(false);
                        setShowSave(true);
                        proceedToPayment(data);
                    })
                    .catch(function (err) {
                        setShowLoader(false);
                        userDataFields[8].value = numberWithoutCode;
                    });
                }})
            }
        }
    }

    const getPhoneNumber = (data) => {
        if(data.split('-')[1]){
            setChosenCountryCode(data.split('-')[0])
            return data.split('-')[1]
        }else{
            return data
        }
    }

    const setFieldsDefault = (data) => {
        if (Object.keys(data).length) {
            setFields([
                { name: 'First_Name', value: data.data.addresses[0].firstname },
                { name: 'Last_Name', value: data.data.addresses[0].lastname },
                { name: 'Company', value: data.data.addresses[0].company },
                { name: 'Street_Name', value: data.data.addresses[0].address1 },
                { name: 'House_Number', value: data.data.addresses[0].address2 },
                { name: 'Addition', value: data.data.addresses[0].address3 },
                { name: 'Postal_Code', value: data.data.addresses[0].postcode },
                { name: 'City', value: data.data.addresses[0].city },
                { name: 'Phone_Number', value: getPhoneNumber(data.data.addresses[0].phone_mobile) }
            ])
        }
        setFormErrors({})
    }

    const changeInputValue = (e) => {

        validateField(e.target.name, e.target.value)

        let oldList = [...fields];
        consoleLog(e.target.name)
        for (let i = 0; i < oldList.length; i++) {
            if (oldList[i].name === e.target.name) {
                if(GeneralTextPattern.test(e.target.value)){
                    oldList[i].value = e.target.value;
                    setFields(oldList);
                    break;                    
                }
            }
        }
    }

    const handleCancel = () => {
        setEdit(false);
        setFieldsDefault(userData);
        proceedToPayment(userData);
    }

    const submitPaymentMethod = (e) => {
        e.preventDefault();
        setFieldsDefault(userData)
        let userDataFields = [...fields];
        let method = '';
        let IBAN_num = '';
        let PAYPAL_email = '';
        let PAYPAL_verified = 0;
        if (paymentMethod === "PAYPAL" && e.target.PAYPAL && e.target.PAYPAL.value !== "") {
            method = 2;
            PAYPAL_verified = 1;
            PAYPAL_email = e.target.PAYPAL.value;
            var pattern = EmailPattern;
            if (!pattern.test(PAYPAL_email)){
                setFormErrors({"": t("Please_enter_a_valid")+" "+t("E-mail_ID_in")})
                setErrorSubmit(true)
                return false;
            }
        }
        else if (paymentMethod === "IBAN" && e.target.IBAN && e.target.IBAN.value !== "") {
            method = 1;
            IBAN_num = e.target.IBAN.value;
            var pattern = GeneralTextPattern;
            if(!pattern.test(IBAN_num)){
                setFormErrors({"": t("Please_enter_a_valid")+" "+t("IBAN")})
                setErrorSubmit(true)
                return false;
            }
        }
        else {
            setFormErrors({"": t("add_a_payment_method")})
            setErrorSubmit(true)
            return false;
        }

        let paymentInfo = {
            method: method,
            IBAN_num: IBAN_num,
            PAYPAL_email: PAYPAL_email,
            PAYPAL_verified: PAYPAL_verified
        }
        const payload = PaymentData(userCustomerData, userDataFields, paymentInfo);
        config['headers']['Content-Type'] = 'text/xml';

        consoleLog(payload)
        setShowLoader(true)
        axios.put('/customers', payload, config)
            .then(function (data) {
                setShowLoader(false);
                if(Object.keys(userData).length){
                    setShowSave(true);
                }
                setShowPaymentMethod(false);
                setPaymentMethod(paymentMethod);
                setIBANValue(IBAN_num);
                setPaypalEmailValue(PAYPAL_email);
            })
            .catch(function (err) {
                setShowLoader(false);
            });
    }

    /*const chooseCityBasedOnCountry = (e) => {
        if(e.target.value !== "Country"){
            axios.get('/states?display=full&output_format=JSON&filter[id_country]='+e.target.value, config)
            .then(function(data){
                if(data.data.states){
                    setStatesData(data.data.states)
                }else{
                    setStatesData([])
                }
            })
        }
    }*/

    /*useEffect(() => {
        axios.get('/countries?display=[id,name]&output_format=JSON', config)
            .then(function (data) {
                consoleLog(data)
                setCountriesData(data.data.countries)
            })
    }, [])*/
    const sortPhoneCodes = (data) => {
        data.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });
        return data
    }

    const sortCountries = (data) => {
        data.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });
        return data
    }

    useEffect(() => {
        let tempCountry = defaultCountryText;
        let tempCountryId = chosenCountry;
        let optionList = [];
        let phoneCodeList = [];

        countriesData.map(function(country){
            consoleLog("CALLED")
            if(Object.keys(userData).length){
                if(userData.data.addresses[0].id_country == country.id){
                    tempCountry = country.name[countryLangIndex].value
                    tempCountryId = country.id
                }
            }
            optionList.push({name: country.name[countryLangIndex].value , value: country.id})
            phoneCodeList.push({name: "+"+country.call_prefix, value: "+"+country.call_prefix})
        })
        consoleLog(optionList)
        setChosenCountry(tempCountryId)
        setDefaultCountry(tempCountry)
        setCountriesOptionList(sortCountries(optionList))
        setCountryCodes(sortPhoneCodes(phoneCodeList))
    },[countriesData, showSave])

    return (
        <div>
            <p className={classes.Heading}></p>
            <Block
                customStyle={props.customStyle}>

                <div className={classes.PersonalInfo}>

                    {!edit ? <div >
                        <img onClick={handleEdit} className={classes.EditIcon} src={editIcon} alt="" />
                        <div className={classes.Personal1}>
                            <p className={classes.MediaHide}>
                                { props.mainText ? props.mainText : t("Below_you_can_find_your_personal_information._Click_the_edit_button_to_change_or_remove_this_information.") }
                            </p>

                            {Object.keys(userData).length ?
                                <React.Fragment>
                                    <h3 className={classes.Name}>{userData.data.addresses[0].firstname}&nbsp;{userData.data.addresses[0].lastname}</h3>
                                    <p>{userData.data.addresses[0].address1}</p>
                                    <p>{userData.data.addresses[0].address2}</p>
                                    <p>{userData.data.addresses[0].address3}</p>
                                    <p>{userData.data.addresses[0].postcode}</p>
                                    <p>{userData.data.addresses[0].city}</p>
                                    <h3>{userData.data.addresses[0].phone_mobile}</h3>
                                </React.Fragment>
                                : null}
                        </div>
                    </div> :
                        <div className={classes.PersonalForm}>
                            <form onSubmit={submitUserData}>
                                {fields.map((f, index) => {
                                    if(f.name === "Phone_Number"){
                                        return (<span>
                                                <p className={classes.FieldError}>{formErrors[t(f.name)]}</p>
                                                <div className={classes.PhoneNumberField}>
                                                    <CustomSelect
                                                        optionsList={countryCodes}
                                                        changed={setChosenCountryCode}
                                                        styling={{
                                                            container: { width: "70px", position: "absolute"},
                                                            defaultText: { background: "var(--white)",
                                                                            padding: "5px 10px",
                                                                            paddingLeft: "1px",
                                                                            color: "var(--black)",
                                                                            borderRadius: listOpened === 1 ? "10px 0 0 0" : "10px 0px 0px 10px",
                                                                            fontSize: "14px",
                                                                            height: "29px",
                                                                            boxSizing: "border-box",
                                                                            border: "2px solid var(--black)" },
                                                            ul: {},
                                                            li: { background: "var(--white)",
                                                                    color: "var(--black)",
                                                                    paddingLeft: "5px",
                                                                    paddingRight: "5px",
                                                                    border: "1px solid var(--black)"}
                                                        }}
                                                        defaultText={chosenCountryCode}
                                                        listOpened={(item)=>setListOpened(item)}
                                                        listOpenIndex={1}
                                                    />
                                                    <input className={"myInput"}
                                                        type="text"
                                                        name={f.name} placeholder={t(f.name)} value={f.value} onChange={(e) => changeInputValue(e)} /><br />
                                                </div>
                                            </span>)
                                    }else{
                                        return (<span>
                                                <p className={classes.FieldError}>{formErrors[t(f.name)]}</p>
                                                <input className={"myInput"}
                                                    type="text"
                                                    name={f.name} placeholder={t(f.name)} value={f.value} onChange={(e) => changeInputValue(e)} /><br />
                                            </span>)
                                    }
                                })}
                                <CustomSelect
                                    optionsList={countriesOptionList}
                                    changed={setChosenCountry}
                                    styling={{
                                        container: { width: "100%"},
                                        defaultText: { background: "var(--white)",
                                                        padding: "5px 10px",
                                                        color: "var(--black)",
                                                        borderRadius: listOpened === 2 ? "10px 10px 0 0" : "10px",
                                                        border: "2px solid var(--black)" },
                                        ul: {},
                                        li: { background: "var(--white)",
                                                color: "var(--black)",
                                                border: "1px solid var(--black)"}
                                    }}
                                    defaultText={defaultCountry}
                                    listOpened={(item)=>setListOpened(item)}
                                    listOpenIndex={2}
                                />
                                <br />
                                <button type="submit" className={["myButton", classes.PersonalButton].join(' ')}> {t("Save")}</button>
                                { Object.keys(userData).length ?
                                <button type="button" onClick={handleCancel} className={["myButton", classes.PersonalButton].join(' ')}>{t("Cancel")}</button>
                                : null }
                            </form >
                            { !props.addPaymentOption ?
                            <div className={classes.AddPaymentMethod}>
                                <div onClick={() => {setShowPaymentMethod(true)} }>+ {t('Payment_method')}</div>
                            </div> : null }
                        </div>
                    }
                </div>
            </Block>

            {showSave &&
                <div className="backdropCont">
                    <div>
                        <Save refer={editRef} />
                    </div>
                </div>}
            {   consoleLog(formErrors)}
            {   ErrorSubmit &&
                <ErrorBox data={{
                    errorTitle: t("Error(s)"),
                    errorSubTitle: t('please_resolve_your_errors'),
                    errors: [formErrors],
                    acceptText: "OK",
                    acceptAction: () => { setErrorSubmit(false) }
                }} />
            }

            {showPaymentMethod ?
                <div className={classes.PaymentMethod}>
                    <div className={classes.PaymentMethodContainer}>
                        <div className={classes.WrapBox}>
                            <div className={classes.Text}>
                                {t('Payment_method_text')}
                                <div className={classes.Help}>
                                    <div>?</div>
                                    <span className={classes.Tooltip}>{t('payment_method_help_text')}</span>
                                </div>
                            </div>
                            <form onSubmit={(e) => submitPaymentMethod(e)}>
                                <div className={classes.InputMethod}>
                                    { paymentMethod ? /*<select onChange={(e) => askPayment(e.target.value)}>
                                        <option value="">Choose a method:</option>
                                        <option value="IBAN">IBAN Number</option>
                                        <option value="PAYPAL">Paypal Address</option>
                                    </select>*/
                                    /*<CustomSelect
                                        optionsList={[
                                        {name: "IBAN", value: "IBAN"},
                                        {name: t("PAYPAL_EMAIL_ADDRESS"), value: "PAYPAL"}
                                        ]}
                                        changed={askPayment}
                                        styling={{
                                            container: { width: "210px", margin: "15px auto"},
                                            defaultText: { background: "var(--black)",
                                                            padding: "5px 10px",
                                                            color: "var(--white)",
                                                            borderRadius: "20px",
                                                            border: "2px solid var(--blue)" },
                                            ul: {},
                                            li: { background: "var(--black)",
                                                    color: "var(--white)",
                                                    border: "1px solid var(--blue)"}
                                        }}
                                        defaultText={ i18n.language === LANG_ENGLISH ? "All" : "Alle" }
                                        darkBackground={true}
                                    />*/

                                        <>
                                        <label htmlFor="payment1">
                                            <input type="radio" name="choosePayment" id="payment1" checked={paymentMethod === "IBAN" ? true : false } onClick={()=>setPaymentMethod("IBAN")} />
                                            <input type="text" name="paymentMethod" placeholder="IBAN" name="IBAN" value={iban_value} onChange={paymentMethod === "IBAN" ? (e)=>setIBANValue(e.target.value) : null} />
                                        </label>
                                        <label htmlFor="payment2">
                                            <input type="radio" name="choosePayment" id="payment2" checked={paymentMethod === "PAYPAL" ? true : false } onClick={()=>setPaymentMethod("PAYPAL")} />
                                            <input type="text" name="paymentMethod" placeholder={t("PAYPAL_EMAIL_ADDRESS")} name="PAYPAL" value={paypal_email_value} onChange={paymentMethod === "PAYPAL" ? (e)=>setPaypalEmailValue(e.target.value) : null} />
                                        </label>
                                        </>: null }
                                </div>
                                <div className={classes.ActionButtons}>
                                    <button type="submit">{t('Save')}</button>
                                    { hideCancelButton ? null :
                                    <button type="button" onClick={() => { setShowPaymentMethod(false) }}>{t('Cancel')}</button> }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                : null}
        { showLoader ? <LogoLoader /> : null }
        </div>
    );
}

export default PersonalInfo;