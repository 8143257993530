import axios from 'axios';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_DOMAIN+'/api/'
});

export default axiosInstance;
export const config = {
	headers: {
		"Authorization": "Basic " + btoa(process.env.REACT_APP_AUTH_KEY + ":"),
		"Accept-Language": localStorage.getItem("i18nextLng")
	}
};

export const token_config = (token) => {
	return {
		headers: {
			"Authorization": "Bearer " + token,
			"Accept-Language": localStorage.getItem("i18nextLng")
		}
	}
}