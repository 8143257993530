import React,{useState, useEffect} from 'react';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import classes from './Sofort.module.css';
import Klarna from '../../../../../assets/images/icons/klarna.png';

import axios, {token_config} from '../../../../../axiosBase.js';
import {checkForLogin} from '../../../../PrivateRoute/PrivateRoute';

import customerDetails from '../../../../UserData';
import consoleLog from '../../../../../log';

export default function CheckoutForm(props) {
  consoleLog(props,"SOFORT")
  const stripe = useStripe();
  const elements = useElements();
  consoleLog(stripe)


  const [clientSecret, setClientSecret] = useState('');
  const [clientID, setClientID] = useState('');

  consoleLog(clientSecret,"SETCS",customerDetails)
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
      checkForLogin().then(function(token){
      if(token){

      const config = token_config(token)

      const payload = {
        amount: parseInt(parseFloat(props.price)*100)
      }
      axios.post('/payment?a=create',payload,config)
      .then(res => {
        consoleLog("FETCHED",res)
        return res
      })
      .then(data => {
        setClientSecret(data.data.clientSecret.client_secret);
        setClientID(data.data.clientSecret.id);
      });

      }})
  }, []);


  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // For brevity, this example is using uncontrolled components for
    // the accountholder's name. In a real world app you will
    // probably want to use controlled components.
    // https://reactjs.org/docs/uncontrolled-components.html
    // https://reactjs.org/docs/forms.html#controlled-components

    const accountholderName = event.target['accountholder-name'];

//    sessionStorage.setItem("SPI",clientID);
//    sessionStorage.setItem("SPICS",clientSecret);
    sessionStorage.setItem("SP_Details",JSON.stringify({
      eventDetails: props.eventDetails,
      ticketDetails: props.ticketDetails,
      priceBreakdown: props.priceBreakdown
    }))

    const {error} = await stripe.confirmSofortPayment(clientSecret, {
      payment_method: {
        sofort: {
          country: "DE"
        },
        billing_details: {
          name: accountholderName.value,
        },
      },
      return_url: window.location.href,
    });

    if (error) {
      // Show error to your customer.
      consoleLog(error.message);
    }

    // Otherwise the customer will be redirected away from your
    // page to complete the payment with their bank.
  };

  return (
    <form onSubmit={handleSubmit} className={classes.SofortForm}>
      <div className="form-row">
        <img src={Klarna} />
        <label>
          <input name="accountholder-name" placeholder="" value={customerDetails.data.firstname+" "+customerDetails.data.lastname} required />
        </label>
      </div>
      <button type="submit" id="submitSofortPayment" disabled={!stripe}>
        Submit Payment
      </button>
    </form>
  );
}