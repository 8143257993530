import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import {ALL_LANG_URLS, HOME} from './URLS';

const fallbackLng = ['en'];
const availableLanguagesConf = ['en', 'de'];


const availableLanguages = ['gb', 'de'];
const getCurrentURL = (lng) => {
  let currentPath = window.location.pathname;
  let newUrl = "";

  Object.entries(ALL_LANG_URLS).map(function([key, value]){
    if((currentPath.includes(value.de) || currentPath.includes(value.en))
      && (value.de == ALL_LANG_URLS.event_string.de || value.en == ALL_LANG_URLS.event_string.en
        || value.de == ALL_LANG_URLS.festival_string.de || value.en == ALL_LANG_URLS.festival_string.en
        || value.de == ALL_LANG_URLS.theater_string.de || value.en == ALL_LANG_URLS.theater_string.en
        || value.de == ALL_LANG_URLS.sport_string.de || value.en == ALL_LANG_URLS.sport_string.en
        )
      && (currentPath.includes(ALL_LANG_URLS.event_string.en) || currentPath.includes(ALL_LANG_URLS.event_string.de)
        || currentPath.includes(ALL_LANG_URLS.festival_string.en) || currentPath.includes(ALL_LANG_URLS.festival_string.de)
        || currentPath.includes(ALL_LANG_URLS.theater_string.en) || currentPath.includes(ALL_LANG_URLS.theater_string.de)
        || currentPath.includes(ALL_LANG_URLS.sport_string.en) || currentPath.includes(ALL_LANG_URLS.sport_string.de)
        )
      )
    {  // SPECIFICALLY FOR EVENTS URL
      currentPath = currentPath.replace(value.de, '')
      currentPath = currentPath.replace(value.en, '')
      newUrl = value[lng]+currentPath
    }
    else if(currentPath == value.en || currentPath == value.de){ // FOR ALL URLS
      newUrl = value[lng]
    }
  })
  if(newUrl == ""){
    newUrl = window.location.pathname
  }
  newUrl = newUrl + window.location.search
  return newUrl;
}

i18n.on('languageChanged', function (lng) {
    let de_pattern = /^\/de(?=\/|$)/;
    let en_pattern = /^\/gb(?=\/|$)/;

    let en_included = en_pattern.test(window.location.pathname)
    let de_included = de_pattern.test(window.location.pathname)

    let changedURL = getCurrentURL(lng)
    if(lng == "en"){
      lng = "gb"
    }

    if (!en_included && !de_included){
      //i18n.off('languageChanged')
      changedURL = '/' + lng + changedURL;
      window.location.replace(changedURL)
    }else if(lng == availableLanguages[0] && de_included){
      changedURL = changedURL.replace("/"+availableLanguages[1],'')
      window.location.replace(changedURL)
    }else if(lng == availableLanguages[1] && en_included){
      changedURL = changedURL.replace("/"+availableLanguages[0],'')
      window.location.replace(changedURL)
    }
})

i18n
  .use(Backend) // load translations using http (default                                               public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    react: {
      useSuspense: false,
    },
    fallbackLng, // fallback language is english

    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true, // options for language detection
    },

    debug: false,

    whitelist: availableLanguagesConf,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;
export {availableLanguages};