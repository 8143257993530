import React from 'react';
import classes from './Block.module.css';

const Block = (props) => {


	return (
		<div className={classes.Block} style={props.customStyle}>
			{props.children}
		</div>
	);
}

export default Block;