import React from 'react';
import AccountList from '../AccountList/AccountList';
import PersonalInfo from '../Profile/PersonalInfo/PersonalInfo';
import ChangeEmail from '../Profile/ChangeEmail/ChangeEmail';
import ChangePassword from '../Profile/ChangePassword/ChangePassword';
//import EmailPreferences from '../Profile/EmailPreferences/EmailPreferences';
import MyListings from '../MyListings/MyListings';
import Service from '../AboutUs/Service/Service';
import Vision from '../AboutUs/Vision/Vision';
import Team from '../AboutUs/Team/Team';
import Orders from '../Orders/Orders';

import { useTranslation } from 'react-i18next';


export default function AccountListWrapper(props) {

    const { t, i18n } = useTranslation();


    const settingsTab = [
        { name: t('Personal_Info'), comp: <PersonalInfo /> },
        { name: t('Change_E-mail'), comp: <ChangeEmail /> },
        { name: t('Change_Password'), comp: <ChangePassword /> }
        /*{ name: t('E-mail_Notifications'), comp: <EmailPreferences /> }*/
    ]

    const mylistingsTab = [
        { name: "Active Listings", comp: <MyListings /> },
        { name: "Inactive Listings", comp: <MyListings /> }
    ]

    const aboutUsTab = [
        { name: "Service", comp: <Service /> },
        { name: "Vision", comp: <Vision /> },
        { name: "Team", comp: <Team /> }
    ]

    const ordersTab = [
        { name: t('All_Orders'), comp: <Orders type="ALL" /> },
        { name: t('Awaiting_Upload'), comp: <Orders type="AWAITING_UPLOAD" /> },
        { name: t('Awaiting_Shipment'), comp: <Orders type="AWAITING_SHIPMENT" /> },
        { name: t('Completed'), comp: <Orders type="COMPLETED" /> },
        { name: t('Canceled'), comp: <Orders type="CANCELED" /> }

    ]


    return (
        <div>
            <AccountList subName={props.name}
                tabDetails={props.name === 'SETTINGS' ? settingsTab
                    : props.name === 'ABOUT_US' ? aboutUsTab
                        : props.name === 'MY_ORDERS' ? ordersTab
                            : props.name === 'MY_LISTINGS' ? mylistingsTab
                                : null}
            />
        </div>

    )
}