import React from 'react';
import classes from './Privacy.module.css';
import { useState,useEffect } from 'react';
import { LANG_GERMAN } from '../../URLS';
import axios from '../../axiosBase';
import { config } from '../../axiosBase';

import { useTranslation } from 'react-i18next';

const Privacy = () => {

	const { t, i18n } = useTranslation();
	const [title, setTitle] = useState("");
	const [privacyContent,setPrivacyContent]=useState("");
	const linkLangCode=localStorage.getItem("i18nextLng") === LANG_GERMAN ? 1 : 2;

	useEffect(() => {
    axios.get('/content_management_system/2?output_format=JSON',config)
	.then(function(data){
		setTitle(data.data.content.meta_title[linkLangCode].value)
		setPrivacyContent(data.data.content.content[linkLangCode].value)
	})
	.catch(function(err){
		console.log(err)
	})
	
	  
	}, [privacyContent])

	return (
		<div className={classes.PrivacyContainer}>
			<div className={classes.Heading}><span>{title}</span></div>
			<div className={classes.MainContent} dangerouslySetInnerHTML={{__html: privacyContent}}></div>
			{/*<div className={classes.Heading}><span>{t('PRIVACYPOLICY')}</span></div>
			<div className={classes.MainContent}>
				<p><span>{t('P_LINE_1')}</span></p>
				<p><span className={classes.SubHeading}>{t('P_HEAD_1')}</span></p>
				<p>{t('P_LINE_2')}</p>
				<p><span>{/*t('P_LINE_3').split('<br />').map(function(res){
					return <>{res}<br /></>;
				})*/}
				{/*</span></p>
				<p><span className={classes.SubHeading}>{t('P_HEAD_2')}</span></p>
				<p>{t('P_LINE_4')} <span className={classes.Link}><a href={"mailto:"+process.env.REACT_APP_KMT_EMAIL}><span className={classes.Link}>{process.env.REACT_APP_KMT_EMAIL}</span></a></span>{t('P_LINE_4_1')}</p>
				<p><span className={classes.SubHeading}>{t('P_HEAD_3')}</span></p>
				<p>{t('P_LINE_6')}</p>
				<p>{/*t('P_LINE_7').split('<br />').map(function(res){
					return <>{res}<br /></>;
				})*/}
				{/* </p> */}
				{/* <p><span className={classes.SubHeading}>{t('P_HEAD_4')}</span></p> */}
				{/*t('P_LINE_8').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})*/}
				{/* <p><span className={classes.SubHeading}>{t('P_HEAD_5')}</span></p>
				<p><span>{t('P_LINE_9')}</span></p>
				<p><span className={classes.SubHeading}>{t('P_HEAD_6')}</span></p> */}
				{/*t('P_LINE_10').split('</p><p>').map(function(res){
					return <p>{res}</p>;
				})*/}
				{/* <p><span className={classes.SubHeading}>{t('P_HEAD_7')}</span></p>
				<p>{t('P_LINE_11')} <span className={classes.Link}><a href="mailto:kundenservice@kaufmeinticket.de"><span className={classes.Link}>kundenservice@kaufmeinticket.de</span></a></span>. {t('P_LINE_12')}</p>
				<p><span className={classes.SubHeading}>{t('P_HEAD_8')}</span></p>
				<p>{t('P_LINE_13')}<span className={classes.Link}><a href="mailto:kundenservice@kaufmeinticket.de"><span className={classes.Link}>kundenservice@kaufmeinticket.de</span></a></span>.</p>
	    	</div>*/} 
		</div>
	);
}

export default Privacy;