import React, {useState,useEffect} from 'react';
import classes from './HowItWorks.module.css';

import { useTranslation } from 'react-i18next';
import {FAQS, LANG_GERMAN} from '../../URLS';
import ContactUs from '../ContactUsBox/ContactUs';

import Video from '../../assets/images/videos/KMT_Video.mp4';
import  axios  from '../../axiosBase';
import { config } from '../../axiosBase';

const HowItWorks = () => {

	const { t, i18n } = useTranslation();
    // const [showContactBox, setShowContactBox] = useState(false);
    const [title, setTitle] = useState("");
	const [howItWorksContent,setHowItWorksContent]=useState("");
	const linkLangCode=localStorage.getItem("i18nextLng") === LANG_GERMAN ? 1 : 2;

	useEffect(() => {
    axios.get('/content_management_system/1?output_format=JSON',config)
	.then(function(data){
		setTitle(data.data.content.meta_title[linkLangCode].value)
		setHowItWorksContent(data.data.content.content[linkLangCode].value)
	})
	.catch(function(err){
		console.log(err)
	})
	
	  
	}, [howItWorksContent])
	
	return (
		<div className={classes.TermsContainer}>
			<div className={classes.Heading}><span>{title}</span></div>
		    <div className={classes.MainContent} dangerouslySetInnerHTML={{__html: howItWorksContent }}></div>
			{/*<div className={classes.Heading}><span>{t('HOWITWORKS')}</span></div>
			<div className={classes.VideoContainer}>
				<video controls>
  					<source src={Video} type="video/mp4" />
  					Your browser does not support HTML video.
				</video>
			</div>
			<div className={classes.MainContent}>
				{t('HIW_1').split('<br />').map(function(res){
					return <>{res}<br /></>;
				})}
				<p className={classes.SubHeading}>
					{t('HIW_H1')}
				</p>
				{t('HIW_2').split('<br />').map(function(res){
					return <>{res}<br /></>;
				})}
				<p className={classes.SubHeading}>
					{t('HIW_H2')}
				</p>
				{t('HIW_3').split('<br />').map(function(res){
					return <>{res}<br /></>;
				})}
				{t('HIW_4_1')}
				<a href={FAQS}>{t('HIW_FAQ')}</a>
				{t('HIW_4_2').split('<br />').map(function(res){
					return <>{res}<br /></>;
				})}
				{t('HIW_5_1')}
				<a onClick={()=>(setShowContactBox(true))}>
					{t('HIW_CONTACT')}
				</a>
			</div>
			{
                showContactBox ?
                    <ContactUs
                        hideContact={()=>setShowContactBox(false)}
                        pageDetails="How it Works Contact - " // for internal use
                    /> : null
            }*/}
		</div>
	);
}

export default HowItWorks;