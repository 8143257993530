import React, { useState } from 'react';
import classes from './AccountList.module.css'
import settingIcon from "../../assets/images/icons/settings-page-header-icon.png";
import listIcon from "../../assets/images/icons/list.png"
import aboutIcon from "../../assets/images/icons/about.png"
import CheckIcon from '../../assets/images/icons/check.png'

import { useTranslation } from 'react-i18next';

export default function AccountList(props) {
    const buttonList = props.tabDetails
    const [buttonVal, setButtonVal] = useState(buttonList[0].comp)
    const activeTabJson = {};

    const { t, i18n } = useTranslation();

    buttonList.map(function (value, index) {
        activeTabJson['tab' + index] = false
    })
    activeTabJson['tab0'] = true;
    const [activeTabs, setActiveTabs] = useState(activeTabJson)


    //changing the styles of button and rendering respective component from the list received through props on clicking the buttons
    const handleClick = (index) => {
        setButtonVal(buttonList[index].comp)
        for (var key in activeTabs) {
            activeTabs[key] = false
        }
        setActiveTabs({
            ...activeTabs,
            ['tab' + index]: true
        })
    }


    return (
        <div className={classes.AccountList}>

            <div className={classes.AccountHr}>
            </div>

            <div className={classes.AccountHeading}>
                {props.subName === "SETTINGS" ? <img src={settingIcon} alt="" /> : props.subName === "ABOUT_US" ? <img src={aboutIcon} alt="" style={{ transform: "rotate(-22deg)" }} /> : <img src={listIcon} alt="" style={{ transform: "rotate(-22deg)"}} />}
                <span>{t(props.subName)}</span>
            </div>
            <div className={props.subName === "SETTINGS" ? classes.SettingsStyle : props.subName === "MY_ORDERS" ? [classes.OrdersHide, classes.AccountButton].join(" ") : classes.AccountButton}>
                {buttonList.map((b, index) => {
                    return (
                        <button
                            key={index}
                            onClick={() => handleClick(index)}
                            className={!activeTabs['tab' + index] ? classes.NormalB : classes.ChangeB}
                        >
                            {buttonList[index].name}

                            {activeTabs['tab' + index] && <img className={classes.CheckImg} src={CheckIcon} alt="" />}
                        </button>
                    )
                })}

            </div>
            <div className={classes.AccountHrMedia}>
            </div>
            {buttonVal}

        </div>
    )
}