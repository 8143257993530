import React, { useRef, useState, useEffect } from 'react';
import classes from './Header.module.css';
import fIcon from '../../assets/images/icons/navbar_facebook.png';
import gIcon from '../../assets/images/icons/nav_gmail.png';
import iIcon from '../../assets/images/icons/nav_insta.png';
import profileIcon from './../../assets/images/icons/profile.png';
import down from '../../assets/images/icons/navbar_down-arrow.png';
import Login from '../Login/Login';
import logo from '../../assets/images/logo/kaufmeinticket_logo.png';
import de from '../../assets/images/icons/de-flag.png'
import en from '../../assets/images/icons/en-flag.png'
import Cart from '../Cart/Cart';
import CartIcon from '../../assets/images/icons/cart-icon.png'
import useOutsideAlerter from '../UseOutsideAlerter/UseOutsideAlerter';
import Account from '../Account/Account';
import Search from '../Search/Search';
import profileFilled from '../../assets/images/icons/profileFilled.png'
import MobileLogo from '../../assets/images/logo/kaufmein-ticket.png'
import cancelIcon from '../../assets/images/icons/cancel.png'
import { HOME, SETTINGS, LISTINGS, ORDERS, SALES, SELL, EVENT, EVENT_STRING, FESTIVAL_STRING, THEATER_STRING, SPORT_STRING, SEARCHRESULTS, LANG_ENGLISH, LANG_GERMAN, REGISTER, langURL } from '../../URLS';
import {Facebook, Instagram} from '../../SocialURLs';
import customerDetails, {storage} from '../UserData';
import {checkForLogin} from '../PrivateRoute/PrivateRoute';
import axios, {token_config} from '../../axiosBase';

import ContactUs from '../ContactUsBox/ContactUs';

import { useTranslation } from 'react-i18next';
import consoleLog from '../../log';

export default function Header() {



    const { t, i18n } = useTranslation(); //translation functions to convert a text in multiple languages and switch to a new language, respectively
    const [showContactBox, setShowContactBox] = useState(false);

    const login_name = 'login_name';    //used for login modal reference
    const account_name = 'account_name';//used for account modal reference
    const cart_name = 'cart_name';//used for cart modal reference
    var editRef = useRef(null);
    var editRef1 = useRef(null);
    const [open, setOpen] = useState(false); //Set modal open or close
    const [openName, setOpenName] = useState("")  //Display name of Modal on header ex. Account, Cart, Login
    const [headColor, setHead] = useState("") //header background color
    const [nav, setNav] = useState(false)    //for switching between header default and onscroll
    const [showLogo, setShowLogo] = useState(false) //Company Logo
    const [loginName, setLogin] = useState("login_name")  //check for loggedin user in local storage and login
    const [showLangDrop, setShowLangDrop] = useState(false)  //language switching dropdown
    const [headerElementContainer, setHeaderElementContainer] = useState(false); //
    const [MobileSell, setMobileSell] = useState(false) //to change "sell your tickets to "sell" in mobile view
    const [hideOnSearch, setHideOnSearch] = useState(false) //to hide all on clicking search button
    const [langName, setLangName] = useState(localStorage.getItem("i18nextLng") || 'de')//set language while starting the app
    const [alert, setAlert] = useState(false); // information alert box
    const [mobileHeaderView, setMobileHeaderView] = useState(false)
    const [showHamburger, setShowHamburger] = useState(false)
    const [openMobileCart, setOpenMobileCart] = useState(false)
    const [cartItems, setCartItems] = useState(0);

    document.title = t("home_page_title");
    const pathname = window.location.pathname;
    // manage the header color and icons on window scroll 
    const listenScrollEvent = e => {

        if (window.scrollY >= 20) {
            setNav(false)
        } else {
            setNav(true)
        }

        // if (window.scrollY <= 310) {
        //     setShowLogo(false)
        //     setHeaderElementContainer(false);
        // }
        // else {
        //     setShowLogo(true)
        //     setHeaderElementContainer(true);
        // }
    }

    const listenScrollEvent2 = () => {
        if (window.scrollY >= 20) {
            setNav(false)
        } else {
            setNav(true)
        }
    }
    const resize = () => {

        if (window.innerWidth <= 1000)
            setMobileSell(true)
        else {
            setMobileSell(false)
        }
    }


    const resize1 = () => {

        if (window.innerWidth <= 1100 && pathname != HOME)
            setMobileHeaderView(true)
        else {
            setMobileHeaderView(false)
        }
    }



    const changeLanguage = (val) => {
        i18n.changeLanguage(val);
        setLangName(val)
        //langDrop()
        window.location.reload()
    }
    // Language Dropdown
    const langDrop = () => {
        setShowLangDrop(!showLangDrop)
    }


    // on the initial render of Header component set the eventListeners
    useEffect(() => {
        resize() // To check screen size on load
        resize1()

        if (customerDetails && customerDetails.data.id)
            setLogin(account_name)
        else {
            setLogin(login_name)
        }



        if (localStorage.getItem("showWelcomeLogin")) {
            setAlert(true)
        }
        //to set default header for homepage and non-defualt for other webpages
        if (pathname === HOME) {
            //window.addEventListener('scroll', listenScrollEvent);
            window.addEventListener('resize', resize);

            return () => {
                //window.removeEventListener('scroll', listenScrollEvent);
                window.removeEventListener('resize', resize);
            }
        }
        else if(pathname === langURL + REGISTER){
            document.getElementById("loginBtnId").click();
            setTimeout(()=>{
                document.getElementById("registerBtnId").click();
            },500)
        }
        else {
            if (pathname.includes(EVENT_STRING) && pathname.split('/').length > 4){
                // Do nothing
            }
                //&& !pathname.includes(FESTIVAL_STRING)
                //&& !pathname.includes(THEATER_STRING)
                //&& !pathname.includes(SPORT_STRING) 
            else{
                //window.addEventListener('scroll', listenScrollEvent2);
            }

            setHeaderElementContainer(true)
            setShowLogo(true)

        }

        if (pathname != HOME) {
            window.addEventListener('resize', resize1);
        }

    }, [])


    //Alert box display for sometime
    useEffect(() => {

        const timer = setTimeout(() => {
            // setAlert(false);
            localStorage.removeItem("showWelcomeLogin");
        }, 1000);

        return () => {
            clearTimeout(timer)
        };
    });


    useEffect(()=>{

        checkForLogin().then(function(token){
        if(token){
            const config = token_config(token)

            axios.get('/carts?output_format=JSON&display=full&filter[id_customer]=['+customerDetails.data.id+']', config)
            .then(function(data){
                const cart = data.data;
                if(cart.carts && cart.carts.length){
                    if(cart.carts[cart.carts.length-1].associations){
                        if(sessionStorage.getItem("cart")){
                            setCartItems(cart.carts.length)
                        }
                    }
                }
            })
            .catch(function(err){
                consoleLog(err)
            })            
        }})
        
    },[])

    //   fade the header background color on opening the dropDowns
    useEffect(() => {
        if (showLangDrop || open) {
            setHead("blur(2px)")
        }
        else {
            setHead("")
        }
    }, [showLangDrop, open])

    // Initialize language with either browser or default
    useEffect(() => {
        i18n.changeLanguage(langName);
    }, []);

    const setEditF = (value) => {
        if (value) {
            setOpen(value)
        }
        else {

            setTimeout(() => {
                setOpen(value)
            }, 200)
        }
    }

    const setEditF1 = (value) => {
        if (value) {
            setShowLangDrop(value)
        }
        else {

            setTimeout(() => {
                setShowLangDrop(value)
            }, 200)
        }
    }

    useEffect(()=>{
        let url_string = window.location.href;
        let url = new URL(url_string);
        let scope = url.searchParams.get("scope");
        let code = url.searchParams.get("code");
        if(code && scope){
            document.getElementById("loginBtnId").click();
        }
    },[])

    // close login ,account and cart dropDown on outside click 
    useOutsideAlerter(editRef, setEditF, false);
    // close language dropDown on outside click 
    useOutsideAlerter(editRef1, setEditF1, false);

    const hideAllOnSearchClick = (value) => {
        consoleLog(value)
        setHideOnSearch(value);
    }

    const handleLogout = () => {
        storage.removeItem("userId");
        localStorage.removeItem("showWelcomeLogin");
        window.location.href = HOME;
    }


    const closeMobileCart = () => {
        setOpenMobileCart(false)
    }

    const closeDesktopCart = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>
            {mobileHeaderView ?
                <div className={nav ? classes.Header : classes.Header1} style={{ backdropFilter: headColor }}>
                    <div>
                    <div className={classes.HeaderText}>
                        <span>{t('headtext')}</span>
                    </div>
                    <div>
                        {/*pathname != SELL && */}<img onClick={() => { window.location.href = HOME }} className={classes.Logo1} src={MobileLogo} alt="" />
                    </div>
                    <div>
                        {/*pathname != SELL && */}<Search header={false} mobileHeader={true} handleSearch={(value) => hideAllOnSearchClick(value)} />
                        <span className={hideOnSearch && classes.Hide} >
                            <div className={classes.MobileCartSpan}>
                                <img onClick={() => { setOpenMobileCart(true); setShowHamburger(true); }} src={CartIcon} alt="" />
                                { cartItems ? <div className={classes.CartBadge}>1</div> : null }
                            </div>
                            {!pathname.includes(EVENT_STRING)
                                && !pathname.includes(FESTIVAL_STRING)
                                && !pathname.includes(THEATER_STRING)
                                && !pathname.includes(SPORT_STRING)
                                && !pathname.includes(SEARCHRESULTS)
                                && <a href={SELL} className={classes.HeaderSellLink}><span className={classes.HeaderSellMobile}>{t('sell')}</span></a>}
                            <div onClick={() => { setShowHamburger(!showHamburger) }} className={classes.container} id="loginBtnMobileId">
                                <div className={classes.bar1}></div>
                                <div className={classes.bar2}></div>
                                <div className={classes.bar3}></div>
                            </div>
                        </span>
                    </div>
                    </div>
                </div> :
                <div className={nav ? classes.Header : classes.Header1} style={{ backdropFilter: headColor }}>
                    <div>
                    <div className={classes.HeaderText}>
                        <span>{t('headtext')}</span>
                    </div>
                    <div className={classes.HeaderIcon}>
                        <span onClick={langDrop} className={classes.HeaderNewLang}>
                            <img src={down} alt="" className={[classes.HeaderIconImg, classes.HeaderIconImg1, classes.IconMobile].join(' ')} />

                            {!headerElementContainer && <span className={classes.LangName}>{langName.toUpperCase()}</span>}
                            {langName === LANG_ENGLISH ? <img src={en} alt="" className={[classes.HeaderIconImg, classes.HeaderIconImg2].join(' ')} /> :
                                <img src={de} alt="" className={[classes.HeaderIconImg, classes.HeaderIconImg2].join(' ')} />
                            }
                        </span>

                        { !showLogo ? <span className={classes.HeaderLogo}>
                            <img onClick={() => { window.location.href = HOME }} src={logo} />
                        </span> : null }

                        {showLogo /*&& pathname != SELL*/ ? <span>
                            <a href="mailto:info@kaufmeinticket.de" target="_blank"><img src={gIcon} className={[classes.HoverWhite, classes.HeaderIconImg].join(' ')} alt="" /></a>
                            <img onClick={() => { window.location.href = HOME }} className={classes.Logo} src={logo} alt="" />
                        </span> : <span>
                            <a href={Instagram} target="_blank"><img src={iIcon} className={[classes.HoverWhite, classes.HeaderIconImg, classes.IconMobile].join(' ')} alt="" /></a>
                            <a href={Facebook} target="_blank"><img src={fIcon} className={[classes.HoverWhite, classes.HeaderIconImg, classes.IconMobile].join(' ')} alt="" /></a>
                            <a href="mailto:info@kaufmeinticket.de" target="_blank"><img src={gIcon} className={[classes.HoverWhite, classes.HeaderIconImg].join(' ')} alt="" /></a>
                        </span>
                        }
                    </div>
                    <div>
                        {pathname != SELL && headerElementContainer && <Search header={true} mobileHeader={false} handleSearch={(value) => hideAllOnSearchClick(value)} />}
                    </div>
                    {
                        pathname.includes(SELL)
                        || pathname.includes(EVENT_STRING)
                        || pathname.includes(FESTIVAL_STRING)
                        || pathname.includes(THEATER_STRING)
                        || pathname.includes(SPORT_STRING)
                        || pathname.includes(SEARCHRESULTS) ? null :
                            !headerElementContainer ? <div className={classes.HeaderSell} >
                                {MobileSell ? <a href={SELL} className={classes.HeaderSellLink}><span className={classes.HeaderSellMobile} >{t('sell')}</span></a> :
                                    <a href={SELL} className={classes.HeaderSellLink}><span  >{t('sell_your_tickets')}</span></a>}

                            </div> :
                                <div className={[classes.HeaderSell, classes.HeaderSell1].join(' ')} >

                                    <a href={SELL} className={classes.HeaderSellLink}><span  >{t('sell')}</span></a>

                                </div>
                    }
                    <div className={classes.HeaderLogin}>
                        <span onClick={() => { setOpenName(loginName); setOpen(!open) }} id="loginBtnId">
                            {loginName === account_name || MobileSell ?
                                <span>  {loginName === account_name ? <img className={classes.HeaderIconImg} src={profileFilled} alt="" /> : <img className={classes.HeaderIconImg} src={profileIcon} alt="" />}
                                    <img src={down} alt="" className={[classes.LangImg, classes.IconMobile].join(' ')} /></span>
                                : <span> {t(loginName)}</span>}
                        </span>
                        <span onClick={() => { setOpenName(cart_name); setOpen(!open) }} >
                            <img src={CartIcon} alt="" className={classes.LangImg} />
                            { cartItems ? <div className={classes.CartBadge}>1</div> : null }
                            <img src={down} alt="" className={[classes.LangImg, classes.IconMobile].join(' ')} />
                        </span>
                        <span>
                            <label className={classes.LangSwitch}>
                                <img className={langName === LANG_ENGLISH ? classes.Selected : null} src={en} alt="english" onClick={() => { changeLanguage(LANG_ENGLISH) }} />
                                <img className={langName === LANG_GERMAN ? classes.Selected : null} src={de} alt="german" onClick={() => { changeLanguage(LANG_GERMAN) }} />
                            </label>
                        </span>
                    </div>
                    </div>
                </div>
            }


            {
                open && <div className="backdropCont">
                    <div ref={editRef} >
                        {openName === login_name ? <div className={classes.Backdrop}><Login mobileV={false} /> </div> : openName === cart_name ? <div className={[classes.Backdrop, classes.BackdropCart].join(' ')}>
                            <Cart closeIt={closeDesktopCart} />
                        </div> : openName === account_name ? <div className={classes.BackdropAcc}> <Account /> </div> : ""
                        }
                    </div>
                </div>
            }
            {
                showLangDrop && <div className="backdropCont" style={showHamburger ? { zIndex: 100000, textAlign: "right", textAlign: "-webkit-right" } : {} }>
                    <div ref={editRef1} className={classes.HeaderLangOpen}>
                        <div onClick={() => { changeLanguage(LANG_ENGLISH) }} className={classes.LangOpen1}><span>EN</span> <img src={en} alt="" className={classes.HeaderIconImg} /> </div>
                        <div onClick={() => { changeLanguage(LANG_GERMAN) }} className={classes.LangOpen2}><span>DE </span><img src={de} alt="" className={classes.HeaderIconImg} /> </div>
                    </div>
                </div>

            }
            {alert && <div className={classes.LoginPop}>
                <div>{t('Welcome_Back')}, <span>{customerDetails.data.firstname}</span>!</div>
            </div>
            }

            {
                showHamburger &&
                <div className={classes.Hamburger}>

                    <div className={classes.HamTop}>
                        <div> <img onClick={() => { window.location.href = HOME }} className={[classes.Logo1, classes.Logo2].join(" ")} src={MobileLogo} alt="" /></div>
                        <div className={classes.MobileCartDiv}>
                            <img onClick={() => { setOpenMobileCart(true) }} src={CartIcon} className={classes.HamIcon} alt="" />
                            { cartItems ? <div className={classes.CartBadge}>1</div> : null }
                        </div>
                        <span onClick={langDrop} className={[classes.HeaderNewLang, classes.HamburgerLang].join(' ')}>
                            {!headerElementContainer && <span className={classes.LangName}>{langName.toUpperCase()}</span>}
                            {langName === LANG_ENGLISH ? <img src={en} alt="" className={[classes.HeaderIconImg, classes.HeaderIconImg2].join(' ')} /> :
                                <img src={de} alt="" className={[classes.HeaderIconImg, classes.HeaderIconImg2].join(' ')} />
                            }
                        </span>
                        <div> <img className={classes.HamIcon1} onClick={() => { setShowHamburger(!showHamburger) }} src={cancelIcon} alt="" /></div>
                    </div>

                    {!openMobileCart ? <div>

                        {loginName === account_name ?
                            <div className={classes.AccountMobile}>
                                <a href={ORDERS}><p>{t('my_Orders')}</p></a>
                                <a href={LISTINGS}><p>{t('my_Listings')}</p></a>
                                <a href={SALES}><p>{t('my_Sales')}</p></a>
                                <a href={SETTINGS}><p>{t('settings')}</p></a>
                                <button onClick={() => { handleLogout() }} >{t('sign_out')}</button>
                                <div className={classes.SocialMediaIcons}>
                                    <a onClick={()=>(setShowContactBox(true))}>
                                        <img src={gIcon} alt="contact us" />
                                    </a>
                                    <a href={Facebook} target="_blank">
                                        <img src={fIcon} />
                                    </a>
                                    <a href={Instagram} target="_blank">
                                        <img src={iIcon} />
                                    </a>
                                </div>
                            </div>
                            :
                            <div className={classes.MobileLogin}>
                                <Login mobileV={true} />
                            </div>
                        }
                    </div>
                        :
                        <div>
                            <Cart mobileV={true} closeIt={closeMobileCart} />
                            {loginName === account_name &&
                                <div className={classes.CartLogout} >  < button onClick={() => { handleLogout() }} >Sign out</button>
                                </div>
                            }

                        </div>
                    }
            {
                showContactBox ?
                    <ContactUs
                        hideContact={()=>setShowContactBox(false)}
                    /> : null
            }
            </div>
            }

        </React.Fragment >
    )
}
