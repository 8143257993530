import React, {useState, useEffect} from 'react';
import classes from './SocialSignup.module.css';
import FBIcon from '../../assets/images/icons/social_facebook.png';
import PaypalIcon from '../../assets/images/icons/social_paypal.png';
import GmailIcon from '../../assets/images/icons/social_gmail.png';
import { useTranslation } from 'react-i18next';

import axios, { config } from '../../axiosBase';
import ErrorBox from '../UI/ErrorBox/ErrorBox';

import LogoLoader from '../UI/LogoLoader/LogoLoader';
import {encryptWithAES} from '../Encrypt/AES';
import {availableLanguages} from '../../i18nextConf';

import SocialButton from "./SocialButton";

import consoleLog from '../../log';
import Cookies from 'universal-cookie';

const SocialSignup = (props) => {

    const { t, i18n } = useTranslation();

	consoleLog(props, "social")
	const [showLoader, setShowLoader] = useState(false)
    const [errorList, setErrorList] = useState([])
    const [ErrorSubmit, setErrorSubmit] = useState(false)

    const cookies = new Cookies();

    const callLogin = (payload) => {
    	axios.post('/sociallogins?a=get', payload, config)
		.then(function (data) {
            consoleLog(data.data.success, typeof(data.data.success), typeof(data.data.token), data.data.token)
            if (data.data.success && sessionStorage.getItem("socialRedirectURL")) {
                let today = new Date();
                localStorage.setItem("userId", encryptWithAES(data.data.token+":"+data.data.refresh_token))
                today.setDate(today.getDate() + 1)
                cookies.set('loginTime', today, { path: '/', expires: today })

                localStorage.setItem("showWelcomeLogin", true)
                const redirectURL = sessionStorage.getItem("socialRedirectURL")
                sessionStorage.removeItem("socialRedirectURL")
                window.location.href = redirectURL
            } else {
                setShowLoader(false)
                setErrorSubmit(true)
                setErrorList([{"":t("Some_error_in_social_login")}])
            }
        })
        .catch(function (err) {
            setShowLoader(false)
            setErrorSubmit(true)
            setErrorList([{"":t("Some_error_in_social_login")}])
        })

    }

	const handleSocialLogin = (user) => {
		consoleLog(user);

		let payload = {}
		if(user._provider == "faceBook"){
			payload['type'] = 1
		}

		callLogin(payload);
	};

	const handleSocialLoginFailure = (err) => {
	  console.error(err);
	};

	const handlePaypalLogin = () => {
		sessionStorage.setItem("socialCode",2)
		sessionStorage.setItem("socialRedirectURL",window.location.href)
		window.location.href = "https://www.paypal.com/connect/?client_id=Afe70_5QDu61uFbxLPlOMOG463RMo1W5WK0CItBhx0OMjAubZnBDx_FjADI2WSQEBZ87h67Ke5baeIUT&response_type=code&scope=profile%20openid%20email&redirect_uri="+process.env.REACT_APP_FRONT_DOMAIN;
	}

	const handleGoogleLogin = () => {
		const payload = {
			type: 3
		}
		sessionStorage.setItem("socialCode",3)
		sessionStorage.setItem("socialRedirectURL",window.location.href)
		axios.post('/sociallogins?a=get', payload, config)
		.then(function (data) {
			consoleLog(data)
			if(data.data.success){
				window.location.href = data.data.redirectURL
			}
		})
	}

	useEffect(()=>{
		let url_string = decodeURI(window.location.href);
		let url = new URL(url_string);
		let scope = url.searchParams.get("scope");
		let code = url.searchParams.get("code");
		if((code && scope) && ( window.location.pathname.includes("/"+availableLanguages[0]) || window.location.pathname.includes("/"+availableLanguages[1]) ) && sessionStorage.getItem("socialCode") ){ // temporary check if the url has been loaded fully
			let payload = {}
			payload['type'] = sessionStorage.getItem("socialCode")
			payload['code'] = code
			sessionStorage.removeItem("socialCode")
			callLogin(payload)
		}
	},[])

	return (
		<div className={classes.SocialSignup}>
			<span className={classes.SocialIcon} onClick={handleGoogleLogin}>
				<img className={props.mobileVer ? classes.Black : null} src={GmailIcon} alt="" />
			</span>
			<span className={[classes.GmailLabel, classes.Label].join(' ')}>Google</span>
		    {/*<SocialButton
		      provider="facebook"
		      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
		      onLoginSuccess={handleSocialLogin}
		      onLoginFailure={handleSocialLoginFailure}
		    >
				<span className={classes.SocialIcon}>
					<img className={props.mobileVer ? classes.Black : null} src={FBIcon} alt="" />
				</span>
				<span className={[classes.FacebookLabel, classes.Label].join(' ')}>Facebook</span>
			</SocialButton>*/}
			<span className={classes.SocialIcon} onClick={handlePaypalLogin}>
				<img className={props.mobileVer ? classes.Black : null} src={PaypalIcon} alt="" />
			</span>
			<span className={[classes.PaypalLabel, classes.Label].join(' ')}>Paypal</span>
			{ErrorSubmit &&
                <ErrorBox data={{
                    errorTitle: t("Error(s)"),
                    errorSubTitle: t("please_resolve_your_errors"),
                    errors: errorList,
                    acceptText: "OK",
                    acceptAction: () => { setErrorSubmit(false) }
            	}} />
            }
			{ showLoader ? <LogoLoader /> : null }
		</div>
	);
}

export default SocialSignup;