import React from 'react';
import classes from './ContactUs.module.css';
import ContactUs from '../ContactUsBox/ContactUs';

import {HOME} from '../../URLS';

export default function Contact() {
	return (
		<div>
			<ContactUs
				hideContact={()=>{window.location.href=HOME}}
				pageDetails={"Contact us page -"}
			/>
		</div>
	);
}