import React, {useState, useEffect} from 'react'
import Jumbotron from '../Jumbotron/Jumbotron';
import classes from './Landing.module.css';
import LogoLoader from '../UI/LogoLoader/LogoLoader';

export default function Landing() {

    const [showLoader, setShowLoader] = useState(localStorage.getItem("loading")==="false" ? false : true)

    useEffect(() => {
        if(localStorage.getItem("loading")==="false"){
            //NOTHING TO DO
        }
        else{
            setTimeout(function(){
                localStorage.setItem("loading", false)
                setShowLoader(false)
            },2000);
        }
    },[])

    return (
        <div>
            <Jumbotron />
            <div className={classes.InstagramPosts}>
                <iframe src="https://cdn.lightwidget.com/widgets/d63f77903b7257d69a5d3af80d61f041.html" scrolling="no" allowtransparency="true" className="lightwidget-widget" style={{width:"100%", border:"0", overflow:"hidden", color: "white"}}></iframe>
            </div>
            { showLoader ? <LogoLoader /> : null }
        </div>
    )
}